import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { Dropdown, MenuItem } from '@components/dmp';

@autoBindMethods
export default class ReportSelect extends Component {
  static defaultProps = {
    disabled: false,
    reports: [],
    reportID: null,
    title: 'Select Report',
  };

  static propTypes = {
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    reports: PropTypes.array,
    reportID: PropTypes.string,
    title: PropTypes.string,
  };

  renderReports() {
    const { reports, reportID } = this.props;

    if (reports.length === 0) {
      return <MenuItem disabled>No saved filters</MenuItem>;
    }

    return reports.map((report, idx) => (
      <MenuItem eventKey={report} key={idx} active={reportID && reportID === report.reportID}>
        {report.title}
      </MenuItem>
    ));
  }

  render() {
    const { id, disabled, onSelect, title } = this.props;

    return (
      <Dropdown disabled={disabled} id={id} onSelect={onSelect} title={title} size="small" block>
        {this.renderReports()}
      </Dropdown>
    );
  }
}
