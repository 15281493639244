import React from 'react';

const Address = ({ address }) => { 
  const { line1, line2, city, state, postalCode, country } = address; 

  return (
    <>
      <div>{line1}</div>
      <div>{line2}</div>
      <div>{city && `${city},`} {state} {postalCode}</div>
      <div>{country}</div>
    </>
  ); 
}

export default Address;