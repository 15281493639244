import React from 'react';

import PropTypes from 'prop-types';

import { keepSpaces as replaceHTMLSpaces } from '@core/models/Content';

const Breakable = (props) => {
  let { className, keepSpaces, children: text } = props;

  // We want to allow <Breakable> elements to seamlessly wrap text with line breaks (\n) throughout the app (VariableView)
  // While gracefully degrading if the nested element is not a simple string -- so if so, just return the inner node
  if (typeof text !== 'string') return text;

  // Also ensure that multiple normal whitespaces get retained in html
  if (keepSpaces) text = replaceHTMLSpaces(text);

  const lines = text.split('\n'),
    elements = [];

  lines.map((line, idx) => {
    elements.push(line);
    if (idx + 1 < lines.length) elements.push(<br key={idx} />);
  });

  // If we have either a className or found line breaks, we need to wrap content in a span
  if (className || elements.length > 1) {
    return (
      <span className={className} data-cy={props['data-cy']}>
        {elements}
      </span>
    );
  }
  // Otherwise we can pass through and just return the normal string itself
  // without any jsx wrapping
  else {
    return elements[0];
  }
};

Breakable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  keepSpaces: PropTypes.bool,
};

Breakable.defaultProps = {
  keepSpaces: true,
  children: null,
};

export default Breakable;
