import { assign, merge, omit, pick } from 'lodash';

export const FILTER_USER_ALL = {
  filterID: 'USER_ALL',
  hardcoded: true,
  title: 'My Contracts',
};

class Filter {
  filterID = null;
  hardcoded = false;

  // If they stays null, it's a user's private filter
  teamID = null;

  searchParams = {};
  title = null;

  constructor(json) {
    assign(this, pick(json, ['filterID', 'userID', 'teamID', 'title', 'hardcoded']));

    this.searchParams = {};
    if (json.searchParams) {
      // We're not duplicating the filterID in the stored params, but we need to add it in here
      // so that SearchParams will know that it's a Search
      this.searchParams = merge({ filterID: this.filterID }, json.searchParams);
    }
  }

  get json() {
    const json = pick(this, ['title', 'userID', 'teamID']);

    // Strip filterID back off for storage
    json.searchParams = omit(this.searchParams, 'filterID');

    return json;
  }

  get isTeamFilter() {
    return !!this.teamID;
  }

  get isUserFilter() {
    return !!this.userID;
  }
}

export default Filter;
