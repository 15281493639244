import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl } from 'react-bootstrap';

import { SIDES } from '@core/models/SectionStyle';
import Source from '@core/models/Source';

import { Switch } from '@components/dmp';

import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import Fire from '@root/Fire';

@autoBindMethods
export default class CellSidebar extends Component {
  static propTypes = {
    section: PropTypes.instanceOf(Source).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleBorder(side) {
    const { section } = this.props;
    const border = section.style.borders[side];
    if (border.style === 'none') {
      border.style = 'solid';
      border.width = 1;
    } else {
      border.style = 'none';
      border.width = 0;
    }
    Fire.saveSectionStyle(section, section.style.jsonBorders);
  }

  updatePadding(side, padding) {
    const { section } = this.props;
    const numbersOnly = padding.replace(/[^\d]/g, '');
    section.style.padding[side] = numbersOnly ? parseInt(numbersOnly) : 0;
    Fire.saveSectionStyle(section, section.style.jsonPadding);
  }

  render() {
    const { section } = this.props;

    return (
      <div className="cell-sidebar" data-cy="cell-sidebar">
        <DealPanelItem borderBottom>
          <div className="property-title" data-cy="property-title">
            Formatting
          </div>
          <div className="item-label label-borders">Borders</div>
          <div className="property-row property-borders">
            {_.map(SIDES, (side) => {
              const borderStyle = _.get(section, `style.borders.${side.key}.style`);
              return (
                <div className="property-row-item" key={side.key} data-cy={side.key}>
                  <Switch
                    id={`chk-border-${side.key}`}
                    checked={borderStyle === 'solid'}
                    onChange={() => this.toggleBorder(side.key)}
                    className="item-label"
                    size="small"
                  >
                    {side.title}
                  </Switch>
                </div>
              );
            })}
          </div>

          <hr />

          <div className="item-label label-padding">Padding</div>

          <div className="property-row">
            {_.map(SIDES, (side) => (
              <div className="property-row-item item-label" key={side.key}>
                {side.title}
              </div>
            ))}
          </div>
          <div className="property-row">
            {_.map(SIDES, (side) => (
              <div className="property-row-item" key={side.key}>
                <FormControl
                  type="text"
                  placeholder="0"
                  value={_.get(section, `style.padding.${side.key}`, '') || ''}
                  onChange={(e) => this.updatePadding(side.key, e.target.value)}
                  bsSize="small"
                  data-cy={`property-row-item-${side.key}`}
                />
              </div>
            ))}
          </div>
        </DealPanelItem>
      </div>
    );
  }
}
