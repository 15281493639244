import { assign, find } from 'lodash';

import { generateNumberOptions } from '../utils/Generators';
import LayoutStyle from './LayoutStyle';

export const BORDER_STYLES = {
  SOLID: { value: 'solid', label: 'Solid' },
  //DASHED: { value: 'dashed', label: 'Dashed' },
  DOUBLE: { value: 'double', label: 'Double' },
  //DOTTED: { value: 'dotted', label: 'Dotted' },
};

export const BORDER_LOCATIONS = [
  {
    key: 'BorderTop',
    title: 'Top border',
    layoutKey: 'top',
    cssKeys: { border: 'borderTop', margin: 'marginTop' },
    align: 'horizontal',
  },
  {
    key: 'BorderBottom',
    title: 'Bottom border',
    layoutKey: 'bottom',
    cssKeys: { border: 'borderBottom', margin: 'marginBottom' },
    align: 'horizontal',
  },
  {
    key: 'BorderLeft',
    title: 'Left border',
    layoutKey: 'left',
    cssKeys: { border: 'borderLeft', margin: 'marginLeft' },
    align: 'vertical',
  },
  {
    key: 'BorderRight',
    title: 'Right border',
    layoutKey: 'right',
    cssKeys: {
      border: 'borderRight',
      margin: 'marginRight',
      align: 'vertical',
    },
    align: 'vertical',
  },
];

export const BORDER_EDITABLE_PROPERTIES = [
  //{ key: 'color', title: 'Color', values: [], units: '' },
  {
    key: 'width',
    title: 'Width',
    values: generateNumberOptions(1, 2, 1, 0),
    units: 'px',
  },
  { key: 'style', title: 'Style', values: BORDER_STYLES, units: '' },
  {
    key: 'layout',
    title: 'From  edge',
    values: generateNumberOptions(0.4, 2.1, 0.1, 1),
    units: '"',
  },
];

export default class BorderStyle {
  style;
  color;
  width;
  key;
  enabled;
  layout;
  layoutKey;
  align;

  constructor({ style = BORDER_STYLES.SOLID.value, color = '#000000', width = 1, key, enabled = false, layout = {} }) {
    assign(this, { style, color, width, key, enabled });
    const location = find(BORDER_LOCATIONS, (location) => {
      return location.key === key;
    });
    this.layoutKey = location.layoutKey;
    this.align = location.align;
    this.layout = new LayoutStyle(layout);
  }

  //The doubled property is to add the default spacing of 4px (3pt in pdf) to the second line
  inlineStyle(pageMargin, doubled = false) {
    const { border, margin } = find(BORDER_LOCATIONS, (location) => {
      return location.key === this.key;
    })?.cssKeys;

    const offset = this.layout[this.layoutKey] - pageMargin[this.layoutKey];

    return {
      [border]: `${this.width}px ${this.style === 'double' ? 'solid' : this.style} ${this.color}`,
      [margin]: `${doubled ? offset - 4 : offset}px`,
      [this.layoutKey]: pageMargin[this.layoutKey],
    };
  }

  //will be used when we start applying offsets
  //Right now just top of the page
  getOffset(pageMargin) {
    let offsets = {
      top: '0px',
      bottom: '0px',
    };
    if (this.key === 'BorderTop' || this.key === 'BorderBottom') {
      offsets = {
        left: '0px',
        right: '0px',
      };
    }
    return offsets;
  }

  //will be used when we start applying offsets example is commented out.
  getHeight(pageMargin) {
    return {
      //height: `calc(100% - ${pageMargin.bottom}px - ${pageMargin.top}px)`,
      height: '100%',
    };
  }

  getWidth(pageMargin) {
    return {
      //width: `calc(100% - ${pageMargin.left}px - ${pageMargin.right}px)`,
      width: '100%',
    };
  }
}
