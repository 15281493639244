import React, { useEffect, useRef, useState } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import * as pdfjs from 'pdfjs-dist';
import PropTypes from 'prop-types';

import { useDrop } from 'react-dnd';

import { ELEMENT_TYPE } from '@core/models/PDFElement';
import User from '@core/models/User';

import { DraggableElement, Element } from '@components/pdf-editor';
import { BORDER_SIZE, DRAG_HANDLE_SIZE } from '@components/pdf-editor/DraggableElement';
import Fire from '@root/Fire';

// That will need to be moved eslewhere, probably as a utility
const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

const getItemPosition = (itemCoordinates, pageRef, adjustToUI = false) => {
  // In case it won't be set next, let's give it some decent defaults
  const position = { y: 0, x: 0 };

  const pagePosition = pageRef.current.getBoundingClientRect();

  position.x = itemCoordinates.x - pagePosition.left;
  position.y = itemCoordinates.y - pagePosition.top;

  if (adjustToUI) {
    // Add drag handle width (substracted back in DraggableElement)
    position.x = position.x + (DRAG_HANDLE_SIZE + BORDER_SIZE);
    // Add border width (substracted back in DraggableElement)
    position.y = position.y + BORDER_SIZE;
  }

  return position;
};

const PDFPage = (props) => {
  const {
    devicePixelRatio,
    editMode,
    elements,
    id,
    onAddElement,
    onMoveElement,
    pageData,
    pageIndex,
    scale,
    setElementWidth,
    user,
    viewport,
  } = props;

  const [stateElements, setStateElements] = useState(elements);
  const [rendered, setRendered] = useState();
  const [renderedText, setRenderedText] = useState(false);
  const [textContent, setTextContent] = useState();
  const [textLayerStyle, setTextLayerStyle] = useState({});
  const prevScale = usePrevious(scale);

  const pageRef = useRef(null);
  const canvasRef = useRef(null);
  const textLayerRef = useRef(null);

  let canvasStyle = {
    width: viewport.width / devicePixelRatio,
    height: viewport.height / devicePixelRatio,
  };

  useEffect(() => {
    setStateElements([...elements]);
  }, [elements, editMode]);

  useEffect(() => {
    if ((!rendered || scale !== prevScale) && pageData && canvasRef.current) {
      const canvasContext = canvasRef.current.getContext('2d');

      canvasRef.current.height = viewport.height;
      canvasRef.current.width = viewport.width;

      canvasStyle = {
        width: viewport.width / devicePixelRatio,
        height: viewport.height / devicePixelRatio,
      };

      const renderContext = { canvasContext, viewport };
      pageData.render(renderContext);

      setRendered(true);
    }
  }, [canvasRef, scale]);

  useEffect(() => {
    if (!rendered || (textContent && scale === prevScale)) return;

    async function getTextContent() {
      const textContent = await pageData.getTextContent();
      setTextContent(textContent);
    }

    getTextContent();
  }, [rendered, scale]);

  useEffect(() => {
    if (!rendered) return;

    // Make sure that we have all the refs and values we need first
    if (!canvasRef.current || !textLayerRef.current || !textContent) return;

    // Don't make extra rendered if it's already done and the canvas size (scale) did not change
    if (textLayerStyle.width === canvasStyle.width && renderedText) return;

    setTextLayerStyle({
      width: canvasStyle.width,
      height: canvasStyle.height,
    });

    // Set it right away, otherwise, this'll trigger 4x since pdfjs.renderTextLayer takes a while to process.
    setRenderedText(true);

    // Make sure that we empty the container before rendering any new text elements
    if (textLayerRef.current) textLayerRef.current.innerHTML = '';

    async function renderText() {
      await pdfjs.renderTextLayer({
        textContent: textContent,
        container: textLayerRef.current,
        // !important: retrieve the viewpot again, with only the scale, not scale / devicePixelRatio
        viewport: pageData.getViewport({ scale }),
        textDivs: [],
      });

      // The best way found to add line breaks for paragraphs is to append brs to nodes.
      _.forEach(textLayerRef.current.childNodes, (textNode) => {
        textNode.innerHTML = textNode.innerHTML + '<br>';
      });
    }

    renderText();
  }, [textContent]);

  const [, drop] = useDrop({
    accept: 'pdfElement',
    drop(item, monitor) {
      const position = getItemPosition(monitor.getSourceClientOffset(), pageRef, true);

      onMoveElement({ id: item.id, page: pageIndex, ...position });

      return undefined;
    },
  });

  const onChangeValue = async (pdfElement, newValue) => {
    if (pdfElement.elementType === ELEMENT_TYPE.VARIABLE) {
      await Fire.saveVariable(pdfElement.deal, pdfElement.dealVariable, newValue);
    } else if (pdfElement.elementType === ELEMENT_TYPE.SIMPLE) {
      pdfElement.data = newValue;
      await Fire.savePDFElement(pdfElement);
    }
  };

  const onPageClick = (event) => {
    if (!editMode) {
      return null;
    }

    // If we did not click on the page, don't create a new element
    if (event.currentTarget !== pageRef.current) {
      return null;
    }

    const position = getItemPosition({ x: event.clientX, y: event.clientY }, pageRef);

    onAddElement(editMode, { page: pageIndex, ...position });
  };

  const classNames = cx('page-drop', { 'edit-mode': !!editMode }, { [`edit-mode-${editMode || ''}`]: !!editMode });

  // Order them by y value asc for zIndex adjustments
  const orderedElements = _.orderBy(stateElements, 'top', 'desc');

  return (
    <div
      className={classNames}
      id={id}
      onClick={onPageClick}
      ref={(ref) => {
        pageRef.current = ref;
        drop(ref);
      }}
      data-cy="page-drop"
    >
      <div id={id} className="pdf-page" data-cy="pdf-page">
        <canvas ref={canvasRef} style={canvasStyle} />
        <div ref={textLayerRef} style={textLayerStyle} className="page-text-layer"></div>
      </div>
      {_.map(orderedElements, (element, idx) => {
        const { id, pdfElement, zIndex } = element;
        const ElementComponent = pdfElement.canConfigure ? DraggableElement : Element;
        const passedProps = _.pick(element, ['id', 'left', 'top', 'value', 'pageIndex', 'width', 'isNew']);
        // Give higer proprity to fields that have a lower y value
        // Since we're ordered by y value, we can just add the index value
        passedProps.zIndex = zIndex + idx;

        return (
          <ElementComponent
            user={user}
            key={id}
            {...passedProps}
            setElementWidth={setElementWidth}
            pdfElement={pdfElement}
            onChange={(newValue) => onChangeValue(pdfElement, newValue)}
            resizable={pdfElement.canConfigure}
            scale={scale}
            readOnly={!pdfElement.canEdit}
          />
        );
      })}
    </div>
  );
};

PDFPage.defaultProps = {
  pageData: null,
  editMode: null,
  elements: [],
  devicePixelRatio: 1,
  isActive: false,
  position: {},
};

PDFPage.propTypes = {
  user: PropTypes.instanceOf(User),
  editMode: PropTypes.string,
  id: PropTypes.string.isRequired,
  pageData: PropTypes.any.isRequired,
  pageIndex: PropTypes.number,
  elements: PropTypes.any,
  onMoveElement: PropTypes.func.isRequired,
  onAddElement: PropTypes.func.isRequired,
  setElementWidth: PropTypes.func.isRequired,
  scale: PropTypes.number,
  viewport: PropTypes.any.isRequired,
  devicePixelRatio: PropTypes.number,
  isActive: PropTypes.bool,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
};

export default PDFPage;
