import * as React from 'react';

import { FormControl, FormGroup } from 'react-bootstrap';

import { ALIGNMENTS, OUTPUTS, ROTATIONS, SIZES, VALIDATION } from '@core/enums/Watermark';

import Dropdown from '@components/dmp/Dropdown';
import MenuItem from '@components/dmp/MenuItem';

import LogoUploader from '@components/LogoUploader';

//Add a divider between each row
const ALIGNMENT_OPTIONS = Object.keys(ALIGNMENTS).reduce((alignments, alignment, i) => {
  if (i > 0 && i % 3 === 0) alignments.push('-');
  alignments.push(alignment);

  return alignments;
}, []);

const WatermarkForm = ({ updateSetting, watermark }) => {
  const [rangeIsValid, setRangeIsValid] = React.useState(true);

  const validateRange = (range) => {
    if (!range.length) return true;

    const isValid = VALIDATION.test(range);

    setRangeIsValid(isValid);

    if (isValid) updateSetting('watermark', { ...watermark, range });
  };

  return (
    <>
      <label className="property-subtitle">Watermark</label>
      <FormGroup>
        <LogoUploader
          img={watermark?.image || null}
          type="watermark"
          onUpload={(image) => updateSetting('watermark', { ...watermark, image })}
        />
      </FormGroup>
      <FormGroup>
        <div className="equal-grid width-100">
          <div>
            <div className="item-label">Alignment</div>
            <Dropdown
              id="watermark-alignment"
              title={ALIGNMENTS[watermark?.alignment]}
              size="small"
              onSelect={(alignment) => updateSetting('watermark', { ...watermark, alignment })}
              block
              dataCyToggle="watermark-dd-alignment"
            >
              {ALIGNMENT_OPTIONS.map((item, i) => (
                <MenuItem
                  key={`item+${i}`}
                  eventKey={item}
                  active={watermark?.alignment === item}
                  divider={item === '-'}
                  data-cy="watermark-alignment"
                >
                  {ALIGNMENTS[item]}
                </MenuItem>
              ))}
            </Dropdown>
          </div>
          <div>
            <div className="item-label">Rotate</div>
            <Dropdown
              id="watermark-rotation"
              title={ROTATIONS[watermark?.rotation]}
              size="small"
              onSelect={(rotation) => updateSetting('watermark', { ...watermark, rotation })}
              block
              dataCyToggle="watermark-dd-rotation"
            >
              {Object.keys(ROTATIONS).map((item) => (
                <MenuItem key={item} eventKey={item} active={watermark?.rotation === item} data-cy="watermark-rotation">
                  {ROTATIONS[item]}
                </MenuItem>
              ))}
            </Dropdown>
          </div>
          <div>
            <div className="item-label">Size</div>
            <Dropdown
              id="watermark-size"
              title={SIZES[watermark?.size]}
              size="small"
              onSelect={(size) => updateSetting('watermark', { ...watermark, size })}
              pullRight={true}
              block
              dataCyToggle="watermark-dd-size"
            >
              {Object.keys(SIZES).map((item) => (
                <MenuItem key={item} eventKey={item} active={watermark?.size === item} data-cy="watermark-size">
                  {SIZES[item]}
                </MenuItem>
              ))}
            </Dropdown>
          </div>
        </div>
      </FormGroup>
      <FormGroup className="watermark-end">
        <div className="equal-grid width-100">
          <div>
            <div className="item-label">Output to</div>
            <Dropdown
              id="watermark-output"
              title={OUTPUTS[watermark?.output]}
              size="small"
              onSelect={(output) => updateSetting('watermark', { ...watermark, output })}
              block
              dataCyToggle="watermark-dd-output"
            >
              {Object.keys(OUTPUTS).map((item) => (
                <MenuItem key={item} eventKey={item} active={watermark?.output === item} data-cy="watermark-output">
                  {OUTPUTS[item]}
                </MenuItem>
              ))}
            </Dropdown>

            <div className="padding-top-small">
              {watermark?.output === 'custom' && (
                <div>
                  <FormControl
                    id="watermark-output-custom"
                    defaultValue={watermark?.range}
                    type="text"
                    bsSize="small"
                    onChange={(e) => validateRange(e.target.value)}
                    width="100%"
                    placeholder="e.g. 1-5, 8, 11-15"
                    data-cy="watermark-output-custom"
                  />
                  {!rangeIsValid && (
                    <div className="invalid margin-top-small" dmpStyle="danger">
                      Invalid page range, use e.g. <i>1-5, 8, 11-15</i>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* 
          // Omitting these controls for now to keep the customization more "on rails"
              <div>
                <ControlLabel>Opacity</ControlLabel>
                <Dropdown
                  id="watermark-end"
                  title={OPACITIES[watermark?.opacity - 0]}
                  size="small"
                  onSelect={(opacity) => updateSetting('watermark', { ...watermark, opacity })}
                  block
                >
                  {Object.keys(OPACITIES).map((item) => (
                    <MenuItem key={item} eventKey={item} active={watermark?.opacity - 0 === item}>
                      {OPACITIES[item]}
                    </MenuItem>
                  ))}
                </Dropdown>

                <div className="padding-top-small">
                  <FormControl
                    bsSize="small"
                    value="Opacity Preview"
                    disbled={true}
                    className="color-initial"
                    style={{ opacity: watermark?.opacity / 100 }}
                  />
                </div>
              </div> */}
        </div>
      </FormGroup>
    </>
  );
};

export default WatermarkForm;
