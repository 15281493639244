import React, { Component } from 'react';

import OverviewSection from '@components/section_types/OverviewSection';

export default class SummaryView extends Component {
  render() {
    const { deal } = this.props;
    if (!deal) return null;

    const { layout } = deal.style;

    //enable conditional filtering to work even at top-level
    //so that entire summary blocks can be hidden
    const sections = deal.applyConditions(deal.root.children).filter((sec) => !sec.isTemplateHeaderFooter);

    return (
      <div
        className={`inside-paper-layout deal-view ${deal.status.data.toLowerCase()}`}
        style={layout.PageMargin.getPageWebMargins(window)}
        data-cy="summary-view"
      >
        {sections.map((sec, idx) => (
          <OverviewSection {...this.props} key={sec.id} section={sec} showBranding={idx === 0 && deal.showLetterhead} />
        ))}
      </div>
    );
  }
}
