import { assign, pick } from 'lodash';

import { FILTER_USER_ALL } from './Filter';

export const REPORT_TYPES = {
  DEFAULT: 'DEFAULT', // Saved search data table results
  PIPELINE: 'PIPELINE', // User's all Deals Pipeline
};

export const REPORT_TYPES_DISPLAY = {
  [REPORT_TYPES.DEFAULT]: {
    name: 'Default',
    description: 'Report data sorted in columns',
  },
  [REPORT_TYPES.PIPELINE]: {
    name: 'Pipeline totals',
    description: 'Contracts count separated by deal status',
  },
};

export const REPORT_TYPE_CONFIG = {
  [REPORT_TYPES.DEFAULT]: {
    allowColumns: true,
    columnLimit: 4,
  },
  [REPORT_TYPES.PIPELINE]: {
    allowColumns: false,
  },
};

export const DEFAULT_REPORTS = {
  RECENT: {
    filterID: FILTER_USER_ALL.filterID,
    title: 'Recent Contracts',
    type: REPORT_TYPES.DEFAULT,
    index: 0,
  },
  CONTRACTS_PIPELINE: {
    filterID: FILTER_USER_ALL.filterID,
    title: 'My Contracts',
    type: REPORT_TYPES.PIPELINE,
    index: 1,
  },
};

export const REPORT_DEFAULT_COLS = ['status', 'name', 'updated'];

export default class Report {
  reportID = null;
  filterID = null;

  userID = null;

  teamID = null;

  title = null;

  index = 100; // Default with a high number to be sure it renders last
  type = null;
  columns = REPORT_DEFAULT_COLS;

  constructor(json) {
    assign(this, pick(json, ['index', 'filterID', 'reportID', 'teamID', 'title', 'type', 'userID']));

    if (json.columns) {
      this.columns = json.columns.split(',');
    }
  }

  get json() {
    const json = pick(this, ['index', 'filterID', 'teamID', 'title', 'type', 'userID']);

    json.columns = this.columns.join(',');

    return json;
  }

  get isTeamReport() {
    return !!this.teamID;
  }

  get isUserReport() {
    return !!this.userID;
  }
}
