import { cloneDeep, find, forEach } from 'lodash';

import { ValueType } from '../Variable';

const LABELED_FIELDS = {
  ADDRESSES: {
    name: 'Addresses',
    labels: [
      { key: '', name: 'All' },
      { key: 'home', name: 'Home' },
      { key: 'work', name: 'Work' },
      { key: 'other', name: 'Other' },
      { key: 'default', name: 'Default' },
    ],
    valueType: ValueType.ADDRESS_LABEL,
  },
  EMAILS: {
    name: 'Emails',
    labels: [
      { key: 'personal', name: 'Personal' },
      { key: 'work', name: 'Work' },
      { key: 'other', name: 'Other' },
      { key: 'default', name: 'Default' },
    ],
    valueType: ValueType.EMAILS,
  },
  PHONES: {
    name: 'Phones',
    labels: [
      { key: 'fax', name: 'Fax' },
      { key: 'home', name: 'Home' },
      { key: 'main', name: 'Main' },
      { key: 'personal_mobile', name: 'Personal Mobile' },
      { key: 'work', name: 'Work' },
      { key: 'work_mobile', name: 'Work Mobile' },
      { key: 'other', name: 'Other' },
      { key: 'default', name: 'Default' },
    ],
    valueType: ValueType.PHONES,
  },
};

export const ADDRESS_LABELS = LABELED_FIELDS.ADDRESSES.labels.map((label) => label.key);
export const EMAIL_LABELS = LABELED_FIELDS.EMAILS.labels.map((label) => label.key);
export const PHONE_LABELS = LABELED_FIELDS.PHONES.labels.map((label) => label.key);

export const addressFields = [
  {
    data: '',
    label: 'Full Address',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'line1',
    label: 'Line 1',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'line2',
    label: 'Line 2',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'city',
    label: 'City',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'state',
    label: 'State',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'postalCode',
    label: 'Postal Code',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
  {
    data: 'label',
    label: 'Label',
    description: null,
    writable: false,
    valueType: ValueType.STRING,
    multiline: true,
  },
];

const labeledFields = Object.values(LABELED_FIELDS)
  .sort((a,b) => a.name < b.name ? 1 : -1 )
  .map((field) => {
    const defaults = {
      valueType: field.valueType || 'string',
      description: null,
      writable: false,
      multiline: true, // The user COULD have, say, multiple work phone numbers
    };

    let output = [
      {
        ...defaults,
        data: field.name.toLowerCase(),
        label: `${field.name}`,
      },
      ...field.labels.filter(label => !!label.key).map((label) => ({
        ...defaults,
        data: `${field.name.toLowerCase()}_${label.key}`,
        label: `${field.name} - ${label.name}`,
      })),
    ];

    if(field.valueType === ValueType.ADDRESS_LABEL) {
      output = [
        ...output,
        ...LABELED_FIELDS.ADDRESSES.labels
        .map((label) =>
          addressFields
            .map((address) => {
              
              let dataKey = 'addresses';

              if(!address.data && label?.key) {
                dataKey += `_${label.key}`
              } else if(label?.key && address?.data) { 
                dataKey += `_${label.key}_${address.data}`;
              } else if(address?.data) {
                dataKey += `_${address.data}`
              } else if(label?.key) {
                dataKey += `_${label.key}`
              } 

              return {
              ...defaults,
              data:  dataKey,
              label: `${label.name} - ${address.label}`,
              valueType: ValueType.ADDRESS,
            };})
        )
        .flat(),
      ]
    }

    return output;
  })
  .reduce((group, acc) => (acc = [...acc, ...group]), []);

// https://developer.filevine.io/docs/v2/6b4cf68d0eccc-get-contact-metadata
// There are inconsistencies/omissions in FV's Contact field definition,
// as it excludes a few fields (even though they're standard/supported)
// So we're defining these base fields, but then merging in the rest via API
const baseFields = [
  // Added manually -- no property selection will be transformed to the Contact's fullName
  {
    data: null,
    label: 'Full Name (default)',
    writable: false,
    valueType: 'string',
  },

  // Case issues -- birthdate
  {
    data: 'birthDate',
    label: 'Birthday',
    description: null,
    writable: true,
    valueType: 'date',
  },

  // Not returned in API call
  {
    data: 'deathDate',
    label: 'Deathday',
    description: null,
    writable: true,
    valueType: 'date',
  },

  // Not returned in API call
  {
    data: 'isDeceased',
    label: 'Is Deceased',
    description: null,
    writable: true,
    valueType: 'list',
    prompt: 'Yes\nNo',
  },

  ...labeledFields,
];

// This is necessary to still support imports prior to API call returning and merging
let fields = cloneDeep(baseFields);

export const mergeFields = (customFields) => {
  fields = cloneDeep(baseFields);

  forEach(customFields, (field) => {
    // Some legacy issue causes a duplicate "birthdate" field to be returned with the wrong case
    // Ignore this one so that the correct base field we've defined is used. I know. Fuck.
    if (field.data === 'birthdate') return;

    if (field.data === 'addresses') {
      fields.push({
        ...field,
        valueType: ValueType.ADDRESS,
      });
    } else {
      const base = find(baseFields, { data: field.data });
      if (!base) fields.push(field);
    }
  });
};

export const contactFields = () => fields;
