import React, { Component } from 'react';

import _ from 'lodash';

import { Breakable } from '@components/dmp';

const UserField = (props) => (
  <div className="table-inner" data-test={`profile-${props.type}`}>
    <div className="s-mob-half s-tab-onethird bold">{props.field}</div>
    <div className="s-mob-half s-tab-twothird">{props.children}</div>
  </div>
);

export default class UserInfo extends Component {
  render() {
    const { user } = this.props,
      info = _.get(user, 'info', {});

    return (
      <div className="table-outer">
        <UserField field="Name" type="fullName">
          {info.fullName}
        </UserField>
        <UserField field="Email" type="email">
          {info.email}
        </UserField>
        <UserField field="Organization" type="org">
          {info.org}
        </UserField>
        <UserField field="Title" type="title">
          {info.title}
        </UserField>
        <UserField field="Phone" type="phone">
          {info.phone}
        </UserField>
        <UserField field="Address" type="address">
          <Breakable>{info.addressProperties?.fullAddress || info.address}</Breakable>
        </UserField>
      </div>
    );
  }
}
