import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import SERVICES from '@core/enums/IntegrationServices';
import Deal from '@core/models/Deal';
import { buildHydratedFields } from '@core/models/DealConnection';
import Team from '@core/models/Team';
import { dt } from '@core/utils/Environment';

import { Button, Icon } from '@components/dmp';
import DropdownDots from '@components/dmp/DropdownDots';

import ConnectionModal from '@components/deal/DealHeader/ConnectionModal';
import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import CONFIG from '@root/Config';

const MenuItem = DropdownDots.MenuItem;

const { DEFAULT_CONNECTIONS } = CONFIG;

@autoBindMethods
export default class ConnectionView extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    dealTeam: PropTypes.instanceOf(Team).isRequired,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      action: null,
      selectedConnection: null,
    };
  }

  // Filter list of IntegrationService to only show the ones that have UI enabled,
  // and where this deal's team is configured with an active integration (otherwise the connection will fail)
  // Also inject any instance-level default connection
  get enabledServices() {
    const { dealTeam } = this.props;
    const services = [];

    if (DEFAULT_CONNECTIONS) {
      DEFAULT_CONNECTIONS.forEach((connectionType) => {
        let service = SERVICES.find(({ key }) => key === connectionType);
        if (!service) {
          // Generate a default one if not found in the services
          service = {
            key: connectionType,
            name: connectionType,
            idFields: [{ key: 'id', label: 'ID', name: '', required: true }],
          };
        }
        services.push(service);
      });
    }

    _.forEach(SERVICES, (service) => {
      if (_.get(dealTeam, `integrations.${service.key}`) && service.enableUI) {
        services.push(service);
      }
    });

    return services;
  }

  get unusedServices() {
    const dealConnections = _.map(this.props.deal.connections, 'type');
    return this.enabledServices.filter((service) => service.multi || !dealConnections.includes(service.key));
  }

  handleModel({ action, connection }) {
    this.setState({
      action,
      selectedConnection: connection,
    });
  }

  reset() {
    this.setState({
      action: null,
      selectedConnection: null,
    });
  }

  renderConnection(connection, index) {
    let keyId = `${index}-${connection.key}-${connection.id}`;
    const service = connection.service;
    const idFields = buildHydratedFields(service, connection.idFields);

    // buildHydratedFields fills the service's defined titles on the stored ID for pretty display
    // But it will omit any undefined IDs, such as dynamically designated collection items
    // So we need to add those back here to show them in the panel
    _.forEach(connection.idFields, (value, key) => {
      if (!_.find(idFields, { key }) && value) idFields.push({ key, label: `${key} ID`, value });
    });

    return (
      <DealPanelItem borderBottom className={cx('connect-panel', 'pending')} key={keyId} data-cy="connect-panel">
        <div className="connect-container">
          <div className="connect-item">
            <div className="connect-item-row">
              {service && (
                <div className="left-section">
                  <Icon name={service.icon || 'connect'} className={service.iconClassName} />
                </div>
              )}
              <div className="right-section">{connection.type && <span>{connection.type}</span>}</div>
              <div className="dropdown-right-align">
                <DropdownDots
                  pullRight
                  onSelect={this.handleModel}
                  id={`${keyId}-dots`}
                  dataCyToggle="connection-dd-toggle"
                >
                  <MenuItem key="update" eventKey={{ action: 'update', connection }} data-cy="edit-connection">
                    Edit
                  </MenuItem>
                  <MenuItem key="delete" eventKey={{ action: 'delete', connection }} data-cy="delete-connection">
                    Delete
                  </MenuItem>
                </DropdownDots>
              </div>
            </div>

            {connection.source && (
              <div className="connect-item-row">
                <div className="row-left-item">Source</div>
                <a
                  className="row-right-item connect-source-link"
                  target="_blank"
                  rel="noreferrer"
                  href={connection.source}
                >
                  View in {connection.type}
                </a>
              </div>
            )}

            {connection.objectType && (
              <div className="connect-item-row">
                <div className="row-left-item">Object type</div>
                <span className="row-right-item">{connection.objectType}</span>
              </div>
            )}

            {_.map(
              idFields,
              (field) =>
                !!field.value && ( //don't render unpopulated idFields
                  <div key={field.key} className="connect-item-row">
                    <div className="row-left-item">{_.capitalize(field.label)}</div>
                    <span className="row-right-item">{field.value}</span>
                  </div>
                )
            )}
          </div>
        </div>
      </DealPanelItem>
    );
  }

  render() {
    const { deal, container, show, onHide, target, title, dealTeam } = this.props;
    const { action, selectedConnection } = this.state;

    return (
      <DealPanel id={'connections'} onHide={onHide} show={show} target={target} title={title} container={container}>
        <div className="filter-bar">
          <span className="connection-count" data-cy="connection-count">
            {deal.connections.length} connection{deal.connections.length > 1 ? 's' : ''}{' '}
          </span>
          <TooltipButton
            disabled={false}
            placement="bottom"
            tip={`Adds a new connection from your ${dt} to an external service`}
          >
            <Button
              size="small"
              className={cx({ disabled: false })}
              onClick={() => this.handleModel({ action: 'create', connection: null })}
              data-cy="btn-add-connection"
              disabled={!this.unusedServices.length}
            >
              Add Connection
            </Button>
          </TooltipButton>
        </div>

        <div
          className={
            deal.connections.length < 1
              ? `connection-list panel-scroll connect-empty-panel`
              : `connection-list panel-scroll`
          }
          data-cy={deal.connections.length < 1 ? 'connect-empty-panel' : 'connection-list'}
        >
          {_.map(deal.connections, this.renderConnection)}
          {deal.connections.length < 1 ? <div>No active connections</div> : null}
        </div>

        <ConnectionModal
          show={!!action}
          deal={deal}
          dealTeam={dealTeam}
          enabledServices={this.enabledServices}
          selectedConnection={selectedConnection}
          action={action}
          onClose={this.reset}
        />
      </DealPanel>
    );
  }
}
