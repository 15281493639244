import React from 'react';

import PropTypes from 'prop-types';

import { useDrag } from 'react-dnd';

import PDFElement from '@core/models/PDFElement';
import User from '@core/models/User';

import { Element } from '@components/pdf-editor';

export const DRAG_HANDLE_SIZE = 18;
export const BORDER_SIZE = 2;

const DraggableElement = (props) => {
  const { user, id, left, top, title, page, setElementWidth, width, pdfElement, zIndex, ...passingProps } = props;

  const [{ isDragging }, dragHandleRef, dragPreviewRef] = useDrag({
    // Define the item that will be passed to drag'n'drop events
    // Important! Use the minimum to avoid unecessary memory consumption, keep things snappy
    item: { id, left, top, type: 'pdfElement', title, page },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    previewOptions: {
      // Important! This ensure that we can tweak styling before
      // dnd takes the drag screenshot.
      captureDraggingState: true,
    },
  });

  const realLeft = left - (DRAG_HANDLE_SIZE + BORDER_SIZE);
  const realTop = top - BORDER_SIZE;

  return (
    <div style={{ left: realLeft, top: realTop /*, zIndex*/ }} className="element-drag" data-cy="element-drag">
      <div ref={dragPreviewRef}>
        <Element
          id={id}
          user={user}
          isDragging={isDragging}
          dragHandleRef={dragHandleRef}
          draggable
          text={title}
          width={width}
          onResize={(width) => setElementWidth(id, width)}
          pdfElement={pdfElement}
          style={{ zIndex }}
          {...passingProps}
        />
      </div>
    </div>
  );
};

DraggableElement.defaultProps = {
  left: 0,
  top: 0,
  title: '',
  page: null,
  width: 0,
  zIndex: 1,
};

DraggableElement.propTypes = {
  user: PropTypes.instanceOf(User),
  id: PropTypes.string.isRequired,
  left: PropTypes.number,
  top: PropTypes.number,
  title: PropTypes.string,
  page: PropTypes.number,
  setElementWidth: PropTypes.func.isRequired,
  width: PropTypes.number,
  pdfElement: PropTypes.instanceOf(PDFElement),
  zIndex: PropTypes.number,
};

export default DraggableElement;
