import { assign, map, merge, pick } from 'lodash';

import TableColumn from './/TableColumn';
import Variable, { CONNECTED_VAR_FIELDS, ValueType, VariableType } from './Variable';

// DataSource is like a detatched Variable, meaning a Variable definition that is not part of the Deal.variables store.
// It is currently used to enable Repeater sections to define and configure their own data structure,
// without sharing that structure with other Repeaters
// For example, this enables multiple Repeaters to pull from the same external collection (e.g., Expenses)
// but format their fields (TableColumns) differently without affecting each other

// The Variable constructor is extended here in order to fix instantiation and not rely on a subsequent assign
// (See Variable instantiation in Deal.constructor with subsequent assign,
// which was done this way to support inferred variables, but here we always have stored json data,
// so we can simplify and just do a normal instantiation; see List.constructor
export default class DataSource extends Variable {
  section;

  constructor(json, section) {
    const props = merge(
      {},
      pick(json, CONNECTED_VAR_FIELDS, {
        type: VariableType.CONNECTED,
        valueType: ValueType.TABLE,
        deal: section.deal,
      })
    );

    super(props);

    assign(this, props);

    this.section = section;
    this.deal = section.deal;
    this._columns = map(json.columns, (colJSON) => new TableColumn(colJSON, this));
  }
}
