import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import { findFilter } from '@core/models/FilterStore';
import Report, { REPORT_TYPES, REPORT_TYPES_DISPLAY, REPORT_TYPE_CONFIG } from '@core/models/Report';
import { DEFAULT_COLS } from '@core/models/SearchParams';
import Team from '@core/models/Team';
import User from '@core/models/User';

import { Button, Dropdown, MenuItem } from '@components/dmp';

import ColumnTag from '@components/reports/ColumnTag';
import ColumnsManager from '@components/reports/ColumnsManager';
import ReportSelect from '@components/reports/ReportSelect';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

@autoBindMethods
export default class ColumnReport extends Component {
  static defaultProps = {
    dealTemplate: null,
    onSave: _.noop,
    report: null,
  };

  static propTypes = {
    availableReports: PropTypes.arrayOf(PropTypes.instanceOf(Report)),
    onClose: PropTypes.func.isRequired,
    dealTemplate: PropTypes.instanceOf(Deal),
    report: PropTypes.instanceOf(Report),
    onSave: PropTypes.func,
    show: PropTypes.bool.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
  };

  constructor(props) {
    super(props);

    const report = props.report || new Report({});

    this.state = {
      dealTemplate: props.dealTemplate,
      report,
      reportID: report.reportID, // Allow updating an existing Report and also prevent from modifying the Filter
      selectedColumns: [...report.columns],
      title: report.title || '',
      type: report.type,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadRelations();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get configOptions() {
    return REPORT_TYPE_CONFIG[_.get(this.state, 'type', null)];
  }

  get isSubmitDisabled() {
    const { report, title, type } = this.state;
    return !report.filterID || !title || !type;
  }

  async loadRelations() {
    const { user, teams } = this.props;
    const {
      report: { filterID, teamID },
    } = this.state;

    // It's possible (especially on Report creation) that we don't have the dealTemplate
    // to configure variable columns, so let's load it.
    if (filterID) {
      const filter = findFilter({ user, teams, filterID, teamID });
      if (_.get(filter, 'searchParams.sourceTemplateKey')) {
        const dealTemplate = await this.loadTemplate(filter);
        if (this._isMounted) this.setState({ dealTemplate });
      }
    }
  }

  loadTemplate(filter) {
    const { dealTemplate } = this.state;

    return new Promise(async (resolve) => {
      const { searchParams } = filter;

      // This is somewhat annoying, but since we only have the sourceTemplateKey (via URL),
      // we first need another (very fast) API call to look up the dealID
      const [teamID, key] = searchParams.sourceTemplateKey.split(':');

      //It's possible that we already have that dealTemplated loaded in (Configuring an existing report)
      if (key === _.get(dealTemplate, 'dealID')) {
        resolve(dealTemplate);
        return;
      }

      // If we don't have it yet, search it
      const dealRecord = await API.call('getTeamTemplates', { teamID, key });
      const dealID = _.get(dealRecord, '[0].dealID');
      if (!dealID) {
        resolve(null);
        return;
      }

      // Finally, fetch the full Deal object and store in state to pass to children
      const filteredDealTemplate = await Fire.getDeal(dealID);
      resolve(filteredDealTemplate);
    });
  }

  handleSave() {
    const { onClose, onSave } = this.props;
    const { reportID, report, type, title, selectedColumns } = this.state;

    // Out of the state properties, update the Report model, then pass it back for saving.
    const savedReport = new Report({
      ...report.json,
      reportID, // If the report exist, re-apply it's ID so that it gets updated.
      type,
      title,
      columns: selectedColumns.join(','),
    });

    onSave(savedReport);
    onClose();
  }

  onColumnsChange(columns = []) {
    this.setState({ selectedColumns: columns });
  }

  handleColumnToggle(col, add) {
    const { selectedColumns } = this.state;
    const colIdx = selectedColumns.indexOf(col);
    const { columnLimit } = this.configOptions;

    if (add) {
      // Make sure we don't bust the limit
      if (!!columnLimit && selectedColumns.length >= columnLimit) return;

      if (colIdx === -1) {
        selectedColumns.push(col);
        this.setState({ selectedColumns });
      }
    } else {
      if (colIdx > -1) {
        selectedColumns.splice(colIdx, 1);
        this.setState({ selectedColumns });
      }
    }
  }

  async handleSelectFilter(newReport = null) {
    await this.setState({
      report: newReport,
      title: _.get(newReport, 'title', ''),
      type: null,
      dealTemplate: null,
    });

    // Since the filter changed, we might need to reload the dealTemplate
    this.loadRelations();
  }

  handleSelectType(type) {
    this.setState({ type });
  }

  renderColumnTag(key, idx) {
    const { dealTemplate } = this.state;
    const added = this.state.selectedColumns.includes(key);

    return (
      <li key={idx}>
        <ColumnTag checked={added} columnKey={key} dealTemplate={dealTemplate} onChange={this.handleColumnToggle} />
      </li>
    );
  }

  renderAvailableColumns() {
    const { dealTemplate } = this.state;

    if (!_.get(this.configOptions, 'allowColumns')) return null;

    const { columnLimit } = this.configOptions;
    const varCols = !dealTemplate ? [] : _.map(dealTemplate.filterableVariables, (v) => `v.${v.name}`);

    return (
      <div className="available-columns" data-cy="available-columns">
        {columnLimit && <div className="list-title">Select a maximum of {columnLimit} columns</div>}

        {!!varCols.length && <div className="list-title">Template columns</div>}

        {!!varCols.length && <ul className="cols template-cols">{varCols.map(this.renderColumnTag)}</ul>}

        <div className="list-title">Default columns</div>
        <ul className="cols default-cols">{DEFAULT_COLS.map(this.renderColumnTag)}</ul>
      </div>
    );
  }

  renderConfiguration() {
    const { dealTemplate, selectedColumns, report } = this.state;

    if (!this.configOptions) return null;

    return (
      <>
        {this.configOptions.allowColumns && (
          <ColumnsManager
            dealTemplate={dealTemplate}
            defaultColumns={report.columns}
            columns={selectedColumns}
            onChange={this.onColumnsChange}
            onRemoveColumn={(col) => this.handleColumnToggle(col, false)}
          />
        )}
      </>
    );
  }

  renderReportFields() {
    const { availableReports } = this.props;
    const { reportID, report, title, type } = this.state;

    return (
      <>
        <FormGroup controlId="reportFilter" data-cy="saved-report-filter">
          <ControlLabel>Saved filter</ControlLabel>
          <p>Select a saved filter as the basis of the report</p>
          <ReportSelect
            disabled={!!reportID}
            id="dd-config-report-filter"
            data-cy="dd-config-report-filter"
            title={_.get(report, 'title') || 'Select filter...'}
            reports={availableReports}
            reportID={report.reportID}
            onSelect={this.handleSelectFilter}
          />
        </FormGroup>
        <FormGroup controlId="reportFilter" data-cy="report-title">
          <ControlLabel>Title of dashboard report</ControlLabel>
          <FormControl
            bsSize="small"
            name="title"
            disabled={!report.filterID}
            onChange={(e) => this.setState({ title: e.target.value })}
            type="text"
            value={title}
            data-cy="report-title-input"
          />
        </FormGroup>
        <FormGroup controlId="reportType" data-cy="report-type">
          <ControlLabel>Type of report</ControlLabel>
          <Dropdown
            block
            id="dd-report-type"
            className="dd-report-type"
            disabled={!report.filterID}
            title={_.get(REPORT_TYPES_DISPLAY[type], 'name') || 'Display report as...'}
            size="small"
            onSelect={this.handleSelectType}
          >
            {_.map(REPORT_TYPES, (typeKey, key) => (
              <MenuItem
                eventKey={typeKey}
                key={key}
                active={typeKey === type}
                info={_.get(REPORT_TYPES_DISPLAY[typeKey], 'description')}
              >
                {_.get(REPORT_TYPES_DISPLAY[typeKey], 'name')}
              </MenuItem>
            ))}
          </Dropdown>
        </FormGroup>
      </>
    );
  }

  render() {
    const { show, onClose } = this.props;
    const { report } = this.state;

    if (!show) return null;

    return (
      <Modal dialogClassName="modal-report-config" data-cy="modal-report-config" show={true} onHide={onClose}>
        <Modal.Header closeButton>
          <span className="headline" data-cy="headline">
            Configure {!report.reportID ? 'new ' : null}report
          </span>
        </Modal.Header>
        <Modal.Body>
          <div className="two-col" data-cy="two-col">
            <div className="col col- col-configuration" data-cy="col-configuration">
              {this.renderReportFields()}
              {this.renderAvailableColumns()}
            </div>

            <div className="col col-type-configuration" data-cy="col-type-configuration">
              {this.renderConfiguration()}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="spacer" data-cy="spacer" />
          <Button
            dmpStyle="primary"
            onClick={this.handleSave}
            disabled={this.isSubmitDisabled}
            data-cy="btn-create-report"
          >
            {!report.reportID ? 'Create report' : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
