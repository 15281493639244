// TODO: Discuss putting API Errors in their own namespace Like API/Error/APIError
export default class APIError extends Error {
  constructor(code, error) {
    super(error);

    if (typeof code === Object) {
      this.code = code.code;
      this.error = code.error;
    } else {
      this.code = code;
      // TODO: Discuss changing the property name from error to message, to match JavaScript Error class
      // in case we want to extend it.
      this.error = error;
    }
  }
}
