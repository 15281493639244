import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { Dropdown as BSDropdown } from 'react-bootstrap';

import { classNamePrefixer } from '@core/utils/Generators';

const cl = classNamePrefixer('dd-menu');

const DropdownMenu = React.forwardRef(({ className, ...passingProps }, ref) => {
  const classNames = cx(cl(), className);

  return <BSDropdown.Menu {...passingProps} role="menu" className={classNames} ref={ref} />;
});

DropdownMenu.displayName = 'DropdownMenu';

DropdownMenu.defaultProps = {
  bsRole: 'menu',
};

DropdownMenu.propTypes = {
  className: PropTypes.string,
};

export default DropdownMenu;
