import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Overlay } from 'react-bootstrap';

import DealRecord from '@core/models/DealRecord';
import { REALTIME_EVENTS } from '@core/models/DealRecord';
import { TAG_MAX_LENGTH } from '@core/models/Tag';
import { ARCHIVED_TAG } from '@core/models/TagStore';
import { dt } from '@core/utils/Environment';

import { Button, ButtonIcon, Ellipsis, Form, Icon, Loader, Popover } from '@components/dmp';

import API from '@root/ApiClient';
import Fire from '@root/Fire';

@autoBindMethods
export default class Tagger extends Component {
  static defaultProps = {
    onTagsUpdated: _.noop,
  };

  static propTypes = {
    dealRecord: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    container: PropTypes.object,
    getPosition: PropTypes.func,
    target: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    onHide: PropTypes.func.isRequired,
    onTagsUpdated: PropTypes.func,
  };

  constructor(props) {
    super(props);
    const { user } = props;

    this.state = {
      tagsLoaded: false,
      managing: false,
      creating: false,
      editingTag: null,
      allTags: user.tags,
      newTag: '',
    };
  }

  componentDidMount() {
    this.loadTags();
  }

  reset() {
    this.setState({
      managing: false,
      creating: false,
      editingTag: null,
      deletingTag: null,
      newTag: '',
    });
  }

  async create() {
    await this.setState({ creating: true });
    const el = ReactDOM.findDOMNode(this.refs.newTag);
    if (el) el.focus();
  }

  async edit(editingTag) {
    this.setState({ editingTag });
    const el = ReactDOM.findDOMNode(this.refs.editingTag);
    if (el) el.focus();
  }

  updateTagText(e) {
    const { editingTag } = this.state;
    editingTag.tag = e.target.value;
    this.setState({ editingTag });
  }

  isTagValid(text) {
    return !!text.trim() && text.length <= TAG_MAX_LENGTH;
  }

  async loadTags() {
    const counts = await API.call('getUserTags');

    this.setState({
      allTags: this.state.allTags.mergeCounts(counts),
      tagsLoaded: true,
    });
  }

  async toggleTag(tag) {
    const { user, dealRecord, onTagsUpdated } = this.props;
    const { allTags } = this.state;

    const hasTag = dealRecord.tags.indexOf(tag.tagID) > -1;
    const realtimeEvent = hasTag ? REALTIME_EVENTS.UNTAG : REALTIME_EVENTS.TAG;

    if (!hasTag) dealRecord.tags.push(tag.tagID);
    else dealRecord.tags.splice(dealRecord.tags.indexOf(tag.tagID), 1);

    // This will kick off an update cloud function and re-index the DealRecord with new tags
    Fire.updateDealUserTags(user.id, dealRecord.dealID, dealRecord.tags);

    // Indexing will take a few seconds, so we want to update state immediately in this component,
    // as well as pass notification to parent component to update visual display
    tag.count = hasTag ? tag.count - 1 : tag.count + 1;
    onTagsUpdated(dealRecord, tag.tag, realtimeEvent);
    return await this.setState({ allTags });
  }

  async createTag() {
    const { allTags, newTag } = this.state;
    const { user } = this.props;

    if (!this.isTagValid(newTag)) return;

    // First create and save the new tag in the TagStore (on User)
    const tag = allTags.create(newTag.trim());
    await Fire.saveTag(user.id, tag);
    await this.toggleTag(tag);
    this.setState({ newTag: '', creating: false });
  }

  async saveTag() {
    const { editingTag } = this.state;
    const { user } = this.props;

    if (!this.isTagValid(editingTag.tag)) return;
    editingTag.tag = editingTag.tag.trim();

    await Fire.saveTag(user.id, editingTag);
    this.setState({ editingTag: null });
  }

  async deleteTag() {
    const { deletingTag, allTags } = this.state;
    const { user } = this.props;

    // IMPORTANT: This whole process should happen on the server side.
    // To whoever read this code, you've been summoned to fulfill this task !

    // First, we need to fetch all deals that have this tag
    // (Need to separately fetch those that are archived or not; search API currently doesn't support both at once)
    const dealsWithTagResult = await API.call('getDeals', {
      hitsPerPage: 1000,
      tags: [deletingTag.tagID],
      getAll: true,
    });
    const dealsToUntag = _.map(dealsWithTagResult.hits, (record) => new DealRecord(record, user.id));

    // Go through each and remove the tag. Cloud Functions and Watcher will take care of removing it from search index
    _.forEach(dealsToUntag, async (dealRecord) => {
      dealRecord.tags.splice(dealRecord.tags.indexOf(deletingTag.tagID), 1);
      await Fire.updateDealUserTags(user.id, dealRecord.dealID, dealRecord.tags);
    });

    // Now delete the actual tag from the User model
    await Fire.deleteTag(user.id, deletingTag.tagID);

    // And remove from state so that display updates immediately
    allTags.splice(allTags.indexOf(deletingTag), 1);

    this.setState({ deletingTag: null, allTags });
  }

  getTarget() {
    const { dealRecord, target } = this.props;
    if (typeof target === 'function') return target(dealRecord.dealID);
    return target;
  }

  render() {
    const { dealRecord, onHide, container } = this.props;
    const { tagsLoaded, allTags, creating, newTag, editingTag, managing, deletingTag } = this.state;
    const showError =
      (editingTag && editingTag.tag.length > TAG_MAX_LENGTH) || (creating && newTag.length > TAG_MAX_LENGTH);

    return (
      <Overlay
        shouldUpdatePosition
        rootClose
        show={true}
        placement="left"
        onHide={onHide}
        target={this.getTarget}
        container={container}
      >
        <Popover
          className="popover-tagger"
          id={`pop-tagger-${dealRecord.dealID}`}
          title={creating ? 'Create new tag' : 'Tags'}
          onClick={(e) => e.stopPropagation()}
          data-cy="tag-popover"
        >
          <Form data-cy="form-edit-tag">
            {!creating && !editingTag && (
              <FormGroup className="available-tags" data-cy="available-tags">
                {tagsLoaded && allTags.map(this.renderTag)}
              </FormGroup>
            )}

            {!tagsLoaded && <Loader centered size="small" />}

            {creating && (
              <FormGroup className="creating-tag">
                <FormControl
                  type="text"
                  value={newTag}
                  placeholder="Add a new tag"
                  ref="newTag"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') this.createTag();
                  }}
                  onChange={(e) => this.setState({ newTag: e.target.value })}
                  data-cy="creating-tag"
                />
              </FormGroup>
            )}

            {editingTag && (
              <FormGroup className="editing-tag">
                <FormControl
                  type="text"
                  value={editingTag ? editingTag.tag : ''}
                  placeholder="Edit tag"
                  ref="editTag"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') this.saveTag();
                  }}
                  onChange={this.updateTagText}
                  data-cy="editing-tag"
                />
              </FormGroup>
            )}

            {showError && <small className="error">{`Tags are limited to ${TAG_MAX_LENGTH} characters or less`}</small>}

            {creating && (
              <div className="actions creating">
                <Button className="cancel" size="small" dmpStyle="link" onClick={this.reset} data-cy="btn-cancel">
                  Cancel
                </Button>
                <div className="spacer" />
                <Button
                  className={cx({ add: this.isTagValid(newTag) })}
                  size="small"
                  dmpStyle="link"
                  disabled={!this.isTagValid(newTag)}
                  onClick={this.createTag}
                  data-cy="btn-save"
                >
                  Save
                </Button>
              </div>
            )}

            {editingTag && (
              <div className="actions editing">
                <Button
                  className="cancel"
                  size="small"
                  dmpStyle="link"
                  onClick={this.reset}
                  data-cy="btn-cancel-editing-tag"
                >
                  Cancel
                </Button>
                <div className="spacer" />
                <Button
                  className={cx({ add: this.isTagValid(editingTag.tag) })}
                  size="small"
                  dmpStyle="link"
                  disabled={!this.isTagValid(editingTag.tag)}
                  onClick={this.saveTag}
                  data-cy="btn-save"
                >
                  Save
                </Button>
              </div>
            )}

            {managing && !editingTag && !deletingTag && (
              <div className="actions managing">
                <Button
                  className="cancel"
                  size="small"
                  dmpStyle="link"
                  onClick={this.reset}
                  data-cy="cancel-manage-tags"
                >
                  Back
                </Button>
              </div>
            )}

            {!creating && !managing && (
              <div className="actions">
                {allTags.length > 1 && ( // If only 1 tag, it's the special "Archived" tag so there's nothing to manage
                  <Button
                    className="manage"
                    size="small"
                    dmpStyle="link"
                    onClick={() => this.setState({ managing: true })}
                    data-cy="btn-manage-tag"
                  >
                    Manage tags
                  </Button>
                )}
                <div className="spacer" />
                <Button className="add" size="small" dmpStyle="link" onClick={this.create} data-cy="btn-add-tag">
                  Add tag
                </Button>
              </div>
            )}
          </Form>
        </Popover>
      </Overlay>
    );
  }

  renderTag(tag, idx) {
    const { managing, deletingTag } = this.state;
    const { dealRecord } = this.props;
    const active = dealRecord.tags.indexOf(tag.tagID) > -1;
    const isArchivedTag = tag.tagID === ARCHIVED_TAG.tagID;
    const deleting = deletingTag === tag;

    return (
      <div
        key={idx}
        className={cx('deal-tag', { active: active && !managing, managing, deleting })}
        onClick={() => (managing ? null : this.toggleTag(tag))}
        data-cy="deal-tag-manager"
      >
        <div className="tag-meta">
          <Icon name="tag" className="tag-icon" />
          <span className="tag-name">
            <Ellipsis> {tag.tag} </Ellipsis>
          </span>
          {managing && <span className="tag-count">({tag.count})</span>}
        </div>

        <div className="spacer" />

        {!deletingTag && (
          <div className="tag-actions">
            {active && !managing && !isArchivedTag && <Icon name="check" className="check" />}
            {managing && !isArchivedTag && (
              <ButtonIcon icon="deal" data-cy="icon-edit-tag" onClick={() => this.edit(tag)}></ButtonIcon>
              // <Icon name="deal" className="edit" onClick={() => this.edit(tag)} />
            )}
            {managing && !isArchivedTag && (
              <ButtonIcon
                data-cy="icon-delete-tag"
                icon="trash"
                className="trash"
                iconOnly={true}
                onClick={() => this.setState({ deletingTag: tag })}
              ></ButtonIcon>
            )}
          </div>
        )}

        {deleting && (
          <div className="delete-confirm">
            <div className="instructions">Deleting this tag will remove it from all of your {dt}s. Are you sure?</div>
            <div className="actions deleting">
              <Button
                dmpStyle="link"
                className="cancel"
                size="small"
                onClick={() => this.setState({ deletingTag: null })}
                data-cy="btn-cancel-delete-tag"
              >
                Cancel
              </Button>
              <Button dmpStyle="link" className="delete" size="small" onClick={this.deleteTag} data-cy="btn-delete-tag">
                Delete permanently
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}
