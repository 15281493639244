import React, { Component } from 'react';

import cx from 'classnames';

export default class EditorStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: null,
      type: 'info',
    };
  }

  showMessage(message, type, hide) {
    this.setState({ message, type, hide });
  }

  render() {
    const { hide, message, type } = this.state;

    if (!message) return null;

    return (
      <div className="status-message" data-cy="status-message">
        <span className={cx(type, { 'hide-message': hide })}>{message}</span>
      </div>
    );
  }
}
