import React from 'react';

import PropTypes from 'prop-types';

import Deal from '@core/models/Deal';

import { Checkbox, Ellipsis, Tag } from '@components/dmp';

import { findColumn } from '../deal/Columns';

const ColumnTag = ({ columnKey, checked, dealTemplate, onChange }) => {
  const column = findColumn(columnKey, dealTemplate);
  if (!column) return null;

  return (
    <Tag block>
      <Checkbox
        block
        bold
        checked={checked}
        name={column.key}
        id={`check-column-${column.key}`}
        onChange={({ target }) => onChange(target.name, target.checked)}
      >
        <Ellipsis>{column.Header}</Ellipsis>
      </Checkbox>
    </Tag>
  );
};

ColumnTag.defaultProps = {
  checked: false,
};

ColumnTag.propTypes = {
  checked: PropTypes.bool,
  columnKey: PropTypes.string.isRequired,
  dealTemplate: PropTypes.instanceOf(Deal),
  onChange: PropTypes.func.isRequired,
};

export default React.memo(ColumnTag);
