import React, { Component } from 'react';

import autobindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Overlay, Radio } from 'react-bootstrap';

import CheckpointGroup from '@core/models/CheckpointGroup';
import { sanitize } from '@core/models/Variable';
import WorkflowStep, { CHECKPOINT_GROUP_APPROVAL_RULE, STEP_COLORS, STEP_TYPES } from '@core/models/WorkflowStep';

import { Button, Dropdown, Form, MenuItem, Popover, Switch, Validator } from '@components/dmp';

import ColorPicker from '@components/ColorPicker';
import TooltipButton from '@components/editor/TooltipButton';

const MAX_NAME_LENGTH = 12;
@autobindMethods
class WorkflowStepEditor extends Component {
  static defaultProps = {
    onSave: _.noop,
  };

  static propTypes = {
    step: PropTypes.instanceOf(WorkflowStep).isRequired,
    target: PropTypes.object,
    container: PropTypes.object,
    onSave: PropTypes.func,
    hide: PropTypes.func,
    checkpointGroups: PropTypes.arrayOf(PropTypes.instanceOf(CheckpointGroup)),
    serviceProviders: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      key: '',
      validKey: false,
      editingKey: false,
      name: '',
      description: '',
      color: null,
      locked: false,
      signable: false,
      checkpoint: false,
      checkpointGroups: [],
      checkpointGroupKey: '',
      checkpointSkippable: false,
      checkpointGroupApprovalRule: CHECKPOINT_GROUP_APPROVAL_RULE.UNANIMOUS,
      restricted: false,
    };

    // This is just for a base id for child controls
    this.id = 'step-editor';
  }

  componentDidMount() {
    this.populate(this.props);
  }

  componentDidUpdate(prevProps) {
    const { step } = this.props;

    if (!_.isEqual(_.get(prevProps, 'step.json'), _.get(step, 'json'))) {
      this.populate(this.props);
    }
  }

  populate(props) {
    const { step, checkpointGroups } = props;

    this.setState({
      key: step.key || '',
      name: step.name || '',
      editingKey: false,
      validKey: false,
      description: step.description || '',
      color: step.color || '',
      locked: step.locked || false,
      signable: step.signable || false,
      checkpoint: step.checkpoint || false,
      checkpointGroupKey: step.checkpointGroupKey || '',
      checkpointGroups: checkpointGroups || [],
      checkpointSkippable: step.checkpointSkippable || false,
      checkpointGroupApprovalRule: step.checkpointGroupApprovalRule || CHECKPOINT_GROUP_APPROVAL_RULE.UNANIMOUS,
      restricted: step.restricted || false,
    });
  }

  handleChange(e, prop) {
    const newState = {};

    // Step.key needs to be sanitized; all other properties can accept whatever is typed in
    if (prop === 'key') {
      newState.key = sanitize(e.target.value);
    } else if (prop === 'name') {
      newState.name = e.target.value.slice(0, MAX_NAME_LENGTH);
    } else {
      newState[prop] = e.target.value;
    }

    this.setState(newState);
  }

  updateFunctionality({ locked, signable, checkpoint }) {
    this.setState({ locked, signable, checkpoint });
  }

  selectCheckpointGroup(checkpointGroupKey) {
    this.setState({ checkpointGroupKey });
  }

  onCheckpointSkippableChange() {
    this.setState((prevState) => {
      return {
        checkpointSkippable: !prevState.checkpointSkippable,
      };
    });
  }

  onCheckpointGroupApprovalRuleChange(e) {
    const checkpointGroupApprovalRule = e.target.value;
    this.setState({ checkpointGroupApprovalRule });
  }

  get functionalityLabel() {
    const { locked, signable, checkpoint } = this.state;
    return _.find(STEP_TYPES, { locked, signable, checkpoint }) || STEP_TYPES[0];
  }

  get selectedCheckpointGroup() {
    const { checkpointGroupKey, checkpointGroups } = this.state;
    return _.find(checkpointGroups, { checkpointGroupKey }) || {};
  }

  get canSave() {
    const { name, key, description, editingKey, validKey, checkpoint, checkpointGroupKey } = this.state;

    if (!key || !name.trim() || !description.trim()) return false;
    if (checkpoint && !checkpointGroupKey.trim()) return false;
    if (editingKey && !validKey) return false;

    return true;
  }

  validateKey(key) {
    const { step } = this.props;

    // No empty step keys
    if (!key) return false;

    const dupes = _.filter(step.steps, { key });

    // Ensure that the new key we're trying to enter is unique within this workflow
    if (dupes.length > 0 && dupes[0].index !== step.index) return false;

    // Keys can have numbers in them (or even start with numbers) but can not be pure numbers
    if (!isNaN(Number(key))) return false;

    return true;
  }

  save(e) {
    const { hide, step: originalStep, onSave } = this.props;
    let {
      key,
      name,
      description,
      color,
      locked,
      signable,
      checkpoint,
      checkpointGroupKey,
      checkpointSkippable,
      checkpointGroupApprovalRule,
      restricted,
    } = this.state;

    if (e) e.stopPropagation();

    // Note that saving does not actually make a Fire call, but instead just merges state values into props.step
    // And passes it back to the calling component
    // This is done to enable multiple steps to be configured sequentially
    // and then saving (or undoing) the whole thing in WorkflowEditor
    const newStep = new WorkflowStep(
      _.merge(originalStep.json, {
        key,
        name: name.trim(),
        description: description.trim(),
        color,
        locked,
        signable,
        checkpoint,
        checkpointGroupKey: checkpoint ? checkpointGroupKey : null,
        checkpointSkippable: checkpoint ? checkpointSkippable : false,
        checkpointGroupApprovalRule: checkpoint ? checkpointGroupApprovalRule : null,
        restricted,
      }),
      originalStep.workflow
    );

    onSave(originalStep, newStep);

    hide();
  }

  cancel() {
    this.props.hide();
  }

  focus() {
    const el = this.refName;
    if (el) el.focus();
  }

  renderForm() {
    const { step, serviceProviders } = this.props;
    const {
      name,
      key,
      color,
      description,
      editingKey,
      checkpointGroups,
      checkpoint,
      checkpointSkippable,
      checkpointGroupApprovalRule,
      restricted,
    } = this.state;

    return (
      <Form>
        <FormGroup className="id dmp-validator-container">
          {editingKey ? (
            <>
              <FormControl
                bsSize="small"
                placeholder="Enter step key (required)"
                value={key}
                onChange={(e) => this.handleChange(e, 'key')}
                data-cy="step-key"
              />
              <Validator
                validate={this.validateKey}
                value={key}
                onResult={(validKey) => this.setState({ validKey })}
                validTip="Valid step key"
                invalidTip="A step with this key already exists in this workflow"
              />
            </>
          ) : (
            <TooltipButton tip="Click to change key">
              <span className="element-name" onClick={() => this.setState({ editingKey: true })} data-cy="element-name">
                {key}
              </span>
            </TooltipButton>
          )}
        </FormGroup>

        <div className="title-color">
          <FormGroup className="step-title">
            <div className="control-label">Title</div>
            <FormControl
              bsSize="small"
              inputRef={(r) => (this.refName = r)}
              value={name}
              placeholder="Step title (required)"
              onChange={(e) => this.handleChange(e, 'name')}
              data-cy="step-title"
            />
            <small>Max {MAX_NAME_LENGTH} characters</small>
          </FormGroup>

          <FormGroup className="step-color">
            <div className="control-label">Color</div>
            <ColorPicker
              color={color || 'black'}
              size="small"
              style="github"
              colors={STEP_COLORS}
              editableInput={false}
              onChange={(color) => this.setState({ color })}
            />
          </FormGroup>
        </div>

        <FormGroup className="step-description">
          <div className="control-label">Description</div>
          <FormControl
            bsSize="small"
            value={description}
            componentClass="textarea"
            placeholder="Step description (required)"
            onChange={(e) => this.handleChange(e, 'description')}
            data-cy="step-description"
          />
          <small>Message displayed to users/recipients</small>
        </FormGroup>

        <FormGroup className="step-func">
          <div className="control-label">Functionality</div>
          <Dropdown
            id={`${this.id}-dd-step-func`}
            className="dd-step-func"
            title={this.functionalityLabel.title}
            onSelect={this.updateFunctionality}
            disabled={step.isFirst || step.isLast}
            size="small"
            block
            data-cy="dd-step-func"
          >
            {STEP_TYPES.map((stepType, idx) => (
              <MenuItem key={idx} eventKey={stepType} info={stepType.description || null}>
                {stepType.title}
              </MenuItem>
            ))}
          </Dropdown>
          <small>{this.functionalityLabel.description}</small>
        </FormGroup>

        {checkpoint && (
          <FormGroup className="step-func">
            <div className="control-label">Checkpoint</div>
            <Dropdown
              id={`${this.id}-dd-step-func`}
              className="dd-step-func"
              title={this.selectedCheckpointGroup.name || 'Select checkpoint group'}
              onSelect={this.selectCheckpointGroup}
              disabled={checkpointGroups.length === 0}
              size="small"
              block
              data-cy="dd-step-func"
            >
              {checkpointGroups.map((checkpointGroup, idx) => (
                <MenuItem
                  key={idx}
                  eventKey={checkpointGroup.checkpointGroupKey}
                  info={checkpointGroup.description || null}
                >
                  {checkpointGroup.name}
                </MenuItem>
              ))}
            </Dropdown>
          </FormGroup>
        )}
        {checkpoint && (
          <FormGroup>
            <div className="control-label">Approval rule</div>
            <div className="contents radio-button-group">
              <Radio
                name="approvalRule"
                checked={checkpointGroupApprovalRule === CHECKPOINT_GROUP_APPROVAL_RULE.UNANIMOUS}
                onChange={this.onCheckpointGroupApprovalRuleChange}
                value={CHECKPOINT_GROUP_APPROVAL_RULE.UNANIMOUS}
              >
                Unanimous
              </Radio>
              <Radio
                name="approvalRule"
                checked={checkpointGroupApprovalRule === CHECKPOINT_GROUP_APPROVAL_RULE.FIRST}
                onChange={this.onCheckpointGroupApprovalRuleChange}
                value={CHECKPOINT_GROUP_APPROVAL_RULE.FIRST}
              >
                First response
              </Radio>
            </div>

            <Switch checked={checkpointSkippable} onChange={this.onCheckpointSkippableChange} size="small">
              Skippable
            </Switch>
          </FormGroup>
        )}

        {serviceProviders && (
          <FormGroup className="step-description">
            <Switch
              checked={restricted}
              id="chk-restricted"
              onChange={() => this.setState({ restricted: !restricted })}
              size="small"
            >
              Restricted to Service Providers
            </Switch>
          </FormGroup>
        )}

        <FormGroup className="actions">
          <Button className="cancel" dmpStyle="link" size="small" onClick={this.cancel} data-cy="btn-cancel-step">
            Cancel
          </Button>

          <Button className="save" size="small" onClick={this.save} data-cy="btn-save-step" disabled={!this.canSave}>
            Update
          </Button>
        </FormGroup>
      </Form>
    );
  }

  render() {
    const { target, container, hide } = this.props;

    return (
      <Overlay
        container={container}
        onHide={hide}
        placement="bottom"
        show={true}
        target={target}
        onEntered={this.focus}
        rootClose
      >
        <Popover className="popover-step-editor" id={`${this.id}-pop`} data-cy="popover-step-editor">
          {this.renderForm()}
        </Popover>
      </Overlay>
    );
  }
}

export default WorkflowStepEditor;
