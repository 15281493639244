/* eslint-disable no-console */
import store from 'store';

// This should grow to include ALL properties that we store in LocalStorage
const LS_PROPS = {
  TEAM: 'team',
};

const log = (...args) => console.log('[LS]', ...args);

// Simple static wrapper class for LocalStorage operations
export default class LS {
  static getTeam() {
    const team = store.get(LS_PROPS.TEAM);
    log(`Retrieve [${LS_PROPS.TEAM}] -> "${team}"`);
    return team;
  }

  static setTeam(team) {
    log(`Set [${LS_PROPS.TEAM}] to "${team}"`);
    try {
      store.set(LS_PROPS.TEAM, team);
    } catch (err) {
      log(`ERROR :`, err);
    }
  }

  static clear() {
    store.clearAll();
  }
}
