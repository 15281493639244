import { assign, pick, filter } from 'lodash';

import { META_FIELD_TYPES } from '../server/search/Meta';
import { PARTY_PROPERTIES } from './Party';

export const ADDRESS_META_FIELDS = {
  type: META_FIELD_TYPES.OBJECT,
  children: {
    line1: META_FIELD_TYPES.TEXT,
    line2: META_FIELD_TYPES.TEXT,
    city: META_FIELD_TYPES.TEXT,
    state: META_FIELD_TYPES.TEXT,
    postalCode: META_FIELD_TYPES.TEXT,
    country: META_FIELD_TYPES.TEXT,
  },
};

export const ADDRESS_PROPERTIES = [
  {
    data: 'none',
    label: 'Full (default)',
    description: '',
    isExportableColumn: false,
  },
  {
    data: 'line1',
    label: 'Address Line 1',
    description: '',
    isExportableColumn: true,
  },
  {
    data: 'line1line2',
    label: 'Address Line 1 & 2',
    description: '',
    isExportableColumn: false,
  },
  {
    data: 'city',
    label: 'City',
    description: '',
    isExportableColumn: true,
  },
  {
    data: 'state',
    label: 'State/Province',
    description: '',
    isExportableColumn: true,
  },
  {
    data: 'postalCode',
    label: 'Zip/Postal Code',
    description: '',
    isExportableColumn: true,
  },
  {
    data: 'country',
    label: 'Country',
    description: '',
    isExportableColumn: true,
  },
];

export const EXPORTABLE_PARTY_PROPERTIES = filter([...PARTY_PROPERTIES, ...ADDRESS_PROPERTIES], {isExportableColumn: true});

export default class Address {
  line1 = null;
  line2 = null;
  city = null;
  state = null;
  postalCode = null;
  country = null;

  constructor(json) {
    const address = pick(json, ['line1', 'line2', 'city', 'state', 'postalCode', 'country']);
    assign(this, address);
  }

  get fullAddress() {
    const addressPropsList = [];
    if (this.line1) {
      addressPropsList.push(this.line1);
    }
    if (this.line2) {
      addressPropsList.push(this.line2);
    }
    if (this.city) {
      addressPropsList.push(this.city);
    }
    if (this.state) {
      addressPropsList.push(this.state);
    }
    if (this.postalCode) {
      addressPropsList.push(this.postalCode);
    }
    if (this.country) {
      addressPropsList.push(this.country);
    }
    return addressPropsList.join(', ');
  }

  getSubPropValue(subprop) {
    if (subprop === 'line1line2') {
      if (this.line1 && this.line2) {
        return `${this.line1} ${this.line2}`;
      } else if (this.line1) {
        return `${this.line1}`;
      } else if (this.line2) {
        return `${this.line2}`;
      }
    }
    return this[subprop];
  }

  get json() {
    return {
      ...pick(this, ['line1', 'line2', 'city', 'state', 'postalCode', 'country']),
      fullAddress: this.fullAddress,
    };
  }
}
