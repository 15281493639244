import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';
import qs from 'query-string';

import Preloader from '@components/Preloader';
import API from '@root/ApiClient';

@autoBindMethods
export default class MergeAccounts extends Component {
  static propTypes = {
    anonEmail: PropTypes.string,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isMerging: false,
    };
  }

  componentDidMount() {
    this.mergeAccounts();
  }

  async mergeAccounts() {
    const { anonEmail, history, location, user } = this.props;
    const params = qs.parse(location.search);
    const email = !user.anonymous ? user.email : anonEmail;

    if (this.state.isMerging) {
      return null;
    }

    await this.setState({ isMerging: true });

    await API.call('mergeAccounts', { fromToken: params.fromToken, email });

    let redirect = params.redirect ? decodeURIComponent(params.redirect) : '/dashboard';

    // Login now always happens via /login route (which could be either /login or /signup),
    // so we need to strip it back off of the redirect here because we're already logged in
    // so after the merge we can go straight to where we were trying to get to
    //redirect = redirect.replace(/^\/(login|signup)\//, '');

    console.log(`Account merge complete; heading on back to [${redirect}]`);

    history.replace(redirect);

    // We're not setting back the state.isMerging to false since we want to get away from this page
    // and not do anything else. Just wait on the redirect to kick in.
  }

  render() {
    return <Preloader />;
  }
}
