import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { initials } from '@core/models/DealUser';
import { getUniqueKey } from '@core/utils/Generators';

import { Ellipsis, Loader } from '@components/dmp';
import Dropdown from '@components/dmp/Dropdown';
import DropdownDots from '@components/dmp/DropdownDots';

import { DEAL_ROLES } from '@components/deal/DealUserBlock';
import TooltipButton from '@components/editor/TooltipButton';

const MenuItem = Dropdown.MenuItem;

export const VARIOUS_ROLE = {
  key: 'various',
  title: 'Various',
};

const TeammateBlock = (props) => {
  const { teammate, loading, onRemove, updateRole, disabled, disabledReason, admin, onClick } = props;
  // Exclude signer role from selectable roles because team roles are independent of deals/parties
  let roles = _.filter(DEAL_ROLES, (role) => role.key !== 'signer');
  const selectedRole = _.find(roles, { role: teammate.role }) || VARIOUS_ROLE;

  const className = cx('teammate', { disabled, simple: !admin && !disabled });

  return (
    <div className={className} key={teammate.id} onClick={onClick} data-cy="teammate-block">
      <div className="initials">{loading ? <Loader size="small" /> : initials(teammate)}</div>

      <div className="name-email">
        <Ellipsis className="name">{teammate.fullName ? teammate.fullName : teammate.email}</Ellipsis>
        {teammate.fullName && (
          <Ellipsis className={disabledReason && !admin ? 'reason' : 'email'}>
            {disabledReason && !admin ? disabledReason : teammate.email}
          </Ellipsis>
        )}
      </div>

      {admin && (
        <div className="role">
          <Dropdown
            id={`dd-du-role-${teammate.key}`}
            pullRight
            block
            size="small"
            onSelect={(role) => updateRole(teammate, role)}
            disabled={disabled}
            title={selectedRole.title}
            menuWidth={240}
          >
            {roles.map((role, idx) => (
              <MenuItem key={idx} eventKey={role.role} info={role.description}>
                {role.title}
              </MenuItem>
            ))}
          </Dropdown>
        </div>
      )}
      {admin && (
        <DropdownDots
          disabled={disabled}
          className="actions"
          pullRight
          onClick={(e) => e.stopPropagation()}
          id={`dd-du-${teammate.id}`}
          onSelect={onRemove}
          dataCyToggle="dd-teammate-du"
        >
          <MenuItem eventKey={teammate}>Remove</MenuItem>
        </DropdownDots>
      )}
      {admin && disabledReason && (
        <TooltipButton placement="left" tip={disabledReason} tipID={getUniqueKey()}>
          <div className="overlay" />
        </TooltipButton>
      )}
    </div>
  );
};

TeammateBlock.propTypes = {
  teammate: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  updateRole: PropTypes.func.isRequired,
  admin: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledReason: PropTypes.string,
  loading: PropTypes.bool,
};

TeammateBlock.defaultProps = {
  admin: false,
  loading: false,
  disabled: false,
  disabledReason: '',
  onClick: _.noop,
  onRemove: _.noop,
  updateRole: _.noop,
};

export default TeammateBlock;
