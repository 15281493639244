import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';

import AdminSearchParams, { DEFAULT_TEAM_SORT, DEFAULT_USER_SORT } from '@core/models/AdminSearchParams';
import Team from '@core/models/Team';
import User from '@core/models/User';

import { Button } from '@components/dmp';

import Dealer from '@root/Dealer';
import NavDash from '@root/nav/NavDash';
import ErrorView from '@root/routes/ErrorView';

import AssistantTab from './AssistantTab';
import AuditLogTab from './AuditLogTab';
import AuditManagementFilters from './AuditManagementFilter';
import TeamsTab from './TeamsTab';
import UserManagementFilters from './UserManagementFilters';
import UsersTab from './UsersTab';

const TABS = [
  {
    key: 'teams',
    title: 'Teams',
    TabComponent: TeamsTab,
    show: (user) => true,
  },
  {
    key: 'users',
    title: 'Users',
    TabComponent: UsersTab,
    show: (user) => true,
  },
  {
    key: 'auditLog',
    title: 'Audit Logs',
    TabComponent: AuditLogTab,
    show: (user) => true,
  },
  {
    key: 'assistant',
    title: 'Assistant',
    TabComponent: AssistantTab,
    show: (user) => user.isSuper,
  },
];

@autoBindMethods
export default class Admin extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    og: PropTypes.func.isRequired,
    team: PropTypes.instanceOf(Team),
    teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
    user: PropTypes.instanceOf(User),
    selectTeam: PropTypes.func.isRequired,
    getTeams: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { location, history } = props;
    const currentTab = this.getCurrentTab(location);
    const defaultSort = currentTab === 'users' ? DEFAULT_USER_SORT : DEFAULT_TEAM_SORT;

    this.state = {
      adminSearchParams: new AdminSearchParams(location, history, defaultSort),
      noAccess: false,
    };
  }

  componentDidMount() {
    const { adminSearchParams } = this.state;
    const { user } = this.props;
    adminSearchParams.query = '';

    if (!user.isAdmin) {
      this.setState({ noAccess: true });
    }
  }

  componentDidUpdate(prevProps) {
    const { location, history } = this.props;
    const currentTab = this.getCurrentTab(location);

    if (prevProps.location !== location) {
      const defaultSort = currentTab === 'users' ? DEFAULT_USER_SORT : DEFAULT_TEAM_SORT;
      this.setState({ adminSearchParams: new AdminSearchParams(location, history, defaultSort) });
    }
  }

  getCurrentTab = (location) => {
    return location.pathname.split('/')[2];
  };

  get selectedTab() {
    const { match } = this.props;
    const tab = _.get(match, 'params.tab', TABS[0].key);
    return _.find(TABS, { key: tab });
  }

  renderTabs() {
    const { history, user } = this.props;

    return (
      <ButtonGroup className="panel-tabs" data-cy="panel-tabs">
        {TABS.map((tab) => {
          if (tab.show(user)) {
            return (
              <Button
                key={tab.key}
                size="large"
                dmpStyle="link"
                active={this.selectedTab === tab}
                onClick={() => history.push(`/admin/${tab.key}`)}
                data-cy={tab.key}
              >
                {tab.title}
              </Button>
            );
          }
        })}
      </ButtonGroup>
    );
  }

  renderTabContent() {
    const { TabComponent } = this.selectedTab;
    const { adminSearchParams } = this.state;
    const props = _.pick(this.props, ['user', 'team', 'teams', 'og', 'location', 'selectTeam', 'getTeams']);

    return <TabComponent {...props} adminSearchParams={adminSearchParams} className="panel-tabs" />;
  }

  render() {
    const { teams, user } = this.props;
    const { adminSearchParams, noAccess } = this.state;
    const tab = this.selectedTab;

    if (noAccess) return <ErrorView error={{ error: 'Only outlaw admins have access to this page.' }} />;

    return (
      <main className="dashboard">
        {/*On mobile this is already covered in global nav so only need on desktop*/}
        {!Dealer.mobile && <NavDash {...this.props} />}
        <div className="main contracts-list">
          <div className="page-deals">
            {tab.key === 'users' && <UserManagementFilters teams={teams} adminSearchParams={adminSearchParams} />}
            {tab.key === 'auditLog' && (
              <AuditManagementFilters teams={teams} user={user} adminSearchParams={adminSearchParams} />
            )}
            <div className="contracts">
              <div className="title-bar">
                <h1>Admin</h1>
              </div>
              <div className="admin-page-body" data-cy="admin-page-body">
                {this.renderTabs()}
                {this.renderTabContent()}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
