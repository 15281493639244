import { filter, find, forEach, merge } from 'lodash';

import Filter from './Filter';

export const getFilters = ({ user, teams }) => {
  const filters = user.filters.getVisible();

  const groups = [
    {
      title: 'My Contracts',
      filters,
    },
  ];

  forEach(teams, (team) => {
    if (!team.filters.length) return;

    groups.push({
      title: team.info.name,
      filters: team.filters,
    });
  });

  return groups;
};

export const findFilter = ({ teams, user, filterID, teamID }) => {
  let filter = null;

  if (filterID) {
    // Try to find a Team Filter if a teamID is set, otherwise, fallback on the users one
    if (teamID) {
      const team = find(teams, { teamID });
      if (team) {
        filter = team.filters.get(filterID);
      }
    }

    if (!filter) {
      filter = user.filters.get(filterID);
    }
  }

  return filter;
};

export default class FilterStore extends Array {
  constructor(store) {
    super();

    if (typeof store !== 'undefined') {
      forEach(store, (json, filterID) => {
        this.push(new Filter(merge({}, json, { filterID })));
      });
    }
  }

  get(filterID) {
    return find(this, { filterID });
  }

  getVisible() {
    return filter(this, { hardcoded: false });
  }
}
