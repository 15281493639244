import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl, Modal } from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';

import { Button, Loader } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

const Methods = {
  DRAW: 'Draw',
  TYPE: 'Type',
};

const ESIGNING_TIP =
  'By clicking "Sign" you indicate that you understand you are using an electronic signature process that allows you to sign agreements online. You are expressing your intent for this signature to be legally binding and that you understand it is governed by the ESIGN Act of 2000 and other similar legislation.';

@autoBindMethods
export default class SigPad extends Component {
  static defaultProps = {
    show: false,
    signatureMaxHeight: null,
  };

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onSignature: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      type: Methods.DRAW,
      text: '',
      isCanvasFresh: true, // Allows triggering a render to update isEmpty()
      saving: false,
    };

    this.refCanvas = createRef();
    this.refText = createRef();
  }

  get canvas() {
    return this.refCanvas.current;
  }

  get isEmpty() {
    const { type, text } = this.state;

    switch (type) {
      case Methods.DRAW:
        if (this.canvas) {
          return this.canvas.isEmpty() !== false ? true : false;
        }
        return true;
      case Methods.TYPE:
        return !text.trim();
      default:
        return true;
    }
  }

  clear() {
    const { show } = this.props;
    const { type } = this.state;

    //reset state values for signing again
    this.setState({ text: '', saving: false });

    //clear the canvas
    if (this.refCanvas.current) this.refCanvas.current.clear();

    // if modal is showing and we're on typing mode, re-focus input
    if (type === Methods.TYPE && show) this.focusInput();
  }

  async sign() {
    const { onSignature } = this.props;
    const { type } = this.state;

    if (this.isEmpty) {
      if (type === Methods.TYPE) {
        this.focusInput();
      }
      return;
    }

    const data = this.canvas.getTrimmedCanvas().toDataURL();
    await this.setState({ saving: true });

    await onSignature(data);

    // Clear canvas immediately after signing so that if user removes/signs again, they need to re-type
    // Without this, when user clicks sign again, it saves a blank signature!
    // https://trello.com/c/p7xzoeUo
    this.clear();
  }

  hide() {
    const { onHide } = this.props;
    const { saving } = this.state;

    // Disallow closing the modal while saving the signature is actually in progress
    if (saving) return;

    this.clear();
    onHide();
  }

  focusInput() {
    const sigText = ReactDOM.findDOMNode(this.refText.current);
    if (sigText) sigText.focus();
  }

  renderTypedSig(text) {
    this.setState({ text });

    if (this.canvas) {
      this.canvas.clear();
      const cnv = this.canvas.getCanvas();
      const ctx = cnv.getContext('2d');

      ctx.font = '48px "kinescope", cursive';
      ctx.fontWeight = 400;
      ctx.textBaseline = 'hanging';

      ctx.fillText(text.trim(), 50, 90);
    }
  }

  async setType(e, type) {
    e.stopPropagation();

    if (this.canvas) {
      this.canvas.clear();
    }

    await this.setState({ type, text: '' });
    if (type === Methods.TYPE) {
      this.focusInput();
    }
  }

  onSigCanvasEnd() {
    this.setState({ isCanvasFresh: false });
  }

  render() {
    const { show } = this.props;
    const { text, type, saving } = this.state;

    return (
      <Modal
        className={cx('signature-pad', { saving })}
        show={show}
        onHide={this.hide}
        onEntered={this.focusInput}
        backdrop="static"
      >
        <Modal.Header closeButton={!saving}>
          <div className="sig-type">
            <ButtonGroup>
              {[Methods.DRAW, Methods.TYPE].map((method) => (
                <Button
                  key={method}
                  active={type === method}
                  onClick={(e) => this.setType(e, method)}
                  disabled={saving}
                  data-cy={`sign-${method}`}
                >
                  {method}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className={`bg ${type}`}>
            <span className="sign-here">
              {type === Methods.DRAW ? 'Sign on the line' : 'Type your name above to sign'}
            </span>
          </div>

          <SignatureCanvas
            penColor="black"
            canvasProps={{ width: 540, height: 160, className: `canvas-draw ${type}` }}
            onEnd={this.onSigCanvasEnd}
            ref={this.refCanvas}
            disabled={!saving}
          />

          <div className={`sig-text ${type}`}>
            <FormControl
              type="text"
              value={text}
              placeholder=""
              ref={this.refText}
              onChange={(e) => this.renderTypedSig(e.target.value)}
              disabled={saving}
              data-cy="sig-text"
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="esigning">
            <span>This signature is </span>
            <TooltipButton tip={ESIGNING_TIP} placement="bottom">
              <a href="https://getoutlaw.com/esigning" rel="noreferrer" target="_blank">
                legally binding.
              </a>
            </TooltipButton>
          </div>

          <div className="spacer" />
          {saving && <Loader className="sig-pad-loader" />}
          <Button onClick={this.clear} disabled={saving}>
            Clear
          </Button>
          <Button dmpStyle="primary" onClick={this.sign} disabled={this.isEmpty || saving} data-cy="btn-sign">
            Sign
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
