import Core from '../Core.js';

export default class Stopwatch {
  // Allow logging only in dev mode while keeping the StopWath in the code
  isDev = false;
  name = null;
  start = null;
  last = null;
  steps = [];

  constructor(name, message, isDev = false) {
    this.isDev = isDev;
    this.name = name;
    this.reset();
    if (message) this.log(message);
  }

  // eslint-disable-next-line no-console
  log(txt) {
    if (Core.Config()?.ENV === 'PROD' && this.isDev) return;
    console.log(this.name ? `[${this.name}] ` : '', txt);
  }

  // Common time tracking function to examine how long each piece of a process takes.
  // Basically like hitting "lap" on a stopwatch :-)
  step(message) {
    const now = new Date().getTime();
    if (message) this.log(`${message} - [Step: ${now - this.last}ms | Total: ${now - this.start}ms]`);
    this.steps.push({ time: now, message: message || null });
    this.last = now;
  }

  reset() {
    this.start = new Date().getTime();
    this.last = this.start;
    this.steps = [];
  }
}
