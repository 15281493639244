import React from 'react';

import PropTypes from 'prop-types';

import { FormGroup } from 'react-bootstrap';

import { ValueType, decimalOptions } from '@core/models/Variable';

import { Dropdown, MenuItem } from '@components/dmp';

const DecimalSelector = ({ id, onSelect, title }) => (
  <FormGroup>
    <div className="control-label">Decimal places to display</div>
    <Dropdown
      size="small"
      block
      id={id}
      className="dd-prop"
      onSelect={onSelect}
      title={title}
      data-cy="variable-prop-decimal-places"
    >
      {decimalOptions.map((option) => (
        <MenuItem key={`option-${option.key}`} eventKey={option.title} info={option.info}>
          {option.title}
        </MenuItem>
      ))}
    </Dropdown>
  </FormGroup>
);

DecimalSelector.propTypes = {
  id: PropTypes.string,
  onSelect: PropTypes.func,
  title: PropTypes.string,
};

DecimalSelector.defaultProps = {
  id: 'decimal-selector',
  onSelect: () => console.log('onSelect() not implemented'),
  title: '',
};

export const showDecimalSelector = (valueType) => {
  return [ValueType.NUMBER, ValueType.PERCENT].includes(valueType);
};

export default DecimalSelector;
