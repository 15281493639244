import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl } from 'react-bootstrap';

import { Checkbox, Ellipsis, Loader } from '@components/dmp';
import { Icon } from '@components/dmp';

import API from '@root/ApiClient';

@autoBindMethods
export default class FacetSearch extends Component {
  static propTypes = {
    facet: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    selected: PropTypes.array.isRequired,
    toggleSelection: PropTypes.func.isRequired,
    empty: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      suggestions: [],
      loading: false,
      focused: false,
    };

    /** This function returns the suggestion from the user's query. It also returns the totalHits of suggestionSearch results.
     * It also uses _.debounce( fn(), 150 )*/
    this.getSuggestions = _.debounce(() => {
      const { value } = this.state;
      const { facet } = this.props;

      // If searching for an empty string, clear results
      if (!value.length) {
        return this.setState({ suggestions: [], loading: false });
      }

      this.setState({ loading: true });
      API.call('getFacetSuggestions', { facet, query: value }, (suggestions) => {
        this.setState({
          suggestions,
          loading: false,
        });
      });
    }, 150);
  }

  async onChange(event) {
    await this.setState({ value: event.target.value, loading: true });
    this.getSuggestions();
  }

  renderSuggestion(suggestion, idx) {
    const { toggleSelection, selected, facet } = this.props;
    const checked = selected.includes(suggestion.email ? suggestion.email : suggestion.key);

    return (
      <Checkbox
        key={idx}
        id={`facet-suggestion-${idx}`}
        className={cx({ checked })}
        checked={checked}
        onChange={() => toggleSelection(suggestion.email ? suggestion.email : suggestion.key)}
        data-cy="party-suggestion-checkbox"
      >
        <span className={`facet-suggestion-${facet}`}>{suggestion.key}</span>
        {suggestion.email ? (
          <small className="suggestion-email">
            <Ellipsis>{suggestion.email}</Ellipsis>
          </small>
        ) : (
          ''
        )}
      </Checkbox>
    );
  }

  render() {
    const { suggestions, value, loading, focused } = this.state;
    const { placeholder, empty } = this.props;

    return (
      <div className={cx('facet-search', { focused })} data-cy="facet-search">
        <FormControl
          type="text"
          bsSize="small"
          value={value}
          onChange={this.onChange}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
          placeholder={placeholder}
          data-cy="facet-search-input"
        />

        {loading ? <Loader size="xsmall" /> : <Icon className="search-icon" name="search" />}
        {suggestions.length > 0 && (
          <div className="facet-suggestions" data-cy="facet-suggestions">
            {suggestions.map(this.renderSuggestion)}
          </div>
        )}
        {empty && value && !loading && !suggestions.length && <div className="no-results">{empty}</div>}
      </div>
    );
  }
}
