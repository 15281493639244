import { ValueType } from './Variable';

export default class Operator {
  key;
  name;
  title;
  valueCount;

  constructor(name, key, title, valueCount) {
    this.name = name;
    this.key = key;
    this.title = title;
    this.valueCount = valueCount;
  }
}

export const SCORING_OPERATORS = {
  LESS: new Operator('LESS', '<', 'Is less than', 1),
  GREATER: new Operator('GREATER', '>', 'Is greater than', 1),
  BETWEEN: new Operator('BETWEEN', '<>', 'Is between', 2),
}

export const OPERATORS = {
  EQUAL: new Operator('EQUAL', '=', 'Is equal to', 1),
  UNEQUAL: new Operator('UNEQUAL', '!=', 'Is not equal to', 1),
  KNOWN: new Operator('KNOWN', '!!', 'Is known', 0),
  UNKNOWN: new Operator('UNKNOWN', '!', 'Is unknown', 0),
  IN: new Operator('IN', '[]', 'Is one of', '*'),
  OUT: new Operator('OUT', '[!]', 'Is none of', '*'),
  ALL: new Operator('ALL', '[&]', 'Is all of', '*'),
  LESS: new Operator('LESS', '<', 'Is less than', 1),
  BEFORE: new Operator('BEFORE', '[', 'Is before', 1),
  GREATER: new Operator('GREATER', '>', 'Is greater than', 1),
  AFTER: new Operator('AFTER', ']', 'Is after', 1),
  BETWEEN: new Operator('BETWEEN', '<>', 'Is between', 2),
  DYNAMIC: new Operator('DYNAMIC', '*', 'Is dynamic', 0),
};

export const getOperators = (valueType) => {
  switch (valueType) {
    case ValueType.NUMBER:
    case ValueType.PERCENT:
    case ValueType.CURRENCY:
      return [
        OPERATORS.EQUAL,
        OPERATORS.UNEQUAL,
        OPERATORS.LESS,
        OPERATORS.GREATER,
        OPERATORS.BETWEEN,
        OPERATORS.KNOWN,
        OPERATORS.UNKNOWN,
      ];
    case ValueType.LIST:
    case ValueType.STATE:
      return [OPERATORS.IN, OPERATORS.OUT, OPERATORS.KNOWN, OPERATORS.UNKNOWN];
    case ValueType.MULTI_SELECT:
      return [OPERATORS.IN, OPERATORS.OUT, OPERATORS.ALL, OPERATORS.KNOWN, OPERATORS.UNKNOWN];
    case ValueType.DATE:
      return [
        OPERATORS.DYNAMIC,
        OPERATORS.EQUAL,
        OPERATORS.UNEQUAL,
        OPERATORS.BEFORE,
        OPERATORS.AFTER,
        OPERATORS.BETWEEN,
        OPERATORS.KNOWN,
        OPERATORS.UNKNOWN,
      ];
    case ValueType.STRING:
    case ValueType.CONTACT:
      return [OPERATORS.EQUAL, OPERATORS.UNEQUAL, OPERATORS.KNOWN, OPERATORS.UNKNOWN];
    case 'DateRange':
      return [OPERATORS.EQUAL, OPERATORS.BETWEEN, OPERATORS.DYNAMIC];
    default:
      return [];
  }
};
