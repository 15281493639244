import React, { createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';

import SectionType from '@core/enums/SectionType';
import { isVine } from '@core/utils/Environment';
import { getBaseUrl } from '@core/utils/Generators';

import { Icon } from '@components/dmp';

import FootnoteDisplay from '@components/FootnoteDisplay';
import PageBreak from '@components/PageBreak';
import PaymentSection from '@components/section_types//PaymentSection';
import ContentSection from '@components/section_types/ContentSection';

@autoBindMethods
export default class AppendixSection extends ContentSection {
  constructor(props) {
    super(props);
    this.childRefs = {};
  }

  onCreate(newSectionID) {
    const newSection = _.get(this.childRefs[newSectionID], 'current');
    if (newSection) newSection.focus();
  }

  renderBody() {
    const { section, location, history } = this.props;

    switch (section.appendixType) {
      case SectionType.SCOPE:
        const items = _.filter(section.parent.children, { sectiontype: SectionType.ITEM });
        return <div className="items-container">{_.map(items, this.renderScopeItem)}</div>;
      case SectionType.PAYMENT:
        return (
          <div className="items-container">
            {_.map(section.deal.payments, (pmt, index) => this.renderPaymentItem(pmt, index + 1))}
          </div>
        );
      case SectionType.LINKED:
        // The "title" of a linked (bundled) Deal is actually stored in the body of this section
        const title = section.currentVersion.body.getPlainText();

        // In dotvine mode, we're in an iframe and want to stay there so use history.push, and keep any querystring params present
        // In a normal case, just deep-link to separate tab (so user can see both docs at once)
        const path = `/deals/${section.linkedDealID}`;
        const url = `${getBaseUrl()}${path}`;
        const qs = _.get(location, 'search', '');

        return (
          <div className="items-container appendix-linked">
            <Icon name="document" />
            <div className="linked-doc">
              <div style={section.deal.style.type.SectionTitle.css}>{title}</div>
              <div className="linked-url" style={section.deal.style.type.SectionBody.css} data-cy="linked-url">
                {isVine ? (
                  <a onClick={() => history.push(`${path}${qs}`)}>{url}</a>
                ) : (
                  <a href={url} target="_blank">
                    {url}
                  </a>
                )}
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  renderScopeItem(item, idx) {
    if (!this.childRefs[item.id]) this.childRefs[item.id] = createRef();
    const props = _.merge({}, this.props, {
      section: item,
      number: idx + 1,
      editableTitle: true,
    });
    return <ContentSection {...props} key={idx} ref={this.childRefs[item.id]} onCreate={this.onCreate} />;
  }

  renderPaymentItem(payment, number) {
    const props = _.merge({}, this.props, { payment, number, section: payment });
    return <PaymentSection {...props} key={number} appendix={this.props.section} />;
  }

  render() {
    const { container, section, readonly } = this.props;
    const className = cx('appendix', { readonly }, { 'page-break': section.pageBreak });

    const styleTitle = section.styleTitle.css;
    const styleBody = section.styleBody.css;
    if (section.sectionStyle.isAligned) {
      styleTitle.textAlign = section.sectionStyle.align;
      styleBody.textAlign = section.sectionStyle.align;
    } else {
      //for legacy themes prior to the type being modified
      styleBody.textAlign = styleTitle.textAlign;
    }

    return (
      <div
        data-sectionid={section.id}
        className={className}
        style={{ marginBottom: section.deal.style.layout.Section.web.bottom }}
      >
        {section.pageBreak && <PageBreak section={section} />}
        {section.displayTitle && (
          <div style={styleTitle} data-cy="appendix-title">
            {section.displayTitle}
          </div>
        )}
        {section.content && section.appendixType !== SectionType.LINKED && (
          <div style={styleBody} data-cy="appendix-body">
            {this.renderContent()}
          </div>
        )}
        {this.renderBody()}
        {container && (
          <FootnoteDisplay
            section={section}
            styles={section.footnoteStyle.css}
            activeFootnotes={section.activeFootnotes}
            container={container.current}
          />
        )}
      </div>
    );
  }
}
