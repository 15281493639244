import axios from 'axios';

import Fire from '@root/Fire';

const trackEvent = async (eventName, eventData, isAPI) => {
  // TODO: FIXME: standardize the eventData input so we can call it the same frontend/backend

  //Two seperate calls. On structured for the API and one for Pendo sdk.
  const teamID = eventData.teamID ?? eventData.properties?.teamID;

  if (teamID) {
    // Check for SalesforceID for team
    const team = await Fire.getTeam(teamID);
    if (team?.info?.salesforceID) {
      if (isAPI && eventData.properties) {
        eventData.properties.salesforceID = team.info.salesforceID;
      } else {
        eventData.salesforceID = team.info.salesforceID;
      }
    }
  }
  if (isAPI) {
    // Call Pendo using API while in a backend context
    try {
      let requestData = eventData;

      if (!requestData.type && !requestData.properties) {
        // Data is being passed in frontend format, convert it to match API request
        requestData = {
          type: 'track',
          event: eventName,
          visitorID: eventData.dealID ?? null,
          accountID: teamID,
          timestamp: Date.now(),
          properties: { ...eventData },
        };
      }

      const headers = {
        'x-pendo-integration-key': '88d81976-af44-4c81-5a0f-262c5a78559c',
        'Content-Type': 'application/json',
      };

      console.log('PENDO api request', JSON.stringify(requestData));
      const response = await axios.post('https://app.pendo.io/data/track', requestData, { headers });
      console.log('PENDO api call response status', response.status);
    } catch (err) {
      console.log('Error tracking Pendo Event', err);
    }
  } else if (typeof pendo === 'object' && typeof pendo.track === 'function') {
    // Call using JS SDK
    eventData.environment = window.environment;
    console.log(`[Pendo] ${eventName}`, eventData);
    pendo.track(eventName, eventData);
  }
};

export default trackEvent;
