import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';

import { Overlay, Popover } from 'react-bootstrap';

import DealStatus from '@core/enums/DealStatus';
import InviteStatus from '@core/enums/InviteStatus';
import { ReadyLabels } from '@core/models/ReadyCheck';
import { Dt, dt, isVine } from '@core/utils/Environment';

import { Swatch } from '@components/dmp';
import Ellipsis from '@components/dmp/Ellipsis';

import SendDeal from '@components/deal/SendDeal';
import Dealer from '@root/Dealer';
import Fire from '@root/Fire';

@autoBindMethods
export default class ABC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatingStatus: false,
      sending: false,
      rcStatusShown: false,
      revertingFlow: false,
    };
  }

  componentDidMount() {
    this.syncDealStatus(this.props);
  }

  syncDealStatus(props) {
    const { deal, compact, toggle } = props;
    const { rcStatusShown, updatingStatus } = this.state;

    // Ignore status on Template Editor previews, and for non-native (INGESTED and EXTERNAL) deals
    if (!deal || deal.preview || deal.isWorkflowManual) return;

    // Don't auto update (and show) deal status if current user is not in a party and not the owner
    if (!deal.currentDealUser || (deal.currentDealUser.role !== 'owner' && !deal.currentDealUser.partyID)) return;

    // If there's an active ReadyCheck, that takes precedence over the normal ABC deal flow
    if (deal.readyCheck != null) {
      if (!updatingStatus && !rcStatusShown) {
        this.setState({ updatingStatus: true, rcStatusShown: true }, () => {
          if (typeof toggle == 'function' && !compact) toggle(true);
          this.setState({ updatingStatus: false });
        });
      }
      return;
    }

    const computed = deal.status.data;
    const stored = deal.info.status;

    // Finally, ensure that we never touch a deal that has already been fully executed
    // This could come into play if there were "dormant" parties, see https://trello.com/c/aB554aFa
    // It doesn't really do much because the computed status is the one that is used for UI logic
    // But doesn't hurt to have this check in regardless
    if (stored === DealStatus.SIGNED.data) return;

    if (computed !== stored && !this.state.updatingStatus) {
      this.setState({ updatingStatus: true });

      //automatically show deal status tooltip any time it advances
      const steps = deal.workflow.steps;
      const show = _.findIndex(steps, { key: computed }) > _.findIndex(steps, { key: stored });

      this.setState({ updatingStatus: false });
      if (typeof toggle == 'function' && !compact) toggle(true);
    }
  }

  async activateStatus(status) {
    this.setState({ updatingStatus: true });
    await Fire.updateDealInfo(this.props.deal.info, { status });
    this.setState({ updatingStatus: false });
  }

  get instructions() {
    const { deal, status } = this.props;

    if (deal.readyCheck != null) return `Normal ${dt} flow will resume once the ${ReadyLabels.EVENT} is closed`;

    switch (status) {
      case 'todo':
        return 'Start by filling in all required sections';
      case 'draft':
        return `Your ${deal.name} is ready to send`;
      case 'signed':
      case 'complete':
        return `This ${deal.name} is official. A PDF of the signed ${dt} is in your email`;
      case 'review':
        return `Carefully read ${deal.isOwner ? 'your' : 'the'} ${deal.name} and sign when ready`;
      case 'signing':
        const unsigned = !_.get(deal, 'currentDealUser.signed');
        if (unsigned) {
          return `Review and sign to complete the ${dt}`;
        } else {
          return `You've signed this ${deal.name} and will be notified by email once everyone has signed`;
        }
      default:
        return '';
    }
  }

  get compactInstructions() {
    const { status } = this.props;
    switch (status) {
      case 'todo':
        return 'Start by filling in all required fields.';
      case 'draft':
      case 'review':
        return `Carefully read the full ${dt} and sign when ready.`;
      case 'signing':
      case 'signed':
      case 'complete':
        return 'All done! Redirecting...';
      default:
        return '';
    }
  }

  get headline() {
    const { deal } = this.props;

    if (deal.readyCheck != null) return `${ReadyLabels.EVENT} ${deal.readyCheck.result.label}`;

    const status = deal.currentWorkflowStep.isLast ? 'complete' : deal.info.status || deal.workflow.steps[0].key;

    switch (status) {
      case 'todo':
        return `${Dt} created`;
      case 'draft':
        return 'Draft ready';
      case 'review':
        if (deal.isOwner) {
          return 'Review and sign';
        } else {
          return `${Dt} received`;
        }
      case 'signing':
        const unsigned = !_.get(deal, 'currentDealUser.signed');
        if (unsigned) {
          return `${deal.counterparty} signed`;
        } else {
          return 'All set for now';
        }
      case 'signed':
      case 'complete':
        return `${Dt} complete`;
      default:
        return '';
    }
  }

  render() {
    const { status, compact, deal, user, suppressABC, action, senderRef, abcContainerRef } = this.props;

    if (!deal || !user || suppressABC) return null;

    //deal flow will vary if the deal does not require signing
    //some templates (e.g., Terms of Service, Stock Plan) never require signature
    const steps = deal.workflow.steps;
    const current = _.findIndex(steps, { key: status });

    //special case for a ReadyCheck that's in progress
    //if we find one, we actually want to dim all the steps and instead render a generic "ReadyCheck in progress" tip
    const rc = deal.readyCheck;

    //same logic as in action, to ensure we're pointing to the same dealuser
    const unsent = _.filter(deal.users, { inviteStatus: InviteStatus.ADDED });
    const du = unsent.length > 0 ? unsent[0] : null;

    const className = cx({ abc: !compact }, { 'abc-compact': compact }, { dotvine: isVine }, status);

    return (
      <div className={className} ref={abcContainerRef}>
        {!compact && !isVine && Dealer.mobile && <Ellipsis className="abc-dealname">{deal.name}</Ellipsis>}

        {!compact && (
          <div className={`steps${rc ? ' checking' : ''}`}>
            {steps.map((step, idx) => this.renderStep(current, step, idx))}
          </div>
        )}

        {this.renderTip(steps, action, compact)}

        <SendDeal deal={deal} user={user} du={du} ref={senderRef} onSend={() => senderRef.current.hide()} />
      </div>
    );
  }

  renderTip(steps, action, compact) {
    const { status, deal, toggle, show, handleAction, actionRef, abcContainerRef, draftStepTargetRef } = this.props;
    // Don't show tooltips for external (PDF) deals
    // Also skip for Timelines
    if (deal.isExternal || deal.timeline) return null;
    let current = _.findIndex(steps, { key: status });
    if (current < 0) current = 0;
    const ref = () => ReactDOM.findDOMNode(this.refs[status]);

    const headline = this.headline;
    const instructions = this.instructions;

    //compact mode is always showing so no need to render inside Popover
    if (compact)
      return (
        <div>
          {this.compactInstructions}{' '}
          {action && (
            <span className="action" ref={actionRef} onClick={() => handleAction()}>
              {action}
            </span>
          )}
        </div>
      );
    //if not compact, the only tooltip-originated action that has a target is sharing (SendDeal)
    //so we're passing a reference to the "Draft" label so that sharing popup
    else
      return (
        <Overlay
          show={show}
          onHide={() => toggle(false)}
          target={status === 'draft' ? draftStepTargetRef.current : ref}
          container={abcContainerRef.current}
          placement="bottom"
          rootClose
        >
          <Popover
            className="popover-abc"
            title={headline}
            positionLeft="100"
            id="pop-deal-status"
            data-cy="pop-deal-status"
          >
            <span className="instructions">{instructions}</span>
            {action && (
              <div className="action" onClick={() => handleAction()}>
                {action}
              </div>
            )}
          </Popover>
        </Overlay>
      );
  }

  renderStep(current, step, idx) {
    const { deal, toggle, draftStepTargetRef } = this.props;
    let status = idx === current ? 'active' : idx > current ? 'incomplete' : 'done';
    const canActivate = deal.isOwner && deal.isWorkflowManual && idx !== current;
    if (canActivate) status += ' activate';

    if ((Dealer.mobile || isVine) && idx !== current) return null;

    return (
      <div
        key={idx}
        className={`step step-${step.name.toLowerCase()} ${status}`}
        ref={step.key === 'draft' ? draftStepTargetRef : step.key}
        onClick={() => (status == 'active' ? toggle(true) : canActivate ? this.activateStatus(step.key) : null)}
      >
        {Dealer.mobile && !isVine ? (
          <span className={cx('statusLabel', step.key)}>{step.name}</span>
        ) : (
          <>
            <Swatch color={step.color} />
            {step.name}
          </>
        )}
      </div>
    );
  }
}
