import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Modal } from 'react-bootstrap';

import AIPrompt, { AI_ENGINES } from '@core/models/AIPrompt';
import Deal from '@core/models/Deal';

import { Alert, Button, Dropdown, Ellipsis, Loader, MenuItem } from '@components/dmp';

import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import API from '@root/ApiClient';
import Fire from '@root/Fire';
import trackEvent from '@utils/EventTracking';

@autoBindMethods
export default class AiBlocksView extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    user: PropTypes.object.isRequired,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);
    this.state = {
      aiBlock: null,
      aiPrompt: {},
      originAIPrompt: {},
      results: [],
      selectedBlockIndex: -1,
      selectedShowMoreIndex: -1,
      loading: false,
      error: null, //'Error: Sampling error: 400 Bad Request',
      showAutoSave: false,
      showRevert: false,
    };

    this.autosave = _.debounce(() => this.savePrompt(true), 1000);
    this.showAutoSave = _.debounce(() => {
      this.setState({ showAutoSave: false });
    }, 1000);
  }

  componentDidMount() {
    this.populate();
  }

  // Rerender variables any time they change
  componentDidUpdate(prevProps, prevState) {
    const { blockID, deal } = this.props;
    //If block id changes
    //Or if we just rebuilt a legacy ai block.
    if (
      prevProps.blockID !== blockID ||
      (!prevProps.deal.sections[blockID].aiPrompt && deal.sections[blockID].aiPrompt)
    ) {
      this.populate();
    }
  }

  async populate() {
    const { deal, blockID } = this.props;

    if (!deal.sections[blockID].aiPrompt) {
      const promptJSON = AIPrompt.buildJSON(deal.sections[blockID], deal.workflow);
      await Fire.saveSection(deal.sections[blockID], { aiPrompt: promptJSON });
    } else {
      this.setState({
        aiBlock: deal.sections[blockID],
        aiPrompt: deal.sections[blockID].aiPrompt,
      });
      this.originPrompt();
    }
  }

  updateEngine(engine) {
    const { aiBlock, aiPrompt } = this.state;
    aiPrompt.engine = engine;
    Fire.saveSection(aiBlock, { aiPrompt: aiPrompt.json });
    this.setState({ aiPrompt });
  }

  updatePrompt(e, index) {
    const { aiPrompt } = this.state;
    const content = e.target.value;
    aiPrompt.prompt[index].content = content;
    this.setState({ aiPrompt });
    this.autosave();
  }

  async savePrompt(autosave) {
    const { aiBlock, aiPrompt } = this.state;
    await Fire.saveSection(aiBlock, { aiPrompt: aiPrompt.json });
    if (autosave) {
      this.setState({ showAutoSave: true });
      this.showAutoSave();
    }
  }

  async saveAIBlock() {
    const { onHide } = this.props;
    const { aiBlock, results, selectedBlockIndex } = this.state;
    await Fire.saveSection(aiBlock, { content: results[selectedBlockIndex] });
    onHide();
  }

  async revert() {
    const { aiBlock, originAIPrompt } = this.state;

    //We need the parse/stringify to create a deep copy of the originAIPrompt. If we do not it modifies both the state value and the prop we check against.
    const origin = new AIPrompt(JSON.parse(JSON.stringify(originAIPrompt.json)), aiBlock.deal);

    await Fire.saveSection(aiBlock, { aiPrompt: origin.json });
    this.setState({ aiPrompt: origin, showRevert: false });
  }

  async originPrompt() {
    const { deal, blockID } = this.props;
    const { info } = deal;
    const { sourceTeam, sourceTemplate } = info;

    if (!sourceTemplate || !sourceTeam) {
      return;
    }

    const dealRecord = await API.call('getTeamTemplates', { teamID: sourceTeam, key: sourceTemplate });
    const dealID = _.get(dealRecord, '[0].dealID');
    if (!dealID) return;

    // Finally, fetch the full Deal object and store in state to pass to children
    const dealTemplate = await Fire.getDeal(dealID);
    const originAIPrompt = dealTemplate.sections[blockID].aiPrompt;
    this.setState({ originAIPrompt });
  }

  async summarize() {
    const { deal, user } = this.props;
    const { aiPrompt } = this.state;
    const { engine } = aiPrompt;

    let source = aiPrompt.dataSourceAI;

    this.setState({ loading: true, selectedBlockIndex: -1, results: [], error: null });
    let skynet = await API.call('summarize', { aiPrompt: aiPrompt.json, source, dealID: deal.dealID });
    this.setState({ loading: false });

    if (skynet?.result) {
      this.setState({ results: skynet.result });

      const eventData = {
        serviceType: `${_.upperFirst(aiPrompt.type)} AI Block Live`, //should eventually be enumerated somewhere for additional types
        docID: deal.dealID,
        user: user.email,
        teamID: deal.team,
        engine: engine.key,
        isTemplate: deal.isTemplate,
        template: deal.info.sourceTemplate,
      };
      await trackEvent('ListSectionSummarize', eventData);
    }

    if (skynet.error) {
      this.setState({ error: skynet.error });
    }
  }

  renderRevertModal() {
    const { showRevert } = this.state;
    return (
      <Modal
        dialogClassName="revert-ai-instructions"
        show={showRevert}
        onHide={() => this.setState({ showRevert: false })}
      >
        <Modal.Header closeButton>
          <span className="headline">Reset instructions</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            Would you like to restore the original instructions from this document’s template? This action cannot be
            undone.
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="spacer" />
          <Button data-cy="btn-cancel" onClick={() => this.setState({ showRevert: false })}>
            Cancel
          </Button>
          <Button dmpStyle="danger" data-cy="btn-delete" onClick={this.revert}>
            Reset Instructions
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { id, container, show, onHide, target, title, user } = this.props;
    const {
      aiPrompt,
      loading,
      results,
      selectedBlockIndex,
      error,
      originAIPrompt,
      showAutoSave,
      selectedShowMoreIndex,
    } = this.state;
    const revert =
      aiPrompt?.json && originAIPrompt?.json
        ? JSON.stringify(aiPrompt.json) !== JSON.stringify(originAIPrompt.json)
        : false;

    return (
      <DealPanel id={id} onHide={onHide} show={show} target={target} title={title} container={container}>
        {this.renderRevertModal()}
        <div className="panel-scroll">
          {aiPrompt.engine && user.isSuper && (
            <DealPanelItem className="engine-selector" borderBottom>
              <div className="item-label">AI Engine</div>
              <FormGroup>
                <Dropdown
                  id="dd-ai-engine"
                  title={`${aiPrompt.engine.title} [${aiPrompt.engine.model}]`}
                  onSelect={this.updateEngine}
                  size="small"
                  block
                >
                  {_.map(AI_ENGINES, (engine, idx) => (
                    <MenuItem key={idx} eventKey={engine}>
                      {engine.title} [{engine.model}]
                    </MenuItem>
                  ))}
                </Dropdown>
              </FormGroup>
            </DealPanelItem>
          )}
          <DealPanelItem className="engine-selector" borderBottom>
            {aiPrompt.prompt &&
              aiPrompt.prompt.map(({ role, content, step, adminOnly }, index) => {
                if (!adminOnly || (adminOnly && user.isSuper))
                  return (
                    <>
                      <div className="item-label">{step}</div>
                      <FormGroup>
                        <FormControl
                          placeholder="e.g., Summarize this content"
                          componentClass="textarea"
                          value={content}
                          onChange={(e) => this.updatePrompt(e, index)}
                          bsSize="small"
                          data-cy="AI-instructions"
                          disabled={adminOnly}
                        />
                      </FormGroup>
                    </>
                  );
              })}
            <div className="generate-action">
              {showAutoSave && <div className="auto-saved">Autosaved</div>}
              {loading && <Loader />}
              {revert && (
                <Button bsSize="small" dmpStyle="link" onClick={() => this.setState({ showRevert: true })}>
                  Reset instructions
                </Button>
              )}
              <Button size="small" onClick={this.summarize} disabled={loading}>
                {loading ? 'Generating Results' : results.length > 0 ? 'Regenerate Results' : 'Generate Results'}
              </Button>
            </div>
          </DealPanelItem>

          {(results.length > 0 || error) && (
            <DealPanelItem
              className="engine-selector"
              borderBottom
              onClick={() => this.setState({ selectedBlockIndex: -1 })}
            >
              <div className="item-label">AI Results</div>
              {error && (
                <Alert size="small" dmpStyle="danger">
                  {error}
                </Alert>
              )}
              {results.length > 0 && (
                <>
                  <div className="results-container">
                    {results.map((option, idx) => (
                      <div
                        className={cx('result', {
                          selected: idx === selectedBlockIndex,
                          showMore: selectedShowMoreIndex === idx,
                        })}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.setState({ selectedBlockIndex: idx });
                        }}
                      >
                        <div className="result-text">
                          {selectedShowMoreIndex === idx ? option : <Ellipsis lines={3}>{option}</Ellipsis>}
                        </div>
                        {selectedShowMoreIndex === idx ? (
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ selectedShowMoreIndex: -1 });
                            }}
                          >
                            Show Less
                          </a>
                        ) : (
                          <a
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ selectedShowMoreIndex: idx });
                            }}
                          >
                            Show More
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="button-group">
                    <Button
                      bsSize="small"
                      dmpStyle="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.saveAIBlock();
                      }}
                      disabled={selectedBlockIndex === -1}
                    >
                      Insert Selected Clause
                    </Button>
                  </div>
                </>
              )}
            </DealPanelItem>
          )}
        </div>
      </DealPanel>
    );
  }
}
