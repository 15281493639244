// These words (regex groups) can appear lowercase in titles
export const TITLE_JOINERS = ['an?d?', 'of', 'in', 'upon', 'on', 'the', 'f?or', 'to', 'by', 'with'];

export const SALUTATIONS = ['Dear', 'Attn:?', 'Attention:?', 'Whom', 'Ms.?', 'Mr.?', 'Mrs.?', 'Sincerely,?'];

export const APPENDIX_NAMES = ['appendix', 'exhibit', 'schedule', 'attachment', 'annex'];

export const SECTION_NAMES = ['section', 'article', 'paragraph', 'clause'];

export const PARTY_NAMES = [
  'company',
  'client',
  'consultant',
  'contractor',
  'vendor',
  'signer',
  'counterparty',
  'buyer',
  'investor',
  'seller',
];

// This is actually a good candidate (finally) for ML
export const SIGNATURE_INDICATORS = {
  PRE: ['signature page follows', 'left blank'],
  START: ['whitness whereof', 'by signing', 'hereby agreed', 'accepted by', 'signing below'],
  PARTY: ['By', 'Name', 'Title', 'Date'],
  END: ['signature page'],
};
