import React, { Component } from 'react';

import { CohereClient } from 'cohere-ai';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import MarkdownRenderer from 'react-markdown-renderer';

import { Loader } from '@components/dmp';

import API from '@root/ApiClient';
import Fire from '@root/Fire';

export default class DuplicateDealCheck extends Component {
  static defaultProps = {
    show: false,
    deals: [],
  };

  static propTypes = {
    show: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      deals: [],
      loading: false,
      answer: null,
    };
  }

  componentDidMount() {
    this.compareDocuments();
  }

  async compareDocuments() {
    const { deals } = this.props;
    const documents = [];

    this.setState({ loading: true });

    for (const esDeal of deals) {
      const deal = await Fire.getDeal(esDeal.dealID);

      const OCR = _.values(deal.currentOCR?.content).join('');

      documents.push({ title: deal.info.name, snippet: OCR });
    }

    const prompt = `
    Read the documents slowly and carefully. I want you to compare the documents and put them into lists based on the following instructions
    First, if they are different contracts or involve different parties, put the contact in question into its own list.
    Second, if they are different states of the same contract, put them in the same list.
    
    Please structure your response the following way...
    First, each list should be categorized and titled using the legal document type.
    Second, each list should have a subtitle using the signing parties involved.
    Third, each document within the list should use the referenced document title followed by a short explanation for why it is in a specific list.`;

    const preamble = `
    ## Task & Context
    You are a large language model designed to help lawyers with contract analysis. Follow the all the instructions in the prompt to best serve lawyers reading your response.
    ## Style Guide
    List title should be formatted using markdown H3 ####.
    List subtitle's should be formatted using markdown H4 #####.
    Each document within the list should use markdown for unorder list (-) and thier titles should be bolded (**)
    
    Each entry will be structured like this...

    ### Title
    #### Subtitle
    - **List Title**: explanation 
    - **List Title** : explanation 
    ...
    `;

    const cohere = new CohereClient({
      token: 'gzFRC76Mcf0b6xueAkgBEkzjQBNDdnmxHTe6isXB',
    });

    const params = {
      preamble,
      model: 'command-r-plus',
      message: prompt,
      temperature: 0.3,
      max_tokens: 4000,
    };

    if (documents) params.documents = documents;

    const chatStream = await cohere.chatStream(params);

    this.setState({ loading: false });
    for await (const message of chatStream) {
      if (message.eventType === 'text-generation') {
        let streamedText;
        if (!this.state.answer) streamedText = message.text;
        else streamedText = this.state.answer + message.text;
        this.setState({ answer: streamedText });
      }
    }
  }

  onHide() {
    const { onHide } = this.props;
    this.setState({ error: false, testMode: false });
    onHide();
  }

  render() {
    const { show } = this.props;
    const { loading, answer } = this.state;

    return (
      <Modal dialogClassName="deal-settings" show={show} onHide={() => this.onHide()}>
        <Modal.Header closeButton>
          <span className="headline">Document Duplicate Check</span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">
            {loading && <Loader centered />}
            {!loading && <MarkdownRenderer markdown={answer} />}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
}
