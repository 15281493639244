import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import { DEAL_TYPE } from '@core/models/Deal';
import DealFactory from '@core/models/DealFactory';
import Template from '@core/models/Template';
import { Dt, dt } from '@core/utils/Environment';

import { Button, Loader } from '@components/dmp';

import TeamSelector from '@components/teams/TeamSelector';
import TemplateSelector from '@components/teams/TemplateSelector';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

export const NEW_DEAL_MODES = [
  {
    header: true,
    key: 'header-flow',
    title: 'Outlaw Flow',
  },
  {
    key: 'template',
    title: 'Template',
    action: 'Generate from template',
    tip: `Create a new ${dt} from one of your team's templates`,
    icon: 'template',
  },
  {
    header: true,
    key: 'header-draft',
    title: 'Outlaw Draft',
  },
  {
    key: 'blank',
    title: 'Blank',
    action: 'Draft from scratch',
    tip: `Draft a new ${dt} from scratch`,
    icon: 'draft',
  },
];

@autoBindMethods
export default class NewDeal extends Component {
  static defaultProps = {
    mode: 'template',
    onClose: _.noop,
    team: null,
    teams: null,
  };

  static propTypes = {
    close: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    mode: PropTypes.string,
    selectTeam: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    team: PropTypes.object,
    teams: PropTypes.array,
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      mode: props.mode,
      newTitle: '',
      isCreating: false,
      teamID: props.teams && props.teams.length === 1 ? props.teams[0].teamID : null,
    };
  }

  componentDidMount() {
    const { teams } = this.props;
    const { teamID } = this.state;

    this._isMounted = true;

    if (teamID) {
      const team = _.find(teams, { teamID });
      if (team) this.loadTemplates(team);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.team && (!this.props.team || this.props.team.teamID != nextProps.team.teamID))
      this.loadTemplates(nextProps.team);
    if (nextProps.show && nextProps.mode && nextProps.mode != this.props.mode) this.setState({ mode: nextProps.mode });
  }

  get accessibleTeams() {
    return this.props.teams.filter((team) => this.props.user.canCreateNewDoc(team));
  }

  async loadTemplates(team) {
    const { user } = this.props;
    let raw = [],
      templates = [];
    try {
      raw = await API.call('getTeamTemplates', { teamID: team.teamID, activeOnly: !user.canEditTeam(team) });
      templates = _.map(raw, (json) => new Template(json, json.dealID));
    } catch (err) {
      console.log(`Error loading templates for team [${(_.get(team, 'teamID'), 'NO ID')}]`);
    }
    if (this._isMounted) {
      this.setState({ templates, loading: false });
    }
  }

  async createDealFromTemplate({ dealTemplate, template }) {
    // create deal, then go into it!
    const { history, user } = this.props;
    await this.setState({ isCreating: true });

    if (dealTemplate.isBundle) {
      const { team } = template;
      const bundle = await API.call('createBundle', {
        teamID: team,
        templateKey: dealTemplate.template.key,
        userOrigin: user.userOrigin,
      });
      if (bundle.parent) {
        history.push(`/deals/${bundle.parent}`);
      }
    } else {
      const branding = await Fire.getBranding(user, template);
      let newDeal = await Fire.createDealFromTemplate({ user, dealTemplate, branding });

      newDeal = DealFactory.create(newDeal);

      await this.logDealCreation(newDeal);

      history.push(`/deals/${newDeal.dealID}`);
    }
  }

  async createBlankDeal() {
    const { user, team, history } = this.props;
    const { newTitle, teamID } = this.state;

    await this.setState({ isCreating: true });
    let newDealID = await Fire.createDeal({ user, title: newTitle, dealType: DEAL_TYPE.BESPOKE, teamID: teamID });

    const newDeal = await Fire.getDeal(newDealID);
    await this.logDealCreation(newDeal);

    history.push(`/deals/${newDealID}/draft`);
  }

  async logDealCreation(newDeal) {
    const { user } = this.props;
    // Log the CREATE event right after a new deal is created,
    // which will be the very first event in the audit log
    if (!newDeal.activity.length && _.get(newDeal, 'currentDealUser.role') === 'owner') {
      await Fire.addActivity(newDeal, user, DealAction.CREATE);
      await API.call('trigger', { dealID: newDeal.dealID, action: DealAction.CREATE });
    }
  }

  async onSelectedTemplate(selectedTemplate) {
    // We pass in the entire template and need to extract the deal ID
    // in order to find that deal in firebase
    const templateId = selectedTemplate.dealID;

    if (!templateId) return;

    // Fetch the dealTemplate associated with this template.
    const dealTemplate = await Fire.getDeal(templateId);

    if (!dealTemplate) return;

    const template = {
      key: templateId,
      title: selectedTemplate.title,
      description: selectedTemplate.description,
      team: this.state.teamID,
    };

    this.createDealFromTemplate({ dealTemplate, template });
  }

  selectTeam(teamID) {
    this.setState({ teamID: teamID || null, sourceTemplate: null });
  }

  clearTeam() {
    this.setState({ teamID: null });
  }

  render() {
    const { user, teams, show, close } = this.props;
    const { mode, newTitle, isCreating, teamID } = this.state;
    if (!user || !show) return null;

    const team = _.find(teams, { teamID });
    const accessibleTeams = this.accessibleTeams;

    const selectedTab = _.find(NEW_DEAL_MODES, { key: mode }) || NEW_DEAL_MODES[0];

    // This will likely be moved to a separate component, but for now,
    // There is ONE case where we want an extra-wide modal that doesn't auto-close
    // This is when we're looking at the "real" version of batch upload mode, which is only usable by Outlaw team
    const realBatch = selectedTab.key === 'batch' && user.isAdmin;

    return (
      <Modal
        dialogClassName={cx('new-deal-modal', mode, { static: realBatch })}
        show
        onHide={close}
        keyboard={!realBatch}
        backdrop={realBatch ? 'static' : true}
      >
        <Modal.Header closeButton={!realBatch}>
          <span className="headline">{selectedTab.action}</span>
        </Modal.Header>

        {isCreating ? (
          <Modal.Body className="template-loading">
            <Loader centered full size="large" />
          </Modal.Body>
        ) : (
          <>
            <Modal.Body>
              {teams && teams.length > 0 && mode !== 'batch' && (
                <FormGroup className="team-selector-wrapper">
                  <ControlLabel>Team</ControlLabel>

                  <TeamSelector
                    user={user}
                    teamID={teamID}
                    teams={accessibleTeams}
                    onSelect={this.selectTeam}
                    disableNew
                    placeholder={'Select...'}
                    disabled={team && accessibleTeams.length === 1}
                    onClear={this.clearTeam}
                  />
                </FormGroup>
              )}

              {mode === 'template' && (
                <>
                  {team ? (
                    <div className="templates outlaw">
                      <div className="new-deal-templates-list" data-cy="new-deal-templates-list">
                        <TemplateSelector
                          className="new-deal-search"
                          id="existing-template"
                          team={team}
                          size="large"
                          type="search"
                          placeholder="Search Templates"
                          searchable
                          open={true}
                          onSelect={this.onSelectedTemplate}
                        />
                      </div>
                    </div>
                  ) : (
                    <Modal.Body className="template-no-team-template outlaw">
                      Select a team to reveal available templates
                    </Modal.Body>
                  )}
                </>
              )}

              {mode === 'blank' && (
                <div className="wrapper">
                  <FormGroup>
                    <ControlLabel>{Dt} Title</ControlLabel>
                    <div className="contents">
                      <FormControl
                        type="text"
                        value={newTitle}
                        data-cy="new-deal-title"
                        placeholder={`e.g., Vendor ${Dt}`}
                        onChange={(e) => this.setState({ newTitle: e.target.value })}
                      />
                    </div>
                  </FormGroup>
                </div>
              )}
            </Modal.Body>

            {mode === 'blank' && (
              <Modal.Footer>
                <Button
                  dmpStyle="primary"
                  disabled={!newTitle || !team}
                  onClick={this.createBlankDeal}
                  data-cy="btn-create-new-contract"
                >
                  Create
                </Button>
              </Modal.Footer>
            )}
          </>
        )}
      </Modal>
    );
  }
}
