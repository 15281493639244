const DealAction = {
  //not actually stored, but action user takes to manage editing state
  EDIT: 'edit',
  //SAVE: 'save',
  //CANCEL: 'cancel',
  //SOURCE: 'source',
  READ: 'read', //for pending statuses that need review

  SHARE_SECRET: 'shareSecret', //logged when user saves a PROTECTED variable value
  VIEW_SECRET: 'viewSecret', //logged when non-assigned user views a PROTECTED variable

  CREATE: 'create',
  // Used instead of CREATE, when deal is copied from another deal (instead of template)
  // In this case, event data will be the dealID of the source deal
  COPY: 'copy',
  UPDATE: 'update',
  DELETE: 'delete',
  RESTORE: 'restore',
  SEND: 'send',
  REJECT: 'reject', //fired when user adds a comment on a section
  SUBMIT: 'submit', //fired when user resolves section comments
  APPROVE: 'approve', //fired when user saves a section and approves all prior changes with no new ones
  PASTE: 'paste', //fired when user pastes in multiple ITEM sections into LIST or SCOPE
  CLEAR_LIST: 'clearList', //user clears all ITEMs in LIST

  CREATE_VERSION: 'createVersion',
  DELETE_VERSION: 'deleteVersion',

  VARIABLE: 'variable', //fired to log variable updates

  //TERMS: 'terms', //user agrees to terms (required before signing a deal)
  // LOCK: 'lock',
  SIGN: 'sign',
  BULK_SIGN: 'bulkSign',
  UNSIGN: 'unsign', //user clears own signature
  CLEAR: 'clear', //user clears all signatures (to re-enable editing)
  COMPLETE: 'complete', //deal is fully executed (called after last signature)
  READY_CHECK: 'readyCheck', //owner starts a ReadyCheck

  WEBHOOK: 'webhook',
  WEBHOOK_UPDATE: 'webhookUpdate',
  INTEGRATION: 'integration',

  UPDATE_STATUS: 'updateStatus',
  CHECKPOINT_ACTIVITY: 'checkpointActivity',
};

export const CHECKPOINT_ACTIVITY_MESSAGE = {
  RE_RUN: { action: 'RE_RUN', message: 're-ran checkpoint' },
  RUN: { action: 'RUN', message: 'ran checkpoint' },
  REMOVE_VOTE: { action: 'REMOVE_VOTE', message: 'removed vote from checkpoint' },
  APPROVE: { action: 'APPROVE', message: 'approved checkpoint' },
  REJECT: { action: 'REJECT', message: 'rejected checkpoint' },
  COMPLETE: { action: 'COMPLETE', message: 'completed checkpoint' },
  CANCEL: { action: 'CANCEL', message: 'cancelled checkpoint' },
  SKIP: { action: 'SKIP', message: 'skipped checkpoint' },
};

export default DealAction;
