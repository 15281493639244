import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Lens, { CLAUSE_LENS_PROMPTS, LENS_TYPES } from '@core/models/Lens';
import { getOperators } from '@core/models/Operator';

import { Alert, ButtonIcon, Icon } from '@components/dmp';

import ConditionsView from '@components/editor/ConditionsView';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

import LensAdvancedEditor from './LensAdvancedEditor';
import LensClauseEditor from './LensClauseEditor';
import LensVariableEditor from './LensVariableEditor';

const LensDisplay = (props) => {
  const {
    lens,
    isEditing,
    isShowing,
    isDeleting,
    deal,
    toggleShowLens,
    setDeletingLens,
    setEditingLens,
    setEditingFilter,
    reset,
    editingFilter,
    editingConfig,
    setEditingConfig,
  } = props;

  const hasLinkingError = (lens) => {
    let isSetForExtraction = true;

    if (!lens.isClause) return !isSetForExtraction;

    if (_.size(lens.relatedSections) === 1) {
      //check if the section is set for extraction...
      const section = _.find(deal.sections, { id: lens.relatedSections[0] });
      isSetForExtraction = section.extract;
    } else {
      isSetForExtraction = false;
    }

    return !isSetForExtraction;
  };

  const onClickTitle = () => {
    switch (lens.type) {
      case 'advanced':
        return setEditingConfig(lens);
      case 'clause':
        return setEditingConfig(lens);
      case 'variable':
        return null;
    }
  };

  const titleClassName = () => {
    switch (lens.type) {
      case 'advanced':
        return 'lens-advanced-title';
      case 'clause':
        return 'lens-clause-title';
      case 'variable':
        return 'lens-variable-title';
    }
  };

  const sortedFilters = () => {
    switch (lens.type) {
      case 'advanced':
        return _.orderBy(lens.advancedFilters, ['isDefault', 'timestamp'], ['asc', 'asc']);
      case 'clause':
        return _.sortBy(lens.clauseFilters, ['title']);
      case 'variable':
        const variable = deal.variables[lens.relatedVariable];
        if (!variable) return lens.valueFilters;

        const operators = getOperators(variable.valueType);
        if (!operators.length) return lens.valueFilters;

        return _.values(lens.valueFilters).sort((a, b) => {
          const titleA = _.findIndex(operators, { key: a.valueFilter.operator.key });
          const titleB = _.findIndex(operators, { key: b.valueFilter.operator.key });
          if (titleA < titleB) return -1;
          if (titleA > titleB) return 1;
          return 0;
        });
    }
  };

  const filterTitle = (filter) => {
    switch (lens.type) {
      case 'advanced':
        return filter.option;
      case 'clause':
        return _.find(CLAUSE_LENS_PROMPTS, { id: filter.id }).title;
      case 'variable':
        return filter.valueFilter.displayLabel;
    }
  };

  const lensEditor = () => {
    switch (lens.type) {
      case 'advanced':
        return (
          <LensAdvancedEditor
            deal={deal}
            lens={lens}
            onHide={(persistEditing) => reset(persistEditing)}
            advancedFilter={editingFilter}
            editingConfig={editingConfig}
            key={lens.id}
          />
        );
      case 'clause':
        return (
          <LensClauseEditor
            deal={deal}
            lens={lens}
            onHide={(persistEditing) => reset(persistEditing)}
            clauseFilter={editingFilter}
            editingConfig={editingConfig}
            key={lens.id}
          />
        );
      case 'variable':
        return (
          <LensVariableEditor
            deal={deal}
            lens={lens}
            onHide={(persistEditing) => reset(persistEditing)}
            valueFilter={editingFilter}
            key={lens.id}
          />
        );
    }
  };

  const renderLinkingAlert = (lens) => {
    const section = _.find(deal.sections, { id: lens.relatedSections[0] });

    const alertText = 'Clause extraction must be enabled for the linked section. Would you like to enable it?';

    return (
      <Alert dmpStyle="danger" size="small">
        {alertText}
        <div className="alert-actions">
          <a onClick={() => Fire.saveSection(section, { extract: !section.extract })}>Enable extraction</a>
        </div>
      </Alert>
    );
  };

  const icon = _.find(LENS_TYPES, { key: lens.type }).icon;

  return (
    <div className={cx('display-block', { error: hasLinkingError(lens) })} key={lens.id} data-cy="lens-display-block">
      <div className="left">
        <Icon name={icon} className="condition-icon" />
      </div>
      <div className="right">
        <div className="lens-topline">
          <div
            className={cx(
              'lens-title',
              { 'lens-clause-title': lens.isClause },
              { 'lens-advanced-title': lens.isAdvanced }
            )}
            onClick={onClickTitle}
            data-cy={titleClassName()}
          >
            {lens.title}
          </div>
          {isShowing && (
            <ButtonIcon
              icon="chevronUp"
              className="openContent"
              size="default"
              onClick={() => toggleShowLens(lens, 'remove')}
            />
          )}
          {!isShowing && (
            <ButtonIcon
              icon="chevronDown"
              className="closeContent"
              size="default"
              onClick={() => toggleShowLens(lens, 'add')}
            />
          )}
        </div>
        {lens.question && <div className="question">{lens.question}</div>}
        {hasLinkingError(lens) && lens.isClause && !isEditing && isShowing && renderLinkingAlert(lens)}
        {isShowing &&
          _.map(sortedFilters(), (filter, index) => {
            return (
              <>
                <div className={cx('lens-filter', { editing: editingFilter?.id === filter.id })} key={filter.id}>
                  <div
                    className="filter-display-label"
                    onClick={() => {
                      setEditingFilter(filter);
                      setEditingLens(lens);
                    }}
                    data-cy="filter-display-label"
                  >
                    {lens.isAdvanced ? `${String.fromCharCode(65 + index)}. ` : ''}
                    {filterTitle(filter)}
                    {lens.isAdvanced && filter.isDefault && (
                      <TooltipButton
                        tip={
                          'A default “None of the above” option is automatically added to each Advanced Lens. A score may be assigned to this option.'
                        }
                      >
                        <Icon name="info" />
                      </TooltipButton>
                    )}
                  </div>
                  <div
                    className="filter-risk-value"
                    onClick={() => {
                      setEditingFilter(filter);
                      setEditingLens(lens);
                    }}
                  >
                    {filter.riskValue}
                  </div>
                </div>
                {_.size(filter.conditions) > 0 && (
                  <div>
                    <ConditionsView conditions={_.values(filter.conditions)} deal={deal} />
                  </div>
                )}
                {isEditing && isShowing && filter.id === editingFilter?.id && lensEditor()}
              </>
            );
          })}
        {!isEditing && isShowing && (
          <div className="lens-filter add-new" onClick={() => setEditingLens(lens)}>
            <Icon name="plus2" size="small" />
            Add scoring factor
          </div>
        )}
        {isEditing && isShowing && !editingFilter && lensEditor()}

        {!isEditing && isShowing && !isDeleting && (
          <ButtonIcon icon="trash" className="delete" size="default" onClick={() => setDeletingLens(lens)} />
        )}
      </div>
    </div>
  );
};

LensDisplay.propTypes = {
  lens: PropTypes.instanceOf(Lens).isRequired,
  isEditing: PropTypes.bool.isRequired,
  isShowing: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  deal: PropTypes.object.isRequired,
  toggleShowLens: PropTypes.func.isRequired,
  setDeletingLens: PropTypes.func.isRequired,
  setEditingLens: PropTypes.func.isRequired,
  setEditingFilter: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  editingFilter: PropTypes.object,
  editingConfig: PropTypes.bool.isRequired,
  setEditingConfig: PropTypes.func.isRequired,
};

export default LensDisplay;
