import React, { Component, createRef } from 'react';

import { arrayMoveImmutable } from 'array-move';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { STEPS } from '@core/enums/DealStatus';
import { AI_ENGINES } from '@core/models/AIPrompt';
import Team from '@core/models/Team';
import User, { isAdmin } from '@core/models/User';
import Workflow, { DEFAULT_WORKFLOW, WORKFLOW_LEGAL, WORKFLOW_SERVICE_PROVIDER } from '@core/models/Workflow';
import WorkflowStep from '@core/models/WorkflowStep';
import { getSafeKey } from '@core/utils/Generators';

import {
  Button,
  Checkbox,
  DragHandle,
  Dropdown,
  Ellipsis,
  Loader,
  MenuItem,
  Swatch,
  Switch,
  Tag,
  Validator,
} from '@components/dmp';

import WorkflowSelector from '@components/teams/WorkflowSelector';
import WorkflowStepEditor from '@components/teams/WorkflowStepEditor';
import Fire from '@root/Fire';

const StepView = React.forwardRef(({ step, onEdit, onRemove }, ref) => {
  const movable = typeof onRemove === 'function';
  return (
    <Tag block color="gray" removable={movable} onRemove={onRemove}>
      <DragHandle disabled={!movable} />
      <Swatch color={step.color} />
      <Ellipsis className="step-title" onClick={() => onEdit(step)} ref={ref}>
        {step.name}
      </Ellipsis>
    </Tag>
  );
});

const SortableStep = sortableElement(({ step, onRemove, onEdit, forwardRef }) => {
  return (
    <li className="sortable" data-cy="sortable">
      <StepView step={step} onEdit={onEdit} onRemove={onRemove} ref={forwardRef} />
    </li>
  );
});

const SortableContainer = sortableContainer(({ children }) => <ul>{children}</ul>);

@autoBindMethods
export default class WorkflowEditor extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    team: PropTypes.instanceOf(Team).isRequired,
    show: PropTypes.bool.isRequired,
    workflow: PropTypes.object,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      workflowKey: '',
      validKey: false,
      isDefault: false,
      description: '',
      steps: [],
      editingStep: null,
      basedOnKey: null,
      serviceProviders: false,
      serviceProviderName: '',
      aiEngine: null,
    };

    this.refSteps = {};
    this.refModal = createRef();

    // Placeholder for creating new workflows;
    // this is necessary so that WorkflowStep instances always have a valid reference back to parent Workflow
    // even when it's new
    this.newWorkflow = null;
  }

  componentDidMount() {
    this.updateBasedOn();
  }

  componentDidUpdate(prevProps) {
    const { workflow, show } = this.props;

    if (!_.isEqual(workflow, prevProps.workflow) || !_.isEqual(show, prevProps.show)) {
      this.populate();
    }
  }

  get isNew() {
    return !this.props.workflow;
  }

  get workflow() {
    return this.props.workflow || this.newWorkflow;
  }

  get sortableSteps() {
    const { steps } = this.state;
    if (steps.length < 2) return [];
    return steps.slice(1, steps.length - 1);
  }

  populate() {
    const workflow = this.workflow;
    const steps = _.get(workflow, 'steps', []);

    this.setState({
      name: _.get(workflow, 'name') || '',
      workflowKey: this.isNew ? '' : _.get(workflow, 'workflowKey', '') || '',
      description: this.isNew ? '' : _.get(workflow, 'description', '') || '',
      isDefault: this.isNew ? false : _.get(workflow, 'isDefault', false),
      serviceProviders: _.get(workflow, 'serviceProviders', false),
      serviceProviderName: _.get(workflow, 'serviceProviderName'),
      collectionStepName: _.get(workflow, 'collectionStepName'),
      aiEngine: _.get(workflow, 'aiEngine', null),
      steps,
      loading: false,
    });

    _.forEach(steps, (step) => {
      if (!this.refSteps[step.key]) this.refSteps[step.key] = createRef();
    });
  }

  handleChange(e, prop) {
    this.setState({ [prop]: e.target.value });

    // Updating template title or key on a new template also requires key sanitization,
    // followed by (debounced) validation to ensure uniqueness
    if ((prop === 'name' || prop === 'workflowKey') && this.isNew) {
      const workflowKey = getSafeKey(e.target.value, false, true, '');
      this.setState({ workflowKey });
    }
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { steps } = this.state;
    let sortableSteps = this.sortableSteps;

    sortableSteps = arrayMoveImmutable(sortableSteps, oldIndex, newIndex);
    this.setState({ steps: [steps[0], ...sortableSteps, _.last(steps)] });
  }

  addStep() {
    const { steps } = this.state;
    const name = `Step ${steps.length}`;
    const key = `step${steps.length}`;
    steps.splice(steps.length - 1, 0, new WorkflowStep({ name, key }, this.workflow));
    this.setState({ steps });
    this.refSteps[key] = createRef();
  }

  removeStep(step) {
    const { steps } = this.state;
    const idx = _.findIndex(steps, step);
    if (idx > -1) {
      steps.splice(idx, 1);
      this.setState({ steps });
    }
  }

  focusInput() {
    if (this.refName) {
      this.refName.focus();
    }
  }

  async validateKey(workflowKey) {
    const { team } = this.props;
    const valid = !!workflowKey && !_.find(team.workflows, { workflowKey });
    return valid;
  }

  // Create a copy for editing so that it doesn't affect state if editing is cancelled
  editStep(step) {
    const editingStep = new WorkflowStep(step.json, this.workflow);
    this.setState({ editingStep });
  }

  commitEditStep(originalStep, newStep) {
    const { steps } = this.state;

    const idx = _.findIndex(steps, { key: originalStep.key });
    if (idx > -1) {
      steps.splice(idx, 1, newStep);
      this.setState({ steps });
      this.refSteps[newStep.key] = createRef();
    }
  }

  updateBasedOn(basedOnKey = null) {
    const { team } = this.props;

    switch (basedOnKey) {
      case DEFAULT_WORKFLOW.workflowKey:
        this.newWorkflow = new Workflow(DEFAULT_WORKFLOW);
        break;
      case WORKFLOW_LEGAL.workflowKey:
        this.newWorkflow = new Workflow(WORKFLOW_LEGAL);
        break;
      case WORKFLOW_SERVICE_PROVIDER.workflowKey:
        this.newWorkflow = new Workflow(WORKFLOW_SERVICE_PROVIDER);
        break;
      case '':
      case null:
        // If there's no key selected, see if there's a default wf on the team
        // (this will match what is shown in the WorkflowSelector)
        const defaultTeamWF = _.find(team.workflows, { isDefault: true });
        if (defaultTeamWF) {
          this.newWorkflow = new Workflow(defaultTeamWF.json);
        }
        // If not, use default Outlaw workflow
        else {
          this.newWorkflow = new Workflow(DEFAULT_WORKFLOW);
        }
        break;
      default:
        const [, workflowKey] = basedOnKey.split(':');
        if (workflowKey) {
          const wf = _.find(team.workflows, { workflowKey });
          if (wf) this.newWorkflow = new Workflow(wf.json);
        }
        break;
    }

    this.setState({ basedOnKey });
    this.populate();
  }

  async save() {
    const { team, onSave, onHide } = this.props;
    const { steps } = this.state;

    const workflow = _.pick(this.state, [
      'name',
      'workflowKey',
      'description',
      'isDefault',
      'serviceProviders',
      'serviceProviderName',
      'collectionStepName',
      'aiEngine',
    ]);
    workflow.steps = _.map(steps, 'json');

    await this.setState({ loading: true });
    await Fire.saveWorkflow(team, workflow);

    onHide();
    onSave();
  }

  renderStep(step, sortIndex = -1) {
    if (sortIndex === -1) {
      return <StepView step={step} onEdit={this.editStep} ref={this.refSteps[step.key]} />;
    }
    return (
      <SortableStep
        forwardRef={this.refSteps[step.key]}
        key={`item-${sortIndex}`}
        index={sortIndex}
        step={step}
        onRemove={() => this.removeStep(step)}
        onEdit={this.editStep}
      />
    );
  }

  render() {
    const { onHide, show, team, user } = this.props;
    const {
      name,
      workflowKey,
      isDefault,
      description,
      loading,
      validKey,
      steps,
      editingStep,
      basedOnKey,
      serviceProviders,
      serviceProviderName,
      collectionStepName,
      aiEngine,
    } = this.state;

    const engine = _.find(AI_ENGINES, { key: aiEngine }) || AI_ENGINES[0];

    return (
      <Modal
        dialogClassName="workflow-editor"
        backdrop="static"
        show={show}
        onHide={onHide}
        onEnter={this.focusInput}
        data-cy="workflow-editor"
      >
        <Modal.Header closeButton>
          <span className="headline">{this.isNew ? 'New workflow' : 'Update workflow'}</span>
        </Modal.Header>
        <Modal.Body ref={this.refModal}>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <div className="contents">
              <FormControl
                type="text"
                bsSize="small"
                inputRef={(ref) => (this.refName = ref)}
                disabled={loading}
                value={name}
                placeholder="Enter workflow name"
                onChange={(e) => this.handleChange(e, 'name')}
                data-cy="workflow-name"
              />
              <Checkbox
                id="chk-workflow-default"
                checked={isDefault}
                onChange={() => this.setState({ isDefault: !isDefault })}
              >
                Set as default
              </Checkbox>
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Workflow Key</ControlLabel>
            <div className="contents workflow-key dmp-validator-container">
              <FormControl
                bsSize="small"
                readOnly={!this.isNew}
                type="text"
                value={workflowKey}
                placeholder="Required"
                onChange={(e) => this.handleChange(e, 'workflowKey')}
                data-cy="workflow-key"
              />
              {this.isNew && (
                <Validator
                  validate={this.validateKey}
                  validateEmpty
                  value={workflowKey}
                  onResult={(validKey) => this.setState({ validKey })}
                  validTip="Available"
                  invalidTip={
                    !workflowKey
                      ? 'Enter a unique key for this workflow'
                      : 'A workflow with this key already exists on this team'
                  }
                />
              )}
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Description</ControlLabel>
            <div className="contents">
              <FormControl
                bsSize="small"
                type="text"
                value={description}
                placeholder="Optional"
                onChange={(e) => this.handleChange(e, 'description')}
                data-cy="workflow-description"
              />
            </div>
          </FormGroup>

          {team.features.vineAI && isAdmin(user) && (
            <FormGroup>
              <ControlLabel>AI Engine</ControlLabel>
              <Dropdown
                id="dd-ai-engine"
                title={engine.title}
                onSelect={(aiEngine) => this.setState({ aiEngine })}
                size="small"
                block
              >
                {_.map(AI_ENGINES, (engine, idx) => (
                  <MenuItem key={idx} eventKey={engine.key}>
                    {engine.title}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
          )}

          {team.features.serviceProviders && (
            <FormGroup>
              <ControlLabel>Service Providers</ControlLabel>
              <div className="contents">
                <Switch
                  checked={serviceProviders}
                  id="chk-service-provider"
                  onChange={() => this.setState({ serviceProviders: !serviceProviders })}
                  size="small"
                >
                  Support Service Providers
                </Switch>
              </div>
            </FormGroup>
          )}

          {serviceProviders && (
            <FormGroup>
              <ControlLabel>Service Provider Name</ControlLabel>
              <div className="contents">
                <FormControl
                  bsSize="small"
                  type="text"
                  value={serviceProviderName}
                  placeholder="Optional"
                  onChange={(e) => this.handleChange(e, 'serviceProviderName')}
                  data-cy="workflow-service-provider-name"
                />
              </div>
            </FormGroup>
          )}

          {serviceProviders && (
            <FormGroup>
              <ControlLabel>Data Collection Step Name</ControlLabel>
              <div className="contents">
                <FormControl
                  bsSize="small"
                  type="text"
                  value={collectionStepName}
                  placeholder='e.g., "Case Data"'
                  onChange={(e) => this.handleChange(e, 'collectionStepName')}
                  data-cy="workflow-collection-step"
                />
              </div>
            </FormGroup>
          )}

          {this.isNew && (
            <FormGroup>
              <ControlLabel>Based On</ControlLabel>
              <div className="contents" data-cy="basedon-workflow-selector">
                <WorkflowSelector
                  id="dd-basedon-workflow"
                  onSelect={this.updateBasedOn}
                  workflowKey={basedOnKey}
                  team={team}
                  block
                  size="small"
                />
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <ControlLabel>Steps</ControlLabel>
            <div className="contents" data-cy="workflow-steps-content">
              {steps.length > 0 && this.renderStep(steps[0])}
              <SortableContainer axis="y" onSortEnd={this.onSortEnd} distance={5}>
                {this.sortableSteps.map(this.renderStep)}
              </SortableContainer>

              <div className="add-workflow-step">
                <Button icon="plus2" onClick={this.addStep} size="small" data-cy="btn-add-workflow-step">
                  Add workflow step
                </Button>
              </div>

              {steps.length > 1 && this.renderStep(_.last(steps))}
              {editingStep && (
                <WorkflowStepEditor
                  step={editingStep}
                  target={this.refSteps[editingStep.key].current}
                  container={this.refModal.current}
                  hide={() => this.setState({ editingStep: null })}
                  onSave={this.commitEditStep}
                  checkpointGroups={this.props.team?.checkpointGroups}
                  serviceProviders={serviceProviders}
                />
              )}
            </div>
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          {loading && <Loader />}
          <Button disabled={loading} onClick={onHide}>
            Cancel
          </Button>
          <Button
            dmpStyle="primary"
            disabled={loading || (this.isNew && !validKey)}
            onClick={this.save}
            data-cy="btn-save"
          >
            {this.isNew ? 'Save' : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
