import React, { Component } from 'react';

import autobindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Filter from '@core/models/Filter';
import Report from '@core/models/Report';
import SearchParams from '@core/models/SearchParams';

import { Loader } from '@components/dmp';

import API from '@root/ApiClient';

import ReportItemStatus from './ReportItemStatus';

@autobindMethods
class ReportItemPipeline extends Component {
  static propTypes = {
    filter: PropTypes.instanceOf(Filter).isRequired,
    report: PropTypes.instanceOf(Report).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      data: null,
      isLoading: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getReportData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async getReportData() {
    const { filter } = this.props;
    let { searchParams } = filter;

    const searchParamsModel = new SearchParams(null, null, searchParams);

    const results = await API.call('getPipeline', {
      ...searchParamsModel.apiArgs,
      teamID: filter.teamID,
      filterID: filter.filterID,
    });

    if (this._isMounted) this.setState({ data: _.get(results, 'steps', []), isLoading: false });
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div className="report-item-content pipeline" data-cy="report-item-content-pipeline">
        {isLoading ? <Loader centered /> : this.renderReport()}
      </div>
    );
  }

  renderReport() {
    const { data } = this.state;

    return (
      <div className="status-grid">
        {data.map((item, key) => (
          <ReportItemStatus
            key={`status-${key}-${item.status.toLowerCase().replaceAll(' ', '-')}`}
            status={item.status}
            color={item.color}
            total={item.total}
          />
        ))}
      </div>
    );
  }
}

export default ReportItemPipeline;
