import { autoBindMethods } from 'class-autobind-decorator';
import { find, findIndex, forEach, get, keys, remove } from 'lodash';
import qs from 'query-string';

import { TEAM_ROLES } from './Team';

export const ACCESS_TYPES = {
  ADMIN: { type: 'admin' },
  PARTNER: { type: 'partner' },
};

export const FILTERS = [
  { key: 'names', name: 'Names', multi: true, render: false, hide: true },
  { key: 'emails', name: 'Email', multi: true, render: false, hide: true },
  {
    key: 'teams',
    name: 'Team',
    multi: true,
    options: null,
    optionKey: 'teamID',
    optionAccessor: 'info.name',
  },
  {
    key: 'teamID',
    name: 'Team',
    multi: false,
    options: null,
    optionKey: 'teamID',
    optionAccessor: 'info.name',
  },
  {
    key: 'roles',
    name: 'Role',
    multi: true,
    options: TEAM_ROLES,
    optionKey: 'value',
    optionAccessor: 'title',
  },
  {
    key: 'access',
    name: 'Access',
    multi: true,
    options: ACCESS_TYPES,
    optionKey: 'type',
    optionAccessor: 'type',
  },
  { key: 'observer', name: 'Observer', multi: false, type: 'bool' },
  { key: 'defaultFilter', name: 'Default Filter', multi: false, type: 'bool' },
];

export const DEFAULT_USER_SORT = 'fullName.asc';
export const DEFAULT_TEAM_SORT = 'name.asc';

@autoBindMethods
export default class AdminSearchParams {
  location;
  history;
  params;
  page;
  tab;
  defaultSort;

  constructor(location, history, defaultSort) {
    this.location = location;
    this.history = history;
    let params;

    //load in from URL
    params = qs.parse(location.search);
    this.params = params;
    this.tab = this.location.pathname.split('/')[2];
    this.defaultSort = defaultSort;

    // For each param in querystring, lookup the corresponding named facet for display
    forEach(params, (value, key) => {
      // Special url params handled elsewhere -- skip these in the loop
      if (
        [
          'defaultFilter',
          'names',
          'emails',
          'teams',
          'titles',
          'roles',
          'observer',
          'access',
          'hitsPerPage',
          'page',
          'query',
          'teamID',
        ].indexOf(key) > -1
      )
        return;
    });

    // Paging is visually a 1-based index, but convert to 0-based internally for API/Search
    this.page = params.page ? params.page - 1 : 0;
    if (this.tab === 'users') {
      params.defaultFilter = params.defaultFilter ? params.defaultFilter : true;
    }
  }

  //all multiple selectable parameters will be shown as x|y|z|... and generated into an array on the way back in.
  setParams(key, value) {
    const filter = find(FILTERS, (filter) => {
      return filter.key === key;
    });

    if (filter?.multi) {
      let values = get(this.params, key) ? get(this.params, key).split('|') : [];
      if (values.length > 0) {
        //remove
        if (
          findIndex(values, (entry) => {
            return entry === value;
          }) > -1
        ) {
          values = remove(values, (entry) => {
            return entry !== value;
          });
        }
        //add
        else {
          values.push(value);
        }
      }

      //if there is not parameter yet create it
      else {
        values.push(value);
      }

      //if there is nothing in the parameter delete it.
      if (values.length === 0) {
        delete this.params[key];
      } else {
        this.params[key] = values.join('|');
      }
    } else {
      if (typeof value === 'undefined' || value === null) delete this.params[key];
      else this.params[key] = value;
    }

    this.go();
  }

  getParams(key) {
    const filter = find(FILTERS, (filter) => {
      return filter.key === key;
    });

    // TODO: why is filter null? Should this be possible?

    if (filter?.multi) {
      let values = get(this.params, key) ? get(this.params, key).split('|') : [];
      return values;
    } else {
      return filter?.type === 'bool' && this.params[key] ? JSON.parse(this.params[key]) : this.params[key];
    }
  }

  setPage(page) {
    this.params.page = page;
    this.go();
  }

  get query() {
    return this.params.query || '';
  }

  set query(query) {
    if (query) this.params.query = query;
    else delete this.params.query;
    this.go();
  }

  get hitsPerPage() {
    return this.params.hitsPerPage || 50;
  }

  set hitsPerPage(hitsPerPage) {
    this.params.hitsPerPage = hitsPerPage;
    if (this.params.page) delete this.params.page;
    this.go();
  }

  get sort() {
    return this.params.sort ? this.params.sort : this.defaultSort;
  }

  set sort(sort) {
    this.params.sort = sort;
    if (this.params.page) delete this.params.page;
    this.go();
  }

  get apiArgs() {
    const args = {
      hitsPerPage: this.hitsPerPage,
      page: this.page,
      sort: this.sort,
      query: this.query,
    };

    forEach(FILTERS, (filter) => {
      if (filter.multi && this.getParams(filter.key).length > 0) {
        args[filter.key] = this.getParams(filter.key);
      }
      if (!filter.multi && this.getParams(filter.key) !== undefined) {
        args[filter.key] = this.getParams(filter.key);
      }
    });

    return args;
  }

  get isEmpty() {
    const params = qs.parse(location.search);

    if (keys(params).length > 0) {
      return false;
    } else {
      return true;
    }
  }

  go() {
    let path = this.location.pathname;
    if (keys(this.params).length) path += `?${qs.stringify(this.params)}`;
    this.history.push(path);
  }

  clear() {
    this.history.push(this.location.pathname);
  }
}
