import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { DEAL_TYPE } from '@core/models/Deal';
import DealOrigin from '@core/models/DealOrigin';
import DealRecord from '@core/models/DealRecord';

import { Icon, Loader } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import API from '@root/ApiClient';

export const getIcon = (dealType) => {
  switch (dealType) {
    case DEAL_TYPE.INGESTED:
    case DEAL_TYPE.EXTERNAL_WORD:
      return <Icon name="docx" />;
    case DEAL_TYPE.EXTERNAL:
      return <Icon name="pdf" />;
    case DEAL_TYPE.BESPOKE:
      return <Icon name="draft" />;
    case DEAL_TYPE.NATIVE:
    default:
      return <Icon name="native" />;
  }
};

@autoBindMethods
export default class OriginIcon extends Component {
  static defaultProps = {
    showOrigin: false,
  };

  static propTypes = {
    container: PropTypes.object,
    dealRecord: PropTypes.object.isRequired,
    showOrigin: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      origin: null,
      loading: false,
    };

    this.timeoutTip;
  }

  async getDealOrigin() {
    const { dealRecord } = this.props;
    const { origin } = this.state;

    if (origin) return;

    this.setState({ loading: true });

    const record = await API.call('getDealRecord', { dealID: dealRecord.dealID, getActivities: true });
    const newDealRecord = new DealRecord(record);
    const newOrigin = new DealOrigin(newDealRecord);

    this.setState({ origin: newOrigin, loading: false });
  }

  onMouseEnter = () => {
    this.timeoutTip = setTimeout(async () => {
      await this.getDealOrigin();
    }, 500);
  };

  render() {
    const { dealRecord } = this.props;
    const { origin, loading } = this.state;

    const tip = origin
      ? `${origin.source ? `${origin.source}` : ''} ${origin.created ? origin.created : ''} ${
          origin.creator ? `by ${origin.creator}` : ''
        }`
      : null;

    const content = (
      <span className="icon" onMouseEnter={this.onMouseEnter} onMouseLeave={() => clearTimeout(this.timeoutTip)}>
        {loading ? <Loader /> : getIcon(dealRecord.dealType)}
      </span>
    );

    return <div className="origin-icon">{origin ? <TooltipButton tip={tip}>{content}</TooltipButton> : content}</div>;
  }
}
