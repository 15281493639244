export const DEFAULTS = {
  image: null,
  alignment: "center-center",
  rotation: 0,
  size: "medium",
  start: 1,
  end: 0,
  opacity: 100,
  output: 'every-page',
  range: ''
};

export const ALIGNMENTS = {
    'top-left': 'Top Left',
    'top-center': 'Top Center',
    'top-right': 'Top Right',
    'center-left': 'Middle Left',
    'center-center': 'Middle Center',
    'center-right': 'Middle Right',
    'bottom-left': 'Bottom Left',
    'bottom-center': 'Bottom Center',
    'bottom-right': 'Bottom Right',
};

export const PDF_ALIGNMENTS = [
    ['top-left', 'top-center', 'top-right'],
    ['center-left', 'center-center', 'center-right'],
    ['bottom-left', 'bottom-center', 'bottom-right'],
];

export const OUTPUTS = {
    'every-page': 'All Pages',
    'custom': 'Custom',
};

export const SIZES = {
    'xsmall': 'Extra-small',
    'small': 'Small', 
    'medium': 'Medium', 
    'large': 'Large',
    'xlarge': 'Extra-large',
};

export const OPACITIES = {
    25: '25%',
    50: '50%',
    75: '75%',
    100: '100%',
}

export const ROTATIONS = {
    '-90': '-90°',
    '0': '0°',
    '90': '+90°',
};

export const VALIDATION = /^(?!([ \d]*-){2})\d+(?: *[-,] *\d+)*$/;

export const Watermark = {
    defaults: DEFAULTS,
    alignments: ALIGNMENTS,
    sizes: SIZES,
    opacities: OPACITIES,
    rotations: ROTATIONS,
    outputs: OUTPUTS,
};

export default Watermark;
