import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import AdminSearchParams, { FILTERS } from '@core/models/AdminSearchParams';
import Team from '@core/models/Team';

import { Button, ButtonIcon, MultiSelect, Sidebar, Switch } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import BooleanFilter from '@root/routes/admin/BooleanFilter';

@autoBindMethods
export default class UserManagementFilters extends Component {
  static propTypes = {
    adminSearchParams: PropTypes.instanceOf(AdminSearchParams),
    teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  renderFilterActions() {
    const { adminSearchParams } = this.props;
    return (
      <div className="filters-actions">
        <Button
          size="small"
          onClick={adminSearchParams.clear}
          disabled={adminSearchParams.isEmpty}
          data-cy="btn-clear-filter"
        >
          Clear
        </Button>
      </div>
    );
  }

  renderUserFilters() {
    const { teams, adminSearchParams } = this.props;
    const multiSelectFilters = _.filter(FILTERS, (filter) => {
      if (filter.key === 'teams') {
        filter.options = teams;
      }
      if (filter.multi && !filter.hide) {
        return filter;
      }
    });

    return (
      <div className="filters">
        {_.map(multiSelectFilters, (filter) => {
          const { key, name, options, optionKey, optionAccessor } = filter;
          return (
            <div className="filter-group" key={key}>
              <div className="title">{name}</div>
              <div className="options">
                <MultiSelect
                  id={`multi-${key}`}
                  options={_.map(options, (option) => {
                    return {
                      key: option[optionKey],
                      text: _.get(option, optionAccessor),
                      selected: adminSearchParams.getParams(key).indexOf(option[optionKey]) > -1,
                    };
                  })}
                  onSelect={(selection) => adminSearchParams.setParams(key, selection.key)}
                  onRemove={(removedItem) => adminSearchParams.setParams(key, removedItem.key)}
                  size="small"
                />
              </div>
            </div>
          );
        })}
        <div className="filter-group">
          <BooleanFilter
            handleSelection={(value) => adminSearchParams.setParams('observer', value)}
            filterValue={adminSearchParams.getParams('observer')}
            title="Observer"
          />
        </div>
        <div className="filter-group">
          <div className="options switch">
            <div className="switch-filter">
              <span> Show All Users</span>
              <TooltipButton tip="Switching this on will show all users, not just users who are associated with at least one team.">
                <ButtonIcon icon="info" />
              </TooltipButton>
            </div>
            <Switch
              checked={!adminSearchParams.getParams('defaultFilter')}
              id="default-filter"
              onChange={(value) => adminSearchParams.setParams('defaultFilter', !value)}
              size="small"
              data-cy="switch-filter"
            />
          </div>
        </div>
        {this.renderFilterActions()}
      </div>
    );
  }

  render() {
    return <Sidebar children={this.renderUserFilters()} />;
  }
}
