import { find } from 'lodash';

import { FACETS } from './DealRecord';

export default class FacetFilter {
  facet = null;
  values = [];

  constructor(key, values) {
    if (key) this.facet = find(FACETS, { key });
    if (values && values.length) this.values = values;
  }

  get json() {
    return {
      facet: this.facet.key,
      values: this.values,
    };
  }
}
