import { cloneDeep } from 'lodash';

import { BASE_LAYOUT, BASE_NUMBERING, baseOn } from '../models/DealStyle';

////////////////////////////
// BODY STYLES
////////////////////////////
const type = {};
type.SectionBody = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 12,
    lineSpacing: 1,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};

type.LineNumbering = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 12,
    lineSpacing: 1,
    alignment: 'right',
    bold: false,
    underline: false,
    italic: false,
    numberScheme: 'off', // will be "off", "paged", or "continuous"
  },
};

/*
{
  css: {
    fontFamily: 'Times New Roman',
    color: '#000000',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: 1.15,
  },
  docx: {
    id: 'SectionBody',
    name: 'SectionBody',
    basedOn: 'Normal',
    next: 'Normal',
    run: {
      font: 'Times New Roman',
      size: 24,
    },
    paragraph: {
      alignment: 'left',
      spacing: {
        line: 235.2, 
      },
    },
  },
  pdf: {
    fontName: 'Times New Roman', 
    fontSize: 12,
    color: '#000000',
    lineSpacing: 0,
  }
};
*/

type.SectionTitle = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 12,
    lineSpacing: 1,
    alignment: 'left',
    bold: true,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.SectionBody, 'SectionTitle', {
  css: { fontWeight: 'bold'},
  docx: { run: { bold: true } },
  pdf: { fontName: 'Times New Roman Bold' }
});
*/

type.AppendixSubtitle = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 12,
    lineSpacing: 1,
    alignment: 'center',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.SectionBody, 'AppendixSubtitle', {
  css: { textAlign: 'center' },
  docx: { paragraph: { alignment: 'center' } },
  pdf: { align: 'center' }
});
*/

type.Letterhead = cloneDeep(type.SectionBody); //baseOn(type.SectionBody, 'Letterhead');

type.Footer = cloneDeep(type.SectionBody); //baseOn(type.SectionBody, 'Footer');

type.Header = cloneDeep(type.SectionBody); //baseOn(type.SectionBody, 'Footer');

type.SignatureSubtitle = cloneDeep(type.SectionBody); //baseOn(type.SectionBody, 'SignatureSubtitle');

type.SectionNumber = cloneDeep(type.SectionTitle);

/*
baseOn(type.SectionTitle, 'SectionNumber', {
  css: { minWidth: '32px'},
});
*/

////////////////////////////
// HEADER STYLES
////////////////////////////

type.Title = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 21,
    lineSpacing: 1,
    alignment: 'center',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
{
  css: {
    fontFamily: 'Times New Roman',
    textAlign: 'center',
    color: '#000000',
    fontWeight: 'normal',
    fontSize: '28px',
    lineHeight: '30px',
    // Make sure to overwrite any <h#> settings
    marginTop: 0,
    marginBottom: 0,
  },
  docx: {      
    id: 'Title',
    name: 'Title',
    run: {
      font: 'Times New Roman',
      size: 42,
      bold: false
    },
    paragraph: {
      alignment: 'center',
      spacing: {
        line: 223.2
      }
    },
  },
  pdf: {
    fontName: 'Times New Roman', 
    fontSize: 21,
    color: '#000000',
    align: 'center',
  }
};
*/

type.H1 = cloneDeep(type.Title); //baseOn(type.Title, 'H1');

type.H2 = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 17,
    lineSpacing: 1,
    alignment: 'center',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.H1, 'H2', {
  css: {
    fontSize: '22px',
    lineHeight: '24px',
  },
  docx: {
    run: { size: 33, },
    paragraph: {
      spacing: {
        line: 228
      }
    }
  },
  pdf: {
    fontSize: 16.5,
  }
})
*/

type.H3 = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 14,
    lineSpacing: 1,
    alignment: 'center',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.H1, 'H3', {
  css: {
    fontSize: '18px',
    lineHeight: '22px',
  },
  docx: {
    run: { size: 27 },
    paragraph: {
      spacing: {
        line: 254.4
      }
    }
  },
  pdf: {
    fontSize: 13.5,
  }
});
*/

// H4 is actually closest to SectionTitle but centered

type.H4 = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 12,
    lineSpacing: 1,
    alignment: 'center',
    bold: true,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.H1, 'H4', {
  css: {
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: 1.15,
  },
  docx: {
    run: { 
      size: 24,
      bold: true 
    },
    paragraph: { 
      spacing: {
        line: 235.2
      }
    },
  },
  pdf: {
    fontName: 'Times New Roman Bold', 
    fontSize: 12,
  }
});
*/

type.SignatureTitle = cloneDeep(type.H4); //baseOn(type.H4, 'SignatureTitle');

type.AppendixTitle = cloneDeep(type.H4); //baseOn(type.H4, 'AppendixTitle');

////////////////////////////
// TABLE STYLES
////////////////////////////

type.TableHeader = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 9,
    lineSpacing: 1,
    alignment: 'left',
    bold: true,
    underline: false,
    italic: false,
  },
};

/*
{
  css: {
    fontFamily: 'Times New Roman',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
  },
  docx: {
    id: 'TableHeader',
    name: 'TableHeader',
    basedOn: 'Normal',
    run: {
      font: 'Times New Roman',
      size: 18,
      bold: true
    },
    paragraph: {
      spacing: {
        line: 240, 
        before: 150,
        after: 134, 
      }
    }
  },
  pdf: {
    fontName: 'Times New Roman Bold',
    fontSize: 9,
    color: '#000000',
  }
};
*/

type.TableBody = {
  native: {
    font: 'Times New Roman',
    color: '#2B3542',
    size: 9,
    lineSpacing: 1,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.TableHeader, 'TableBody', {
  css: {
    color: '#2B3542',
    fontWeight: 'normal',
  },
  docx: {
    run: {
      color: '#2B3542',
      bold: false,
    },
  },
  pdf: {
    fontName: 'Times New Roman',
    color: '#2B3542',
  }
});
*/

////////////////////////////
// Overview styles are currently only used on web (Draft/Flow), but still differ by theme
////////////////////////////

type.OverviewTitle = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 20,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};
/*
{
  css: {
    fontFamily: 'Times New Roman',
    color: '#000000',
    fontWeight: 'normal',
    fontSize: '27px',
    lineHeight: '40px',
  },
  // Not currently rendered to docx/pdf
  docx: {},
  pdf: {}
};
*/

type.OverviewSubtitle = {
  native: {
    font: 'Times New Roman',
    color: '#999999',
    size: 12,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.OverviewTitle, null, {
  css: {
    color: 'rgba(153, 153, 153, 1.0)',
    fontSize: '16px',
    lineHeight: '21px',
  }
});
*/

type.OverviewBody = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 12,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.OverviewSubtitle, null, {
  css: { 
    color: '#000000',
    lineHeight: '24px',
  },
});
*/

type.OverviewNumber = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 12,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: true,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.OverviewBody, null, {
  css: {  
    fontWeight: 'bold',
    minWidth: '32px',
  }
});
*/

type.FootnoteBody = {
  native: {
    font: 'Times New Roman',
    color: '#000000',
    size: 12,
    lineSpacing: 1,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
    numberScheme: 'continuous',
  },
};

export default {
  type,
  layout: cloneDeep(BASE_LAYOUT),
  numbering: cloneDeep(BASE_NUMBERING),
};
