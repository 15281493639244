import React, { Component, createRef } from 'react';

import cx from 'classnames';

import { FOOTNOTE_FONT_RATIO } from '@core/models/DealStyle';

import FootnoteEditor from '@components/FootnoteEdtior';

class FootnoteDisplay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFootnote: null,
      footnoteTarget: null,
    };
    this.refFootnoteTargets = {};
  }

  render() {
    const { activeFootnotes, container, styles, section } = this.props;
    const { selectedFootnote, footnoteTarget } = this.state;

    return (
      <>
        <div className="footnotes" style={styles} data-cy="footnotes">
          {_.map(activeFootnotes, (footnote) => {
            const fontSize = section.footnoteStyle.native.size * FOOTNOTE_FONT_RATIO;
            this.refFootnoteTargets[footnote.name] = createRef();
            return (
              <div
                key={footnote.name}
                className={cx('footnote-content', { numbered: section.showOrder })}
                ref={this.refFootnoteTargets[footnote.name]}
              >
                <div className={cx('footnote-order', { deleting: footnote.deleting })} style={{ fontSize }}>
                  {footnote.ftNumber}
                </div>
                <div
                  className={cx('footnote-body', { deleting: footnote.deleting })}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                      footnoteTarget: this.refFootnoteTargets[footnote.name].current,
                      selectedFootnote: footnote,
                    });
                  }}
                  onMouseDown={(e) => e.stopPropagation()}
                  onMouseUp={(e) => e.stopPropagation()}
                  data-cy="footnote-body"
                >
                  {footnote.value}
                </div>
              </div>
            );
          })}
          {this.state.footnoteTarget && (
            <FootnoteEditor
              rootClose
              container={container}
              deal={section.deal}
              target={footnoteTarget}
              hide={() => this.setState({ selectedFootnote: null, footnoteTarget: null })}
              footnote={selectedFootnote}
            />
          )}
        </div>
      </>
    );
  }
}

export default FootnoteDisplay;
