import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Team from '@core/models/Team';

import { Card, Icon } from '@components/dmp';

const MESSAGES = {
  OWNER: 'As a team owner, you can create reports that will be visible by all members of this team',
  OWNER_NO_FILTERS: 'Your team must have at least 1 saved filter',
  VIEWER: 'The owner(s) of this team have not added reports',
};

const EmptyReports = ({ canEdit, team }) => {
  let title = 'No personal reports shown';
  let message = null;
  if (team) {
    title = `${_.get(team, 'info.name', 'This team')} has no team reports`;
    if (canEdit && !team.filters.length) {
      message = MESSAGES.OWNER_NO_FILTERS;
    } else if (canEdit) {
      message = MESSAGES.OWNER;
    } else {
      message = MESSAGES.VIEWER;
    }
  }

  return (
    <Card className="report-item report-item-empty">
      <div className="state empty">
        <div className="icon">
          <Icon name="close" />
        </div>
        <h4>{title}</h4>
        {message && <p>{message}</p>}
      </div>
    </Card>
  );
};

EmptyReports.defaultProps = {
  canEdit: false,
};

EmptyReports.propTypes = {
  canEdit: PropTypes.bool,
  team: PropTypes.instanceOf(Team),
};

export default React.memo(EmptyReports);
