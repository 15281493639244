import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Team from '@core/models/Team';
import Workflow, { DEFAULT_WORKFLOW, WORKFLOW_LEGAL, WORKFLOW_SERVICE_PROVIDER } from '@core/models/Workflow';

import { Dropdown, MenuItem } from '@components/dmp';

const WorkflowSelector = ({
  id,
  onSelect,
  team,
  pullRight,
  disabled,
  className,
  workflowKey,
  block,
  size,
  menuWidth,
}) => {
  const pieces = (workflowKey || '').split(':');
  const isPredefined = !!workflowKey && pieces.length === 1;
  const [, teamWorkflowKey] = pieces;

  const teamWorkflows = team ? _.sortBy(team.workflows, ['isDefault']).reverse() : [];
  let selectedWorkflow = null;

  const defaultTeamWorkflow = _.find(teamWorkflows, { isDefault: true });

  if (!workflowKey && defaultTeamWorkflow) {
    selectedWorkflow = defaultTeamWorkflow;
  } else if (teamWorkflowKey) {
    selectedWorkflow = _.find(teamWorkflows, { workflowKey: teamWorkflowKey }) || defaultTeamWorkflow;
  } else if (isPredefined) {
    switch (workflowKey) {
      case DEFAULT_WORKFLOW.workflowKey:
        selectedWorkflow = new Workflow(DEFAULT_WORKFLOW);
        break;
      case WORKFLOW_LEGAL.workflowKey:
        selectedWorkflow = new Workflow(WORKFLOW_LEGAL);
        break;
      case WORKFLOW_SERVICE_PROVIDER.workflowKey:
        selectedWorkflow = new Workflow(WORKFLOW_SERVICE_PROVIDER);
        break;
    }
  }

  // If no workflow is selected/found, use default Outlaw one
  if (!selectedWorkflow) {
    selectedWorkflow = new Workflow(DEFAULT_WORKFLOW);
  }

  return (
    <>
      <Dropdown
        className={cx('dd-workflow-selector', className)}
        disabled={disabled}
        id={`workflow-selector-${id}`}
        onSelect={onSelect}
        pullRight={pullRight}
        size={size}
        title={selectedWorkflow.name}
        block={block}
        menuWidth={menuWidth}
      >
        <MenuItem header>Outlaw Workflows</MenuItem>
        <MenuItem eventKey={DEFAULT_WORKFLOW.workflowKey} active={selectedWorkflow.workflowKey === null}>
          {DEFAULT_WORKFLOW.name}
        </MenuItem>
        <MenuItem
          eventKey={WORKFLOW_LEGAL.workflowKey}
          active={selectedWorkflow.workflowKey === WORKFLOW_LEGAL.workflowKey}
        >
          {WORKFLOW_LEGAL.name}
        </MenuItem>
        {team.features.serviceProviders && (
          <MenuItem
            eventKey={WORKFLOW_SERVICE_PROVIDER.workflowKey}
            active={selectedWorkflow.workflowKey === WORKFLOW_SERVICE_PROVIDER.workflowKey}
          >
            {WORKFLOW_SERVICE_PROVIDER.name}
          </MenuItem>
        )}

        {teamWorkflows.length > 0 && <MenuItem header>{team.info.name} Workflows</MenuItem>}
        {_.map(teamWorkflows, (workflow) => (
          <MenuItem
            key={workflow.workflowKey}
            eventKey={`${team.teamID}:${workflow.workflowKey}`}
            active={workflow.workflowKey === selectedWorkflow.workflowKey}
          >
            {workflow.name} {workflow.isDefault && '(Default)'}
          </MenuItem>
        ))}
      </Dropdown>
    </>
  );
};

WorkflowSelector.defaultProps = {
  selectedTheme: null,
  pullRight: false,
  disabled: false,
  className: null,
  block: false,
  size: 'default',
  menuWidth: 250,
};

WorkflowSelector.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  team: PropTypes.instanceOf(Team),
  block: PropTypes.bool,
  workflowKey: PropTypes.string,
  pullRight: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  menuWidth: PropTypes.number,
};

export default WorkflowSelector;
