import { pick } from 'lodash';

export default class FieldMapping {
  outlaw = '';
  service = '';

  constructor({ outlaw, service }) {
    this.outlaw = outlaw;
    this.service = service;
  }

  get json() {
    return pick(this, ['outlaw', 'service']);
  }
}
