// https://stackoverflow.com/questions/512528/set-keyboard-caret-position-in-html-textbox
export const setCursor = (input, position) => {
  if (!input) return;

  if (input.createTextRange) {
    const range = input.createTextRange();
    range.move('character', position);
    range.select();
  } else {
    if (input.selectionStart) {
      input.focus();
      input.setSelectionRange(position, position);
    } else {
      input.focus();
    }
  }
};

export const getCursor = (input) => {
  let position = 0;

  // Standard
  if (input.selectionStart || input.selectionStart == 0) {
    position = input.selectionStart;
  }
  // Legacy IE
  else if (document.selection) {
    input.focus();
    const selection = document.selection.createRange();
    selection.moveStart('character', -input.value.length);
    position = selection.text.length;
  }
  return position;
};
