import React, { Component } from 'react';

import { arrayMoveImmutable } from 'array-move';
import autobindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';

import Report from '@core/models/Report';
import Team from '@core/models/Team';
import User from '@core/models/User';

import { Loader } from '@components/dmp';

import Fire from '@root/Fire';

import EmptyReports from './EmptyReports';
import ReportItem from './ReportItem';

const SortableReportContainer = SortableContainer((props) => <div {...props} />);

const SortableReportItem = SortableElement((props) => <ReportItem {...props} />);

@autobindMethods
class ReportsGrid extends Component {
  static defaultProps = {
    availableReports: [],
    canEdit: false,
    reports: [],
    team: null,
  };

  static propTypes = {
    availableReports: PropTypes.arrayOf(PropTypes.instanceOf(Report)),
    canEdit: PropTypes.bool,
    getTeams: PropTypes.func.isRequired,
    reports: PropTypes.arrayOf(PropTypes.instanceOf(Report)),
    team: PropTypes.instanceOf(Team),
    teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)).isRequired,
    user: PropTypes.instanceOf(User).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      reports: _.sortBy([...props.reports], 'index'),
    };
  }

  static getDerivedStateFromProps(props, state) {
    const newReports = _.sortBy([...props.reports], 'index');
    if (!_.isEqual(newReports, state.reports)) {
      return { reports: newReports };
    }
    return null;
  }

  async onSortEnd({ oldIndex, newIndex }) {
    const { reports } = this.state;

    const newReports = _.compact(arrayMoveImmutable(reports, oldIndex, newIndex));
    _.forEach(newReports, (report, idx) => (report.index = idx));

    await Fire.saveReportsOrder(newReports);

    this.setState({ reports: newReports });
  }

  render() {
    const { canEdit, user, team, teams } = this.props;
    const { isSaving, reports } = this.state;
    const isLoading = user.hasTeams && teams.length === 0;

    if (isLoading) return <Loader large centered />;

    if (!reports.length) return <EmptyReports canEdit={canEdit} team={team} />;

    return (
      <SortableReportContainer
        axis="xy"
        className={cx('reports-grid', { saving: isSaving })}
        onSortEnd={this.onSortEnd}
        pressDelay={50}
        useDragHandle
      >
        {this.renderReportItems()}
      </SortableReportContainer>
    );
  }

  renderReportItems() {
    const { availableReports, canEdit, user, teams, getTeams } = this.props;
    const { reports } = this.state;

    const reportItems = _.map(reports, (report, idx) => (
      <SortableReportItem
        availableReports={availableReports}
        canEdit={canEdit}
        disabled={!canEdit}
        getTeams={getTeams}
        index={idx}
        key={report.reportID}
        report={report}
        sortIndex={idx}
        teams={teams}
        user={user}
      />
    ));

    return reportItems;
  }
}

export default ReportsGrid;
