import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

import ButtonIcon from '@components/dmp/ButtonIcon';

const cl = classNamePrefixer('btn-close');

const ButtonClose = ({ className, ...passingProps }) => (
  <ButtonIcon icon="close" className={cx(cl(), className)} {...passingProps} />
);

ButtonClose.propTypes = {
  className: PropTypes.string,
};

export default ButtonClose;
