import { assign } from 'lodash';

import DealStatus from '../enums/DealStatus';
import SectionType from '../enums/SectionType';
import ActivityLog from './ActivityLog';
import { sanitize } from './Version';

// https://github.com/yahoo/react-intl/wiki/Components
export const CurrencyFormat = {
  style: 'currency',
  currency: 'usd',
  currencyDisplay: 'symbol',
  useGrouping: true,
  minimumIntegerDigits: 1,
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export const Triggers = new Map([
  ['start', 'Due at Start'],
  ['completion', 'Due at Completion'],
  ['custom', 'Other'],
]);
export const Units = new Map([
  ['hour', 'Per Hour'],
  ['day', 'Per Day'],
  ['week', 'Per Week'],
  ['custom', 'Other'],
]);

export const Types = new Map([
  ['fixed', 'Fixed'],
  ['variable', 'Variable'],
]);

//not yet used but may use in the future
export const ParseAmount = (amt) => {
  //https://stackoverflow.com/questions/25910808/javascript-regex-currency-symbol-in-a-string
  const parser =
    /[\$\xA2-\xA5\u058F\u060B\u09F2\u09F3\u09FB\u0AF1\u0BF9\u0E3F\u17DB\u20A0-\u20BD\uA838\uFDFC\uFE69\uFF04\uFFE0\uFFE1\uFFE5\uFFE6]?([\d.,]+)/;
  if (parser.test(amt)) return parser.exec(amt);
  else return null;
};

export default class Payment extends ActivityLog {
  //make Payment look like a Section so that canEdit() passes
  sectiontype = SectionType.PAYMENT;

  constructor(json, deal) {
    const { activity, amount, trigger, type, unit, id, order, custom } = json;
    super(activity, deal);
    assign(this, { amount, trigger, type, unit, id, order, custom });
  }

  get status() {
    // return this.todo == 0 ? DealStatus.AGREED : DealStatus.TODO;
    if (this.todo > 0) return DealStatus.TODO;
    else return this.activityStatus;
  }

  get todo() {
    if (!this.amount) return 1;

    //for our purposes a payment is valid as long as it contains ANY number greater than 0
    const amt = Number.parseFloat(this.amount.replace(/[^0-9\.-]+/g, ''));
    return isNaN(amt) || amt == 0 ? 1 : 0;
  }

  get displayAmount() {
    //if $ present or omitted, format as USD. otherwise leave unformatted
    let amt = this.amount || '';
    amt = amt.replace(/^\$/, '').replace(',', '');
    amt = Number.parseFloat(amt);
    return isNaN(amt) ? this.amount : amt.toLocaleString('en-US', CurrencyFormat);
  }

  get description() {
    if (!this.type) return null;
    var s = `${Types.get(this.type)} payment of ${this.displayAmount} `;

    if (this.type == 'variable') {
      s += this.unit == 'custom' ? this.custom : Units.get(this.unit).toLowerCase();
    } else {
      s += this.trigger == 'custom' ? this.custom : Triggers.get(this.trigger).toLowerCase();
    }
    s += '.';

    return sanitize(s);
  }
}
