import _ from 'lodash';

// These words (regex groups) can appear lowercase in section titles
export const TITLE_JOINERS = [
  'a',
  'an',
  'and', 
  'of', 
  'in', 
  'upon', 
  'on', 
  'the',
  'or', 
  'for', 
  'to', 
  'by', 
  'with',
  'without',
];

export const SALUTATIONS = ['Dear', 'Attn:?', 'Attention:?', 'Whom', 'Ms.?', 'Mr.?', 'Mrs.?', 'Sincerely,?'];

export const APPENDIX_NAMES = ['appendix', 'exhibit', 'schedule', 'attachment', 'annex'];

export const SECTION_NAMES = ['section', 'article', 'paragraph', 'clause'];

export const PARTY_NAMES = [
  'company',
  'client',
  'consultant',
  'contractor',
  'vendor',
  'signer',
  'counterparty',
  'buyer',
  'investor',
  'seller',
];

// This is actually a good candidate (finally) for ML
export const SIGNATURE_INDICATORS = {
  PRE: ['signature page follows', 'left blank'],
  START: ['whitness whereof', 'by signing', 'hereby agreed', 'accepted by', 'signing below'],
  PARTY: ['By', 'Name', 'Title', 'Date'],
  END: ['signature page'],
};

// Move to general utils function at some point
export const capitalize = (text) => {
  const rawWords = text.split(' ');
  const capitalizedWords = [];

  _.forEach(rawWords, (word) => {
    if (TITLE_JOINERS.includes(word.toLowerCase())) {
      capitalizedWords.push(word.toLowerCase());
    }
    else {
      capitalizedWords.push(_.capitalize(word));
    }
  });

  return capitalizedWords.join(' ');
}