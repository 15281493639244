import { pick } from 'lodash';

import { isVine } from '../utils/Environment';
import { generateNumberOptions } from '../utils/Generators';

export const MARGIN_SIZES = generateNumberOptions(0.4, 2.1, 0.1, 1);

export const MARGIN_LOCATIONS = [
  { key: 'top', title: 'Top' },
  { key: 'bottom', title: 'Bottom' },
  { key: 'left', title: 'Left' },
  { key: 'right', title: 'Right' },
];

export const LAYOUT_TYPES = {
  COLUMN: 'Column',
  INDENT: 'Indent',
  LETTERHEAD: 'Letterhead',
  PAGE_MARGIN: 'PageMargin',
  SECTION: 'Section',
  SIGNATURE_BLOCK: 'SignatureBlock',
};

export const LINE_NUMBER_OPTIONS = [
  { key: 'off', title: 'Off' },
  { key: 'continuous', title: 'Continuous' },
  { key: 'paged', title: 'Reset every page' },
];

export const FOOTNOTE_NUMBERING_OPTIONS = [
  { key: 'continuous', title: 'Continuous' },
  { key: 'paged', title: 'Reset every page' },
];

export const MEDIA_QUERY_LARGE = 992;

const LEGAL_PAPER_WIDTH = 816;
export default class LayoutStyle {
  top;
  right;
  bottom;
  left;

  constructor({ top = 0, right = 0, bottom = 0, left = 0 }) {
    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;
  }

  transform(endpoint) {
    let multiplier;
    switch (endpoint) {
      case 'inches':
        multiplier = 1 / 96;
        break;
      case 'docx':
        multiplier = 15;
        break;
      case 'pdf':
        multiplier = 0.75;
        break;
      case 'web':
      default:
        multiplier = 1;
        break;
    }

    return {
      top: this.top * multiplier,
      right: this.right * multiplier,
      bottom: this.bottom * multiplier,
      left: this.left * multiplier,
    };
  }

  get web() {
    return this.transform('web');
  }

  get docx() {
    return this.transform('docx');
  }

  get pdf() {
    return this.transform('pdf');
  }

  get json() {
    return pick(this, ['top', 'right', 'bottom', 'left']);
  }

  get inches() {
    return this.transform('inches');
  }

  //We need to style the page here based on large media query screen size.
  //Since we want to make the document viewable on different screen sizes.
  //We must only set the custom margins when viewing the document in standard size
  getPageWebMargins(window) {
    return window.innerWidth >= MEDIA_QUERY_LARGE || isVine
      ? {
          paddingTop: this.web.top,
          paddingBottom: this.web.bottom,
          paddingLeft: this.web.left,
          paddingRight: this.web.right,
          maxWidth: LEGAL_PAPER_WIDTH,
        }
      : {};
  }

  get pageBreakMargin() {
    return {
      left: -this.web.left,
      right: -this.web.right,
    };
  }

  get footerMargin() {
    return 40 - this.web.bottom;
  }
}
