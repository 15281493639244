import React from 'react';

import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { getUniqueKey } from '@core/utils/Generators';

import Dealer from '@root/Dealer';

const TooltipButton = (props) => {
  const { children, container, delay, disabled, placement, positionLeft, positionTop, tip, trigger } = props;

  if (Dealer.mobile || disabled) {
    return children;
  }

  const tipID = props.tipID || getUniqueKey();

  const tooltipProps = { id: tipID };
  if (positionTop) tooltipProps.positionTop = positionTop;
  if (positionLeft) tooltipProps.positionLeft = positionLeft;

  const overlay = <Tooltip {...tooltipProps}>{tip}</Tooltip>;

  return (
    <OverlayTrigger placement={placement} overlay={overlay} delayShow={delay} container={container} trigger={trigger}>
      {children}
    </OverlayTrigger>
  );
};

TooltipButton.defaultProps = {
  container: null,
  delay: 300,
  disabled: false,
  placement: 'top',
  positionTop: null,
  positionLeft: null,
  trigger: ['hover', 'focus'],
};

TooltipButton.propTypes = {
  children: PropTypes.node.isRequired,
  container: PropTypes.object,
  delay: PropTypes.number,
  disabled: PropTypes.bool,
  placement: PropTypes.string,
  positionTop: PropTypes.number,
  positionLeft: PropTypes.number,
  tip: PropTypes.node,
  tipID: PropTypes.string,
  trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default TooltipButton;
