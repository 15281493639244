// This is an adapted list of FV project fields to be used as connected variables
// https://developer.filevine.io/docs/v2/d39ee7d85fc16-get-project
export const PROJECT_FIELDS = [
  {
    data: 'projectId',
    externalSelector: 'projectId.native',
    label: 'Project ID',
    valueType: 'string',
  },
  {
    data: 'projectTypeId',
    externalSelector: 'projectTypeId.native',
    label: 'Project type ID',
    valueType: 'string',
  },
  {
    data: 'client',
    label: 'Client',
    valueType: 'contact',
  },
  {
    data: 'projectTypeCode',
    label: 'Project type code',
    valueType: 'string',
  },
  {
    data: 'rootDocFolderId',
    externalSelector: 'rootDocFolderId.native',
    label: 'Root doc folder ID',
    valueType: 'string',
  },
  {
    data: 'isArchived',
    label: 'Is archived?',
    valueType: 'list',
    prompt: 'Yes\nNo',
  },
  {
    data: 'lastActivity',
    label: 'Last activity',
    valueType: 'date',
  },
  {
    data: 'uniqueKey',
    label: 'Unique key',
    valueType: 'string',
  },
  {
    data: 'phaseName',
    label: 'Phase name',
    externalSelector: 'phaseName',
    valueType: 'string',
  },
  {
    data: 'clientName',
    label: 'Client name',
    valueType: 'string',
  },
  {
    data: 'firstPrimaryName',
    label: 'First primary name',
    valueType: 'string',
  },
  {
    data: 'firstPrimaryUsername',
    label: 'First primary username',
    valueType: 'string',
  },
  {
    data: 'projectOrClientName',
    label: 'Project or client name',
    valueType: 'string',
  },
  {
    data: 'projectName',
    label: 'Project name',
    valueType: 'string',
  },
  {
    data: 'incidentDate',
    label: 'Incident date',
    valueType: 'date',
  },
  {
    data: 'number',
    label: 'Case number',
    valueType: 'string',
  },
];

/*
  Unsupported project fields:
  - hashtags (array, special case necessary?)
  - description (seems deprecated?)
*/
