import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import AdminSearchParams, { FILTERS } from '@core/models/AdminSearchParams';

import { Button, Sidebar } from '@components/dmp';

import TeamSelector from '@components/teams/TeamSelector';
import API from '@root/ApiClient';

@autoBindMethods
export default class AuditManagementFilters extends Component {
  static propTypes = {
    adminSearchParams: PropTypes.instanceOf(AdminSearchParams),
    user: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { teamData: [] };
    this.getTeams();
  }

  async getTeams() {
    const teams = await API.request({ functionName: 'userAuditLogging-teams', method: 'get' });
    this.setState({ teamData: teams.data ?? [] });
  }

  renderFilterActions() {
    const { adminSearchParams } = this.props;

    return (
      <div className="filters-actions">
        <Button
          size="small"
          onClick={adminSearchParams.clear}
          disabled={adminSearchParams.isEmpty}
          data-cy="btn-clear-filter"
        >
          Clear
        </Button>
      </div>
    );
  }

  selectTeam(teamID) {
    const { adminSearchParams } = this.props;
    adminSearchParams.setParams('teamID', teamID);
  }

  renderAuditLogFilters() {
    const { adminSearchParams, user } = this.props;
    const { teamData } = this.state;

    const selectedTeamID = adminSearchParams.params.teamID ?? null;

    const auditLogFilters = _.filter(FILTERS, (filter) => {
      if (filter.key === 'teamID') {
        filter.options = teamData;
        return filter;
      }
    });

    return (
      <div className="filters">
        {_.map(auditLogFilters, (filter) => {
          const { key, name } = filter;
          return (
            <div className="filter-group" key={key}>
              <div className="title">{name}</div>
              <div className="options">
                <TeamSelector
                  user={user}
                  teamID={selectedTeamID}
                  teams={teamData}
                  onSelect={this.selectTeam}
                  disableNew
                  enableEmpty
                  placeholder="Select..."
                  size="small"
                  className="filter-team"
                  onClear={() => {
                    adminSearchParams.clear();
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    return <Sidebar children={this.renderAuditLogFilters()} />;
  }
}
