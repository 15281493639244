const EMAIL_TEMPLATES = {
  DEFAULT: 'default',
  INVITE: 'invite',
  TEAM_INVITE: 'teamInvite',
  TEMPLATE_INVITE: 'templateInvite',
  BUNDLE_INVITE: 'bundleInvite',
  READY_CHECK: 'ready',
  VOTE_READY: 'voteReady',
  ACTIVITY: 'activity',
  DEAL_COMPLETE: 'dealComplete',
  UPGRADE: 'upgrade',
  AUTH: 'auth',
  REQUEST_ACCESS: 'requestAccess',
  DASHBOARD_REPORT: 'dashboardReport',
  USER_INVITATION_ERROR: 'userInvitationError',
  REQUEST: 'request',
};

export const EMAIL_PREVIEW_QUERY = {
  DEFAULT: '?emailTemplate=default',
  INVITE: '?emailTemplate=invite',
  TEAM_INVITE: '?emailTemplate=teamInvite',
  BUNDLE_INVITE: '?emailTemplate=bundleInvite',
  READY_CHECK: '?emailTemplate=ready',
  VOTE_READY: '?emailTemplate=voteReady',
  ACTIVITY_SIGN: '?emailTemplate=activity&action=sign',
  ACTIVITY_EDIT: '?emailTemplate=activity&action=edit',
  ACTIVITY_APPROVE: '?emailTemplate=activity&action=approve',
  ACTIVITY_COMMENT: '?emailTemplate=activity&action=comment',
  ACTIVITY_RESOLVE: '?emailTemplate=activity&action=resolveComment',
  DEAL_COMPLETE: '?emailTemplate=dealComplete',
  AUTH: '?emailTemplate=auth',
  REQUEST_ACCESS: '?emailTemplate=requestAccess',
  DASHBOARD_REPORT: '?emailTemplate=dashboardReport',
  USER_INVITATION_ERROR: '?emailTemplate=userInvitationError',
  REQUEST: '?emailTemplate=request',
};

export const EMAIL_TEMPLATES_CONFIG = {
  [EMAIL_TEMPLATES.DEFAULT]: {},
  [EMAIL_TEMPLATES.INVITE]: {},
  [EMAIL_TEMPLATES.TEAM_INVITE]: {},
  [EMAIL_TEMPLATES.TEMPLATE_INVITE]: {},
  [EMAIL_TEMPLATES.BUNDLE_INVITE]: {},
  [EMAIL_TEMPLATES.READY_CHECK]: {},
  [EMAIL_TEMPLATES.VOTE_READY]: {},
  [EMAIL_TEMPLATES.ACTIVITY]: {},
  [EMAIL_TEMPLATES.DEAL_COMPLETE]: {},
  [EMAIL_TEMPLATES.UPGRADE]: {},
  [EMAIL_TEMPLATES.AUTH]: { noLogo: true },
  [EMAIL_TEMPLATES.REQUEST_ACCESS]: { noLogo: true },
  [EMAIL_TEMPLATES.DASHBOARD_REPORT]: { noLogo: true },
  [EMAIL_TEMPLATES.USER_INVITATION_ERROR]: {},
  [EMAIL_TEMPLATES.REQUEST]: {},
};

export default EMAIL_TEMPLATES;
