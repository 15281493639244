import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { CLAUSE_LENS_PROMPTS } from '@core/models/Lens';
import { OPERATORS } from '@core/models/Operator';
import VariableFilter from '@core/models/VariableFilter';

import { Tag } from '@components/dmp';

import { DATE_FILTERS } from '@components/DealFilters.jsx';

const FacetBar = (props) => {
  const { searchParams, tags, interactive, dealTemplate } = props;
  const { facets, variableQuery } = searchParams;
  const filterTags = [];
  const lenses = dealTemplate?.template?.lenses;

  const updateVariableQuery = (name) => {
    const queryVariables = searchParams.params.variableQuery.split('+');
    const updatedVariables = _.remove(queryVariables, (variable) => {
      return !variable.match(name);
    });
    const updatedQuery = updatedVariables.join('+');
    return updatedQuery;
  };

  if (searchParams.query) {
    filterTags.push(
      <Tag
        key="query"
        size="xsmall"
        removable={interactive}
        onClick={() => (interactive ? (searchParams.query = null) : null)}
      >
        <Tag.Label text="Keywords" />
        {searchParams.query}
      </Tag>
    );
  }

  if (searchParams.needsReview) {
    filterTags.push(
      <Tag
        key="needsReview"
        size="xsmall"
        removable={interactive}
        onClick={() => (interactive ? (searchParams.needsReview = null) : null)}
      >
        <Tag.Label text="Needs Review" />
        {searchParams.needsReview}
      </Tag>
    );
  }

  if (searchParams.name) {
    filterTags.push(
      <Tag
        key="name"
        size="xsmall"
        removable={interactive}
        onClick={() => (interactive ? (searchParams.name = null) : null)}
      >
        <Tag.Label text="Title" />
        {searchParams.name}
      </Tag>
    );
  }

  if (searchParams.grades.length > 0) {
    filterTags.push(
      <Tag
        key="grades"
        size="xsmall"
        removable={interactive}
        onClick={() => (interactive ? searchParams.setGrades([]) : null)}
      >
        <Tag.Label text="Grades" />
        {searchParams.grades.join(', ')}
      </Tag>
    );
  }

  if (searchParams.lenses.length > 0 && lenses) {
    _.forEach(searchParams.lenses, (rawLens) => {
      const lens = _.find(lenses, { id: rawLens.id });
      _.forEach(rawLens.filters, (f) => {
        let newLensFilters = JSON.parse(JSON.stringify(searchParams.lenses));
        const filter = _.find(lens.allFilters, { id: f.id });

        const filterTitle = (filter) => {
          switch (lens.type) {
            case 'advanced':
              return filter.option;
            case 'clause':
              return _.find(CLAUSE_LENS_PROMPTS, { id: filter.id }).title;
            case 'variable':
              return filter.valueFilter.displayLabel;
          }
        };
        const display = filterTitle(filter);

        const lensIndex = _.findIndex(newLensFilters, { id: lens.id });
        //1.) We are removing a single filter from a group of defined lens filters
        //2.) We are removing the last filter from the group. Remove the enitre lens filter
        if (newLensFilters[lensIndex].filters.length > 1) {
          newLensFilters[lensIndex].filters = _.remove(newLensFilters[lensIndex].filters, ({ id }) => {
            return filter.id !== id;
          });
        } else {
          newLensFilters = _.remove(newLensFilters, (l) => {
            return lens.id !== l.id;
          });
        }

        filterTags.push(
          <Tag
            key={`lens-tag-${filter.id}${lens.id}`}
            size="xsmall"
            removable={interactive}
            onClick={() => (interactive ? searchParams.setLenses(newLensFilters, lens.id, f.id, true) : null)}
          >
            <Tag.Label text={`${lens.title}`} />
            {display}
          </Tag>
        );
      });
    });
  }

  if (searchParams.sharingStatus) {
    filterTags.push(
      <Tag
        key="sharingStatus"
        size="xsmall"
        removable={interactive}
        onClick={() => (interactive ? (searchParams.sharingStatus = null) : null)}
      >
        <Tag.Label text="Sharing" className="capitalize" />
        {searchParams.sharingStatus}
      </Tag>
    );
  }

  if (variableQuery.length > 0) {
    _.forEach(variableQuery, (variable, index) => {
      filterTags.push(
        <Tag
          key={`variableQuery-${index}`}
          size="xsmall"
          removable={interactive}
          onClick={() => (interactive ? (searchParams.variableQuery = updateVariableQuery(variable.name)) : null)}
        >
          <Tag.Label text={`${variable.name}`} />
          {variable.value}
        </Tag>
      );
    });
  }

  // We need a nested loop here to show each individual *value* of each facet as its own (removable) tag
  _.forEach(facets, (filter) => {
    _.forEach(filter.values, (value, idx) => {
      let display = value;
      // Tag facets are special; the search values are IDs,
      // so the displayable titles must be looked up from the passed-in TagStore
      if (filter.facet.key === 'tags') {
        const tag = tags.get(value);
        if (tag) display = tag.tag;
      }

      // For facets that can have multiple values, passing the same value into toggleParam() will clear it
      // for non-multi-value facets (e.g., sourceTemplateKey), we need to explicitly pass null
      const clearValue = filter.facet.multi ? value : null;

      filterTags.push(
        <Tag
          key={`${filter.facet.key}-${idx}`}
          size="xsmall"
          removable={interactive}
          onClick={() => (interactive ? searchParams.toggleParam(filter.facet.key, clearValue) : null)}
        >
          <Tag.Label text={filter.facet.name} />
          {display}
        </Tag>
      );
    });
  });

  _.forEach(searchParams.variables, (varFilter, idx) => {
    filterTags.push(
      <Tag
        key={`var-filter-${idx}`}
        size="xsmall"
        removable={interactive}
        onClick={() =>
          interactive ? searchParams.toggleVarFilter(new VariableFilter(varFilter.variable, null)) : null
        }
      >
        <Tag.Label text={varFilter.variable} />
        {varFilter.displayLabel}
      </Tag>
    );
  });

  _.forEach(searchParams.dateFilters, (dateFilter, idx) => {
    const operator = _.get(OPERATORS, dateFilter.o, null);
    const title = _.find(DATE_FILTERS, (f) => {
      return f.name === dateFilter.n;
    }).title;

    filterTags.push(
      <Tag
        key={`date-filter-${idx}`}
        size="xsmall"
        removable={interactive}
        onClick={() => searchParams.toggleDateFilter(null, null, dateFilter.n)}
      >
        <Tag.Label text={title} />
        {operator.name === OPERATORS.DYNAMIC.name
          ? `${dateFilter.filterLabel}`
          : `${operator.title}: ${dateFilter.filterLabel} `}
      </Tag>
    );
  });

  _.forEach(searchParams.connections, (connection, idx) => {
    filterTags.push(
      <Tag
        key={`connection-${idx}`}
        size="xsmall"
        removable={interactive}
        onClick={() => searchParams.toggleConnection({ type: connection.type, id: null })}
      >
        <Tag.Label text="Connection" />
        {connection.type}: {connection.id}
      </Tag>
    );
  });

  return (
    <div className="tag-wrapper">
      <div className="tags" data-cy="tags">
        {filterTags}
        {filterTags.length > 0 && interactive && (
          <span
            className="clear-tags"
            onClick={() => {
              searchParams.clearSearch();
            }}
            data-cy="clear-tags"
          >
            clear
          </span>
        )}
      </div>
    </div>
  );
};

FacetBar.propTypes = {
  searchParams: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  interactive: PropTypes.bool,
};

FacetBar.defaultProps = {
  interactive: true,
};

export default FacetBar;
