import { assign, cloneDeep, find, forEach, get, isNil, map, pick } from 'lodash';

import SERVICES, { FILEVINE_SERVICE, SALESFORCE_SERVICE } from '../enums/IntegrationServices';

const FIELDS = ['id', 'key', 'objectType', 'source', 'sourceType', 'type', 'idFields', 'debugID'];

// Build a state-ready merged object with renderable labels from an IntegrationService,
// hydrated with values from the raw DealConnection.idFields json store and empty strings for unfilled fields
export const buildHydratedFields = (service, idFieldsJSON) => {
  let idFields;
  // Assuming we have a valid service, clone the field definitions so that we're not modifying the underlying enum
  if (service) {
    idFields = cloneDeep(service.idFields);
  }
  // If no IntegrationService is passed in, just use the keys for labels and only show the ones present
  else if (idFieldsJSON) {
    idFields = map(idFieldsJSON, (val, key) => {
      return { key, label: key };
    });
  }
  // If we've got nothing, return a default "id" field so that custom connections still works and render.
  else {
    idFields = [{ key: 'id', label: 'ID', name: '', required: true }];
  }
  // Now hydrate whatever field definitions we have with the values
  forEach(idFields, (field) => (field.value = get(idFieldsJSON, field.key, '')));
  return idFields;
};

export default class DealConnection {
  // Reference to parent Deal
  deal = null;

  // Unique key on Deal.connections collection (created by Firebase)
  key = null;
  // Outlaw-constructed string containing one or more (concatenated) ids to remote objects
  id = null;
  // Maps to a key of one of the services enumerated in IntegrationServices
  type = null;
  // TODO: deprecate (lookup service from IntegrationServices and display name of that service)
  name = null;
  // Remote object type, differs by service (e.g. "opportunity" for SF; ["project", "projectType"] for FV)
  objectType = null;
  // Type of source data, e.g., text or url
  sourceType = null;
  // Data allowing direct link back to source object (e.g., url)
  source = null;
  // Service-specific map of ids (converted from concatenated id string in constructor below)
  idFields = {};

  // Auxiliary ID for debugging connections; not used in live service connection,
  // but enables additional metadata to be stored at Template level for how connected Deals should behave
  // For example, Filevine Template connections point to a Filevine projectType, but not to a specific project
  // Populating this value in the Template connection will be used as the Filevine projectId for Deal previews (from Outlaw)
  // So that we can test connected variables directly without needing to trigger generation from Filevine
  debugID = null;

  constructor(json = {}, deal) {
    this.deal = deal;
    const { type, id } = json;

    if (!type || isNil(id)) {
      throw new Error('DealConnection require a type and id');
    }

    const ids = id.toString().split('_');

    assign(this, pick(json, FIELDS));
    if (typeof this.idFields !== 'object') this.idFields = {};

    // Depending on the connection type
    // We can use different fields of our DealConnection model to store different keys required for connection to services
    switch (type) {
      case SALESFORCE_SERVICE.key:
        this.name = 'salesforce';
        // TODO: make dynamic once there are more types supported in IntegrationServices and UI
        this.objectType = 'Opportunity';
        this.idFields.opportunityId = id;
        break;

      // This ports logic from legacy parseFilevineId so that it's baked into the DealConnection
      // It will work for legacy DealConnection objects (where only the id field was specified)
      // And new DealConnection objects will have these values stored in the db as well
      case FILEVINE_SERVICE.key:
        this.name = 'filevine';
        if (get(deal, 'isTemplate')) {
          this.objectType = 'projectTemplate';
          this.idFields.projectTypeId = ids[0];
          this.idFields.sectionSelector = ids[1];
        } else {
          this.objectType = 'project';
          this.idFields.projectId = ids[0];
          this.idFields.sectionId = ids[1];
        }
        break;
      default:
        // Default to simple id value for custom connections
        this.idFields.id = id;
        break;
    }
  }

  get json() {
    return pick(this, FIELDS);
  }

  // Get a reference to the service defined in IntegrationServices
  // For simpler access to titles/labels/etc
  get service() {
    return find(SERVICES, { key: this.type });
  }
}
