import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { MenuItem as BSMenuItem } from 'react-bootstrap';

import { classNamePrefixer } from '@core/utils/Generators';

import Ellipsis from '@components/dmp/Ellipsis';
import Icon from '@components/dmp/Icon';
import { ICONS } from '@components/dmp/shared/icons';

const cl = classNamePrefixer('menu-item');

const WrapEllipsis = ({ children, ellipsis, ...props }) =>
  ellipsis ? <Ellipsis {...props}>{children}</Ellipsis> : children;

WrapEllipsis.propTypes = {
  children: PropTypes.node,
  ellipsis: PropTypes.bool,
};

const MenuItem = ({ className, info, icon, divider, header, ellipsis, children, border, ...passingProps }) => {
  const classNames = cx(
    cl(),
    { [cl('icon')]: icon },
    { [cl('header')]: header },
    { [cl('divider')]: divider },
    { [cl('border')]: border },
    className
  );

  let IconComponent = null;
  if (icon) {
    if (ICONS[icon]) {
      IconComponent = <Icon name={icon} />;
    } else {
      throw new Error(`@dmp/MenuItem : <Icon name="${icon}"> does not exist.`);
    }
  }

  const content = (
    <>
      {IconComponent}
      {!!children && <WrapEllipsis ellipsis={ellipsis}>{children}</WrapEllipsis>}
      {!!info && (
        <div className={cl('info')}>
          <WrapEllipsis ellipsis={ellipsis}>{info}</WrapEllipsis>
        </div>
      )}
    </>
  );

  return (
    <BSMenuItem className={classNames} header={header} divider={divider} {...passingProps}>
      {!divider ? content : null}
    </BSMenuItem>
  );
};

MenuItem.displayName = 'MenuItem';

MenuItem.defaultProps = {
  children: null,
  info: null,
  icon: null,
  header: false,
  divider: false,
  border: false,
  ellipsis: false,
  disabled: false,
};

MenuItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  info: PropTypes.string,
  icon: PropTypes.string,
  header: PropTypes.bool,
  divider: PropTypes.bool,
  border: PropTypes.bool,
  ellipsis: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default MenuItem;
