import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ATTACHMENT_TYPE } from '@core/models/Attachment';
import DealVersion from '@core/models/DealVersion';
import User from '@core/models/User';

import { Button, Icon } from '@components/dmp';

import AttachmentUploader from '@components/deal/AttachmentUploader';
import CommitVersion from '@components/deal/CommitVersion';
import ChangeList from '@components/deal/DealHeader/ChangeList';
import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import DealVersionBlock from '@components/deal/DealHeader/DealVersionBlock';
import DeleteAttachment from '@components/deal/DeleteAttachment';
import TooltipButton from '@components/editor/TooltipButton';

@autoBindMethods
export default class DealVersions extends Component {
  static propTypes = {
    deal: PropTypes.object.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    selectedVersion: PropTypes.instanceOf(DealVersion),
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      uploading: null,
      deleting: null,
      committing: null,
    };
  }

  get versions() {
    const { deal } = this.props;
    return _.sortBy(deal.versions, 'dateCreated').reverse();
  }

  // VersionUploader is used to both upload new versions and update existing ones
  // We're using the state.uploading var to manage both;
  // If it's true, that means we're uploading a new version (i.e., no existing version being updated)
  // Otherwise it's a reference to the version being updated
  get updatingVersion() {
    const { uploading } = this.state;
    return typeof uploading === 'boolean' ? null : uploading;
  }

  openCommit() {
    this.setState({ committing: this.props.deal.currentVersion });
  }

  onDelete() {
    const { selectedVersion, history } = this.props;

    // onDelete is called AFTER a DealVersion is successfully deleted,
    // so selectedVersion will already have an updated deepLink reflecting 1 less version in Deal.versions
    // If the deleted version was later than the selectedVersion, we won't need to do anything,
    // But if the deletedVersion WAS the selectedVersion (or was before it), we need to sync the URL,
    // i.e., decrement the version in the URL by 1
    if (selectedVersion.deepLink !== _.get(this.props, 'location.pathname')) {
      history.push(selectedVersion.deepLink);
    }
  }

  renderPending() {
    const { deal } = this.props;
    const className = cx('deal-file-block', 'pending');
    return (
      <DealPanelItem borderBottom className={className}>
        <div className="file-icon">
          <Icon name="native" />
        </div>
        <div className="file-info" data-cy="file-info">
          <div className="file-topline">
            <div className="file-title">Unsaved changes</div>
          </div>
          <ChangeList deal={deal} />
          <Button size="small" className="commit" onClick={this.openCommit} data-cy="btn-save-version">
            Save Version
          </Button>
        </div>
      </DealPanelItem>
    );
  }

  render() {
    const { deal, user, id, container, show, onHide, target, title, history, selectedVersion } = this.props;
    const { uploading, deleting, committing } = this.state;
    const versionCount = _.keys(deal.versions).length;

    const hasPDFBlocks = deal.pdfElements ? deal.pdfElements.length > 0 : false;
    const hasPDFChanges = deal.pendingPDFChanges.length > 0;

    return (
      <DealPanel id={id} onHide={onHide} show={show} target={target} title={title} container={container}>
        <div className="filter-bar">
          <span className="version-count">
            {versionCount} version{versionCount > 1 ? 's' : ''}{' '}
          </span>
          <TooltipButton
            disabled={!hasPDFBlocks}
            placement="bottom"
            tip="You can not upload a new version while there are unsaved changes on the current version or unused PDF fields."
          >
            <Button
              size="small"
              className={cx({ disabled: hasPDFBlocks })}
              onClick={() => (!hasPDFChanges ? this.setState({ uploading: true }) : null)}
              data-cy="btn-new-version"
            >
              New Version
            </Button>
          </TooltipButton>
        </div>
        <div className="versions-list panel-scroll" data-cy="versions-list">
          {hasPDFChanges && this.renderPending()}

          {_.map(this.versions, (version) => (
            <DealVersionBlock
              key={version.key}
              version={version}
              deleteAllowed={this.versions.length > 1}
              history={history}
              selected={selectedVersion === version}
              onEdit={(version) => this.setState({ uploading: version })}
              onDelete={(version) => this.setState({ deleting: version })}
            />
          ))}
        </div>

        <AttachmentUploader
          deal={deal}
          user={user}
          show={!!uploading}
          history={history}
          item={this.updatingVersion}
          attachmentType={ATTACHMENT_TYPE.VERSION}
          onClose={() => this.setState({ uploading: null })}
        />

        <DeleteAttachment
          user={user}
          show={!!deleting}
          item={deleting}
          onClose={() => this.setState({ deleting: null })}
          onDelete={this.onDelete}
        />

        <CommitVersion
          user={user}
          deal={deal}
          show={!!committing}
          history={history}
          onClose={() => this.setState({ committing: null })}
        />
      </DealPanel>
    );
  }
}
