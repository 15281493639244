import React from 'react';
import ReactDOM from 'react-dom';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

const cl = classNamePrefixer('mobile-nav');

const MobileNav = ({ children, show, onHide, className, title, renderToRoot }) => {
  const classNames = cx(cl(), className);

  const nav = (
    <div className={classNames}>
      <div className={`side ${show ? 'on' : 'off'}`}>
        <div className="bg" onClick={onHide} />
        <div className="contents">
          {title && (
            <div className="nav-header">
              <span className="nav-title">{title}</span>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );

  if (renderToRoot) {
    // React portals let us render to a container div that differs from where this component occurs in the DOM
    // Since this is an overlay-style sidebar, we can just render to app root element
    const root = document.getElementById('outlaw');
    return ReactDOM.createPortal(nav, root);
  } else {
    return nav;
  }
};

MobileNav.propTypes = {
  children: PropTypes.node.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  renderToRoot: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
};

MobileNav.defaultProps = {
  renderToRoot: true,
};

export default MobileNav;
