import React from 'react';

import { Button } from '@components/dmp';

export default (props) => {
  return (
    <div className="empty-state" data-cy="empty-state">
      <div className="contents">
        <h3 className="lighter">{props.title}</h3>
        <p className="lighter">{props.subtitle}</p>
        {props.action && props.onAction && (
          <Button
            onClick={props.onAction}
            data-cy="btn-start-deal"
            dmpStyle={props.buttonStyle ? props.buttonStyle : 'default'}
          >
            {props.action}
          </Button>
        )}
        {props.children}
      </div>
    </div>
  );
};
