import { forEach, get, omit } from 'lodash';

import Notification from './Notification';

export default class NotificationStore {
  userID = null;
  notifications = {};
  constructor(userID, notifications = {}) {
    // Ignore the new notification settings system as it is handled separately:
    notifications = omit(notifications, 'settings');

    this.userID = userID;

    // Ignore the new notification settings system as it is handled separately:
    notifications = omit(notifications, 'settings');

    forEach(notifications, ({ type, ...options }) => {
      this.notifications[type] = new Notification(userID, type, options);
    });
  }

  isActive(type) {
    return !!get(this.notifications[type], 'active');
  }

  activate(type, options) {
    let notification = this.notifications[type];
    if (!notification) {
      notification = new Notification(this.userID, type, options);
      this.notifications[type] = notification;
    }

    // Always reset lastSent when activating, since the new Notification
    // has never been sent, it's a new one.
    return notification.save({ ...options, active: true, lastSent: null });
  }

  deactivate(type) {
    const notification = this.notifications[type];
    if (!notification) return Promise.resolve();

    return notification.deactivate();
  }
}
