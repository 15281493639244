import React from 'react';

import PropTypes from 'prop-types';

import DropdownDots from '@components/dmp/DropdownDots';

const MenuItem = DropdownDots.MenuItem;

const ScoringListItem = ({ matrix, editMatrix, confirmDelete }) => {
  const handleAction = (action) => {
    switch (action) {
      case 'editMatrix':
        editMatrix(matrix);
        break;
      case 'delete':
        confirmDelete(matrix);
        break;
      default:
        break;
    }
  };

  if (!matrix) return null;

  return (
    <div className="table-inner d-flex justify-content-between" data-cy="matrix-item">
      <div className="col theme-title">
        <span>{matrix.displayName}</span>
        <small className="block">{matrix.description}</small>
      </div>

      <DropdownDots
        pullRight
        onClick={(e) => e.stopPropagation()}
        id={`dd-${matrix.id}`}
        onSelect={handleAction}
        dataCyToggle="dd-matrix-actions"
      >
        <MenuItem eventKey="editMatrix" data-cy="edit-scoring">
          Edit Scoring Matrix
        </MenuItem>
        <MenuItem eventKey="delete" data-cy="delete-scoring">
          Delete
        </MenuItem>
      </DropdownDots>
    </div>
  );
};

ScoringListItem.propTypes = {
  matrix: PropTypes.object.isRequired,
  editMatrix: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default ScoringListItem;
