import React, { Component } from 'react';

import DealAttachmentBlock from '@components/deal/DealHeader/DealAttachmentBlock';
import DealVersionBlock from '@components/deal/DealHeader/DealVersionBlock';
import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import Attachment, { ATTACHMENT_TYPE } from '@core/models/Attachment';
import DealVersion from '@core/models/DealVersion';
import User from '@core/models/User';

import Fire from '@root/Fire';

import { Button, Loader } from '@components/dmp';

@autoBindMethods
export default class DeleteAttachment extends Component {
  static propTypes = {
    item: PropTypes.oneOfType([PropTypes.instanceOf(DealVersion), PropTypes.instanceOf(Attachment)]),
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { deleting: false };
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  get isVersion() {
    return this.props.item instanceof DealVersion;
  }

  get displayType() {
    return this.isVersion ? 'version' : 'attachment';
  }

  close() {
    if (this.state.deleting) return;
    else this.props.onClose();
  }

  async deleteAttachment() {
    const { user, item, onDelete } = this.props;

    await this.setState({ deleting: true });

    try {
      if (this.isVersion) {
        await Fire.deleteDealVersion(item);
        Fire.addActivity(item.deal, user, DealAction.DELETE_VERSION, item.key);
      } else {
        await Fire.deleteAttachment(item);
      }
      onDelete(item);
    } catch (error) {
      console.log('Error deleting attachment', error);
    } finally {
      if (this._isMounted) {
        await this.setState({ deleting: false });
      }
      this.close();
    }
  }

  render() {
    const { show, item } = this.props;
    const { deleting } = this.state;

    if (!item) return null;

    return (
      <Modal dialogClassName="delete-deal-version" show={show} onHide={this.close}>
        <Modal.Header closeButton>
          <span className="headline">Confirm {this.displayType} deletion</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <div className="bold">Delete this {this.displayType}?</div>
            <div>This file will be permanently deleted. This action can not be undone.</div>
            {this.isVersion ? (
              <DealVersionBlock version={item} interactive={false} />
            ) : (
              <DealAttachmentBlock attachment={item} interactive={false} downloadable={false} />
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          {deleting && <Loader />}
          <div className="spacer" />
          <Button onClick={this.close} disabled={deleting}>
            Cancel
          </Button>
          <Button dmpStyle="danger" disabled={deleting} onClick={this.deleteAttachment}>
            Delete {this.isVersion ? 'Version' : 'Attachment'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
