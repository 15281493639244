import React, { Component } from 'react';

import autobindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

import Notification, { FREQUENCIES, NOTIFICATION_TYPES } from '@core/models/Notification';
import User from '@core/models/User';

import { Button, Dropdown, Form, MenuItem } from '@components/dmp';

const TIMES = {
  0: '12:00 AM',
  1: '1:00 AM',
  2: '2:00 AM',
  3: '3:00 AM',
  4: '4:00 AM',
  5: '5:00 AM',
  6: '6:00 AM',
  7: '7:00 AM',
  8: '8:00 AM',
  9: '9:00 AM',
  10: '10:00 AM',
  11: '11:00 AM',
  12: '12:00 PM',
  13: '1:00 PM',
  14: '2:00 PM',
  15: '3:00 PM',
  16: '4:00 PM',
  17: '5:00 PM',
  18: '6:00 PM',
  19: '7:00 PM',
  20: '8:00 PM',
  21: '9:00 PM',
  22: '10:00 PM',
  23: '11:00 PM',
};

const DAYS_FROM_FREQUENCY = {
  // Since we're using an array, we must set 29, then skip the 0 index when we loop it in the render()
  [FREQUENCIES.MONTHLY]: _.times(29, (day) => day),
  [FREQUENCIES.WEEKLY]: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
};

const getTimeUTC = (time) => {
  const d = new Date();
  d.setHours(time);
  return d.getUTCHours();
};

const getTimeFromUTC = (time) => {
  const d = new Date();
  d.setUTCHours(time);
  return d.getHours();
};
@autobindMethods
class NotificationForm extends Component {
  static defaultProps = {};

  static propTypes = {
    notification: PropTypes.instanceOf(Notification),
    onHide: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    sendStatus: PropTypes.string,
    user: PropTypes.instanceOf(User).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      frequency: null,
      day: null,
      time: null,
      isSaving: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props.notification);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get isActive() {
    const { notification } = this.props;
    return notification && notification.active;
  }

  populate(notification) {
    if (!this._isMounted || !notification) return;

    this.setState({
      frequency: notification.frequency,
      day: notification.day,
      time: notification.time ? getTimeFromUTC(notification.time) : null,
    });
  }

  handleFrequency(frequency) {
    const newState = { frequency };

    if (frequency !== this.state.frequency) {
      newState.day = null;
    }

    if (this._isMounted) this.setState(newState);
  }

  handleTime(time) {
    this.setState({ time });
  }
  handleDay(day) {
    this.setState({ day });
  }

  async handleDeactivate() {
    const {
      onHide,
      user: { notificationStore },
    } = this.props;
    await this.setState({ isSaving: true });
    await notificationStore.deactivate(NOTIFICATION_TYPES.DASHBOARD);

    await this.setState({ isSaving: false });
    onHide();
  }

  async handleSave() {
    const {
      onHide,
      user: { notificationStore },
    } = this.props;
    const { frequency, day, time } = this.state;
    const timeUTC = getTimeUTC(time);

    await this.setState({ isSaving: true });
    await notificationStore.activate(NOTIFICATION_TYPES.DASHBOARD, { frequency, day, time: timeUTC });

    await this.setState({ isSaving: false });
    onHide();
  }

  sendReport() {
    const { handleSend, sendStatus } = this.props;

    let message = null;
    let sendLabel = 'Send report now';

    if (sendStatus === 'error') {
      message = <small className="text-danger">Sending failed.</small>;
      sendLabel = 'Try again?';
    }

    if (sendStatus === 'sending') {
      sendLabel = 'Sending...';
    }

    if (sendStatus === 'sent') {
      message = (
        <small className="text-success" data-cy="text-success">
          Report Sent.
        </small>
      );
      sendLabel = 'Re-send';
    }

    return (
      <div>
        <Button
          loading={sendStatus === 'sending'}
          disabled={sendStatus === 'sending'}
          dmpStyle="link"
          size="small"
          style={{
            /* TEMPORARY, because @dmp/dropdown is not merged yet (max 3 days) */
            textDecoration: sendStatus !== 'sending' ? 'underline' : 'none',
            display: 'block',
            paddingLeft: 0,
            paddingRight: 0,
          }}
          onClick={handleSend}
          data-cy="btn-send-report-now"
        >
          {sendLabel}
        </Button>
        {message}
      </div>
    );
  }

  render() {
    const { frequency, day, time, isSaving } = this.state;
    const daysMap = DAYS_FROM_FREQUENCY[frequency];

    const dayMenuItems = [];
    if (daysMap) {
      _.forEach(DAYS_FROM_FREQUENCY[frequency], (day, idx) => {
        if (frequency === FREQUENCIES.MONTHLY && idx === 0) return;
        dayMenuItems.push(
          <MenuItem key={day} eventKey={idx}>
            {day}
          </MenuItem>
        );
      });
    }

    return (
      <Form>
        <div>Receive your dashboard reports via email.</div>
        {this.sendReport()}
        <hr />
        <div>Schedule email every:</div>
        <FormGroup className="fg-frequency form-group-vertical" data-cy="fg-frequency">
          <ControlLabel>Frequency</ControlLabel>
          <ToggleButtonGroup
            bsSize="small"
            type="radio"
            name="frequency"
            value={frequency}
            onChange={this.handleFrequency}
            data-cy="btn-frequency"
          >
            {_.map(FREQUENCIES, (freq) => (
              <ToggleButton key={freq} value={freq} data-cy={freq}>
                {freq}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </FormGroup>

        {frequency && (
          <>
            <div className="flex-row">
              {daysMap && (
                <FormGroup className="form-group-vertical">
                  <ControlLabel>Day</ControlLabel>
                  <Dropdown
                    id="dd-notification-dashboard-day"
                    title={DAYS_FROM_FREQUENCY[frequency][day] || 'Choose'}
                    size="small"
                    onSelect={this.handleDay}
                    block
                    data-cy="notification-dashboard-day"
                  >
                    {dayMenuItems}
                  </Dropdown>
                </FormGroup>
              )}

              {daysMap && <div width={16} style={{ flex: '0 0 16px' }} />}

              <FormGroup className="form-group-vertical">
                <ControlLabel>Time</ControlLabel>
                <Dropdown
                  id="dd-notification-dashboard-time"
                  title={time ? TIMES[time] : 'Choose'}
                  size="small"
                  onSelect={this.handleTime}
                  block
                  data-cy="notification-dashboard-time"
                >
                  {_.map(TIMES, (time, timeKey) => (
                    <MenuItem key={timeKey} eventKey={timeKey}>
                      {time}
                    </MenuItem>
                  ))}
                </Dropdown>
              </FormGroup>
            </div>
          </>
        )}

        <FormGroup className="actions d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            {this.isActive ? (
              <Button
                className="btn-turn-off"
                dmpStyle="link-danger"
                size="small"
                disabled={!this.isActive}
                onClick={this.handleDeactivate}
                data-cy="btn-turn-off"
              >
                Turn off
              </Button>
            ) : (
              <span />
            )}
          </div>
          <div>
            <Button size="small" disabled={isSaving} onClick={this.handleSave} data-cy="btn-turn-on">
              {this.isActive ? 'Update' : 'Turn on'}
            </Button>
          </div>
        </FormGroup>
      </Form>
    );
  }
}

export default NotificationForm;
