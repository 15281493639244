const DealRole = {
  OWNER: 'owner',
  VIEWER: 'viewer',
  PROPOSER: 'proposer',
  // COMMENTER: 'commenter',
  EDITOR: 'editor',
  TEMP: 'temp',
};

export default DealRole;
