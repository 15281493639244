import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';

import { Button, Loader } from '@components/dmp';

import SummaryView from '@components/SummaryView';
import Fire from '@root/Fire';

import ContractView from './ContractView';

const MODES = {
  summary: { key: 'summary', text: 'Overview', Component: SummaryView },
  source: { key: 'source', text: 'Full', Component: ContractView },
};

@autoBindMethods
class PublicDeal extends Component {
  static defaultProps = {
    frameless: false,
    appWidth: 0,
  };

  static propTypes = {
    appWidth: PropTypes.number,
    frameless: PropTypes.bool,
    match: PropTypes.object.isRequired,
    scrollToTop: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      deal: null,
      loading: true,
      mode: this.props.match.params.mode || MODES.summary.key,
      source: null,
      summary: null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.frameless && this.debouncedResizeMessage) {
      if (nextProps.appWidth !== this.props.appWidth) this.debouncedResizeMessage();
    }
  }

  componentDidMount() {
    const { match } = this.props;

    this.props.scrollToTop(true);

    Fire.getDeal(
      match.params.dealID,
      (deal) => {
        this.setState({
          deal,
          summary: deal.buildSummary(),
          source: deal.buildSource(true),
          loading: false,
        });
      },
      false,
      () => this.setState({ loading: false })
    );

    if (this.props.frameless) {
      const page = document.getElementById('outlaw');
      this.debouncedResizeMessage = _.debounce(() => {
        window.top.postMessage(page.scrollHeight, '*');
      }, 250);
      this.resizeObserver = new MutationObserver(this.debouncedResizeMessage);
      this.resizeObserver.observe(page, { attributes: true, childList: true, characterData: true, subtree: true });
      this.resizeObserver.observe(page, { attributes: true, childList: true, characterData: true, subtree: true });
      this.debouncedResizeMessage();
    }
  }

  componentWillUnmount() {
    if (this.props.frameless) {
      if (this.resizeObserver) this.resizeObserver.disconnect();
    }
  }

  get dealProps() {
    return {
      summary: this.state.summary,
      source: this.state.source,
      deal: this.state.deal,
    };
  }

  handleMode(mode) {
    this.setState({ mode });
  }

  renderModeButton(mode) {
    return (
      <Button key={mode.key} active={this.state.mode === mode.key} onClick={() => this.handleMode(mode.key)}>
        {mode.text}
      </Button>
    );
  }

  render() {
    const currentMode = MODES[this.state.mode];
    let content = null;

    if (this.state.loading) {
      content = (
        <div className="loading">
          <Loader />
        </div>
      );
    } else if (!this.state.deal) {
      content = 'Deal not found or not public';
    } else {
      content = (
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 col-sm-offset-3 sm-padBot-lge sm-padTop-sml center">
              <ButtonGroup className="display-buttons">{_.map(MODES, this.renderModeButton)}</ButtonGroup>
            </div>
          </div>
          <div className="deal-content" onClick={this.handleOutsideClick}>
            {currentMode ? <currentMode.Component editable={false} {...this.dealProps} /> : null}
          </div>
        </div>
      );
    }

    return (
      <main className="page-public-deal">
        <div className="sm-padBot-lge md-padBot-lge">{content}</div>
      </main>
    );
  }
}

export default PublicDeal;
