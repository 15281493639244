import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { ToastContainer as ToastContainerToastify, Zoom, cssTransition, toast as toastToastify } from 'react-toastify';

import { classNamePrefixer } from '@core/utils/Generators';

import Icon from '@components/dmp/Icon';

const Fade = cssTransition({
  enter: 'Toastify__fade-enter',
  exit: 'Toastify__fade-exit',
});

const TYPE_ICONS = {
  success: <Icon name="checkSolid" color="dark-green" size="xlarge" />,
  error: <Icon name="closeSolid" color="dark-red" size="xlarge" />,
  warning: <Icon name="exclamationSolid" color="dark-yellow" size="xlarge" />,
  info: <Icon name="infoSolid" color="dark-blue" size="xlarge" />,
};

const ToastIcon = ({ type }) => {
  return TYPE_ICONS[type] || null;
};

const cl = classNamePrefixer('toast');

const ToastContainer = ({ autoClose, className, id }) => {
  const classNames = cx(cl(), className);

  return (
    <ToastContainerToastify
      containerId={id}
      toastClassName={classNames}
      bodyClassName={cl('body')}
      progressClassName={cl('progress')}
      position="top-right"
      autoClose={autoClose}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={true}
      draggable={false}
      pauseOnHover={false}
      theme="light"
      transition={Fade}
    />
  );
};

ToastContainer.defaultProps = {
  autoClose: 5000,
};

ToastContainer.propTypes = {
  autoClose: PropTypes.number,
  className: PropTypes.string,
};

const toast = (text, { type = 'info', position = 'top-right' }) => {
  return toastToastify(text, {
    position,
    type,
    icon: ToastIcon,
  });
};

export { ToastContainer, toast };
export default {};
