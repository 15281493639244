import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import SERVICES from '@core/enums/IntegrationServices';
import Integration from '@core/models/Integration';
import User from '@core/models/User';

import {
  Button,
  ButtonToolbar,
  Card,
  CardList,
  Dropdown,
  Icon,
  Loader,
  MenuItem,
  ModalConfirm,
  Setting,
} from '@components/dmp';

import AutomationTester from '@components/connect/AutomationTester';
import Automator from '@components/connect/Automator';
import TeamSelector from '@components/teams/TeamSelector';
import TemplateSelector from '@components/teams/TemplateSelector';
import API from '@root/ApiClient';
import CONFIG from '@root/Config';

// If a service is only allowed on one specific instance, don't show it.
const FILTERED_SERVICES = _.filter(SERVICES, (service) => {
  return service.instance && service.instance !== CONFIG.INSTANCE ? false : true;
});

@autoBindMethods
export default class Connect extends Component {
  static defaultProps = {
    user: null,
    teams: [],
  };

  static propTypes = {
    og: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    teams: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      connectTeam: null,
      templates: [],
      template: null,
      service: null,
      integrations: [],
      integration: null,
      automation: null,

      // properties of ServiceConfig
      appID: '',
      url: '',
      token: '',
      editingConfig: false,

      deleting: false,
      automating: false,
      loading: false,

      showTestingConnection: false,
      testingConnectionStatus: 'loading',
      testingConnectionError: null,
      testingAutomation: null,
      tokenFormType: 'password',
      deleteAutomation: false,
      automationKeyToDelete: null,
    };
    if (this.props.teams.length == 1) {
      this.state = { connectTeam: this.props.team };
    }
  }

  componentDidMount() {
    this.props.og({ title: 'Outlaw - Connect' });
  }

  handleChange(property, event) {
    this.setState({ [property]: event.target.value });
  }

  async loadIntegrations(template) {
    const { service } = this.state;

    const integrations = [];

    if (!template) return this.setState({ template: null });

    await this.setState({
      template,
      automating: false,
      automation: null,
    });

    const templateID = template.dealID;
    const json = await API.call('getIntegrations', { templateID });

    if (json) {
      _.forEach(json, (integration) => {
        integrations.push(new Integration(integration, templateID));
      });

      await this.setState({ integrations });

      //reload the current integrations for the selected service on add/delete
      if (service) {
        const integration = _.find(integrations, (int) => _.get(int, 'config.service') === service.key);
        this.loadIntegration(integration);
      }
    } else {
      this.setState({
        integrations,
        service: '',
        appID: '',
        token: '',
        url: '',
        ownerID: '',
      });
    }
  }

  async selectTemplate(template) {
    this.setState({
      template,
      service: null,
    });
    await this.loadIntegrations(template);
  }

  async createIntegration() {
    const { template, service } = this.state;
    if (!template || !service) return;
    await API.call('createIntegration', { templateID: template.dealID, service: service.key });
    await this.loadIntegrations(template);
  }

  async deleteIntegration() {
    const { template, service } = this.state;
    if (!template || !service) return;

    await API.call('deleteIntegration', { templateID: template.dealID, service: service.key });
    await this.loadIntegrations(template);
    this.setState({ deleting: false, service: null, integration: null });
  }

  async deleteAutomationByKey() {
    const { automationKeyToDelete, template, service } = this.state;
    if (!template || !service || !automationKeyToDelete) return;

    await API.call('deleteAutomation', {
      key: automationKeyToDelete,
      templateID: template.dealID,
      service: service.key,
    });
    await this.loadIntegrations(template);
    this.setState({
      deleteAutomation: false,
      automationKeyToDelete: null,
    });
  }

  loadIntegration(integration) {
    const config = _.get(integration, 'config', {});
    const { appID = '', token = '', url = '', ownerID = '' } = config;

    const newState = { integration, appID, token, url, ownerID };

    // Also auto-select correct service in dd
    const service = _.find(FILTERED_SERVICES, { key: config.service });
    if (service) newState.service = service;

    this.setState(newState);
  }

  selectService(service) {
    const { integrations } = this.state;
    const integration = _.find(integrations, (int) => _.get(int, 'config.service') === service.key);
    this.setState({ service });
    this.loadIntegration(integration);
  }

  async saveConfig() {
    const { template, appID, token, url, ownerID, service } = this.state;

    await this.setState({ loading: true });

    await API.call('saveIntegrationConfig', {
      templateID: template.dealID,
      service: service.key,
      config: { appID, token, url, ownerID },
    });

    //reload
    await this.setState({ loading: false, editingConfig: false, tokenFormType: 'password' });
  }

  async testConnection() {
    const {
      integration: { templateID },
      service,
    } = this.state;

    await this.setState({ showTestingConnection: true, testingConnectionStatus: 'loading' });

    try {
      await API.call('testIntegrationConnection', { templateID, service: service.key });
      this.setState({ testingConnectionStatus: 'success' });
    } catch (err) {
      console.error(err);
      await this.setState({
        testingConnectionStatus: 'error',
        testingConnectionError: _.get(err, 'response.data.error', null),
      });
    }
  }

  renderAutomation(automation, idx) {
    return (
      <CardList.Item key={idx}>
        <Setting actions>
          <div className="summary">
            <b>{automation.triggerServiceName}:</b> {automation.triggerName} → <b>{automation.actionServiceName}:</b>{' '}
            {automation.actionName}
          </div>
          <div className="spacer" />
          <Button size="small" onClick={() => this.setState({ testingAutomation: automation })}>
            Test
          </Button>
          <Button size="small" onClick={() => this.setState({ automating: true, automation })}>
            Edit
          </Button>
        </Setting>
      </CardList.Item>
    );
  }

  renderTestConnectionModal() {
    const { showTestingConnection, testingConnectionStatus, testingConnectionError } = this.state;
    let body = null;

    if (testingConnectionStatus === 'loading') {
      body = <p>Testing connection...</p>;
    }

    if (testingConnectionStatus === 'success') {
      body = (
        <>
          <Icon size="xlarge" name="check" />
          <p>Connection successful!</p>
        </>
      );
    }

    if (testingConnectionStatus === 'error') {
      body = (
        <>
          <Icon size="xlarge" name="close" />
          <p>{testingConnectionError || 'Connection failed'}</p>
        </>
      );
    }

    return (
      <ModalConfirm
        show={showTestingConnection}
        onConfirm={() => this.setState({ showTestingConnection: false })}
        confirmText="Ok"
        title="Testing connection"
        body={<div className="d-flex flex-column align-items-center">{body}</div>}
        dmpStyle="default"
        isLoading={testingConnectionStatus === 'loading'}
      />
    );
  }

  selectTeam(teamID) {
    const team = _.find(this.props.teams, { teamID });
    this.setState({
      connectTeam: team,
      template: null,
      service: null,
    });
  }

  clearTeam() {
    this.setState({ connectTeam: null, template: null, service: null });
  }
  clearTemplate() {
    this.setState({ template: null, service: null });
  }

  render() {
    const { user, teams } = this.props;
    const {
      integration,
      template,
      service,
      appID,
      token,
      url,
      editingConfig,
      loading,
      deleting,
      automating,
      automation,
      testingAutomation,
      tokenFormType,
      deleteAutomation,
      connectTeam,
    } = this.state;

    return (
      <div className="wrapper connect">
        <div className="title-bar" data-cy="connect-title-bar">
          <h1>Connect</h1>
        </div>

        <div className="setting-block service-selector">
          <div className="block-header" data-cy="connect-setting-header">
            <h4>Setup</h4>
            <span className="prompt">Select team, template and service</span>
          </div>

          <div className="block-content" data-cy="connect-setting-content">
            <Card>
              <div className="connect-setting">
                {/* <div className="setting-title">Configure connection</div> */}
                <div className="setting-content">
                  <FormGroup>
                    <ControlLabel>Team</ControlLabel>
                    <TeamSelector
                      className="connect-team-selector"
                      user={user}
                      teamID={connectTeam?.teamID || null}
                      teams={teams}
                      onSelect={this.selectTeam}
                      disabled={teams.length == 1}
                      onClear={this.clearTeam}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Template</ControlLabel>
                    <TemplateSelector
                      className="connect-template-selector"
                      team={connectTeam}
                      onSelect={this.selectTemplate}
                      selectedTemplateKey={(template && template.key) || null}
                      activeOnly={true}
                      size="medium"
                      disabled={!connectTeam}
                      onClear={this.clearTemplate}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Service</ControlLabel>
                    <Dropdown
                      className="connect-selector"
                      id="dd-service-selector"
                      title={_.get(service, 'name', 'Select...')}
                      onSelect={this.selectService}
                      disabled={!template}
                      dataCyToggle="dd-service-selector"
                    >
                      {FILTERED_SERVICES.map((service) => (
                        <MenuItem key={service.key} eventKey={service} data-cy="service-option">
                          {service.name}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </FormGroup>
                </div>
              </div>
            </Card>
          </div>
        </div>
        {service && (
          <div className="setting-block" data-cy="connect-service-configuration">
            <div className="block-header">
              <h4>Service Configuration</h4>
              <span className="prompt">
                {integration
                  ? `Administer your Outlaw + ${service.name} integration.`
                  : `There is no ${service.name} integration configured yet for ${template.title}.`}
              </span>
            </div>
            <div className="block-content">
              {!integration && (
                <Card>
                  <Button onClick={this.createIntegration} data-cy="btn-create-integration">
                    Create Integration
                  </Button>
                </Card>
              )}
              {integration && (
                <Card>
                  <Setting title="Service Endpoint" subtitle="URL for API requests">
                    <FormControl
                      disabled={!editingConfig || loading}
                      value={url}
                      onChange={(e) => this.handleChange('url', e)}
                      bsSize="small"
                    />
                  </Setting>

                  <Setting title="App ID" subtitle="ID for delegated auth">
                    <FormControl
                      disabled={!editingConfig || loading}
                      value={appID}
                      onChange={(e) => this.handleChange('appID', e)}
                      bsSize="small"
                    />
                  </Setting>

                  <Setting title="Secret" subtitle="Token for delegated auth">
                    <FormControl
                      disabled={!editingConfig || loading}
                      value={token}
                      onChange={(e) => this.handleChange('token', e)}
                      bsSize="small"
                      type={tokenFormType}
                    />
                  </Setting>

                  <ButtonToolbar align="between">
                    <ButtonToolbar.Group>
                      {loading && <Loader />}
                      {editingConfig && !loading && (
                        <Button size="small" dmpStyle="link" onClick={() => this.setState({ deleting: true })}>
                          Delete
                        </Button>
                      )}
                      {!editingConfig && !loading && (
                        <Button size="small" dmpStyle="link" onClick={this.testConnection}>
                          Test connection
                        </Button>
                      )}
                    </ButtonToolbar.Group>

                    <ButtonToolbar.Group>
                      {editingConfig ? (
                        <>
                          <Button
                            disabled={loading}
                            dmpStyle="link"
                            onClick={() => this.setState({ editingConfig: false, tokenFormType: 'password' })}
                          >
                            Cancel
                          </Button>
                          <Button disabled={loading} dmpStyle="primary" onClick={this.saveConfig}>
                            Save
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button onClick={() => this.setState({ editingConfig: true, tokenFormType: 'text' })}>
                            Edit
                          </Button>
                        </>
                      )}
                    </ButtonToolbar.Group>
                  </ButtonToolbar>
                </Card>
              )}
            </div>
          </div>
        )}
        {service && (
          <div className="setting-block" data-cy="connect-automations">
            <div className="block-header">
              <h4>Automations</h4>
              <span className="prompt">Select a trigger to configure an action with corresponding field mappings</span>
            </div>

            <div className="block-content">
              <CardList>
                {_.get(integration, 'automations.length') > 0 ? (
                  _.map(integration.automations, this.renderAutomation)
                ) : (
                  <CardList.Item>You have not yet configured any automations for this integration.</CardList.Item>
                )}

                <CardList.Item>
                  <Setting actions>
                    <div className="spacer" />
                    <Button onClick={() => this.setState({ automating: true })} data-cy="btn-add-automatoin">
                      Add Automation
                    </Button>
                  </Setting>
                </CardList.Item>

                <Automator
                  show={automating}
                  onClose={() => this.loadIntegrations(template)}
                  onDelete={() =>
                    this.setState({ deleteAutomation: true, automating: false, automationKeyToDelete: automation.key })
                  }
                  integration={integration}
                  automation={automation}
                  user={user}
                />
              </CardList>
            </div>
          </div>
        )}

        <ModalConfirm
          show={deleting}
          onHide={() => this.setState({ deleting: false })}
          onConfirm={this.deleteIntegration}
          confirmText="Delete"
          title="Are you sure you want to delete this integration?"
          body="This can not be undone."
        />

        <ModalConfirm
          show={deleteAutomation}
          onHide={() => this.setState({ deleteAutomation: false })}
          onConfirm={this.deleteAutomationByKey}
          confirmText="Delete"
          title="Are you sure you want to delete this automation?"
          body="This is irreversible. Make sure that this integration is not being used."
        />

        <AutomationTester
          user={user}
          template={template}
          automation={testingAutomation}
          integration={integration}
          onClose={() => this.setState({ testingAutomation: null })}
        />

        {integration && this.renderTestConnectionModal()}
      </div>
    );
  }
}
