export const NotificationTypeKeys = Object.freeze({
  contractAccessRequests: 'accessRequests',
  checkpointStatusUpdates: 'checkpointStatusUpdates',
  contractActivity: 'contractActivity',
  checkpointReviewRequests: 'contractReviewRequests',
  dealCompletion: 'dealCompletion',
  userInvitationErrors: 'userInvitationErrors',
});

export function makeNotificationTypes() {
  return [
    {
      key: NotificationTypeKeys.checkpointReviewRequests,
      title: 'Checkpoint Review Requests',
      description: 'Sent to checkpoint group members when a review is requested.',
    },
    {
      key: NotificationTypeKeys.checkpointStatusUpdates,
      title: 'Checkpoint Status Updates',
      description: 'Sent to active contract users when a checkpoint is approved or rejected.',
    },
    {
      key: NotificationTypeKeys.contractAccessRequests,
      title: 'Contract Access Requests',
      description: 'Sent to contract owners when a user requests access.',
    },
    {
      key: NotificationTypeKeys.contractActivity,
      title: 'Contract Activity',
      description:
        'Sent to active contract users when a contract is signed, a section isedited, a section is deleted, section changes were approved, or a comment is added or resolved.',
    },
    {
      key: NotificationTypeKeys.dealCompletion,
      title: 'Deal Completion',
      description: 'Sent to active contract users when a deal is executed.',
    },
    {
      key: NotificationTypeKeys.userInvitationErrors,
      title: 'User Invitation Errors',
      description: 'Sent to a contract user when their sent invitation bounces or otherwise fails.',
    },
  ];
}

export function makeDefaultNotificationSettings(userTeams) {
  const makeItem = () => {
    return { email: true }; // TODO: add SMS and in-app later
  };

  const makeSet = (mixin = undefined) => {
    const set = {
      ...mixin,
    };
    for (const notification of makeNotificationTypes()) {
      set[notification.key] = makeItem();
    }
    return set;
  };

  const makeSettings = () => {
    const teams = {};
    for (const team of userTeams) {
      teams[team.teamID] = makeSet({ enabled: false });
    }

    return {
      global: makeSet(),
      teams,
    };
  };

  return makeSettings();
}
