import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { Form, FormControl, FormGroup } from 'react-bootstrap';

import { Button, Card, Loader } from '@components/dmp';

import API from '@root/ApiClient';
import Fire from '@root/Fire';

@autoBindMethods
class LoginOkta extends Component {
  static defaultProps = {
    mode: 'login',
  };

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object,
    user: PropTypes.any,
  };

  constructor(props) {
    super(props);

    const teamID = _.get(props, 'match.params.teamID', null);

    this.state = {
      teamID,
      isLoading: true,
      teamInfo: null,
      teamName: '',
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    const teamID = _.get(this.props, 'match.params.teamID', null);
    if (teamID !== prevState.teamID) {
      await this.loadTeamIntegrations(teamID);

      if (this._isMounted) {
        this.setState({
          teamID,
          isLoading: false,
        });
      }
    }

    return null;
  }

  async loadTeamIntegrations(teamID) {
    const okta = await API.callAnon('getTeamOktaIntegrations', { teamID });
    if (!okta) return;

    if (this._isMounted) {
      this.setState({
        okta,
        teamInfo: okta.teamInfo,
      });
    }
  }

  async componentDidMount() {
    const { location, history, user } = this.props;
    const { teamID } = this.state;

    const { firebaseToken } = qs.parse(location.search);

    this._isMounted = true;

    if (firebaseToken) {
      try {
        await Fire.auth.signInWithCustomToken(firebaseToken);
        history.push('/');
      } catch (err) {
        console.error('LoginOkta.error : failed at signing using custom token.');
        console.log(err);
        if (this._isMounted) this.setState({ isLoading: false });
      }

      return;
    }

    if (teamID) {
      await this.loadTeamIntegrations(teamID);
    }

    if (user) {
      console.log('user exists, redirect to /dashboard');
      await history.push('/dashboard');
    }

    this.setState({ isLoading: false });
  }

  async handleSetTeam(event) {
    const { history } = this.props;
    const { teamName } = this.state;

    event.preventDefault();

    await history.push(`/okta/${teamName}`);
    await this.setState({ teamID: teamName });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  renderSignIn() {
    const { okta, teamID, teamInfo } = this.state;
    const redirectUri = encodeURIComponent(`${window.location.origin}/auth/okta/${teamID}/callback`);

    return (
      <Card style={{ textAlign: 'center' }}>
        <h2>{teamInfo.name}</h2>
        <p>{teamID}</p>
        <p>
          <a
            className="btn btn-primary"
            href={`${okta.signInUrl}/oauth2/v1/authorize?client_id=${okta.clientId}&response_type=code&scope=openid+profile+email&redirect_uri=${redirectUri}&state=${teamID}`}
          >
            Sign In
          </a>
        </p>
      </Card>
    );
  }

  renderTeamForm() {
    const { teamName } = this.state;

    return (
      <Card style={{ textAlign: 'center' }}>
        <p>Please specify your team ID</p>
        <Form onSubmit={this.handleSetTeam}>
          <FormGroup>
            <FormControl type="text" value={teamName} onChange={(e) => this.setState({ teamName: e.target.value })} />
          </FormGroup>
          <Button dmpStyle="primary" disabled={!teamName || !teamName.length}>
            Set team
          </Button>
        </Form>
      </Card>
    );
  }

  render() {
    const { teamInfo } = this.state;

    if (this.state.isLoading) {
      return <Loader centered size="large" />;
    }

    return (
      <div style={{ width: 400, padding: '80px 20px', margin: '0 auto' }}>
        {teamInfo ? this.renderSignIn() : this.renderTeamForm()}
      </div>
    );
  }
}

export default LoginOkta;
