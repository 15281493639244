import { assign } from 'lodash';

export default class CheckpointGroup {
  name = null;
  checkpointGroupKey = null;
  description = null;
  participants = null;

  constructor({ name, checkpointGroupKey, description = null, participants = [] }) {
    assign(this, { name, checkpointGroupKey, description, participants });
  }

  get json() {
    return {
      name: this.name,
      checkpointGroupKey: this.checkpointGroupKey,
      description: this.description,
      participants: this.participants,
    };
  }
}
