import React, { Component, createRef } from 'react';

import axios from 'axios';
import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Attachment, { ACCEPTED_TYPES, ATTACHMENT_TYPE } from '@core/models/Attachment';
import { DateFormatter } from '@core/utils/DateTime';
import { getDataURL } from '@core/utils/Generators';

import { Icon, Loader } from '@components/dmp';
import DropdownDots from '@components/dmp/DropdownDots';

import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

const MenuItem = DropdownDots.MenuItem;

@autoBindMethods
export default class DealAttachmentBlock extends Component {
  static propTypes = {
    attachment: PropTypes.instanceOf(Attachment).isRequired,
    interactive: PropTypes.bool,
    downloadable: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    onEdit: _.noop,
    onDelete: _.noop,
    interactive: true,
    downloadable: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      downloadURL: null,
      dataURL: null,
    };
    this.downloadLink = createRef();
  }

  componentDidMount() {
    const { attachment } = this.props;

    this._isMounted = true;

    // load dl url, put in state
    Fire.storage
      .ref(attachment.bucketPath)
      .getDownloadURL()
      .then((downloadURL) => {
        if (this._isMounted) {
          this.setState({ downloadURL });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get icon() {
    const { attachment } = this.props;
    if (this.state.downloading) return <Loader />;

    const fileType = _.find(ACCEPTED_TYPES, { extension: attachment.extension });
    const iconName = _.get(fileType, 'icon', 'document');

    return (
      <div className="file-icon">
        <Icon name={iconName} />
      </div>
    );
  }

  get date() {
    const { attachment } = this.props;
    return `${DateFormatter.ymd(attachment.date, '-')} @ ${DateFormatter.time(attachment.date)}`;
  }

  async handleAction(action) {
    const { onEdit, onDelete, attachment } = this.props;
    const { downloadURL } = this.state;

    switch (action) {
      case 'edit':
        onEdit(attachment);
        break;
      case 'delete':
        onDelete(attachment);
        break;
      case 'download':
        if (downloadURL) {
          await this.setState({ downloading: true });
          // This is annoying: the href download attribute (for pretty filenames) only works
          // when the file (or data) is coming from current/same domain
          // Since it's hosted on Firebase storage, this will never be the case, so we need to download the full file locally,
          // and then push it through a data URL in order to achieve this (or, alternatively, proxy it through our API)
          // Since the file can be large, we may want to add a loading state, because this can take a few seconds...
          const raw = await axios.get(downloadURL, { responseType: 'arraybuffer' });
          const dataURL = getDataURL(raw.data);
          await this.setState({ dataURL, downloading: false });
          this.downloadLink.current.click();
        }
        break;
      default:
        break;
    }
  }

  render() {
    const { attachment, interactive, downloadable } = this.props;
    const { downloadURL, dataURL } = this.state;

    const className = cx('deal-file-block', { interactive }, { downloadable });

    return (
      <DealPanelItem borderBottom className={className}>
        {this.icon}
        <div className="file-info" data-cy="attachment-file-info">
          <div className="file-topline">
            <TooltipButton tip="Download file" disabled={!downloadable}>
              <div
                className="file-title"
                onClick={downloadable && downloadURL ? () => this.handleAction('download') : null}
                data-cy="file-title"
              >
                {attachment.filename}
              </div>
            </TooltipButton>

            <div className="spacer" />

            {interactive && (
              <DropdownDots
                pullRight
                id={`dd-attachment-${attachment.key}`}
                onSelect={this.handleAction}
                dataCyToggle="attachment-dd-actions"
              >
                <MenuItem eventKey="edit" data-cy="edit-attachment">
                  Edit metadata
                </MenuItem>
                <MenuItem eventKey="delete" data-cy="delete-attachment">
                  Delete attachment
                </MenuItem>
              </DropdownDots>
            )}
          </div>

          {attachment.attachmentType === ATTACHMENT_TYPE.VARIABLE && (
            <div className="file-meta variableID" data-cy="file-meta-variable">
              <Icon name="variable" />
              <span>Variable: {attachment.variableID}</span>
            </div>
          )}

          <div className="file-meta date" data-cy="file-meta-date">
            <Icon name="calendar" />
            <span>{this.date}</span>
          </div>

          {attachment.description && (
            <div className="file-meta description" data-cy="attachment-file-description">
              <Icon name="info" />
              <span>{attachment.description}</span>
            </div>
          )}
        </div>
        <a className="d-none" ref={this.downloadLink} href={dataURL} download={attachment.filename}>
          {attachment.filename}
        </a>
      </DealPanelItem>
    );
  }
}
