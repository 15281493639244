import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { LENS_TYPES } from '@core/models/Lens';
import { getOperators } from '@core/models/Operator';

import { ButtonIcon, Icon } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

@autoBindMethods
export default class LensCheck extends Component {
  static propTypes = {
    lensChecks: PropTypes.object.isRequired,
    hideNonScoring: PropTypes.bool.isRequired,
    showQuestions: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showTypes: ['advanced', 'variable', 'clause'],
    };
  }

  renderLens({ lens, lensFilterChecks }, idx) {
    const { showQuestions, deal } = this.props;
    const filterChecks = this.props.hideNonScoring
      ? _.filter(lensFilterChecks, ({ risk }) => {
          return risk !== 0;
        })
      : lensFilterChecks;

    if (_.size(filterChecks) === 0) return;

    const sortedFilters = () => {
      switch (lens.type) {
        case 'advanced':
          return _.orderBy(lens.advancedFilters, ['isDefault', 'timestamp'], ['asc', 'asc']);
        case 'clause':
          return _.sortBy(filterChecks, ['title']);
        case 'variable':
          const variable = deal.variables[lens.relatedVariable];
          if (!variable) return lens.valueFilters;

          const operators = getOperators(variable.valueType);
          if (!operators.length) return lens.valueFilters;

          return _.values(filterChecks).sort((a, b) => {
            const titleA = _.findIndex(operators, (o) => {
              return a.displayLabel.includes(o.title.toLowerCase());
            });
            const titleB = _.findIndex(operators, (o) => {
              return b.displayLabel.includes(o.title.toLowerCase());
            });
            if (titleA < titleB) return -1;
            if (titleA > titleB) return 1;
            return 0;
          });
      }
    };

    return (
      <div key={idx} className="lens-check">
        <div className="lens-info">
          <div className="item-label" data-cy="lens-title">
            {lens.title}
          </div>
          {lens.question && showQuestions && (
            <div className="lens-question-label" data-cy="lens-title">
              Q: {lens.question}
            </div>
          )}
          <div>{_.map(sortedFilters(), this.renderLensFilterChecks)}</div>
        </div>
      </div>
    );
  }

  renderLensFilterChecks({ displayLabel, id, risk, failedCheck, error, isDefault }) {
    const { window } = this.props;
    const riskText = risk > 0 ? `+${risk}` : risk;
    const tip = error
      ? 'An error occurred while processing your request. If this issue persists, please contact your account representative.'
      : 'A default “None of the above” option is automatically added to each Advanced Lens. A score may be assigned to this option.';

    return (
      <div className={cx('lens-filter-check', { error: !!error })} key={id} data-cy="lens-sub">
        {failedCheck && <Icon name="check" className={cx('check', { large: window === 'large' })} />}
        <div className="lens-filter-label">{displayLabel}</div>
        {(error || isDefault) && (
          <TooltipButton tip={tip}>
            <Icon name="info" />
          </TooltipButton>
        )}
        <div className="lens-filter-score">{riskText}</div>
      </div>
    );
  }

  renderLensGroups(type, checks) {
    const { icon, title, key } = type;
    const { showTypes } = this.state;
    const { hideNonScoring } = this.props;

    const isShowing = _.find(showTypes, (type) => type === key);

    let totalGroupRisk = 0;

    _.forEach(checks, ({ totalLensRisk }) => (totalGroupRisk += totalLensRisk));

    const riskText = totalGroupRisk > 0 ? `+${totalGroupRisk}` : totalGroupRisk;

    const disabledState = totalGroupRisk === 0 && hideNonScoring;

    return (
      <div className="display-block border-bottom" key={key} data-cy="lens-display-block">
        <div className="left">
          <Icon name={icon} className="condition-icon" />
        </div>
        <div className="right">
          <div className="lens-topline">
            <div className="item-label">{title}</div>
            {(!isShowing || disabledState) && <div className="total-group-risk">Total: {riskText}</div>}
            {isShowing && !disabledState && (
              <ButtonIcon
                icon="chevronUp"
                className="openContent"
                size="default"
                onClick={() => this.setState({ showTypes: _.remove(showTypes, (type) => type !== key) })}
              />
            )}
            {(!isShowing || disabledState) && (
              <ButtonIcon
                icon="chevronDown"
                className={cx('closeContent', { placeholder: disabledState })}
                size="default"
                onClick={() => this.setState({ showTypes: [...showTypes, key] })}
              />
            )}
          </div>

          {isShowing && !disabledState && _.map(_.orderBy(checks, ['lens.title'], ['desc', 'asc']), this.renderLens)}
        </div>
      </div>
    );
  }

  render() {
    const { lensChecks } = this.props;

    return (
      <div className="lens-checks-container">
        {_.map(LENS_TYPES, (lensType) => {
          const checks = _.filter(lensChecks.checks, ({ lens }) => {
            return lens.type === lensType.key;
          });

          if (checks.length > 0) return this.renderLensGroups(lensType, checks);
        })}
        <div className="lens-summary-container">
          <div className="lens-summary" data-cy="lens-summary-has-risk">
            <div className="risk-points">Total score</div>
            <div className="spacer" />
            <div className="risk-points">{lensChecks.grade}</div>
          </div>
          <div className="lens-summary" data-cy="lens-summary-risk-point">
            <div className="spacer" />
            <div className="risk-points">{`(${lensChecks.totalRisk} points)`}</div>
          </div>
        </div>
      </div>
    );
  }
}
