import { get } from 'lodash';

import Source from './Source';

export default class Caption extends Source {
  divider = '';
  dividerWidth = 5;
  dividerEnabled = true;
  dividerLineSpacing = 1;

  constructor(json, deal) {
    super(json, deal);

    this.raw = json;

    this.divider = get(json, 'divider', '');
    this.dividerWidth = get(json, 'dividerWidth', 5);
    this.dividerEnabled = get(json, 'dividerEnabled') === false ? false : true;
    this.dividerLineSpacing = get(json, 'dividerLineSpacing', 1);
  }

  get lhs() {
    return this.sourceChildren[0];
  }
  get rhs() {
    return this.sourceChildren[1];
  }
}
