import React, { Component } from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { classNamePrefixer, getUniqueKey } from '@core/utils/Generators';

import { Icon, Loader } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

const cl = classNamePrefixer('validator');

export default class Validator extends Component {
  static propTypes = {
    validate: PropTypes.func.isRequired,
    onResult: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    debounce: PropTypes.number,
    colored: PropTypes.bool,
    tips: PropTypes.bool,
    validTip: PropTypes.string,
    invalidTip: PropTypes.string,
    validateEmpty: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledTip: PropTypes.string,
  };

  static defaultProps = {
    debounce: 500,
    colored: true,
    tips: true,
    validTip: null,
    invalidTip: 'Unavailable',
    validateEmpty: false,
    disabled: false,
  };

  constructor(props) {
    super(props);
    const { validate, onResult, debounce } = props;

    this.state = {
      checking: true,
      valid: false,
    };

    // This is just for a base id for the Tooltips
    this.id = 'validator-' + getUniqueKey();

    // Wrap the passed in validate function in a debounce
    this.validate = _.debounce(async (value) => {
      if (!value && !this.props.validateEmpty) {
        return this._isMounted ? this.setState({ checking: false }) : Promise.resolve();
      }
      const valid = await validate(value);
      if (this._isMounted) await this.setState({ valid, checking: false });
      onResult(valid);
    }, debounce);
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    // We're watching for a change in the argument that will get passed to the async validate function
    // But we want to set state immediately to show loader
    if (value !== prevProps.value) {
      if (this._isMounted) this.setState({ checking: true });
      this.validate(value);
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.validate(this.props.value);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { checking, valid } = this.state;
    const { colored, validTip, invalidTip, tips, value, validateEmpty, disabled, disabledTip } = this.props;

    const className = cx(cl(), { [cl('colored')]: colored });

    // Don't render anything at all if no validation required on an empty value
    if (!value && !validateEmpty) return null;

    return (
      <div className={className}>
        {checking && <Loader />}
        {!checking && valid && !disabled && validTip && (
          <TooltipButton tipID={`${this.id}-valid`} tip={validTip} disabled={!tips}>
            <Icon name="check" className="valid" />
          </TooltipButton>
        )}
        {!checking && valid && disabled && (
          <TooltipButton tipID={`${this.id}-disabled`} tip={disabledTip} disabled={!tips}>
            <Icon name="nope" className="disabled" />
          </TooltipButton>
        )}
        {!checking && !valid && (
          <TooltipButton tipID={`${this.id}-invalid`} tip={invalidTip} disabled={!tips}>
            <Icon name="exclamation" className="invalid" />
          </TooltipButton>
        )}
      </div>
    );
  }
}
