import React, { Component } from 'react';

import { convertToRaw } from 'draft-js';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import DealAction from '@core/enums/DealAction';
import { tokenizeVariables } from '@core/models/Content';
import { hasDiffs } from '@core/models/Version';

import { Button, Dropdown, MenuItem, Switch } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import ContentSection from '@components/section_types/ContentSection';
import API from '@root/ApiClient';
import Dealer from '@root/Dealer';
import Fire from '@root/Fire';

import VersionListItem from './VersionListItem';

export default class VersionHistory extends Component {
  //({ section, user, onRestore, close })
  static propTypes = {
    section: PropTypes.object,
    user: PropTypes.object,
    onRestore: PropTypes.func,
    close: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDiffs: true,
      selectedVersionIndex: 0,
    };
  }

  get priorVersions() {
    const { section } = this.props;

    if (!section || section.versions.length <= 1) return [];

    // Raw section versions are in chronological order, but for history list, we want to do 3 things:
    // 1. Reverse them to show most recent first
    // 2. Omit the most recent one since it's persistently showed on right
    return section.versions.slice(0, section.versions.length - 1).reverse();
  }

  async restore(version) {
    const { user, onRestore, section } = this.props;

    const title = version.title.getPlainText().trim() || null;

    // Strip variable entities back off and convert to raw for storage
    let body = tokenizeVariables(version.body, true);
    body = convertToRaw(body);

    await Fire.saveSectionVersion(section, user, title, body);

    // After saving, if this section has any activity or deal has already been shared with others, log an update action!
    if (section.activity.length > 0 || section.deal.shared) {
      const activity = await Fire.addActivity(section, user, DealAction.UPDATE);

      // Tell server to notify other parties of edit
      const args = { dealID: section.deal.dealID, logID: section.id, activityID: activity.id };
      API.call('sendActivity', args, (r) => console.log(r));
    }

    // Close modal
    onRestore();
  }

  // When the "focused" section changes to view a different section's history, reset selected version index
  componentDidUpdate(prevProps) {
    const { section } = this.props;
    if (_.get(section, 'id') !== _.get(prevProps, 'section.id')) {
      this.setState({ selectedVersionIndex: 0 });
    }
  }

  render() {
    const { section, close } = this.props;
    const { showDiffs, selectedVersionIndex } = this.state;

    const selectedVersion = this.priorVersions[selectedVersionIndex] || null;

    if (!section || !selectedVersion || Dealer.mobile) return null;

    let num = section.sourceNumber(true);
    //Shave off trailing '.' on numbering
    if (num.endsWith('.')) {
      num = num.substring(0, num.length - 1);
    }

    const hasChanges = hasDiffs(selectedVersion.clean('body'), section.currentVersion.body);
    const showSelector = this.priorVersions.length > 1;

    return (
      <Modal show={true} onHide={close} dialogClassName="version-history modal-lg" data-cy="version-history-modal">
        <Modal.Header closeButton>
          <span className="headline">Version History{num != '' ? `: Section ${num}` : ''}</span>
        </Modal.Header>
        <Modal.Body>
          <div className="compare">
            <div className="section-header">
              <VersionListItem section={section} version={selectedVersion} />

              {showSelector && (
                <Dropdown
                  dmpStyle="link"
                  pullRight
                  onSelect={(selectedVersionIndex) => this.setState({ selectedVersionIndex })}
                  id="dd-version-compare"
                >
                  {this.priorVersions.map((version, idx) => (
                    <MenuItem key={version.id} eventKey={idx} data-cy="version-item">
                      <VersionListItem
                        key={version.id}
                        section={section}
                        version={version}
                        selected={version.id === selectedVersion.id}
                      />
                    </MenuItem>
                  ))}
                </Dropdown>
              )}
            </div>

            <div className="version-col-body" data-cy="version-col-body">
              <ContentSection
                sourceMode
                readonly
                hideMenu
                noReplace
                raw
                activityMode="none"
                section={section}
                version={selectedVersion}
              />
            </div>

            <div className="version-action">
              <TooltipButton
                tip={
                  hasChanges
                    ? 'Revert section text to this version'
                    : 'Latest section text is already identical to this version'
                }
              >
                <Button
                  className={`restore${!hasChanges ? ' disabled' : ''}`}
                  dmpStyle="link"
                  onClick={() => (hasChanges ? this.restore(selectedVersion) : null)}
                  data-cy="restore-version"
                >
                  Restore this version
                </Button>
              </TooltipButton>
            </div>
          </div>

          <div className="current">
            <div className="section-header">
              <VersionListItem section={section} version={section.currentVersion} selected={true} />
            </div>
            <div className="version-col-body" data-cy="edited-version-col-body">
              <ContentSection
                sourceMode
                readonly
                hideMenu
                noReplace
                activityMode="none"
                raw
                section={section}
                version={section.currentVersion}
                showClean={!showDiffs}
                compareVersion={showDiffs ? selectedVersion : null}
              />
            </div>
            <div className="version-action">
              <Switch
                id="check-show-diff"
                checked={showDiffs}
                onChange={() => this.setState({ showDiffs: !showDiffs })}
                size="small"
              >
                Show differences
              </Switch>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
