import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';

import autobindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Overlay } from 'react-bootstrap';

import { findVariableSuggestText, rxSuggestFields } from '@core/models/Content';
import DataSource from '@core/models/DataSource';
import TableColumn from '@core/models/TableColumn';
import {
  DATE_PROPERTIES,
  NUMBER_PROPERTIES,
  NumericValueTypes,
  SimpleTypeLabels,
  TEXT_TRANSFORMS,
  ValueType,
  VariableType,
  isNumeric,
} from '@core/models/Variable';
import { setCursor } from '@core/utils/HTMLInput';

import { Alert, Button, Checkbox, Dropdown, Form, MenuItem, Popover, Switch } from '@components/dmp';

import VariableSuggest from '@components/editor/VariableSuggest';
import VariableIndex from '@root/utils/VariableIndex';

import DecimalSelector, { showDecimalSelector } from './DecimalSelector';

@autobindMethods
class TableColumnEditor extends Component {
  static defaultProps = {
    onSave: _.noop,
  };

  static propTypes = {
    column: PropTypes.instanceOf(TableColumn).isRequired,
    target: PropTypes.object,
    onSave: PropTypes.func,
    hide: PropTypes.func,
    variableIndex: PropTypes.instanceOf(VariableIndex).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      displayName: '',
      hasEdited: false,
      valueType: null,
      property: null,
      textTransform: null,
      useLocale: false,
      totalColumn: false,
      calculated: false,
      formula: '',
    };

    // This is just for a base id for child controls
    this.id = 'column-editor';
    this.refFormula = createRef();
    this.refSuggest = createRef();
  }

  componentDidMount() {
    this.populate(this.props);
  }

  componentDidUpdate(prevProps) {
    const { column } = this.props;

    if (!_.isEqual(_.get(prevProps, 'column.json'), _.get(column, 'json'))) {
      this.populate(this.props);
    }
  }

  populate(props) {
    const { column } = props;

    const useLocale = column.property && column.property.split('-').length === 2;
    this.setState({
      displayName: column.displayName || '',
      hasEdited: false,
      valueType: column.valueType,
      property: column.property,
      textTransform: column.textTransform,
      totalColumn: column.totalColumn,
      decimals: column.decimals || 'Default',
      useLocale,
      calculated: column.calculated,
      formula: column.formula || '',
      vsState: null,
    });
  }

  get availableTypes() {
    return _.filter(SimpleTypeLabels, { column: true });
  }

  handleChange(e, prop) {
    const newState = {};
    newState[prop] = e.target.value;

    // See VariableEditor.displayToName pattern
    // Once we enable advanced config for all tables, we'll want same thing here to generate/sanitize id's
    this.setState(newState);
  }

  save(e) {
    const { hide, column, onSave } = this.props;
    let { displayName, valueType, property, decimals, textTransform, totalColumn, calculated, formula } = this.state;

    if (e) e.stopPropagation();

    if (!displayName) displayName = null;
    if (property === 'none') property = null;
    if (textTransform === 'none') textTransform = null;
    if (decimals === 'Default') decimals = null;

    // Note that saving does not actually make a Fire call, but instead just merges state values into props.column
    // And passes it back to the calling component
    // This is done to enable multiple columns to be configured sequentially
    // and then saving (or undoing) the whole thing in TableColumnManager
    _.merge(column, { displayName, valueType, property, decimals, textTransform, totalColumn, calculated, formula });

    onSave && onSave(column);

    hide();
  }

  cancel(e) {
    this.props.hide();
  }

  focus() {
    const el = this.refDisplayName;
    if (el) el.focus();
  }

  handleVS(e) {
    const { vsState } = this.state;
    const varSuggest = this.refSuggest.current;

    if (!this.state.hasEdited) this.setState({ hasEdited: true });

    // Allow VariableSuggest to hijack certain keys (enter, escape, arrows) if active
    if (vsState && varSuggest && varSuggest.handleKey(e)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  updateVS(e) {
    const vsState = findVariableSuggestText({ element: e.target, rx: rxSuggestFields });
    this.setState({ vsState });
  }

  async commitVS(newText) {
    const { vsState, formula } = this.state;

    const newFormula = formula.slice(0, vsState.start) + newText + formula.slice(vsState.start + vsState.input.length);
    await this.setState({ formula: newFormula, vsState: null });

    const el = ReactDOM.findDOMNode(this.refFormula.current);
    setCursor(el, vsState.start + newText.length);
  }

  renderForm() {
    const { column, variableIndex } = this.props;
    const {
      displayName,
      hasEdited,
      valueType,
      property,
      textTransform,
      useLocale,
      totalColumn,
      calculated,
      formula,
      vsState,
      decimals,
    } = this.state;

    const isDate = valueType === ValueType.DATE;
    let properties = [];
    if ([ValueType.NUMBER, ValueType.CURRENCY].includes(valueType)) properties = NUMBER_PROPERTIES;
    if (isDate) properties = DATE_PROPERTIES;

    const availableTypes = this.availableTypes;
    const valTypeTitle = _.get(_.find(availableTypes, { data: valueType }), 'label', '');
    const propertyTitle = _.get(_.find(properties, { data: property || 'none' }), 'label', '');
    const textTransformTitle = _.get(_.find(TEXT_TRANSFORMS, { data: textTransform || 'none' }), 'label', '');
    const isDS = column.table instanceof DataSource;
    const connected = isDS || !!column.table.connection;

    let error;

    if (calculated && !column.isValid) {
      try {
        column.totalColumn ? column.total() : column.calculate();
      } catch (e) {
        error = e;
      }
    }

    return (
      <Form>
        <FormGroup className="id">
          <span className={cx('element-name', { connected })}>{`{${column.id}}`}</span>
        </FormGroup>

        {!isDS && (
          <>
            <FormGroup className="display">
              <div className="control-label">Column title</div>
              <FormControl
                bsSize="small"
                inputRef={(r) => (this.refDisplayName = r)}
                value={displayName}
                placeholder="Column title"
                onChange={(e) => this.handleChange(e, 'displayName')}
                disabled={false}
                data-cy="column-title"
              />
            </FormGroup>
          </>
        )}

        <FormGroup className="val-type">
          <div className="control-label">Data type</div>

          <Dropdown
            id={`${this.id}-dd-valtype`}
            className="dd-valtype"
            title={valTypeTitle}
            onSelect={(valueType) => this.setState({ valueType })}
            size="small"
            block
            data-cy="dd-val-type"
          >
            {availableTypes.map((t, idx) => (
              <MenuItem key={idx} eventKey={t.data}>
                {t.label}
              </MenuItem>
            ))}
          </Dropdown>
        </FormGroup>

        {isNumeric(valueType) && (
          <Switch
            checked={calculated}
            id={`${this.id}-chk-calculated`}
            onChange={() => this.setState({ calculated: !calculated })}
            size="small"
            data-cy="chk-calculated"
          >
            Calculated column
          </Switch>
        )}
        {calculated && (
          <>
            <FormGroup className="formula">
              <div className="control-label">Formula</div>
              {error && !hasEdited && (
                <Alert dmpStyle="danger" icon="info">
                  {error.message}
                  {error.value && (
                    <span>
                      :<b> {error.value}</b>
                    </span>
                  )}
                </Alert>
              )}
              <FormControl
                bsSize="small"
                ref={this.refFormula}
                componentClass="textarea"
                value={formula}
                placeholder="Enter formula"
                onChange={(e) => this.handleChange(e, 'formula')}
                onKeyDown={this.handleVS}
                onKeyUp={this.updateVS}
                spellCheck="false"
                data-cy="calculated-column-formula"
              />
              {vsState && (
                <VariableSuggest
                  ref={this.refSuggest}
                  variableIndex={variableIndex}
                  deal={column.table.deal}
                  input={vsState.input}
                  onSelect={this.commitVS}
                  target={this.refFormula}
                  variableTypes={[VariableType.SIMPLE, VariableType.CALCULATED, VariableType.CONNECTED]}
                  isFormula
                  group={column.table.name}
                />
              )}
            </FormGroup>

            {showDecimalSelector(valueType) && (
              <DecimalSelector
                id={`${this.id}-dd-prop-decimal-places`}
                onSelect={(decimals) => this.setState({ decimals })}
                title={decimals}
              />
            )}
          </>
        )}

        {properties.length > 0 && (
          <FormGroup>
            <div className="control-label">Display as</div>
            {(!isDate || !useLocale) && (
              <Dropdown
                size="small"
                block
                id={`${this.id}-dd-prop`}
                className="dd-prop"
                onSelect={(property) => this.setState({ property })}
                title={propertyTitle}
                data-cy="variable-prop"
              >
                {properties.map((p, idx) => (
                  <MenuItem key={idx} eventKey={p.data} info={p.description}>
                    {p.label}
                  </MenuItem>
                ))}
              </Dropdown>
            )}
            {isDate && useLocale && (
              <FormControl
                bsSize="small"
                value={property || ''}
                placeholder='e.g., "en-US"'
                onChange={(e) => this.setState({ property: e.target.value })}
              />
            )}
            {isDate && (
              <Switch
                checked={useLocale}
                id={`${this.id}-chk-locale`}
                onChange={() => this.setState({ useLocale: !useLocale, property: null })}
                size="small"
                data-cy="chk-locale"
              >
                Format with Date locale string
              </Switch>
            )}
          </FormGroup>
        )}

        {TEXT_TRANSFORMS.length > 0 && (
          <FormGroup>
            <div className="control-label">Instance overrides</div>
            {
              <Dropdown
                size="small"
                block
                id={`${this.id}-dd-prop`}
                className="dd-prop transform-menu-options"
                onSelect={(textTransform) => this.setState({ textTransform })}
                title={textTransformTitle}
                dataCyToggle="variable-prop-transform"
              >
                {TEXT_TRANSFORMS.map((p, idx) => (
                  <MenuItem key={idx} eventKey={p.data} data-cy="variable-transform-option">
                    {p.label}
                  </MenuItem>
                ))}
              </Dropdown>
            }
          </FormGroup>
        )}

        {NumericValueTypes.includes(valueType) && !isDS && (
          <FormGroup>
            <div className="control-label"></div>
            <Checkbox
              id="total-column"
              checked={totalColumn}
              onChange={() => this.setState((prevState) => ({ totalColumn: !prevState.totalColumn }))}
              data-cy="total-column"
            >
              Sum this column
            </Checkbox>
          </FormGroup>
        )}

        <FormGroup className="actions">
          <Button className="cancel" dmpStyle="link" size="small" onClick={this.cancel} data-cy="btn-cancel-var">
            Cancel
          </Button>

          <Button className="save" size="small" onClick={this.save} data-cy={'btn-save-var'}>
            Update
          </Button>
        </FormGroup>
      </Form>
    );
  }

  render() {
    const { target, container, hide } = this.props;

    return (
      <Overlay
        container={container}
        onHide={hide}
        placement="bottom"
        show={true}
        target={target}
        onEntered={this.focus}
        rootClose
      >
        <Popover className="popover-column-editor" id={`${this.id}-pop`} data-cy="popover-column-editor">
          {this.renderForm()}
        </Popover>
      </Overlay>
    );
  }
}

export default TableColumnEditor;
