import React, { Component, createRef } from 'react';

import autobindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DealNumberFormat from '@core/models/DealNumberFormat';
import Section from '@core/models/Section';
import { NUMBER_STYLES } from '@core/utils/OrderFormatter';

import { Dropdown, MenuItem } from '@components/dmp';

import CustomNumbering from '@components/editor/CustomNumbering';

@autobindMethods
class NumberFormatSelector extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
    section: PropTypes.instanceOf(Section),
    numberFormat: PropTypes.instanceOf(DealNumberFormat),
    includeUnnumbered: PropTypes.bool,
    size: PropTypes.oneOf(['small', 'default']),
    dmpStyle: PropTypes.oneOf(['default', 'primary', 'link', 'link-primary', 'link-info', 'anchor']),
    disabled: PropTypes.bool,
    // Copied from <TooltipButton />
    tip: PropTypes.shape({
      delay: PropTypes.number,
      disabled: PropTypes.bool,
      placement: PropTypes.string,
      positionTop: PropTypes.number,
      positionLeft: PropTypes.number,
      tip: PropTypes.node,
      tipID: PropTypes.string,
      trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }),
  };

  static defaultProps = {
    size: 'default',
    disabled: false,
    dmpStyle: 'default',
    includeUnnumbered: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      custom: false,
    };

    this.refDD = createRef();
  }

  // Wrap the onSelect handler so that we can intercept the custom option
  // And handle internally (show CustomNumbering popup)
  // CustomNumbering then calls the same handler from onSave to pass a DealNumberFormat back out
  onSelect(dealNumberFormat) {
    const { onSelect } = this.props;
    if (_.get(dealNumberFormat, 'custom') === true) {
      this.setState({ custom: true });
    } else {
      onSelect(dealNumberFormat);
    }
  }

  renderDropdown() {
    const { id, numberFormat, size, dmpStyle, disabled, tip, includeUnnumbered } = this.props;

    return (
      <Dropdown
        id={id}
        size={size}
        block
        className="number-format-selector"
        btnClassName="dmp-btn-tool"
        disabled={disabled}
        dmpStyle={dmpStyle}
        onSelect={this.onSelect}
        title={numberFormat ? numberFormat.example : 'Unnumbered'}
        tip={tip}
        data-cy={id}
        ref={this.refDD}
      >
        {includeUnnumbered && <MenuItem eventKey={new DealNumberFormat({ type: 'none' })}>Unnumbered</MenuItem>}
        {includeUnnumbered && <MenuItem divider />}

        {NUMBER_STYLES.map((json, idx) => {
          if (json.divider) {
            return <MenuItem divider key={idx} />;
          } else if (json.header) {
            return (
              <MenuItem header key={idx}>
                {json.header}
              </MenuItem>
            );
          }

          const nf = new DealNumberFormat(json);

          return (
            <MenuItem
              key={idx}
              eventKey={nf}
              active={numberFormat && _.every(numberFormat, (val, key) => _.isEqual(val, nf[key]))}
            >
              <span>{json.label}</span>
              {json.description && <span className="rhs">{json.description}</span>}
            </MenuItem>
          );
        })}
      </Dropdown>
    );
  }

  renderCustom() {
    const { section, onSelect, id } = this.props;
    return (
      <CustomNumbering
        id={`${id}-custom`}
        section={section}
        target={this.refDD.current}
        hide={() => this.setState({ custom: false })}
        onSave={onSelect}
      />
    );
  }

  render() {
    const { custom } = this.state;
    return (
      <>
        {this.renderDropdown()}
        {custom && this.renderCustom()}
      </>
    );
  }
}

export default NumberFormatSelector;
