import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Checkbox } from '@components/dmp';

@autoBindMethods
export default class GradeFilterView extends Component {
  static propTypes = {
    grades: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    searchParams: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {};
  }

  toggleGrade(grade) {
    const { grades, onChange } = this.props;

    let newGradesFilter;

    if (_.includes(grades, grade)) {
      newGradesFilter = grades.filter((value) => value !== grade);
    } else {
      grades.push(grade);
      newGradesFilter = grades;
    }

    onChange(newGradesFilter);
  }

  render() {
    const { gradeOptions } = this.props;

    return (
      <div className="grade-filter" ref="filterAnchor" data-cy="grade-filter">
        {_.map(gradeOptions, this.renderGrades)}
      </div>
    );
  }

  renderGrades(grade, index) {
    const { grades } = this.props;

    return (
      <Checkbox
        id={`check-opt-${grade}-${index}`}
        key={index}
        checked={grades.indexOf(grade) > -1}
        onChange={() => this.toggleGrade(grade)}
      >
        {grade}
      </Checkbox>
    );
  }
}
