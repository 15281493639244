import React from 'react';

import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import { isVine } from '@core/utils/Environment';
import { classNamePrefixer } from '@core/utils/Generators';

import { Button, Loader } from '@components/dmp';

const cl = classNamePrefixer('modal-confirm');

const ModalConfirm = ({
  title,
  body,
  info = null,
  dmpStyle,
  onHide,
  confirmText,
  showConfirmText = true,
  cancelText,
  onConfirm,
  show,
  isLoading,
  ...passingProps
}) => {
  return (
    <Modal
      className={cl()}
      backdropClassName={cl('backdrop')}
      show={show}
      onHide={onHide}
      {...passingProps}
      data-cy="modal-confirm"
    >
      <Modal.Header closeButton={!!onHide}>
        <span className="headline">{title}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="wrapper">
          {body !== null && <p className="bold">{body}</p>}
          {info !== null && <p>{info}</p>}
        </div>
      </Modal.Body>

      <Modal.Footer>
        {isLoading && <Loader inline />}
        {onHide && cancelText && (
          <Button onClick={onHide} disabled={isLoading} data-cy="btn-cancel-text">
            {cancelText}
          </Button>
        )}
        {onConfirm && showConfirmText && (
          <Button dmpStyle={dmpStyle} onClick={onConfirm} disabled={isLoading} data-cy="btn-confirm-text">
            {confirmText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

ModalConfirm.defaultProps = {
  confirmText: 'Delete',
  cancelText: 'Cancel',
  show: false,
  isLoading: false,
  dmpStyle: 'danger',
};

ModalConfirm.propTypes = {
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  dmpStyle: PropTypes.string,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  show: PropTypes.bool,
  onHide: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default ModalConfirm;
