import _ from 'lodash';

export const measure = (component) => {
  const { recomputeSectionHeight, sectionIndex } = component.props;
  const wrapper = component.refSelf;

  const currentHeight = _.get(wrapper, 'current.offsetHeight', null);
  // Leave commented for debug
  // console.log(component.constructor.name, currentHeight, component._lastHeight);

  if (currentHeight && currentHeight !== component._lastHeight) {
    component._lastHeight = currentHeight;
    if (typeof recomputeSectionHeight === 'function') {
      recomputeSectionHeight(sectionIndex);
    }
  }
};
