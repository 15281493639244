import { assign, get, forEach, cloneDeep } from 'lodash';

export default class AITeamConfig {
  team = null;

  examples = null;
  designations = null;
  // additional config options can go here

  rules = [];

  constructor(json, team) {
    this.team = team;

    this.examples = get(json, 'examples', false);
    this.designations = get(json, 'designations', true);
    
    // TODO: if we make a model for AIStyleRule, we can type them here instead of cloneDeep
    const jsonRules = get(json, 'rules', []);
    forEach(jsonRules, (rule) => this.rules.push(cloneDeep(rule)));
    
  }

  get json() {
    return {
      examples: this.examples,
      designations: this.designations,
      // TODO: if we make a model for AIStyleRule, we can serialize here
      rules: this.rules,
    };
  }
}
