import React, { Component } from 'react';
import { createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, Modal } from 'react-bootstrap';

import Column from '@core/models/Column';
import DataSource from '@core/models/DataSource';
import TableColumn from '@core/models/TableColumn';
import Variable, { ValueType, VariableType } from '@core/models/Variable';

import { Alert, Button, DataTable, Loader } from '@components/dmp';
import { selectorColumn } from '@components/dmp/DataTableColumns';

import MultilineColPopover from '@components/MultilineColPopover';
import API from '@root/ApiClient';

@autoBindMethods
export default class DataSourceBrowser extends Component {
  static defaultProps = {
    multiselect: false,
  };

  static propTypes = {
    deal: PropTypes.object.isRequired,
    onHide: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    variable: PropTypes.instanceOf(Variable),
    multiselect: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      saving: false,
      error: null,
      data: [],
      selected: [],
    };

    this.refTable = createRef();
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.show) this.loadData(this.props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    // Always reload data every time we show the modal
    if (this.props.show && !prevProps.show) this.loadData(this.props);
  }

  toggleSelection(item) {
    const { multiselect } = this.props;
    const { selected } = this.state;
    const idx = _.findIndex(selected, { _itemId: item._itemId });
    let newSelected = [...selected];

    // For single select
    if (!multiselect) {
      if (idx > -1) newSelected = [];
      else newSelected = [item];
    }
    // For multi-select
    else {
      if (idx > -1) newSelected.splice(idx, 1);
      else newSelected.push(item);
    }

    this.setState({ selected: newSelected });
  }

  toggleAll({ data: tableData }) {
    // TODO: Refactor this into a re-usable function
    // See DataTable.Story.jsx for a really similar function...
    const { data, selected } = this.state;
    if (!tableData.length) return;

    let newSelected = [...selected];
    // If filtered, only select these rows
    const isFiltering = tableData.length !== data.length;

    // When filtering, only apply selection behavior on the filtered items
    if (isFiltering) {
      if (!newSelected.length) {
        // We had none selected so we just select all the filtered data
        newSelected = [...tableData];
      } else {
        const selectedIds = selected.map((item) => item._itemId);
        const unselectedTableData = tableData.filter((item) => !selectedIds.includes(item._itemId));
        if (!unselectedTableData.length) {
          // They were all selected already, remove them all
          tableData.forEach((item) => newSelected.splice(_.findIndex(newSelected, { _itemId: item._itemId }), 1));
        } else {
          // Add the missing ones
          unselectedTableData.forEach((item) => newSelected.push(item));
        }
      }
    } else {
      // nnn-filtered
      newSelected = !newSelected.length || tableData.length !== newSelected.length ? [...tableData] : [];
    }

    this.setState({ selected: newSelected });
  }

  onSelect() {
    const { variable, onSelect } = this.props;
    this.setState({ saving: true });
    onSelect(variable, this.sortedData, this.dsFields);
  }

  get columns() {
    const { multiselect } = this.props;
    const { selected } = this.state;

    const fields = this.dsFields;
    if (!fields.length) return [];

    const columns = [];
    columns.push(
      selectorColumn({
        // Make sure to use this column name for "toggleAll" behavior
        accessor: '_itemId',
        selected,
        onToggleAll: this.toggleAll,
        multiselect,
        minWidth: 110,
        onToggle: this.toggleSelection,
      })
    );

    let minWidth = null;
    if (fields.length > 5) {
      minWidth = 140;
    }

    _.forEach(fields, (field) => {
      columns.push(
        new Column({
          accessor: field.id,
          key: field.id,
          sortable: true,
          Header: field.displayName,
          minWidth: minWidth,
          Cell: ({ original, value }) => {
            if (field.multiline && value && field.multilineValueOptions && field.multilineValueLabels) {
              const { _itemId } = original;
              const selectedItem = selected.find((item) => item._itemId === _itemId);
              if (!selectedItem || value?.split('\n').length <= 1) {
                const multilineValueLabelsList = field.multilineValueLabels
                  ? field.multilineValueLabels[_itemId]
                  : null;
                const multilineValueLabels = field.multilineValueLabelsMap(multilineValueLabelsList);
                return (
                  <div className="variable-multi-line-text">
                    {value?.split('\n').map((val, idx) => (
                      <span key={idx}>
                        {field.formatValue(val, original)}
                        {multilineValueLabels && multilineValueLabels[val] && (
                          <span className="multi-line-label">{` (${multilineValueLabels[val]})`}</span>
                        )}
                      </span>
                    ))}
                  </div>
                );
              }
              const val = selectedItem[field.id];
              const multilineValueOptions = field.multilineValueOptions[_itemId];
              const multilineValueLabelsList = field.multilineValueLabels[_itemId];
              const multilineValueLabels = field.multilineValueLabelsMap(multilineValueLabelsList);
              return this.renderMultilineValuesCell(field, val, multilineValueOptions, _itemId, multilineValueLabels);
            }
            try {
              return field.formatValue(value, original);
            } catch (e) {
              return e.errorValue;
            }
          },
        })
      );
    });

    return columns;
  }

  updatedSelectionOnMultilineValueSelect(_itemId, column, columnValue) {
    const { selected } = this.state;
    const updatedSelection = selected.map((item) => {
      if (item._itemId === _itemId) {
        return { ...item, [column.id]: columnValue };
      }
      return item;
    });

    this.setState({ selected: updatedSelection });
  }

  renderMultilineValuesCell(field, value, multilineValueOptions, _itemId, multilineValueLabels) {
    return (
      <MultilineColPopover
        col={field}
        container={this.tableRef}
        saveMultilineVar={(value) => this.updatedSelectionOnMultilineValueSelect(_itemId, field, value)}
        colValue={value}
        multilineValueOptions={multilineValueOptions}
        multilineValueLabels={multilineValueLabels}
      />
    );
  }

  tdComponent({ className, children, to, ...rest }) {
    const { multiselect } = this.props;
    const { selected } = this.state;
    const classNames = cx(className, 'rt-td');
    const item = _.get(children, 'props.original');

    // Enable row-click to toggle item selection, unless we're in single selection (Radio) mode and it's already selected
    const onClick = () => {
      if (multiselect || item !== selected[0]) this.toggleSelection(item);
    };

    return (
      <div className={classNames} {...rest} onClick={onClick}>
        {children}
      </div>
    );
  }

  async loadData() {
    const { variable } = this.props;
    const connection = this.connection;
    if (!connection) return;

    const args = {
      teamID: variable.deal.team,
      connection: connection.json,
      variable: variable.json,
      // browse: true,
    };

    args.variable.columns = _.map(this.dsFields, 'json');

    await this.setState({
      error: null,
      loading: true,
      saving: false,
      data: null,
    });

    try {
      const data = await API.call('getCollectionItems', args);
      let selected = [];
      if (variable.connectedDSID) {
        const item = _.find(data.items, { _itemId: variable.connectedDSID });
        if (item) selected.push(item);
      }

      this.setState({ data, loading: false, selected });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  get dsFields() {
    const { deal, variable } = this.props;
    const { data } = this.state;
    const { connection } = this;

    if (!variable) return [];

    if (data?.columns) {
      variable.columns = _.map(data.columns, (col) => new TableColumn(col, variable));
    }

    // If the variable passed in is a collection variable, or if we're looking at a Repeater's DataSource,
    // we already have the columns defined
    if (variable.valueType === ValueType.TABLE || variable instanceof DataSource) {
      return variable.columns;
    }

    const requiredDS = variable.requiredDS;

    // If it's a normal variable with no designated collection (e.g., broken/missing DealConnection)
    // we've got nothing to work with
    if (!requiredDS) return [];

    // Once we get here we know we're looking at a variable that's pointing at an individual field on 3rd-party DataSource
    // (e.g., a Filevine collection item field)
    // So we can inspect the Deal (Template) for other vars pointing at different fields in the same collection
    // and use this to dynamically build a browser for exactly those fields! ;-)
    const fields = _.filter(
      deal.variables,
      (v) =>
        v.type === VariableType.CONNECTED &&
        v.connectType === connection.type && //e.g., filevine
        v.externalType === variable.externalType && //e.g., collectionField
        v.externalSelector?.startsWith(`${requiredDS}.`) //e.g., expenses
    );

    return _.map(fields, (field) => TableColumn.fromConnectVariable(field.json));
  }

  get connection() {
    const { deal, variable } = this.props;
    return _.find(deal.connections, { type: variable?.connectType });
  }

  // Column sorting is managed internally in ReactTable,
  // but we want to deliver the data in the order the user has specified
  // so we can use refs to reach into its state and cross-list with our state here
  get sortedData() {
    const { selected } = this.state;
    const state = _.get(this.refTable, 'current.state');
    if (!state) return null;
    const sortedSelection = state.sortedData?.reduce((acc, item) => {
      const selection = selected.find(({ _itemId }) => _itemId === item.selection);
      if (selection) {
        return [...acc, { ...selection }];
      }
      return acc;
    }, []);
    return sortedSelection;
  }

  // Prevent mouse events from taking whole section into editing when invoked in Flow (ContentSection)
  stop(e) {
    e.stopPropagation();
  }

  render() {
    const { show, onHide, multiselect, variable, headlineAction } = this.props;
    const { saving, loading, data, selected, error } = this.state;
    const items = _.get(data, 'items', []);

    // Use wider modal if we're showing more than 2 fields
    const wide = this.dsFields.length > 2;
    const selectedItem = selected[0] || null;

    return (
      <Modal
        dialogClassName={cx('data-source-browser', { wide })}
        show={show}
        onHide={onHide}
        onMouseDown={this.stop}
        onMouseUp={this.stop}
      >
        <Modal.Header closeButton>
          <span className="headline">
            {headlineAction || 'Select'} {multiselect ? `${variable.displayName} data` : 'Individual Collection Item'}
          </span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">
            {error && (
              <div className="data-error">
                <span>An error occurred while loading {this.connection?.service?.name} data. </span>
                <a onClick={this.loadData}>Try again.</a>
              </div>
            )}
            {loading && (
              <div className="loader">
                <Loader inline />
                <span>Loading collection items...</span>
              </div>
            )}
            {!loading && !saving && headlineAction === 'Re-select' && (
              <Alert dmpStyle="danger">
                <span>
                  {headlineAction} {multiselect ? `${variable.displayName} data?` : 'Individual Collection Item?'}{' '}
                  {'This will clear current data.'}
                </span>
              </Alert>
            )}
            {items.length > 0 && (
              <DataTable
                clickable
                columns={this.columns}
                data={items}
                filterable
                ref={this.refTable}
                showPagination={false}
                TdComponent={this.tdComponent}
                hasFixedColumns
              />
            )}
            {!loading && !error && items.length === 0 && 'No collection items were found.'}
            {!loading && items.length > 0 && !multiselect && (
              <div className="selected-item">
                <ControlLabel>Collection item ID</ControlLabel>
                <FormControl
                  disabled
                  type="text"
                  value={selected[0]?._itemId || ''}
                  placeholder="Select a table row to show collection item ID"
                  bsSize="small"
                  data-cy="collection-item-id"
                />
                {selectedItem && (
                  <a className="link-clear" onClick={() => this.toggleSelection(selectedItem)} data-cy="link-clear">
                    Clear
                  </a>
                )}
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          {saving && <Loader />}
          <Button onClick={onHide} disabled={saving}>
            Cancel
          </Button>
          <Button
            dmpStyle="primary"
            disabled={loading || saving || !items.length || error}
            onClick={this.onSelect}
            data-cy="ds-browser"
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
