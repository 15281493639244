import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { Icon, Loader } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import API from '@root/ApiClient';

@autoBindMethods
export default class BundleIcon extends Component {
  static propTypes = {
    dealRecord: PropTypes.object,
    history: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      inspecting: false,
      bundleParentName: null,
      loading: false,
      access: true,
    };

    this.timeoutTip;
  }

  async getBundleParentName() {
    const { dealRecord } = this.props;
    const { bundleParentName, access } = this.state;

    if (bundleParentName || !access) return;

    this.setState({ loading: true });

    try {
      const parentDealRecord = await API.call('getDealRecord', { dealID: dealRecord.parentDealID });
      this.setState({ access: true, bundleParentName: parentDealRecord.name, loading: false });
    } catch (err) {
      this.setState({ access: false, loading: false });
    }
  }

  redirectToParent(e) {
    const { dealRecord, history } = this.props;

    e.preventDefault();
    history.push(`/deals/${dealRecord.parentDealID}`);
  }

  onMouseEnter = () => {
    this.timeoutTip = setTimeout(async () => {
      await this.getBundleParentName();
    }, 500);
  };

  render() {
    const { bundleParentName, loading } = this.state;

    const content = (
      <span
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={() => clearTimeout(this.timeoutTip)}
        onClick={this.redirectToParent}
      >
        {loading ? <Loader /> : <Icon name="bundle" />}
      </span>
    );

    return (
      <div className="bundle-icon">
        {bundleParentName ? (
          <TooltipButton tip={`${bundleParentName} (Parent document)`}>{content}</TooltipButton>
        ) : (
          content
        )}
      </div>
    );
  }
}
