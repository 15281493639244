import React from 'react';

import PropTypes from 'prop-types';

import StatusLabel from '@components/dmp/StatusLabel';

const ITEM_SHAPE = PropTypes.shape({
  status: PropTypes.string,
  color: PropTypes.string,
  total: PropTypes.number,
});

const ReportItemStatus = ({ color, status, total }) => (
  <div className="grid-item center" data-cy="grid-item">
    <StatusLabel color={color || undefined} status={status} />
    <div className="grid-item-total" data-cy="grid-item-total">
      {total}
    </div>
  </div>
);

ReportItemStatus.propTypes = {
  ITEM_SHAPE,
};

export default ReportItemStatus;
