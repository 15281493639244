import React, { Component } from 'react';

import _ from 'lodash';

import Preloader from '@components/Preloader';
import SummaryView from '@components/SummaryView';
import DealHeader from '@components/deal/DealHeader/DealHeader';
import API from '@root/ApiClient';
import Auth from '@root/Auth';
import Fire from '@root/Fire';
import ErrorView from '@root/routes/ErrorView';

import ContractView from './ContractView';

export default class AutoDeal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      deal: null,
      summary: null,
      source: null,
      settingUp: false,
      error: null,
      loading: true, //start out in loading state
    };
  }

  componentDidMount() {
    const { user } = this.props;
    this._isMounted = true;

    if (!user) {
      Auth.anonLogin();
    } else {
      this.setState({ user }, () => this.setup());
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (props.user) this.setState({ user: props.user }, () => this.setup());
  }

  setup() {
    let { deal, settingUp } = this.state;
    //use state to ensure this is only run once
    if (this.state.user && !deal && !settingUp) {
      this.setState({ settingUp: true });

      //teamID and templateKey are needed to lookup the template to use for generating the deal
      //they can come either directly from props (e.g., internally for public contracts)
      //or from from the url (e.g., in an iframe integration case like Payfully)
      let { teamID, templateKey } = this.props;
      if (this.props.match && this.props.match.params) {
        if (!teamID) teamID = this.props.match.params.teamID;
        if (!templateKey) templateKey = this.props.match.params.templateKey;
      }

      //if we're still missing one of these, don't try to create an auto deal
      if (!teamID || !templateKey) {
        // eslint-disable-next-line no-console
        console.log('missing!');
      } else {
        const { variables, user } = this.ingestVariables();
        const existingKeys = this.state.user.deals || {};

        API.call(
          'createAutoDeal',
          { uid: this.state.user.id, existingKeys, teamID, templateKey, user, variables },
          (dealID) => this.getDeal(dealID),
          (err) => {
            this.setState({ loading: false, settingUp: false, error: err });
          }
        );
      }
    }
  }

  ingestVariables() {
    let { search } = this.props.location;
    if (search) search = search.slice(1).split('&'); //slice off the '?' then break into key=val chunks
    else search = [];
    const variables = {};
    const user = {};

    search.map((arg) => {
      const variable = decodeURIComponent(arg.split('=')[0]);
      let value = decodeURIComponent(arg.split('=')[1] || '');
      if (value == '') value = null;

      if (variable && value !== undefined) {
        const pieces = variable.split('.');
        //variable names beginning with "user." are a separate arg which will become the deal user (and have deal user properties)
        if (pieces.length === 2 && pieces[0] === 'user') {
          user[pieces[1]] = value;
        } else {
          variables[variable] = value;
        }
      }
    });

    return { variables, user };
  }

  getDeal(dealID) {
    Fire.getDeal(
      dealID,
      (deal) => {
        if (this._isMounted) {
          this.setState({
            deal,
            summary: deal.buildSummary(),
            source: deal.buildSource(true),
            settingUp: false,
            loading: false,
          });
          if (typeof this.props.onDealLoaded == 'function') this.props.onDealLoaded(deal);
        }
      },
      true,
      (error) => {
        this.setState({ error: error });
      }
    );
  }

  onSignature() {
    const deal = this.state.deal;
    const du = deal.currentDealUser;

    if (du.signed) {
      if (window && window.parent && typeof window.parent.postMessage === 'function') {
        console.log('All signatures complete for this user; passing dealID and vars to parent');
        //prep some data to send back to iframe parent. remove raw signature data!

        const user = _.clone(deal.raw.users[this.props.user.id]);
        const variables = deal.raw.variables;

        window.parent.postMessage({ function: 'onSignature', args: { dealID: deal.dealID, user, variables } }, '*');
      } else {
        console.log('All signatures complete for this user; host window (iFrame parent) not found');
      }
    } else {
      console.log("One signature complete but there's more to sign!");
    }
  }

  componentWillUnmount() {
    if (this.state.deal) Fire.db.ref(`deals/${this.state.deal.dealID}`).off();
    this._isMounted = false;
  }

  render() {
    const { user, match } = this.props;
    const { loading, deal, error, summary, source } = this.state;

    if (loading) return <Preloader />;

    if (error) return <ErrorView error={error} user={user}></ErrorView>;

    const dealProps = {
      summary,
      source,
      deal,
      user,
      selectedVersion: deal.currentVersion,
    };

    const phase = deal.status.data.toLowerCase();
    let mode = match.params.mode || 'overview';

    //special case for contract-only (no overview)
    if (mode === 'overview' && !summary.length) mode = 'contract';

    //AutoDeal doesn't allow for contract (Source) editing
    //see "readonly" on ContractView below
    //but, if current user has edit privileges,
    //we need to allow editing on SummaryView
    //which will enable editing of UGC sections like Payment and Scope
    const canEditSummary = this.state.deal.currentDealUser && this.state.deal.currentDealUser.canEdit;

    return (
      <ErrorView user={user}>
        <div className="auto-deal" style={this.props.style} data-dealid={this.state.deal.dealID}>
          <DealHeader {...this.props} {...dealProps} compact />
          <div className="outer-paper-layout">
            {mode == 'overview' && <SummaryView editable={canEditSummary} {...dealProps} />}
            {mode == 'contract' && (
              <ContractView editable={false} noActivity {...dealProps} onSignature={() => this.onSignature()} />
            )}
          </div>
        </div>
      </ErrorView>
    );
  }
}
