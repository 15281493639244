import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DealUser from '@core/models/DealUser';
import Section from '@core/models/Section';
import User from '@core/models/User';

import Siggy from '@components/deal/Siggy';

@autoBindMethods
export default class SignatureBlock extends Component {
  static defaultProps = {
    onSignature: _.noop,
    style: null,
    signed: false,
  };

  static propTypes = {
    container: PropTypes.object,
    dealUser: PropTypes.instanceOf(DealUser),
    empty: PropTypes.bool,
    partyID: PropTypes.string,
    section: PropTypes.instanceOf(Section),
    user: PropTypes.instanceOf(User),
    readonly: PropTypes.bool,
    style: PropTypes.object,
    signed: PropTypes.bool,

    // Legacy shit for Siggy
    activity: PropTypes.object,
    onSignature: PropTypes.func,
    toggleDealStatus: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      isAddingNew: false,
    };
  }

  render() {
    const {
      activity,
      container,
      dealUser,
      onSignature,
      partyID,
      section,
      style,
      toggleDealStatus,
      user,
      readonly,
      signed,
    } = this.props;

    const stamp = section && dealUser ? section.stamp(dealUser) : null;

    // There may be no dealUser prop if a user is not yet assigned to this party,
    // but we can use a nbsp; to ensure that the space is still held so that signatures align
    const legalName = _.get(dealUser, 'legalName', <>&nbsp;</>);

    // If a user is just an individual, there won't be a line underneath signature showing title
    let subTitle = '';
    if (!!_.get(dealUser, 'org')) {
      subTitle += dealUser.fullName;
      if (dealUser.fullName && dealUser.title) {
        subTitle += ', ' + dealUser.title;
      }
    }

    // Standard section title/body styles are used for party names and signer info
    const styleBody = section.deal.style.type.SectionBody.css;

    return (
      <div className="deal-user" data-cy="signature-block-deal-user" style={style}>
        <div className="sig-block">
          <div className="legal-name" style={styleBody} data-cy="legal-name">
            {legalName}
          </div>
        </div>

        <Siggy
          user={dealUser}
          currentUser={user}
          deal={section.deal}
          container={container}
          section={section}
          activity={activity}
          toggleDealStatus={toggleDealStatus}
          onSignature={onSignature}
          partyID={partyID}
          readonly={readonly}
          signed={signed}
        />

        {subTitle && (
          <div className="title" style={styleBody} data-cy="title">
            {subTitle}
          </div>
        )}

        {stamp && (
          <div className="sig-stamp">
            <span className="stamp-label">SIGNED</span>
            <span className="stamp-date">{stamp}</span>
          </div>
        )}
      </div>
    );
  }
}
