import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';

import AutoSuggest from 'react-autosuggest';

import { VariableType } from '@core/models/Variable';

import { findColumn } from '@components/deal/Columns';

@autoBindMethods
class TemplateVariableSearch extends Component {
  static propTypes = {};

  constructor(props) {
    super(props);

    this.state = {
      value: '', // aka 'search query'
      suggestions: [], // array of suggestions that will be rendered
      instruction: '',
      variableColumns: [],
    };

    this.getSuggestions = _.debounce(({ value }) => {
      const { variableColumns } = this.state;
      if (!this._isMounted) return;

      //only run search if there are at least 3 characters,
      //OR if there are existing results (i.e., user is hitting backspace to broaden search)
      if (!value || value?.length < 1) {
        this.setState({ suggestions: [] });
        return;
      }

      const suggestions = _.filter(variableColumns, ({ column, dealTemplate }) => {
        const { Header } = column;
        if (Header) return Header.toLowerCase().slice(0, value?.length) === value.toLowerCase();
      });
      this.setState({ suggestions });
    }, 150);
  }

  componentDidMount() {
    this._isMounted = true;
    this.buildVariableColumns();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const { teamID } = this.props;

    if (prevProps.teamID && teamID && teamID !== prevProps.teamID && this._isMounted) {
      this.buildVariableColumns();
    }
  }

  async buildVariableColumns() {
    const { teamTemplates } = this.props;
    const variableColumns = [];
    for await (const template of teamTemplates) {
      const { variables } = template;
      const variableValues = _.values(variables);
      for await (const variable of variableValues) {
        const { name, type } = variable;
        const column = await findColumn(`v.${name}`, template);
        if (column) {
          variableColumns.push({ column, template, type });
        }
      }
    }
    this.setState({ variableColumns: _.uniqBy(variableColumns, 'column.key') });
  }

  onSuggestionSelected(event, { suggestion, method }) {
    const { onSelect } = this.props;
    this.setState({ value: '' });
    onSelect(suggestion);
  }

  /** This is being called by AutoSuggest each time we need to clear suggestions.
   * It will reset the current state and prepare it ready for the next query */
  onSuggestionsClearRequested() {
    if (this._isMounted) {
      this.setState({ suggestions: [], value: '' });
    }
  }

  getSuggestionValue(suggestion) {
    return suggestion.column.Header;
  }

  getSimpleSuggestions(suggestions) {
    return suggestions.filter((variable) => variable.type === VariableType.SIMPLE);
  }

  // When user type in input box, the searchBox's states and dealList's states are being updated
  onChange = (event, { newValue }) => {
    if (this._isMounted) {
      this.setState({ value: newValue });
    }
  };

  render() {
    const { value, suggestions } = this.state;

    // Required by AutoSuggest to pass props into the input
    const inputProps = {
      placeholder: 'Enter a variable name',
      value,
      onChange: this.onChange,
      autoFocus: true,
    };

    return (
      <AutoSuggest
        suggestions={this.getSimpleSuggestions(suggestions)}
        onSuggestionsFetchRequested={this.getSuggestions}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        inputProps={inputProps}
        renderSuggestion={this.renderSuggestion}
        onSuggestionSelected={this.onSuggestionSelected}
      />
    );
  }

  renderSuggestion(suggestion) {
    const { searchedVariables } = this.props;
    const { column } = suggestion;
    let [, varName] = column.key.split('.');

    const active = !!_.find(searchedVariables, { key: column.key });
    if (active) return;

    return (
      <div className="template-var-suggestion">
        <div className="header"> {column.Header}</div>
        <div className="name">{`#${varName}`}</div>
      </div>
    );
  }
}

export default TemplateVariableSearch;
