import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

import Dropdown from '@components/dmp/Dropdown';

const cl = classNamePrefixer('dd');

const DropdownDots = React.forwardRef(({ className, horizontal, faded, pushRight, ...passingProps }, ref) => (
  <Dropdown
    {...passingProps}
    className={cx(cl('dots'), { [cl('dots-faded')]: faded }, { [cl('dots-push-right')]: pushRight }, className)}
    icon={horizontal ? 'dotsHorizontal' : 'dots'}
    title={null}
    dmpStyle="link"
    iconOnly={true}
    noCaret={true}
    width={null}
    block={false}
    pullRight
    ref={ref}
  />
));

DropdownDots.displayName = 'DropdownDots';

DropdownDots.defaultProps = {
  horizontal: false,
  faded: true,
  pushRight: true,
};

DropdownDots.propTypes = {
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  faded: PropTypes.bool,
  pushRight: PropTypes.bool,
};

DropdownDots.MenuItem = Dropdown.MenuItem;

export default DropdownDots;
