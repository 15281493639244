import axios from 'axios';
import CryptoJS from 'crypto-js';
import _ from 'lodash';

import CONFIG from '@root/Config';
import Fire from '@root/Fire';

import APIError from './server/ApiError';

const { GCP } = CONFIG;

class APIClient {
  path = '/api';
  cloudFunctionBaseURL = `https://${GCP.region}-${GCP.projectId}.cloudfunctions.net`;

  call(method, args = {}, success, failure) {
    //API is only open to authenticated users (via Firebase)
    //so we wrap all API calls to insert token so that server can verify request is from a valid user
    return new Promise((resolve, reject) => {
      Fire.token(
        (token) => {
          args.token = token;
          axios
            .post(this.path + '/' + method, args)
            .then((response) => {
              // console.log('SUCCESS', response.data);
              if (typeof success == 'function') success(response.data);
              resolve(response.data);
            })
            .catch((error) => {
              const err = _.assign({}, error);
              const data = err && err.response ? err.response.data : null;
              const status = err && err.response ? err.response.status : null;

              if (typeof failure == 'function') failure(data, status);
              //if we recieve a custom APIError return a new instance of that error.
              else if (data?.error) reject(new APIError(err.response.status, data.error));
              else reject(error);
            });
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }

  // This is a new API client method used for reading streaming HTTP responses
  // Method signature is the same as call() but with an additional onChunk callback used to pass data as it's received
  // Currently only used for streaming responses from AI Blocks -- see API.assist()
  // But it's generic so could be used for any server call where streaming is supported
  async stream(method, args = {}, onChunk = _.noop) {
    args.token = await Fire.token();

    // getting response from server based on the user prompt
    const response = await fetch(this.path + '/' + method, {
      method: 'post',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(args),
    });

    if (!response.ok || !response.body) {
      const body = await response.json();
      if (body.error) return new APIError(response.status, body.error);
      return new APIError(500, new Error(response.statusText));
    }

    // https://medium.com/@jsameer/real-time-askbot-with-react-express-chatgpt-8bb465352a77
    // Here we start prepping for the streaming response
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    const loopRunner = true;
    let buffer = '';

    while (loopRunner) {
      // Here we start reading the stream, until its done.
      const { value, done } = await reader.read();
      if (done) {
        break;
      }
      const decodedChunk = decoder.decode(value, { stream: true });
      buffer += decodedChunk;
      // Here where the magic happens -- we're still reading the response stream and it's still open,
      // but we've received and decoded a chunk of data, so we can pass it back to the caller for use (rendering) while we wait on the next chunk!
      onChunk(decodedChunk, buffer);
    }
    return buffer;
  }

  callAnon(method, args, success, failure) {
    //some calls are anonymously accessible (getPublicDeal); skip auth token piece
    return new Promise((resolve, reject) => {
      axios
        .post(this.path + '/' + method, args)
        .then((response) => {
          if (typeof success == 'function') success(response.data);
          resolve(response.data);
        })
        .catch((error) => {
          const err = _.assign({}, error);
          const data = err && err.response ? err.response.data : null;
          if (typeof failure == 'function') failure(data);
          else console.error(err);
          reject(error);
        });
    });
  }

  signRequest(args, secret) {
    const headers = {
      'Content-Type': 'application/json',
    };

    const time = new Date().getTime().toString();
    const hash = CryptoJS.HmacSHA256(`${time}.${JSON.stringify(args)}`, secret);
    const b64 = CryptoJS.enc.Base64.stringify(hash);

    headers['outlaw-sig'] = `t=${time},v1=${b64}`;

    return headers;
  }

  /**
   * Make a request to an HTTP Cloud Function
   *
   * @param {Object} request Object use to build the request
   * @param {string} request.functionName The name of the cloud function being called
   * @param {string} [request.method=post] The HTTP method of the request (defaults to POST)
   * @param {Object} [request.data] Optional. JSON object to be included in the request
   * @param {Object} [request.queryParams] JSON object of key/value pairs that will be transformed into a query string
   * @param {string} [request.token] The Firebase token used to authorize the request, should only be passed in when this is called from the backend
   * @returns {Object} JSON object if the API returns data, otherwise null
   */
  async request({ functionName, method = 'post', data = null, queryParams = null, token = null }) {
    let idToken;

    // Allow backend to pass in token, otherwise grab it from Fire.token when in frontend context
    try {
      idToken = token ?? (await Fire.token());
    } catch (err) {
      console.error('Failed to get fire token', err);
    }

    if (!idToken) {
      return new APIError(403, 'Invalid token.');
    }

    try {
      let queryString = '';
      if (queryParams) {
        queryString = '?' + new URLSearchParams(queryParams).toString();
      }

      // Call the cloud function, using the token to authorize the request
      const url = `${this.cloudFunctionBaseURL}/${functionName}${queryString}`;
      const config = {
        url,
        method,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      };

      if (data) {
        config.data = data;
      }

      const response = await axios(config);

      return response.data ?? null;
    } catch (err) {
      console.error('Cloud Function API Error', err);
      return new APIError(500, err);
    }
  }
}

const API = new APIClient();
if (typeof window == 'object') window.API = API;
export default API;
