import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { FEATURES } from '@core/models/Team';
import { isAdmin } from '@core/models/User';

import { Button, Icon, Loader, ModalConfirm } from '@components/dmp';

import API from '@root/ApiClient';

@autoBindMethods
export default class FilevineIntegration extends Component {
  static defaultProps = {
    team: null,
    isOwner: false,
  };

  static propTypes = {
    isOwner: PropTypes.bool,
    team: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    reloadTeam: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isSaving: false,
      errorMessage: null,
      isProcessing: false,
      showModal: false,
      appID: '',
      token: '',
      url: '',
      filevine: null,
      isActive: false,
      isDeleting: false,
      isActivating: false,
      showConfirmDelete: false,
      showConfirmDeactivate: false,
      showTestingConnection: false,
      testingConnectionStatus: 'loading',
      testingConnectionError: null,
    };
  }

  async componentDidMount() {
    await this.setActiveState();
    await this.loadIntegration();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.team.teamID !== this.props.team.teamID) {
      await this.setState({ integration: null, appID: '', token: '', url: '' });
      await this.setActiveState();
      this.loadIntegration();
    }
  }

  setActiveState() {
    const { team } = this.props;
    return this.setState({ isActive: team.isFeatureActive(FEATURES.FILEVINE) });
  }

  async loadIntegration() {
    const { isOwner, team } = this.props;
    const { isActive } = this.state;

    if (!isActive || !isOwner) return;

    await this.setState({ isLoading: true });

    const integrations = await API.call('getTeamIntegrations', { teamID: team.teamID });

    this.setState({
      integration: integrations.filevine || null,
      isLoading: false,
    });
  }

  async handleDelete() {
    const { team } = this.props;

    await this.setState({ isDeleting: true });
    await API.call('deleteTeamIntegration', { teamID: team.teamID, type: FEATURES.FILEVINE.key });

    this.setState({
      isDeleting: false,
      integration: null,
      showConfirmDelete: false,
    });
  }

  get isValid() {
    const { appID, token, url } = this.state;
    return appID && token && url;
  }

  async handleSubmit(event) {
    const { team } = this.props;
    const { appID, token, url } = this.state;

    event.preventDefault();

    await this.setState({ isSaving: true });

    try {
      const integration = { config: { appID, token, url } };
      const res = await API.call('saveTeamIntegration', {
        teamID: team.teamID,
        type: FEATURES.FILEVINE.key,
        integration,
      });

      this.setState({
        integration: res.integration,
        isSaving: false,
        showModal: false,
      });
    } catch (err) {
      const errorMessage = "Sorry, we're unable to save your Filevine settings. Please try again later";
      this.setState({ errorMessage, isSaving: false });
    }
  }

  handleHideModal() {
    this.setState({ showModal: false });
  }

  handleConfig() {
    const { integration } = this.state;
    const newState =
      integration && integration.config ? { ..._.pick(integration.config, ['appID', 'token', 'url']) } : {};
    this.setState({ ...newState, showModal: true });
  }

  async setFeature(isActive) {
    const { reloadTeam, team } = this.props;

    await this.setState({ isActivating: true });

    await API.call('setTeamFeature', {
      teamID: team.teamID,
      name: FEATURES.FILEVINE.key,
      isActive,
    });

    await reloadTeam();
    await this.setActiveState();
    this.setState({ isActivating: false });
  }

  async handleDeactivate() {
    await this.setFeature(false);
    this.setState({ showConfirmDeactivate: false });
  }

  async testConnection() {
    const { team } = this.props;

    await this.setState({ showTestingConnection: true, testingConnectionStatus: 'loading' });

    try {
      await API.call('testIntegrationConnection', { teamID: team.teamID, service: FEATURES.FILEVINE.key });
      this.setState({ testingConnectionStatus: 'success' });
    } catch (err) {
      console.error(err);
      await this.setState({
        testingConnectionStatus: 'error',
        testingConnectionError: _.get(err, 'response.data.error', null),
      });
    }
  }

  renderModal() {
    const { errorMessage, isSaving, showModal, appID, token, url, integration } = this.state;

    return (
      <Modal className="modal-filevine-integration" show={showModal} onHide={this.handleHideModal}>
        <Modal.Header closeButton>
          <span className="headline">Filevine Configuration</span>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <FormGroup>
              <ControlLabel>API Key</ControlLabel>
              <FormControl name="apiKey" value={appID} onChange={(e) => this.setState({ appID: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <ControlLabel>API Secret</ControlLabel>
              <FormControl name="apiSecret" value={token} onChange={(e) => this.setState({ token: e.target.value })} />
            </FormGroup>
            <FormGroup>
              <ControlLabel>Base URL</ControlLabel>
              <FormControl name="baseUrl" value={url} onChange={(e) => this.setState({ url: e.target.value })} />
            </FormGroup>
            <div className="d-flex justify-content-end">
              <Button size="small" dmpStyle="link" onClick={this.testConnection}>
                Test connection
              </Button>
            </div>
            {errorMessage && <p className="error">{errorMessage}</p>}
          </Form>
        </Modal.Body>

        <Modal.Footer>
          {isSaving && <Loader inline />}
          {!!integration && (
            <Button dmpStyle="link-danger" onClick={() => this.setState({ showModal: false, showConfirmDelete: true })}>
              Delete
            </Button>
          )}
          <Button dmpStyle="primary" disabled={this.isSaving || !this.isValid} onClick={this.handleSubmit}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  renderTestConnectionModal() {
    const { showTestingConnection, testingConnectionStatus, testingConnectionError } = this.state;
    let body = null;

    if (testingConnectionStatus === 'loading') {
      body = <p>Testing connection...</p>;
    }

    if (testingConnectionStatus === 'success') {
      body = (
        <>
          <Icon size="xlarge" name="check" />
          <p>Connection successful!</p>
        </>
      );
    }

    if (testingConnectionStatus === 'error') {
      body = (
        <>
          <Icon size="xlarge" name="close" />
          <p>{testingConnectionError || 'Connection failed'}</p>
        </>
      );
    }

    return (
      <ModalConfirm
        show={showTestingConnection}
        onConfirm={() => this.setState({ showTestingConnection: false })}
        confirmText="Ok"
        title="Testing connection"
        body={<div className="d-flex flex-column align-items-center">{body}</div>}
        dmpStyle="default"
        isLoading={testingConnectionStatus === 'loading'}
      />
    );
  }

  renderDeleteModal() {
    const { showConfirmDelete, isDeleting } = this.state;

    return (
      <ModalConfirm
        isLoading={isDeleting}
        show={showConfirmDelete}
        onHide={() => this.setState({ showConfirmDelete: false })}
        onConfirm={this.handleDelete}
        title="Really delete Filevine settings?"
        body={
          <>
            <p>
              This action removes the Filevine configuration associated with this team.
              <br />
            </p>
            <p>Are you sure you want to proceed?</p>
          </>
        }
      />
    );
  }

  renderDeactivateModal() {
    const { showConfirmDeactivate, isActivating } = this.state;

    return (
      <ModalConfirm
        isLoading={isActivating}
        show={showConfirmDeactivate}
        onHide={() => this.setState({ showConfirmDeactivate: false })}
        onConfirm={this.handleDeactivate}
        confirmText="Deactivate"
        title="Really deactivate Filevine?"
        body={
          <>
            <p>
              This action deactivate Filevine capabilities for this team.
              <br />
            </p>
            <p>Are you sure you want to proceed?</p>
          </>
        }
      />
    );
  }

  renderIntegration() {
    const { isOwner, user } = this.props;
    const { integration, isActive, isActivating, isLoading } = this.state;

    if (!isActive) {
      if (isAdmin(user)) {
        return (
          <div className="table-inner d-flex justify-content-between align-items-center table-inner-disabled">
            Filevine is currently disabled for this team.
            {isActivating ? (
              <Loader inline />
            ) : (
              <Button size="small" onClick={() => this.setFeature(true)}>
                Enable
              </Button>
            )}
          </div>
        );
      } else {
        return (
          <div className="table-inner text-disabled" data-cy="filevine-disabled-msg">
            <em>
              Filevine is an advanced feature.
              <br />
              If you want to enable it, please contact your Account Manager.
            </em>
          </div>
        );
      }
    }

    if (!isOwner) {
      return (
        <div className="table-inner text-disabled">
          <em>Only Owners can view and manage Filevine.</em>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div className="table-inner">
          <Loader inline />
        </div>
      );
    }

    if (!integration) {
      return (
        <div className="table-inner text-disabled">
          <em>Filevine is not yet configured.</em>
        </div>
      );
    }

    return (
      <>
        <div className="table-inner">
          <div className="s-mob-half s-tab-onethird bold">Base URL</div>
          <div className="s-mob-half s-tab-twothird">
            <div className="message ellipsis">{_.get(integration, 'config.url')}</div>
          </div>
        </div>
        <div className="table-inner">
          <div className="s-mob-half s-tab-onethird bold">API Key</div>
          <div className="s-mob-half s-tab-twothird">
            <div className="message ellipsis">{_.get(integration, 'config.appID')}</div>
          </div>
        </div>
        <div className="table-inner">
          <div className="s-mob-half s-tab-onethird bold">API Secret</div>
          <div className="s-mob-half s-tab-twothird">
            <div className="message ellipsis">************</div>
          </div>
        </div>
      </>
    );
  }

  render() {
    const { isOwner, user } = this.props;
    const { isActive, isActivating } = this.state;

    return (
      <div className="setting-block filevine">
        <div className="block-header">
          <h4>Filevine</h4>
          <span className="prompt">
            <p>Enable Filevine features for your Outlaw Team.</p>
          </span>
        </div>
        <div className="block-content">
          <div className="utility" style={{ minHeight: 33 }}>
            {isActive && isAdmin(user) && (
              <Button
                dmpStyle="link-danger"
                onClick={() => this.setState({ showConfirmDeactivate: true })}
                disabled={isActivating}
              >
                Deactivate
              </Button>
            )}
            {isActive && isOwner && (
              <Button onClick={this.handleConfig} data-cy="btn-filevine-configure">
                Configure
              </Button>
            )}
          </div>
          <div className="table-outer">{this.renderIntegration()}</div>
        </div>
        {this.renderModal()}
        {this.renderDeleteModal()}
        {this.renderDeactivateModal()}
        {this.renderTestConnectionModal()}
      </div>
    );
  }
}
