import { filter, find, forEach, merge } from 'lodash';

import Report from './Report';

export default class ReportStore extends Array {
  constructor(store) {
    super();

    if (typeof store !== 'undefined') {
      forEach(store, (json, reportID) => {
        this.push(new Report(merge({}, json, { reportID })));
      });
    }
  }

  get(reportID) {
    return find(this, { reportID });
  }

  get dashboardReports() {
    return filter(this, 'showOnDashboard');
  }

  get nonDashboardReports() {
    return filter(this, (report) => !report.showOnDashboard);
  }
}
