import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { Redirect } from 'react-router-dom';

import Login from '@components/Login';
import Preloader from '@components/Preloader';
import API from '@root/ApiClient';
import { PROVIDERS } from '@root/Auth';

const Wrapper = ({ cta, children }) => {
  return (
    <div className="wl-login">
      <div className="col1">
        <div className="col1-inner" data-cy="col1-inner">
          <h2>{cta}</h2>
          {children}
        </div>
      </div>
    </div>
  );
};

// This component is meant to handle the full white-label auth flow documented here:
// https://www.notion.so/getoutlaw/White-Label-Auth-Observer-Flow-WIP-131b44cc4f6147178dbe8efbb66f43f3
// It's currently limited to Filevine but is meant to be extensible to other white-label scenarios

// The flow is as follows:
// 1. inside FV frame and not authed -- login/signup inline with email/password or click to login via SSO (open popup)
// 2. inside FV frame -- the auth change is picked up in App.jsx and we redirect back to the originally requested page auto login

// Note, usePopup is always true because Google SSO doesn't allow signInWithRedirect inside an iframe,
// plus Safari doesn't share auth across different tabs, so we always need to use signInWithPopup in a white-label scenario

@autoBindMethods
class WLLoginView extends Component {
  static defaultProps = {
    user: null,
  };

  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loginError: PropTypes.string,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loginTypes: [],
    };
  }

  componentDidMount() {
    // On first load of both frame/non-frame, check for existence of account first
    this.checkAccount();
  }

  async checkAccount() {
    const providers = await API.callAnon('checkAccount', { email: this.wlUser });
    const loginTypes = [];

    // API call gives us a map of provider IDs according to Firebase auth;
    // we need to translate them into "LOGIN_TYPES" as defined in Login.jsx
    _.forEach(providers, (id) => {
      if (id === 'password') {
        loginTypes.push('Password');
        return;
      }
      const provider = _.find(PROVIDERS, { id });
      if (provider) loginTypes.push(provider.name);
    });

    this.setState({ loading: false, loginTypes });
  }

  // This component is only used inside an iframe after wlContext is passed to us via window.postMessage
  // and App.jsx stores them in a wlContext app state var
  getParam(param) {
    const { wlContext } = this.props;
    return _.get(wlContext, param, null);
  }

  get wlUser() {
    return this.getParam('wlUser');
  }
  get wlName() {
    return this.getParam('wlName');
  }
  get vineID() {
    return this.getParam('vineID');
  }

  render() {
    const { location, loginError, history, user } = this.props;
    const { loading, loginTypes } = this.state;
    const params = qs.parse(location.search);

    const loginProps = {
      loginError,
      location,
      history,
      dotvine: true,
      usePopup: true,
      // We should always have email and name from wlContext (passed via qs)
      userEmail: this.wlUser,
      userFullName: this.wlName,
    };

    // If there's already an Outlaw account for the designated email, only allow login via the existing login methods
    if (loginTypes.length > 0) {
      loginProps.loginTypes = loginTypes;
      loginProps.mode = 'login';
    }
    // Otherwise allow normal Outlaw signup
    else {
      loginProps.mode = 'signup';
    }

    if (loading) return <Preloader hideLogo={true} />;

    // Step 1 - prompt to login or open popup (SSO)
    if (!user) {
      const args = {
        wlUser: this.wlUser,
        wlName: this.wlName,
        vineID: this.vineID,
      };

      return (
        <Wrapper cta="Valid login required">
          {args.wlUser && <div className="no-auth">{args.wlUser} is not authenticated</div>}
          <div className="instructions">
            <span>To view this document,</span>
            <br />
            <span>please use the same credentials </span>
            <br />
            <span>as your Filevine account</span>
            <br />
          </div>
          <Login {...loginProps} />
        </Wrapper>
      );
    }
    // Step 2 - redirect after logged in
    else {
      const redirect = _.get(params, 'redirect');
      const from = decodeURIComponent(redirect);
      return <Redirect to={from} />;
    }
  }
}

export default WLLoginView;
