import React, { Component } from 'react';

import { hasRestrictedWorkflowAccess } from '@core/models/Workflow';

import Alert from '@components/dmp/Alert';
import Button from '@components/dmp/Button';

import OverviewSection from '@components/section_types/OverviewSection';

import DocRequestModal from './DocRequestModal';

export default class DocRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  render() {
    const { show } = this.state;
    const { deal, user } = this.props;
    if (!deal || !user) return null;

    const { layout } = deal.style;

    //must fill out all data before passing it on.
    const showConfirm =
      deal.currentWorkflowStep.index === 0 &&
      user.teamMemberships !== undefined &&
      !user.teamMemberships[deal.team].serviceProvider;
    //const disabled = deal.status.data === 'todo'; we might wanna add a check for if variables on the casedata page are not complete later on.

    const readonly = !hasRestrictedWorkflowAccess(deal, user);

    //enable conditional filtering to work even at top-level
    //so that entire summary blocks can be hidden
    const sections = deal.applyConditions(deal.root.children);

    return (
      <div
        className={`inside-paper-layout deal-view ${deal.status.data.toLowerCase()}`}
        style={layout.PageMargin.getPageWebMargins(window)}
      >
        {readonly && (
          <div>
            <Alert
              dmpStyle="warning"
              title={`This document has been submitted for preparation by ${deal.workflow.serviceProviderName}.`}
            >
              You will receive an automated notification when it is ready for review.
            </Alert>
          </div>
        )}
        {sections.map((sec, idx) => (
          <OverviewSection
            {...this.props}
            key={sec.id}
            section={sec}
            showBranding={false}
            editable={readonly}
            readonly={readonly}
          />
        ))}
        {showConfirm && (
          <Button className="case-data-btn" dark onClick={() => this.setState({ show: true })} dmpStyle="filevine">
            Confirm case data
          </Button>
        )}
        <DocRequestModal {...this.props} show={show} deal={deal} onHide={() => this.setState({ show: false })} />
      </div>
    );
  }
}
