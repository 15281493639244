import React, { Component } from 'react';

import cx from 'classnames';

export default class ColorLabel extends Component {
  render() {
    const { disabled, status, onClick, label, right } = this.props,
      className = cx('colorLabel', `deal-status-${status}`, { disabled: disabled });

    return (
      <span className={className} onClick={onClick}>
        <span className={cx('arrow', { right: right })} />
        {label}
      </span>
    );
  }
}
