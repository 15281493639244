import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal, Radio } from 'react-bootstrap';

import Filter from '@core/models/Filter';
import SearchParams from '@core/models/SearchParams';
import { TEAM_ROLES } from '@core/models/Team';
import { dt } from '@core/utils/Environment';

import { Button, Loader } from '@components/dmp';
import Alert from '@components/dmp/Alert';

import TeamSelector from '@components/teams/TeamSelector';
import Fire from '@root/Fire';

@autoBindMethods
export default class SaveFilter extends Component {
  static defaultProps = {
    teams: [],
    show: false,
  };

  static propTypes = {
    close: PropTypes.func.isRequired,
    show: PropTypes.bool,
    user: PropTypes.object.isRequired,
    team: PropTypes.object,
    teams: PropTypes.array,
    searchParams: PropTypes.instanceOf(SearchParams).isRequired,
    onSave: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      isTeamFilter: false,
      isSaving: false,
      selectedTeam: _.get(this.ownedTeams, 'length') === 1 ? _.get(this.ownedTeams, '0') : null,
    };

    this.titleRef = createRef();
  }

  componentDidUpdate(prevProps) {
    // as this component is initiated once only, update selectedTeam when team filter is applied
    if (prevProps.team !== this.props.team) {
      const { team } = this.props;
      this.setState({ selectedTeam: team ? _.find(this.ownedTeams, { teamID: _.get(team, 'teamID') }) : null });
    }
  }

  get ownedTeams() {
    const { teams, user } = this.props;
    //only return teams where the user is the owner
    return _.filter(teams, (verifiedTeam) => user.canEditTeam(verifiedTeam.teamID));
  }

  get canSave() {
    const { isSaving, title, isTeamFilter, selectedTeam } = this.state;
    if (isTeamFilter && !selectedTeam) return false;
    return !isSaving && !!title;
  }

  get isPreExistingTeamFilter() {
    return !!this.props.team;
  }

  async handleOnEnter() {
    await this.setState({ title: '' });
    if (this.titleRef) {
      this.titleRef.focus();
    }
  }

  async saveFilter() {
    const { title, isTeamFilter, selectedTeam } = this.state;
    const { searchParams, close, onSave, user } = this.props;

    const filter = new Filter({
      title,
      teamID: isTeamFilter ? _.get(selectedTeam, 'teamID') : null,
      searchParams: searchParams.json,
    });

    // If we did not pick a team for this Filter, it is a User Filter.
    if (!filter.isTeamFilter) filter.userID = user.id;

    await this.setState({ isSaving: true });
    filter.filterID = await Fire.saveFilter(filter);

    await onSave(filter);
    this.setState({ isSaving: false });
    close();
  }

  selectTeam(teamID) {
    const team = _.find(this.props.teams, { teamID });

    this.setState({ isTeamFilter: true });
    this.setState({ selectedTeam: team });
  }

  onVisibilityChange({ target }) {
    this.setState({ isTeamFilter: target.value === 'true' });
  }

  renderTeamRadio() {
    if (!_.get(this.ownedTeams, 'length')) return null;

    const { user } = this.props;
    const { isTeamFilter, isSaving, selectedTeam } = this.state;
    // if a pre existing team filter is present it will be the selectedTeam
    const preExistingTeamFilter = this.isPreExistingTeamFilter
      ? _.find(this.ownedTeams, { teamID: _.get(selectedTeam, 'teamID') })
      : null;

    let RadioLabel;
    if (preExistingTeamFilter) {
      RadioLabel = (
        <>
          Share with <strong>{_.get(preExistingTeamFilter, 'info.name', 'unknown')}</strong> team
        </>
      );
    } else if (_.get(this.ownedTeams, 'length') === 1) {
      RadioLabel = (
        <>
          Share with <strong>{_.get(this.ownedTeams, '[0].info.name', 'unknown')}</strong> team
        </>
      );
    } else {
      RadioLabel = (
        <>
          Share with team
          <br />
          <TeamSelector
            disabled={isSaving}
            disableNew
            width={180}
            onSelect={this.selectTeam}
            teamID={selectedTeam?.teamID || null}
            teams={this.ownedTeams}
            user={user}
          />
        </>
      );
    }

    return (
      <Radio
        checked={isTeamFilter}
        disabled={isSaving}
        name="isTeamFilter"
        onChange={this.onVisibilityChange}
        value={true}
        data-cy="radio-team"
      >
        {RadioLabel}
      </Radio>
    );
  }

  render() {
    const { show, close, user } = this.props;
    const { title, isSaving, isTeamFilter, selectedTeam } = this.state;

    return (
      <Modal
        dialogClassName="save-filter"
        show={show}
        onHide={close}
        onEnter={this.handleOnEnter}
        data-cy="modal-save-search"
      >
        {isTeamFilter && selectedTeam && !this.isPreExistingTeamFilter && (
          <Alert size="small" dmpStyle="warning" style={{ border: 'none', margin: 0, padding: '10px 20px' }}>
            Saving the filter on a team will only allow this filter to show {dt}s of that specific team.
          </Alert>
        )}
        <Modal.Header closeButton>
          <span className="headline">Save filter as...</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Title</ControlLabel>
              <div className="contents">
                <FormControl
                  disabled={isSaving}
                  onChange={(e) => this.setState({ title: e.target.value })}
                  placeholder="e.g., My Filter"
                  inputRef={(ref) => (this.titleRef = ref)}
                  type="text"
                  value={title}
                  data-cy="input-search-title"
                />
              </div>
            </FormGroup>
            <FormGroup>
              <ControlLabel>Visibility</ControlLabel>
              <div className="contents">
                <Radio
                  checked={!isTeamFilter}
                  disabled={isSaving || user.ownedTeams.length}
                  name="isTeamFilter"
                  onChange={this.onVisibilityChange}
                  value={false}
                  data-cy="radio-self"
                >
                  Just me
                </Radio>
                {this.renderTeamRadio()}
              </div>
            </FormGroup>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {isSaving && <Loader />}
          <Button onClick={close} disabled={isSaving}>
            Cancel
          </Button>
          <Button dmpStyle="primary" disabled={!this.canSave} onClick={this.saveFilter} data-cy="btn-modal-save-search">
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
