import { assign, filter, find, get } from 'lodash';

import DealAction from '../enums/DealAction';
import DealRole from '../enums/DealRole';
import DealStatus from '../enums/DealStatus';
import InviteStatus from '../enums/InviteStatus';
import SectionType from '../enums/SectionType';
import UserStatus from '../enums/UserStatus';
import { isEmail } from '../utils/Validation';
import ActivityLog from './ActivityLog';
import Address from './Address';
import { sanitize } from './Version';

export const COLORS = [
  '#98D6D2', //maroon
  '#E8754F', //burnt orange
  '#899F8B',
  '#B0484D', //sky blue
  '#F1B48D',
  '#C390BC',
  '#8C526C',
  '#628E88', //teal green
  '#C1666B',
  // '#848C8E',
  // '#82A3A1',
  // '#373F47',
  // '#C7CEDB',
  // '#8F9491',
  // '#C1666B',
  // '#BEB7A4'
  // '#4f9eff',
  // '#ff4f5f',
  // '#333333',
  // '#cccccc'
];

export const DEAL_USER_PROPERTIES = {
  EMAIL: 'email',
  FULLNAME: 'fullName',
  TITLE: 'title',
  ORG: 'org',
  ADDRESS: 'address',
  ADDRESS_PROPERTIES: 'addressProperties',
  PHONE: 'phone',
};

//this is exported so that we can use the same logic without a full DealUser object
export const displayName = (du) => {
  if (du.org) {
    //strip off company stuff
    const reg = /,?\s(inc(orporated)?|corp(oration)?|llc|llp|lp|ltd)\.?$/gi;
    return du.org.replace(reg, '');
  }
  //otherwise use name
  else if (du.fullName) {
    return du.fullName;
    //return first name
    // return du.fullName.split(' ')[0];
  }
  //otherwise email...
  else if (du.email) {
    return du.email;
  }
  //got nothin
  else {
    return '';
  }
};

export const initials = (du, max) => {
  if (!du.fullName) return du.email ? du.email[0].toUpperCase() : '';
  const names = du.fullName.split(' ');
  const inits = [];
  names.map((n) => {
    if (n.length > 0) inits.push(n[0].toUpperCase());
  });
  return inits.slice(0, max || 2);
};

export default class DealUser extends ActivityLog {
  key = null;
  uid = null;
  dealUserID = null;
  email = null;

  fullName = null;
  title = null;
  org = null;
  address = null;
  addressProperties = {};
  phone = null;
  role = DealRole.VIEWER;
  partyID = null;
  tags = [];

  constructor(json, deal, key) {
    super(json, deal);

    //if legalName is set on incoming raw data, it causes a crash
    //because legalName is meant to be computed below
    //so for now, delete
    if (json.legalName) delete json.legalName;

    assign(this, json);

    if (
      this.addressProperties &&
      typeof this.addressProperties === 'object' &&
      !(this.addressProperties instanceof Address)
    ) {
      this.addressProperties = new Address(this.addressProperties);
      if (this.addressProperties.fullAddress && this.addressProperties.fullAddress.length) {
        this.address = this.addressProperties.fullAddress;
      }
    }

    //DealUser keys can be 1 of 2 things:
    //1. a temporary key for when user is initially added (but not yet invited)
    //2. a uid once user has accepted invite
    if (key) {
      this.key = key;
      this.dealUserID = key;
    }

    //tags are stored as a comma-delimited string (Firebase doesn't play well with arrays) so convert to array when typed
    //we need a type check though; in at least one case (API.invite) it's already an array,
    //so it will have been assigned above
    if (json.tags && typeof json.tags === 'string') this.tags = json.tags.split(',');
  }

  get(property) {
    return property && this[property] ? this[property] : this.displayName;
  }

  get displayName() {
    return displayName(this);
  }

  get firstName() {
    return this.fullName ? this.fullName.split(' ')[0] : '';
  }

  get lastName() {
    return this.fullName ? this.fullName.split(' ').pop() : '';
  }

  get initials() {
    return initials(this);
  }

  get titleOrg() {
    if (this.title && this.org) return `${this.title}, ${this.org}`;
    else return this.title || this.org || '';
  }

  get color() {
    // if (this == this.deal.currentDealUser) return this.deal.branding.brandColor;

    //insert brand color as first in colors array
    // const colors = [this.deal.branding.brandColor, ...COLORS];
    const colors = COLORS;

    //auto colorize other users by cycling through the same set of colors
    const idx = this.deal.users.indexOf(this) % colors.length;
    return colors[idx];
  }

  get legalName() {
    return sanitize(this.org || this.fullName);
  }

  get status() {
    return this.email && this.fullName ? DealStatus.COMPLETE : DealStatus.TODO;
  }

  get isComplete() {
    return this.status.data === UserStatus.COMPLETE.data;
  }

  get partyName() {
    if (!this.deal || !this.partyID) return null;
    const p = this.deal.getPartyByID(this.partyID);
    return p == null ? null : p.partyName;
  }

  //check whether this DealUser has completed ALL necessary signatures
  get signed() {
    //user must be in party to sign!
    if (!this.partyID) return false;

    // Unfortunately can't pull in the real enum because it would result in a circular reference
    // but we have different logic to check when we're looking at a PDFDeal
    if (this.deal.dealType === 'external') {
      const elements = filter(this.deal.pdfElements, (el) => el.variable === this.partyID);
      const signed = filter(elements, 'data');
      return elements.length > 0 && elements.length === signed.length;
    } else {
      const legacy = filter(this.deal.users, 'sig').length > 0;
      //legacy deals (pre June 2018) have sig data attached to DealUser
      //so signing state is a simple check of whether the sig data is there
      if (legacy) return this.sig != null;
      //newer deals need to check whether the user has signed ALL signature sections where they're a required signatory
      else {
        // First collect all condition passing signature sections where this user's signature is required
        const sections = filter(this.deal.sections, 'passesConditions');
        const signatureSections = filter(
          sections,
          (s) => s.sectiontype == SectionType.SIGNATURE && s.signatories.indexOf(this.partyID) > -1
        );
        const done = filter(signatureSections, (s) => s.sigs[this.key] != null);
        return signatureSections.length > 0 && signatureSections.length == done.length;
      }
    }
  }

  //check whether this DealUser has completed ANY necessary signatures
  get startedSigning() {
    //user must be in party to sign!
    if (!this.partyID) return false;

    if (this.deal.hasVersions) {
      const signed = filter(this.deal.pdfElements, (el) => el.variable === this.partyID && !!el.data);
      return signed.length > 0;
    } else {
      const legacy = filter(this.deal.users, 'sig').length > 0;
      //legacy deals (pre June 2018) have sig data attached to DealUser
      //so signing state is a simple check of whether the sig data is there
      if (legacy) return this.sig != null;
      //newer deals need to check whether the user's signature is required on any sections
      //and if so, see if any are present
      else {
        //first collect all signature sections where this user's signature is required
        const sections = filter(
          this.deal.sections,
          (s) =>
            s.sectiontype === SectionType.SIGNATURE && s.passesConditions && s.signatories.indexOf(this.partyID) > -1
        );
        const done = filter(sections, (s) => !!s.sigs[this.key]);
        return done.length > 0;
      }
    }
  }

  // The first time each user views a contract, a DealAction.READ event is generated
  get readEvent() {
    return (
      find(this.deal.activity, {
        action: DealAction.READ,
        user: this.uid || this.key,
      }) || null
    );
  }

  get canEdit() {
    if (this.deal.locked) return false;

    const editorRoles = [DealRole.OWNER, DealRole.EDITOR, DealRole.PROPOSER];
    return editorRoles.indexOf(this.role) > -1;
  }

  get canSign() {
    const deal = this.deal;

    // If there's a Checkpoint in progress or this user is missing essential details (name and email), disallow signing
    if (deal.readyCheck || this.status !== DealStatus.COMPLETE) return false;

    // If native Outlaw workflow, deal must be in either REVIEW or SIGNING in order to sign
    // If custom workflow, the current step must be an eSigning step
    if (deal.workflow.isOutlaw) {
      if (![DealStatus.REVIEW, DealStatus.SIGNING].includes(deal.status)) return false;
    } else {
      if (!get(deal.currentWorkflowStep, 'signable')) return false;
    }

    // In either workflow case, if there's open activity, disallow signing
    const sections = deal.applyConditions(deal.buildSource(true));
    const activity = deal.buildActivityStats(sections);
    if (!!activity.open || !!activity.changes) return false;

    return true;
  }

  // Email can no longer be edited once a user has accepted an invitation (including guests)
  // Note: deal owners can still change their *own* emails (for now)
  get isEmailLocked() {
    if (this.deal.isTemplate) return false;
    return isEmail(this.email) && this.key === this.uid && this.inviteStatus === InviteStatus.ACCEPTED;
  }

  // Centralizing logic for how to know when to show "Share" or "Re-share" links
  canBeInvitedBy(user) {
    // Only logged-in users can invite, period
    if (!user) {
      return false;
    }

    // Only specific users on a deal can invite others, and cannot invite self
    const me = this.deal.currentDealUser;
    if (!me || this === me) {
      return false;
    }

    // Otherwise, we have an owner looking at a different user;
    // Inviting is possible if either user has not accepted, or if user is guest who has not yet started signing
    return (
      ![InviteStatus.ACCEPTED, InviteStatus.OWNED].includes(this.inviteStatus) || (this.guest && !this.startedSigning)
    );
  }

  isStatus(status) {
    return this.status.data === status.data;
  }
}
