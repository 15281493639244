import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Button, Icon } from '@components/dmp';

export default class ReadOnlyDealHeader extends Component {
  static propTypes = {
    onClickRequest: PropTypes.func.isRequired,
  };

  render() {
    const { onClickRequest } = this.props;

    return (
      <div className="deal-header readonly">
        <div className="label-readonly" data-cy="label-readonly">
          <Icon name="lockClosed" />
          <span>Read Only</span>
        </div>

        <Button dmpStyle="primary" size="small" onClick={onClickRequest} data-cy="btn-request-to-edit">
          Request to edit
        </Button>
      </div>
    );
  }
}
