import React, { useEffect, useRef, useState } from 'react';

import { isNumber } from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import { ASPECTS } from '@core/models/Deal';
import Deal from '@core/models/Deal';
import User from '@core/models/User';
import { generateExportURL } from '@core/utils/Generators';

import { Button, Checkbox, Dropdown, MenuItem } from '@components/dmp';

import PDFPreview from '@components/PDFPreview';
import Fire from '@root/Fire';

const OrientationOptions = Object.freeze({
  portrait: 'Portrait',
  landscape: 'Landscape',
});

const ScalingOptions = Object.freeze({
  'fit-events-to-page': 'Fit events to page',
  'use-current-view': 'Use current view',
});

const TimelineDealExporter_propTypes = {
  show: PropTypes.bool.isRequired,
  deal: PropTypes.instanceOf(Deal).isRequired,
  user: PropTypes.instanceOf(User).isRequired,
  exportSettings: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
export default function TimelineDealExporter({ show, deal, user, exportSettings, onClose }) {
  const [includeProse, setIncludeProse] = useState(true);
  const [includeEventData, setIncludeEventData] = useState(true);
  //const [eventDataOrientation, setEventDataOrientation] = useState(Object.keys(OrientationOptions)[0]);
  const [includeTimeline, setIncludeTimeline] = useState(true);
  const [timelineOrientation, setTimelineOrientation] = useState(Object.keys(OrientationOptions)[0]);
  const [timelineScaling, setTimelineScaling] = useState(Object.keys(ScalingOptions)[0]);

  const [timelineExportSettings, setTimelineExportSettings] = useState({});
  const [showPreview, setShowPreview] = useState(false);

  const [timelineIsActive, setTimelineIsActive] = useState(false);

  const [downloadURL, setDownloadURL] = useState(null);
  const refDownloadLink = useRef();

  useEffect(() => {
    const active = isNumber(exportSettings?.timescale);
    if (!active) {
      if (timelineScaling === 'use-current-view') {
        setTimelineScaling('fit-events-to-page');
      }
    }
    setTimelineIsActive(active);
  }, [exportSettings]);

  const buildFullExportSettings = () => {
    const fullExportSettings = {
      ...exportSettings,
      includeProse,
      includeEventData,
      //eventDataOrientation,
      includeTimeline,
      timelineOrientation,
      timelineScaling,
    };
    return fullExportSettings;
  };

  const handlePreviewClicked = () => {
    setTimelineExportSettings(buildFullExportSettings());
    setShowPreview(true);
  };

  const handleExportFileClicked = () => {
    const timelineExportSettings = buildFullExportSettings();
    const stringified = JSON.stringify(timelineExportSettings);
    const encoded = encodeURIComponent(stringified);

    const aspects = [ASPECTS.CONTRACT];
    const options = {
      aspects: aspects.join(','),
      emptyBlocks: false,
      includeCertificate: false,
      timelineExportSettings: encoded,
    };

    Fire.token((token) => {
      setDownloadURL(generateExportURL({ deal, token, type: 'pdf', options }));
      refDownloadLink.current.click();
      onClose();
    });
  };

  return (
    <>
      <PDFPreview
        show={showPreview}
        close={() => {
          setShowPreview(false);
        }}
        deal={deal}
        user={user}
        timelineExportSettings={timelineExportSettings}
      />

      <Modal dialogClassName="export-contract" show={show} onHide={onClose} data-cy="export-contract">
        <Modal.Header closeButton>
          <span className="headline">Export as PDF</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>Include</ControlLabel>
              <div className="export-options">
                <div className="option">
                  <Checkbox
                    id="chk-include-prose"
                    checked={includeProse}
                    onChange={() => {
                      setIncludeProse((oldVal) => !oldVal);
                    }}
                  >
                    Prose (Source)
                  </Checkbox>
                </div>
                <div className="option">
                  <Checkbox
                    id="chk-event-data"
                    checked={includeEventData}
                    onChange={() => {
                      setIncludeEventData((oldVal) => !oldVal);
                    }}
                  >
                    Event Data (Table)
                  </Checkbox>
                  {/* TODO: we decided event data orientation isn't required for MVP, but could bring back later */}
                  {/* <div style={{ marginLeft: '25px', maxWidth: '200px' }}>
                    <small style={{ fontWeight: 'bold' }}>Orientation</small>
                    <Dropdown
                      id="dd-event-data-orientation"
                      title={OrientationItems[eventDataOrientation]}
                      onSelect={(val) => setEventDataOrientation(val)}
                      block
                      size="small"
                    >
                      {Object.keys(OrientationItems).map((key) => (
                        <MenuItem key={key} eventKey={key}>
                          {OrientationItems[key]}
                        </MenuItem>
                      ))}
                    </Dropdown>
                  </div> */}
                </div>
                <hr />
                <div className="option">
                  <Checkbox
                    id="chk-include-timeline"
                    checked={includeTimeline}
                    onChange={(evt) => {
                      setIncludeTimeline((oldVal) => !oldVal);
                    }}
                  >
                    Timeline
                  </Checkbox>
                  {includeTimeline && (
                    <div style={{ marginLeft: '25px', maxWidth: '300px' }}>
                      <small style={{ fontWeight: 'bold' }}>Orientation</small>
                      <Dropdown
                        id="dd-timeline-orientation"
                        title={OrientationOptions[timelineOrientation]}
                        onSelect={(val) => setTimelineOrientation(val)}
                        block
                        size="small"
                        style={{ maxWidth: '150px' }}
                      >
                        {Object.keys(OrientationOptions).map((key) => (
                          <MenuItem key={key} eventKey={key}>
                            {OrientationOptions[key]}
                          </MenuItem>
                        ))}
                      </Dropdown>
                      <div style={{ height: 10 }}></div>
                      <small style={{ fontWeight: 'bold' }}>Scaling</small>
                      <Dropdown
                        id="dd-timeline-scaling"
                        title={ScalingOptions[timelineScaling]}
                        onSelect={(val) => setTimelineScaling(val)}
                        block
                        size="small"
                        disabled={!timelineIsActive}
                        style={{ maxWidth: '200px' }}
                      >
                        {Object.keys(ScalingOptions).map((key) => (
                          <MenuItem key={key} eventKey={key}>
                            {ScalingOptions[key]}
                          </MenuItem>
                        ))}
                      </Dropdown>
                      {!timelineIsActive && <small>Please export from Timeline tab to select scaling.</small>}
                    </div>
                  )}
                </div>
              </div>
            </FormGroup>
          </div>

          <a ref={refDownloadLink} href={downloadURL} download />
        </Modal.Body>

        <Modal.Footer>
          <div style={{ height: 10 }}></div>
          <Button className="export" onClick={handlePreviewClicked}>
            Preview
          </Button>

          <Button dmpStyle="primary" className="export" onClick={handleExportFileClicked}>
            Export file
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
TimelineDealExporter.propTypes = TimelineDealExporter_propTypes;
