import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

// https://react-day-picker.js.org/api/DayPickerInput
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
// https://www.npmjs.com/package/react-time-picker
// Because we load some DMP components through server.js, we must import react-time-picker without styling
// Then, we import the stylesheets (TimePicker.css, Clock.css) directly in scss (outlaw.scss)
import TimePicker from 'react-time-picker/dist/entry.nostyle';

import { classNamePrefixer } from '@core/utils/Generators';

const cl = classNamePrefixer('daytime');

@autoBindMethods
export default class DayTime extends Component {
  static propTypes = {
    className: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    hasDate: PropTypes.bool,
    hasTime: PropTypes.bool,
    hasDateTo: PropTypes.bool,
    dateTo: PropTypes.instanceOf(Date),
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    atLabel: PropTypes.string,
    readOnly: PropTypes.bool,
    disableFilter: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    hasDate: true,
    hasTime: false,
    hasDateTo: false,
    atLabel: '@',
    readOnly: true,
    disableFilter: _.noop,
  };

  constructor(props) {
    super(props);

    if (props.hasDateTo && props.hasTime) {
      throw new Error('<DayTime> does not support hasTime when used as a date range (hasDateTo).');
    }

    const date = props.date || new Date();
    const dateTo = props.dateTo || undefined;

    this.state = {
      date,
      timeOfDay: date,
      dateTo,
    };
    this.refDateTo = React.createRef();
    this.refDateFrom = React.createRef();
  }

  get className() {
    const { className, disabled } = this.props;
    return cx(cl(), className, { disabled });
  }

  handleDayChange(date) {
    const { onChange } = this.props;
    const { date: time } = this.state;

    // get time of day from current date
    const combined = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      time.getHours(),
      time.getMinutes()
    );

    this.setState({ date: combined });
    onChange({ date: combined });
  }

  handleTimeChange(timeString) {
    const { onChange } = this.props;
    const { date } = this.state;

    //fixes the trying to split on null.
    if (timeString) {
      let [hours, minutes] = timeString.split(':');
      const combined = new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        parseInt(hours),
        parseInt(minutes)
      );

      this.setState({ date: combined });
      onChange({ date: combined });
    }
  }

  showFromMonth() {
    const { date, dateTo } = this.state;
    if (!date) {
      return;
    }
    if (moment(dateTo).diff(moment(date), 'months') < 2) {
      this.refDateTo.current.getDayPicker()?.showMonth(date); //We might not have an open date picker, if the user is manually entering a date via keyboard
    }
  }

  handleFromChange(date) {
    const { onChange, disableFilter } = this.props;
    if (date) {
      disableFilter(false);
    } else {
      disableFilter(true);
    }

    this.setState({ date });
    onChange({ date });
  }

  handleToChange(dateTo) {
    const { onChange, disableFilter } = this.props;
    const { date } = this.state;

    if (dateTo) {
      disableFilter(false);
    } else {
      disableFilter(true);
    }

    this.setState({ dateTo }, this.showFromMonth);
    onChange({ date, dateTo });
  }

  onKeyPressed(e, ref) {
    if (['Enter', 'Tab'].includes(e.key)) {
      ref.current.input.blur();
      ref.current.hideDayPicker();
    }
  }

  render() {
    const { hasDate, hasTime, disabled, atLabel, hasDateTo, readOnly } = this.props;
    const { date, timeOfDay, dateTo } = this.state;
    const modifiers = { start: date, end: dateTo };
    const classNameExtention = hasDateTo ? 'range' : 'single';

    return (
      <div className={`dmp-daytime-container-${classNameExtention}`} data-cy="dmp-daytime">
        {hasDate && (
          <div className={`InputFromTo ${this.className}`}>
            <DayPickerInput
              ref={this.refDateFrom}
              value={date}
              inputProps={{
                readOnly,
                disabled,
                onKeyDown: (e) => this.onKeyPressed(e, this.refDateFrom),
                onBlur: (e) =>
                  readOnly
                    ? this.handleDayChange(parseDate(moment(e.target.value)))
                    : this.handleFromChange(parseDate(moment(e.target.value))),
              }}
              placeholder="Start date"
              format="YYYY-MM-DD"
              formatDate={formatDate}
              parseDate={parseDate}
              dayPickerProps={
                hasDateTo
                  ? {
                      selectedDays: [{ from: date }, { from: date, to: dateTo }],
                      disabledDays: { after: dateTo },
                      toMonth: dateTo,

                      modifiers,
                      numberOfMonths: 1,
                      onDayClick: () => this.refDateTo.current.getInput().focus(),
                    }
                  : {
                      onDayClick: () => this.refDateFrom.current.getInput().blur(),
                    }
              }
              onDayChange={readOnly ? this.handleDayChange : this.handleFromChange}
            />
            {hasDateTo && <div className="and"> and </div>}
            {hasDateTo && (
              <span className="InputFromTo-to">
                <DayPickerInput
                  inputProps={{
                    readOnly,
                    disabled,
                    onKeyDown: (e) => this.onKeyPressed(e, this.refDateTo),
                    onBlur: (e) => {
                      const date = parseDate(moment(e.target.value));
                      date && this.handleToChange(date);
                    },
                  }}
                  ref={this.refDateTo}
                  value={dateTo}
                  placeholder="End date"
                  format="YYYY-MM-DD"
                  formatDate={formatDate}
                  parseDate={parseDate}
                  dayPickerProps={{
                    selectedDays: [{ from: date }, { from: date, to: dateTo }],
                    disabledDays: { before: date },
                    modifiers,
                    month: date,
                    fromMonth: date,
                    numberOfMonths: 1,
                  }}
                  onDayChange={this.handleToChange}
                />
              </span>
            )}
            {hasDate && hasTime && <div className="at">{atLabel}</div>}
            {hasTime && (
              <TimePicker
                disabled={disabled}
                value={timeOfDay}
                autoFocus={false}
                disableClock
                clearIcon={null}
                onChange={this.handleTimeChange}
              />
            )}
          </div>
        )}
      </div>
    );
  }
}
