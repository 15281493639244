import { assign, find, pick } from 'lodash';

import Core from '../Core.js';

export const NOTIFICATION_TYPES = {
  DASHBOARD: 'dashboard',
};

export const FREQUENCIES = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
};

export default class Notification {
  type = null;
  frequency = FREQUENCIES.WEEKLY;
  day = 1; // Monday (UTC starts at Sunday)
  time = 8; // 8AM
  active = true; // turn on/off

  userID = null;

  constructor(userID, type, json = {}) {
    if (!userID) {
      throw new Error('Notifications require a userID.');
    }

    if (!find(NOTIFICATION_TYPES, (typeValue) => typeValue === type)) {
      throw new Error('Invalid Notification type.');
    }

    this.type = type;
    this.userID = userID;

    assign(this, pick(json, ['frequency', 'day', 'time', 'active', 'lastSent']));
  }

  get json() {
    const json = pick(this, ['type', 'frequency', 'day', 'time', 'active', 'lastSent']);

    return json;
  }

  save(options = {}) {
    assign(this, pick(options, ['frequency', 'day', 'time', 'active', 'lastSent']));

    return Core.Fire.saveUserNotification(this.userID, this);
  }

  activate() {
    this.active = true;
    return this.save();
  }

  deactivate() {
    this.active = false;
    return this.save();
  }
}
