import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

import { COLOR_ICONS, ICONS } from './shared/icons';

const cl = classNamePrefixer('i');

const Icon = ({ className, dark, faded, name, size, noFill, color, ...props }) => {
  // Make sure that our icon is defined,
  if (!ICONS[name]) throw new Error(`<Icon name="${name}" /> does not exist.`);

  const IconComponent = ICONS[name];

  const isColor = !!COLOR_ICONS[name];

  const classNames = cx(
    cl(),
    cl(size),
    { [cl('dark')]: dark },
    { [cl('faded')]: faded },
    { [cl('color')]: isColor },
    { [cl(`color-${name}`)]: isColor },
    { [cl(`color-${color}`)]: color },
    { 'no-fill': noFill },
    className
  );

  return <IconComponent className={classNames} {...props} />;
};

Icon.defaultProps = {
  color: null,
  dark: false,
  faded: false,
  noFill: false,
  size: 'default',
};

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'green',
    'dark-green',
    'blue',
    'dark-blue',
    'red',
    'dark-red',
    'yellow',
    'dark-yellow',
    'purple',
    'gray',
  ]),
  dark: PropTypes.bool,
  faded: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noFill: PropTypes.bool,
  size: PropTypes.oneOf([
    'small', // 10x10
    'default', // 15x15
    'large', // 20x20
    'xlarge', // 40x40
  ]),
};

export default Icon;
