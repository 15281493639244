import { assign, find, pick } from 'lodash';

import DealAction from '../enums/DealAction';
import { DateFormatter } from '../utils/DateTime';
import Deal, { DEAL_TYPE } from './Deal';
import DealRecord from './DealRecord';

export default class DealOrigin {
  _creator = null;
  _created = null;
  source = null;
  templateTitle;
  templateURL;

  constructor(data) {
    //when data is from a Deal the format is from Activity.js
    if (data instanceof Deal) {
      const createAction = find(data.activity, { action: DealAction.CREATE });
      if (createAction) {
        this._created = createAction.date;
        const creator = find(data.users, { uid: createAction.user });
        if (creator) this._creator = creator;
      }
    }
    //for DealRecord instance w/ activity. Needed because the activity is formatted differently.
    if (data instanceof DealRecord) {
      const createAction = find(data.activity, { type: DealAction.CREATE });
      if (createAction) {
        this._created = new Date(parseInt(createAction.created));
        const creator = find(data.users, { uid: createAction.user });
        if (creator) this._creator = creator;
      }
    }
    //set source and reuse switch statement
    if (data instanceof DealRecord || data instanceof Deal) {
      switch (data.dealType) {
        case DEAL_TYPE.EXTERNAL:
          this.source = 'PDF uploaded to Outlaw';
          break;
        case DEAL_TYPE.BESPOKE:
          this.source = 'Drafted on Outlaw';
          break;
        case DEAL_TYPE.INGESTED:
          this.source = 'DOCX imported to Outlaw';
          break;
        case DEAL_TYPE.NATIVE:
          this.source = 'Generated from Outlaw Template';
          break;
      }
    } else {
      assign(this, data);
    }
  }

  get creator() {
    if (this._creator) return this._creator.fullName || this._creator.email;
    else return null;
  }
  set creator(data) {
    this._creator = data;
  }

  get created() {
    if (this._created) return DateFormatter.mdy(this._created);
    else return null;
  }
  set created(data) {
    this._created = data;
  }

  get json() {
    return pick(this, ['creator', 'created', 'source']);
  }
}
