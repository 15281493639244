import { cloneDeep, filter, forEach, map, merge, uniq } from 'lodash';

import SectionType from '../enums/SectionType';
import BorderStyle from './BorderStyle';
import DealNumberFormat from './DealNumberFormat';
import LayoutStyle from './LayoutStyle';
import TypeStyle from './TypeStyle';

export const ENDPOINT = {
  WEB: 'web',
  DOCX: 'docx',
  PDF: 'pdf',
};

export const baseOn = (style, docxName = null, custom = null) => {
  let newStyle = cloneDeep(style);

  if (custom) newStyle = merge(newStyle, custom);

  if (docxName)
    newStyle = merge(newStyle, {
      docx: {
        id: docxName,
        name: docxName,
      },
    });

  return newStyle;
};

export const REQUIRED_TYPE = [
  {
    key: 'SectionBody',
    title: 'Paragraph Text',
    description: 'Style of body section text on Contract tab',
    sectionType: SectionType.SOURCE,
    isTitle: false,
  },
  {
    key: 'SectionTitle',
    title: 'Paragraph Title',
    description: 'Style of body section title on Contract tab',
    sectionType: SectionType.SOURCE,
    isTitle: true,
  },
  {
    key: 'SectionNumber',
    title: 'Paragraph Numbering',
    description: 'Style of body section numbering on Contract tab',
  },
  {
    key: 'Letterhead',
    title: 'Letterhead Text',
    description: 'Style of Letterhead section text',
  },
  {
    key: 'Title',
    title: 'Contract Title',
    description: 'Style of Contract title on Contract tab',
  },
  {
    key: 'H1',
    title: 'Heading 1',
    description: 'Style of Heading 1 on Contract tab',
    sectionType: SectionType.HEADER,
    isTitle: true,
  },
  {
    key: 'H2',
    title: 'Heading 2',
    description: 'Style of Heading 2 on Contract tab',
    sectionType: SectionType.HEADER,
    isTitle: true,
  },
  {
    key: 'H3',
    title: 'Heading 3',
    description: 'Style of Heading 3 on Contract tab',
    sectionType: SectionType.HEADER,
    isTitle: true,
  },
  {
    key: 'H4',
    title: 'Heading 4',
    description: 'Style of Heading 4 on Contract tab',
    sectionType: SectionType.HEADER,
    isTitle: true,
  },
  {
    key: 'Footer',
    title: 'Footer Text',
    description: 'Style of Footer text',
    sectionType: SectionType.TEMPLATE_FOOTER,
  },
  {
    key: 'Header',
    title: 'Header Text',
    description: 'Style of Header text',
    sectionType: SectionType.TEMPLATE_HEADER,
  },
  {
    key: 'SignatureTitle',
    title: 'Signature Title',
    description: 'Style of Signature section title on Contract tab',
    sectionType: SectionType.SIGNATURE,
    isTitle: true,
  },
  {
    key: 'SignatureSubtitle',
    title: 'Signature Body',
    description: 'Style of Signature section body on Contract tab',
    sectionType: SectionType.SIGNATURE,
    isTitle: false,
  },
  {
    key: 'AppendixTitle',
    title: 'Appendix Title',
    description: 'Style of Appendix title on Contract tab',
    sectionType: SectionType.APPENDIX,
    isTitle: true,
  },
  {
    key: 'AppendixSubtitle',
    title: 'Appendix Body',
    description: 'Style of Appendix body on Contract tab',
    sectionType: SectionType.APPENDIX,
    isTitle: false,
  },
  {
    key: 'TableHeader',
    title: 'Table Header',
    description: 'Style of header cells in tables on Contract tab',
  },
  {
    key: 'TableBody',
    title: 'Table Body',
    description: 'Style of regular cells in tables on Contract tab',
  },
  {
    key: 'OverviewTitle',
    title: 'Overview Page Title',
    description: 'Style of page title on Overview tab',
    sectionType: SectionType.SUMMARY,
    isTitle: true,
    isNumber: false,
  },
  {
    key: 'OverviewSubtitle',
    title: 'Overview Page Subtitle',
    description: 'Style of page subtitle on Overview tab',
    sectionType: SectionType.SUMMARY,
    isTitle: false,
  },
  {
    key: 'OverviewBody',
    title: 'Overview Body',
    description: 'Style of body sections on Overview tab',
    sectionType: SectionType.SUMMARY,
    isTitle: false,
  },
  {
    key: 'OverviewNumber',
    title: 'Overview Numbering',
    description: 'Numbering style of body sections on Overview tab',
  },
  {
    key: 'LineNumbering',
    title: 'Line Numbering',
    description: 'Numbering style of lines',
  },
  {
    key: 'FootnoteBody',
    title: 'Footnote Body',
    description: 'Footnote body style.',
  },
];

export const REQUIRED_LAYOUT = ['Section', 'Column', 'Letterhead', 'SignatureBlock', 'Indent'];

export const BASE_NUMBERING = [
  { type: 'number', post: '.' }, // 1. 2. 3.
  { type: 'alpha', pre: '(', post: ')', case: 'lower' }, // -- (a) (b) (c)
  { type: 'roman', pre: '(', post: ')', case: 'lower' }, // -- -- (i) (ii) (iii)
  { type: 'number', post: ')' }, // -- -- -- 1) 2) 3)
];

export const BASE_LAYOUT = {
  Section: {
    bottom: 16,
  },
  Column: {
    right: 16,
  },
  Letterhead: {
    bottom: 48,
  },
  SignatureBlock: {
    top: 48,
    bottom: 48,
  },
  Indent: {
    left: 32,
  },
  PageMargin: {
    left: 96,
    right: 96,
    top: 96,
    bottom: 96,
  },
};

export const DEFAULT_LINE_NUMBERING = {
  native: {
    font: 'Georgia',
    color: '#000000',
    size: 9.8,
    lineSpacing: 1.3,
    alignment: 'right',
    bold: false,
    underline: false,
    italic: false,
    numberScheme: 'off', // will be "off", "paged", or "continuous"
  },
};

export const FOOTNOTE_FONT_RATIO = 0.6;

export const DEFAULT_FOOTNOTE_BODY = {
  native: {
    font: 'Georgia',
    color: '#000000',
    size: 9.8,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
    numberScheme: 'continuous', // will be "paged", or "continuous"
  },
};

export const DEFAULT_FOOTNOTE_HEADER = {
  native: {
    font: 'Georgia',
    color: '#000000',
    size: 9.8,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
    numberScheme: 'off', // will be "paged", or "continuous"
  },
};

export const DEFAULT_FOOTNOTE_CONFIG = {
  numbering: { key: 'continuous', title: 'Continuous' }, 
  numberFormat: { label: '1, 2, 3, ...', pre: null, post: null, type: 'number' },
  seperator: true,
}

export const defaultNumberFormat = () => new DealNumberFormat(BASE_NUMBERING[0]);
export const unnumberedFormat = () => new DealNumberFormat({ type: 'none' });

export default class DealStyle {
  // DealStyle is meant as an abstract base class for themes, but every theme should define its own themeKey
  themeKey = null;

  // After much research, we discovered that CSS line-height is NOT the same thing as Word line-spacing (aka "leading")
  // The *exact* conversion differs by font and potentially by font-size,
  // But as a default, a multiplier of .87 gets VERY close to exact (can still be overridden in the theme or in a custom style)
  // For example:
  // -  This correctly converts our Classic theme's 12pt TNR at "normal" CSS line-height (which is 1.15) to single spacing in Word (1)
  // -  This correctly converts our Modern theme's 12pt Arial at 1.5 line-height to 1.3 spacing in Word
  // See StyleFactory.create for conversion logic; overriding this value in a theme and setting it to 0 or null will disable conversion
  spacingRatio = 0.87;

  constructor(
    { layout = cloneDeep(BASE_LAYOUT), numbering = cloneDeep(BASE_NUMBERING), type = {}, border = {} },
    themeKey
  ) {
    this.type = {};
    forEach(type, (ts, key) => {
      this.type[key] = new TypeStyle(ts, key);
    });
    //this is for all legacy themes with linenumbering that has not been applied yet.
    if (!this.type.LineNumbering) {
      this.type.LineNumbering = new TypeStyle(DEFAULT_LINE_NUMBERING, 'LineNumbering');
    }

    if (!this.type.FootnoteBody) {
      this.type.FootnoteBody = new TypeStyle(DEFAULT_FOOTNOTE_BODY, 'FootnoteBody');
    }

    if (!this.type.Header) {
      this.type.Header = new TypeStyle(DEFAULT_FOOTNOTE_HEADER, 'Header');
    }

    this.numbering = map(
      numbering,
      (level, idx) => new DealNumberFormat(level || BASE_NUMBERING[idx] || BASE_NUMBERING[0])
    );

    this.layout = {};
    forEach(layout, (ls, key) => {
      this.layout[key] = new LayoutStyle(ls);
    });

    this.border = {};
    forEach(border, (value, key) => {
      this.border[key] = new BorderStyle(value);
    });

    //this is for all legacy layout properties that have not been applied yet.
    forEach(BASE_LAYOUT, (value, key) => {
      if (!this.layout[key]) {
        this.layout[key] = new LayoutStyle(value);
      }
    });

    this.themeKey = themeKey;

    // For conversion of "advanced" Default/Classic styles into "native"
    // const logTS = {};
    // forEach(type, (ts, key) => {
    //   logTS[key] = {native: generateNative(ts)};
    //   console.log(key, ts, logTS[key]);
    // });
  }

  validate() {
    const errors = [];

    const missingType = filter(REQUIRED_TYPE, (ts) => {
      return !this.type[ts.key];
    });

    if (missingType.length > 0) {
      errors.push(
        `Theme [${this.themeKey}] is missing the following type definitions: [${map(missingType, 'key').join(', ')}]`
      );
    }

    const missingLayout = filter(REQUIRED_LAYOUT, (key) => !this.layout[key]);
    if (missingLayout.length > 0) {
      errors.push(
        `Theme [${this.themeKey}] is missing the following layout definitions: [${missingLayout.join(', ')}]`
      );
    }

    return errors;
  }

  get json() {
    const layout = {};
    const type = {};
    forEach(this.layout, (ls, key) => {
      layout[key] = ls.json;
    });
    forEach(this.type, (ts, key) => {
      type[key] = ts.json;
    });
    return {
      type,
      layout,
      numbering: map(this.numbering, (level) => level.json),
    };
  }

  get fonts() {
    const fonts = uniq(map(this.type, 'selectedFont'));
    return fonts;
  }
}
