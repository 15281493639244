import { find, forEach, get, map } from 'lodash';

import SERVICES from '../enums/IntegrationServices';
import FieldMapping from './FieldMapping';

export const TRIGGER_ORIGIN = {
  OUTLAW: 'outlaw',
  SERVICE: 'service',
};

export default class Automation {
  key = null;
  service = null;

  origin = TRIGGER_ORIGIN.OUTLAW;
  trigger = '';
  action = '';
  enabled = false;
  fieldMap = [];
  objectType = null;

  constructor(json, service) {
    this.service = service;

    if (!this.service && json.service) this.key = json.service;
    if (json.key) this.key = json.key;
    if (json.origin) this.origin = json.origin;
    if (json.trigger) this.trigger = json.trigger;
    if (json.action) this.action = json.action;
    if (json.enabled) this.enabled = json.enabled;
    if (json.objectType) this.objectType = json.objectType;
    if (json.fieldMap) {
      forEach(json.fieldMap, (mapping) => {
        this.fieldMap.push(new FieldMapping(mapping));
      });
    }
  }

  get triggerService() {
    const serviceKey = this.origin === TRIGGER_ORIGIN.OUTLAW ? TRIGGER_ORIGIN.OUTLAW : this.service;
    return find(SERVICES, { key: serviceKey });
  }

  get triggerName() {
    const triggerService = this.triggerService;
    const triggers = get(triggerService, 'triggers', []);
    const trigger = find(triggers, { key: this.trigger });
    return get(trigger, 'name', this.trigger);
  }

  get actionService() {
    const serviceKey = this.origin === TRIGGER_ORIGIN.OUTLAW ? this.service : TRIGGER_ORIGIN.OUTLAW;
    return find(SERVICES, { key: serviceKey });
  }

  get actionName() {
    const actionService = this.actionService;
    const actions = get(actionService, 'actions', []);
    const action = find(actions, { key: this.action });
    return get(action, 'name', this.action);
  }

  get triggerServiceName() {
    return get(this.triggerService, 'name', this.trigger);
  }

  get actionServiceName() {
    return get(this.actionService, 'name', this.action);
  }

  get json() {
    return {
      action: this.action,
      enabled: this.enabled || null,
      fieldMap: map(this.fieldMap, 'json'),
      key: this.key,
      origin: this.origin,
      service: this.service,
      trigger: this.trigger,
      objectType: this.objectType,
    };
  }
}
