import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { SCORING_OPERATORS } from '@core/models/Operator';
import Team from '@core/models/Team';
import { getUniqueKey } from '@core/utils/Generators';

import { Button, ButtonIcon, Dropdown, MenuItem } from '@components/dmp';

import Fire from '@root/Fire';

@autoBindMethods
export default class ScoringEditor extends Component {
  static propTypes = {
    team: PropTypes.instanceOf(Team).isRequired,
    show: PropTypes.bool.isRequired,
    matrix: PropTypes.object,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      displayName: '',
      description: '',
      matrix: [],
      id: '',
      formSubmitted: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { matrix, show } = this.props;

    if (!_.isEqual(matrix, prevProps.matrix) || (!prevProps.show && show)) {
      this.populate(matrix);
    }

    // Reset formSubmitted when modal is hidden
    if (prevProps.show && !show) {
      this.setState({ formSubmitted: false });
    }
  }

  get isNew() {
    return !this.props.matrix;
  }

  populate(matrix) {
    const matrixClone = JSON.parse(JSON.stringify(matrix));

    this.setState({
      displayName: _.get(matrixClone, 'displayName', ''),
      description: _.get(matrixClone, 'description', ''),
      matrix: _.get(matrixClone, 'matrix', []),
      id: _.get(matrixClone, 'id', `score-${getUniqueKey()}`),
    });
  }

  handleEntryChange(index, prop, key) {
    const { matrix } = this.state;

    switch (key) {
      case 'operator':
        matrix[index].range.operator = prop.key;
        break;
      case 'value1':
        matrix[index].range.values[0] = prop;
        break;
      case 'value2':
        matrix[index].range.values[1] = prop;
        break;
      case 'grade':
        matrix[index].grade = prop;
        break;
      case 'description':
        matrix[index].description = prop;
        break;
      case 'delete':
        delete matrix[index];
        break;
      default:
        break;
    }

    this.setState({ matrix });
  }

  addGrade() {
    const { matrix } = this.state;

    matrix.push({
      description: '',
      grade: '',
      range: {
        operator: '',
        values: {},
      },
    });

    this.setState({ matrix });
  }

  async save() {
    const { team, onSave } = this.props;
    const { matrix, displayName, description, id } = this.state;
    const { teamID } = team;

    // Check if all required fields are filled
    const allFieldsFilled = matrix.every((entry) => {
      const { range, grade } = entry;
      const { values, operator } = range;
      const currentOperator = _.find(SCORING_OPERATORS, (o) => o.key === operator);
      const valueCount = _.get(currentOperator, 'valueCount');

      return values[0] && (valueCount !== 2 || values[1]) && operator && grade;
    });

    if (allFieldsFilled) {
      await Fire.saveScoringMatrix(teamID, { matrix, displayName, description, id });
      this.handleHide();
      onSave();
    } else {
      // Set formSubmitted to true to show validation errors
      this.setState({ formSubmitted: true });
      return;
    }
  }

  handleHide() {
    this.setState({ formSubmitted: false });
    this.props.onHide();
  }

  renderScoringEntries(entry, index) {
    const { formSubmitted } = this.state;
    if (!entry) return;
    const { range, grade, description } = entry;
    const { values, operator } = range;

    const currentOperator = _.find(SCORING_OPERATORS, (o) => {
      return o.key === operator;
    });
    const valueCount = _.get(currentOperator, 'valueCount');

    return (
      <div className="entry" key={index} data-cy="scoring-range-entry">
        <div className="scoring-filter">
          <ControlLabel>Score Range</ControlLabel>
          <FormGroup>
            <Dropdown
              id={`dd-scoring-operators`}
              title={_.get(currentOperator, 'title', 'Select one')}
              size="small"
              onSelect={(operator) => this.handleEntryChange(index, operator, 'operator')}
              block
              dataCyToggle="dd-scoring-operators"
              showErrorState={formSubmitted && !currentOperator}
            >
              {_.map(SCORING_OPERATORS, (operator) => (
                <MenuItem key={operator.name} eventKey={operator} data-cy="operator-option">
                  {operator.title}
                </MenuItem>
              ))}
            </Dropdown>
          </FormGroup>

          <div className={cx('values', { pair: valueCount === 2 })}>
            <FormGroup validationState={formSubmitted && !values[0] ? 'error' : null}>
              <FormControl
                type="text"
                bsSize="small"
                value={values[0]}
                onChange={(e) => this.handleEntryChange(index, e.target.value, 'value1')}
                data-cy="score-value1"
                placeholder="Enter an integer (required)"
              />
            </FormGroup>
            {valueCount === 2 && <div className="and">and</div>}
            {valueCount === 2 && (
              <FormGroup validationState={formSubmitted && !values[1] ? 'error' : null}>
                <FormControl
                  type="text"
                  bsSize="small"
                  value={values[1]}
                  onChange={(e) => this.handleEntryChange(index, e.target.value, 'value2')}
                  data-cy="score-value2"
                  placeholder="Enter an integer (required)"
                />
              </FormGroup>
            )}
          </div>
        </div>
        <div className="grade">
          <ControlLabel>Grade</ControlLabel>
          <FormGroup validationState={formSubmitted && !grade ? 'error' : null}>
            <FormControl
              type="text"
              value={grade}
              placeholder="e.g. A+ (required)"
              onChange={(e) => this.handleEntryChange(index, e.target.value, 'grade')}
              bsSize="small"
              className="grade-input"
              data-cy="grade-input"
            />
          </FormGroup>
          <div className="description">
            <FormControl
              type="text"
              value={description}
              placeholder="Description"
              onChange={(e) => this.handleEntryChange(index, e.target.value, 'description')}
              bsSize="small"
              data-cy="score-description"
            />
          </div>
        </div>
        <ButtonIcon
          icon="trash"
          size="default"
          className="delete-entry"
          onClick={() => this.handleEntryChange(index, null, 'delete')}
          data-cy="btn-delete-entry"
        />
      </div>
    );
  }

  render() {
    const { onHide, show } = this.props;
    const { displayName, description, matrix } = this.state;

    return (
      <Modal dialogClassName="scoring-editor" show={show} onHide={this.handleHide} data-cy="scoring-editor">
        <Modal.Header closeButton>
          <span className="headline">{this.isNew ? 'New scoring matrix' : 'Update matrix'}</span>
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Name</ControlLabel>
            <div className="contents">
              <FormControl
                type="text"
                inputRef={(ref) => (this.refName = ref)}
                value={displayName}
                placeholder="Enter scoring matrix name"
                onChange={(e) => this.setState({ displayName: e.target.value })}
                data-cy="matrix-name"
              />
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Description</ControlLabel>
            <div className="contents">
              <FormControl
                type="text"
                value={description}
                placeholder="Optional"
                onChange={(e) => this.setState({ description: e.target.value })}
                data-cy="scoring-description"
              />
            </div>
          </FormGroup>

          <div className="divider" />

          <FormGroup>
            <ControlLabel>Scoring Details</ControlLabel>
            <div className="contents scoring-details">
              {_.map(matrix, (entry, index) => {
                return this.renderScoringEntries(entry, index);
              })}
              <div className="add-grade">
                <Button
                  dmpStyle="link"
                  icon="plus2"
                  bsSize="small"
                  noPadding
                  onClick={() => this.addGrade()}
                  data-cy="btn-add-scoring-range"
                >
                  Add scoring range
                </Button>
              </div>
            </div>
          </FormGroup>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.handleHide} data-cy="btn-cancel-matrix">
            Cancel
          </Button>
          <Button dmpStyle="primary" onClick={this.save} data-cy="btn-save-matrix" disabled={!displayName}>
            {this.isNew ? 'Save' : 'Update'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
