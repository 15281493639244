import React, { Component, createRef } from 'react';

import { arrayMoveImmutable } from 'array-move';
import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import { CALC_ERRORS } from '@core/models/CalculationError';
import DataSource from '@core/models/DataSource';
import TableColumn from '@core/models/TableColumn';
import Variable from '@core/models/Variable';
import { VariableType } from '@core/models/Variable';

import { Alert, Button, Checkbox, DragHandle, Ellipsis, Loader, Tag } from '@components/dmp';

import TableColumnEditor from '@components/editor/TableColumnEditor';
import Fire from '@root/Fire';
import VariableIndex from '@root/utils/VariableIndex';

const SortableItem = sortableElement(({ column, onRemove, onEdit }) => (
  <li className="sortable">
    <Tag block color={!column.isValid ? 'error' : 'gray'} onRemove={onRemove} className="active-field">
      <DragHandle />
      <Ellipsis onClick={onEdit}>{`{${column.id?.replace('_null', '')}}`}</Ellipsis>
    </Tag>
  </li>
));

const SortableContainer = sortableContainer(({ children }) => <ul>{children}</ul>);

// This component can currently be used to configure both the *columns* on a table Variable,
// And the *fields* on a repeater's DataSource
// The .columns array is identical data structure,
// the table columns are attached to a Variable model in the Deal.variables store,
// whereas fields are attached to a Repeater, which is in the List.dataSource store
@autoBindMethods
export default class DataSourceManager extends Component {
  static defaultProps = {
    limit: 6,
  };

  static propTypes = {
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    limit: PropTypes.number,
    dataSource: PropTypes.oneOfType([PropTypes.instanceOf(DataSource), PropTypes.instanceOf(Variable)]),
    variableIndex: PropTypes.instanceOf(VariableIndex).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedColumns: [],
      saving: false,
      editingColumn: null,
      errors: [],
    };

    this.refCols = {};
    this.refModal = createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { show, dataSource, variableIndex } = this.props;
    if ((show && !prevProps.show) || !_.isEqual(variableIndex, prevProps.variableIndex)) {
      this.populate(dataSource);
    }

    if (!_.isEqual(prevState.editingColumn, this.state.editingColumn)) {
      this.validateColumns(this.state.selectedColumns);
    }
  }

  validateColumns(selectedColumns) {
    const errors = [];

    selectedColumns.forEach((column) => {
      try {
        column.formatValue();
      } catch (error) {
        if (error.code !== CALC_ERRORS.DATA.code) errors.push(error);
      }
    });

    this.setState({ errors });
  }

  populate(dataSource) {
    // Each time we populate, make a copy of dataSource columns for state
    // This way if updates are cancelled and the modal is then reopened, it properly resets
    const selectedColumns = _.map(dataSource.columns, ({ json }) => new TableColumn(json, dataSource));

    this.setState({
      selectedColumns,
      saving: false,
    });
    _.forEach(dataSource.columns, (col) => {
      if (!this.refCols[col.id]) this.refCols[col.id] = createRef();
    });

    this.validateColumns(selectedColumns);
  }

  onColumnsChange(columns = []) {
    this.setState({ selectedColumns: columns });
  }

  handleColumnToggle(col, add) {
    const { selectedColumns } = this.state;
    const colIdx = _.findIndex(selectedColumns, { id: col.id });

    if (add) {
      if (colIdx === -1) {
        selectedColumns.push(col);
        if (!this.refCols[col.id]) this.refCols[col.id] = createRef();
        this.setState({ selectedColumns });
      }
    } else {
      if (colIdx > -1) {
        selectedColumns.splice(colIdx, 1);
        this.setState({ selectedColumns });
      }
    }
  }

  editColumn(editingColumn) {
    this.setState({ editingColumn });
  }

  onSortEnd({ oldIndex, newIndex }) {
    let { selectedColumns } = this.state;
    selectedColumns = arrayMoveImmutable(selectedColumns, oldIndex, newIndex);
    this.setState({ selectedColumns });
  }

  async save() {
    const { close, dataSource } = this.props;
    const { selectedColumns } = this.state;

    let json = _.map(selectedColumns, 'json');
    if (!json.length) json = null;

    await this.setState({ saving: true });

    if (dataSource instanceof DataSource) {
      dataSource.columns = selectedColumns;
      await Fire.saveSection(dataSource.section, { dataSource: dataSource.json });
    } else {
      await Fire.saveVariableDefinition(dataSource.deal, {
        name: dataSource.name,
        type: dataSource.type,
        columns: json,
      });
    }

    this.setState({ saving: false });

    close();
  }

  get availableColumns() {
    const { dataSource, variableIndex } = this.props;

    const options = variableIndex.flattenPropertyOptions(dataSource.externalSelector);
    return _.map(options, (field) => TableColumn.fromConnectVariable(field, dataSource));
  }

  renderAvailableColumn(column, idx) {
    const { selectedColumns } = this.state;
    const checked = !!_.find(selectedColumns, { id: column.id });

    return (
      <li key={idx}>
        <Tag block>
          <Checkbox
            block
            bold
            checked={checked}
            name={column.name}
            id={`check-column-${column.id}`}
            onChange={() => this.handleColumnToggle(column, !checked)}
          >
            <Ellipsis>{`{${column.id?.replace('_null', '')}}`}</Ellipsis>
          </Checkbox>
        </Tag>
      </li>
    );
  }

  render() {
    const { show, close, limit, dataSource, variableIndex } = this.props;
    const { selectedColumns, saving, editingColumn, errors } = this.state;

    const varCols = !dataSource ? [] : this.availableColumns;

    return (
      <Modal
        dialogClassName="table-column-manager"
        backdrop="static"
        show={show}
        onHide={() => !saving && close()}
        data-cy="modal-table-column-manager"
      >
        <Modal.Header closeButton>
          <span className="headline" data-cy="headline">
            Configure Table Columns
          </span>
        </Modal.Header>
        <Modal.Body ref={this.refModal}>
          <div className="two-col" ref={this.refwtf}>
            {dataSource?.type !== VariableType.SIMPLE && (
              <div className="col available">
                <div className="list-title">Select a maximum of {limit} columns</div>

                <ul className="cols template-cols">{varCols.map(this.renderAvailableColumn)}</ul>
              </div>
            )}
            <div className="col current" data-cy="active-columns">
              {errors.length > 0 &&
                errors.map((e) => (
                  <Alert icon="info" dmpStyle="danger">
                    {e.message}
                    {e.value && <b>: {e.value}</b>}. Click column for details.
                  </Alert>
                ))}
              <div className="list-title d-flex justify-content-between align-items-center" data-cy="table-list-title">
                <span>Active columns</span>
              </div>

              <SortableContainer axis="y" onSortEnd={this.onSortEnd} distance={5}>
                {selectedColumns.map((column, idx) => (
                  <SortableItem
                    ref={this.refCols[column.id]}
                    key={`item-${idx}`}
                    index={idx}
                    column={column}
                    onRemove={() => this.handleColumnToggle(column, false)}
                    onEdit={() => this.editColumn(column)}
                  />
                ))}
              </SortableContainer>

              {editingColumn && (
                <TableColumnEditor
                  column={editingColumn}
                  target={this.refCols[editingColumn.id].current}
                  container={this.refModal.current}
                  hide={() => this.setState({ editingColumn: null })}
                  variableIndex={variableIndex}
                />
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {saving && <Loader />}
          <div className="spacer" />
          <Button onClick={close} disabled={saving}>
            Cancel
          </Button>
          <Button dmpStyle="primary" onClick={this.save} data-cy="btn-save" disabled={saving || errors.length > 0}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
