import Source from './Source';

export default class Header extends Source {
  // Legacy Header sections were simply Source sections with no body
  // So default to h4 which is standard body size
  headerType = 'h4';

  constructor(json, deal) {
    super(json, deal);

    if (json.headerType) this.headerType = json.headerType;
  }
}
