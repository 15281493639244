import { assign } from 'lodash';

export default class Column {
  key;

  Header;
  Cell;
  accessor;
  minWidth = 70;

  className;
  sortable = true;
  isVariable = false;

  constructor(json) {
    assign(this, json);
  }
}
