import { cloneDeep } from 'lodash';

import { BASE_LAYOUT, BASE_NUMBERING, DEFAULT_LINE_NUMBERING, DEFAULT_FOOTNOTE_BODY } from '../models/DealStyle';

////////////////////////////
// BODY STYLES
////////////////////////////
const type = {};
type.SectionBody = {
  native: {
    font: 'Georgia',
    color: '#000000',
    size: 9.8,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};

type.LineNumbering = DEFAULT_LINE_NUMBERING;
/*
{
  css: {
    fontFamily: 'Georgia',
    color: '#000000',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: 1.5,
  },
  docx: {
    id: 'SectionBody',
    name: 'SectionBody',
    basedOn: 'Normal',
    next: 'Normal',
    run: {
      font: 'Georgia',
      size: 22,
    },
    paragraph: {
      alignment: 'left',
      spacing: {
        line: 312
      },
    },
  },
  pdf: {
    fontName: 'Georgia', 
    fontSize: 11,
    color: '#000000',
    lineSpacing: 1.15,
  }
};
*/

type.SectionTitle = {
  native: {
    font: 'Georgia',
    color: '#000000',
    size: 9.8,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: true,
    underline: false,
    italic: false,
  },
};

/*baseOn(type.SectionBody, 'SectionTitle', {
  css: { fontWeight: 'bold'},
  docx: { run: { bold: true } },
  pdf: { fontName: 'Georgia Bold' }
});
*/

type.AppendixSubtitle = {
  native: {
    font: 'Georgia',
    color: '#000000',
    size: 9.8,
    lineSpacing: 1.3,
    alignment: 'center',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.SectionBody, 'AppendixSubtitle', {
  css: { textAlign: 'center' },
  docx: { paragraph: { alignment: 'center' } },
  pdf: { align: 'center' }
});
*/

type.Letterhead = cloneDeep(type.SectionBody); // baseOn(type.SectionBody, 'Letterhead');

type.Footer = cloneDeep(type.SectionBody); //baseOn(type.SectionBody, 'Footer');

type.Header = cloneDeep(type.SectionBody); //baseOn(type.SectionBody, 'Footer');

type.SignatureSubtitle = cloneDeep(type.SectionBody); // baseOn(type.SectionBody, 'SignatureSubtitle');

// NB: the 32px special case is implemented manually in TypeStyle / Word / PDF
// so we can just use an identical style to the SectionTitle
type.SectionNumber = cloneDeep(type.SectionTitle);

/*
baseOn(type.SectionTitle, 'SectionNumber', {
  css: { minWidth: '32px'},
});
*/

////////////////////////////
// HEADER STYLES
////////////////////////////

type.Title = {
  native: {
    font: 'Helvetica',
    color: '#000000',
    size: 21,
    lineSpacing: 1.3,
    alignment: 'center',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
{
  css: {
    fontFamily: 'Helvetica, Arial',
    textAlign: 'center',
    color: '#000000',
    fontWeight: 'normal',
    fontSize: '28px',
    lineHeight: 1.5,
    // Make sure to overwrite any <h#> settings
    marginTop: 0,
    marginBottom: 0,
  },
  docx: {      
    id: 'Title',
    name: 'Title',
    basedOn: 'Title',
    next: 'Normal',
    run: {
      font: 'Arial',
      color: '#000000',
      size: 42,
      bold: false
    },
    paragraph: {
      alignment: 'center',
      spacing: {
        line: 312,
      }
    },
  },
  pdf: {
    fontName: 'Helvetica', 
    fontSize: 21,
    color: '#000000',
    align: 'center',
  }
};
*/

type.H1 = cloneDeep(type.Title); //baseOn(type.Title, 'H1');

type.H2 = {
  native: {
    font: 'Helvetica',
    color: '#000000',
    size: 17,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.H1, 'H2', {
  css: {
    fontSize: '22px',
    lineHeight: 1.5,
    textAlign: 'left',
  },
  docx: {
    basedOn: 'Heading2',
    run: { size: 33, },
    paragraph: { alignment: 'left' }
  },
  pdf: {
    fontSize: 16.5,
    align: 'left'
  }
});
*/

type.H3 = {
  native: {
    font: 'Helvetica',
    color: '#000000',
    size: 17,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};
/*
baseOn(type.H2, 'H3', {
  css: {
    fontSize: '18px',
    lineHeight: 1.5,
  },
  docx: {
    basedOn: 'Heading3',
    run: { size: 27 },
  },
  pdf: {
    fontSize: 13.5,
  }
});
*/

// H4 is actually closest to SectionTitle but centered
type.H4 = {
  native: {
    font: 'Georgia',
    color: '#000000',
    size: 9.8,
    lineSpacing: 1.3,
    alignment: 'center',
    bold: true,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.H1, 'H4', {
  css: {
    fontFamily: 'Georgia',
    fontWeight: 'bold',
    fontSize: '13px',
    lineHeight: 1.5,
  },
  docx: {
    run: { 
      size: 22,
      bold: true 
    },
    paragraph: { 
      spacing: {
        line: 312
      }
    },
  },
  pdf: {
    fontName: 'Georgia Bold', 
    fontSize: 11,
  }
});
*/

type.SignatureTitle = cloneDeep(type.H4); //baseOn(type.H4, 'SignatureTitle');

type.AppendixTitle = cloneDeep(type.H4); //baseOn(type.H4, 'AppendixTitle');

////////////////////////////
// TABLE STYLES
////////////////////////////

type.TableHeader = {
  native: {
    font: 'Helvetica',
    color: '#000000',
    size: 9,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: true,
    underline: false,
    italic: false,
  },
};
/*
{
  css: {
    fontFamily: 'Helvetica, Arial',
    color: '#000000',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: 1.5,
  },
  docx: {
    id: 'TableHeader',
    name: 'TableHeader',
    basedOn: 'Normal',
    run: {
      font: 'Arial',
      size: 18,
      bold: true
    },
    paragraph: {
      spacing: {
        line: 312, 
        after: 100, 
        before: 100,
      }
    }
  },
  pdf: {
    fontName: 'Helvetica-Bold',
    fontSize: 9,
    color: '#000000',
  }
};
*/

type.TableBody = {
  native: {
    font: 'Helvetica',
    color: '#2B3542',
    size: 9,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};
/*
baseOn(type.TableHeader, 'TableBody', {
  css: {
    color: '#2B3542',
    fontWeight: 'normal',
  },
  docx: {
    run: {
      color: '#2B3542',
      bold: false,
    },
  },
  pdf: {
    fontName: 'Helvetica',
    color: '#2B3542',
  }
});
*/
////////////////////////////
// Overview styles are currently only used on web (Draft/Flow), but still differ by theme
////////////////////////////

type.OverviewTitle = {
  native: {
    font: 'Helvetica',
    color: '#000000',
    size: 20,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
{
  css: {
    fontFamily: 'Helvetica, Arial',
    color: '#000000',
    fontWeight: 'normal',
    fontSize: '27px',
    lineHeight: 1.5,
  },
  // Not currently rendered to docx/pdf
  docx: {},
  pdf: {}
};
*/

type.OverviewSubtitle = {
  native: {
    font: 'Helvetica',
    color: '#999999',
    size: 12,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.OverviewTitle, null, {
  css: {
    color: 'rgba(153, 153, 153, 1.0)',
    fontSize: '16px',
    lineHeight: 1.5,
  }
});
*/

type.OverviewBody = {
  native: {
    font: 'Helvetica',
    color: '#000000',
    size: 12,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: false,
    underline: false,
    italic: false,
  },
};

/*
baseOn(type.OverviewSubtitle, null, {
  css: { 
    color: '#000000',
  },
});
*/

type.OverviewNumber = {
  native: {
    font: 'Helvetica',
    color: '#000000',
    size: 12,
    lineSpacing: 1.3,
    alignment: 'left',
    bold: true,
    underline: false,
    italic: false,
  },
};

type.FootnoteBody = DEFAULT_FOOTNOTE_BODY;

/*
baseOn(type.OverviewBody, null, {
  css: {  
    fontWeight: 'bold',
    minWidth: '32px',
  }
});
*/

export default {
  type,
  layout: cloneDeep(BASE_LAYOUT),
  numbering: cloneDeep(BASE_NUMBERING),
};
