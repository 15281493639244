import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

import { Card } from '@components/dmp';

const cl = classNamePrefixer('card-list');

const CardListItem = ({ children, className, ...passingProps }) => {
  const classNames = cx(cl('item'), className);

  return (
    <div className={classNames} {...passingProps}>
      {children}
    </div>
  );
};

CardListItem.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const CardList = ({ children, className, ...passingProps }) => {
  const classNames = cx(cl(), className);

  return (
    <Card className={classNames} {...passingProps} noPadding>
      {children}
    </Card>
  );
};

CardList.defaultProps = {};

CardList.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(CardListItem),
};

CardList.Item = CardListItem;

export default CardList;
