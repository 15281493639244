import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { Icon, Loader } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

@autoBindMethods
export default class AIStatusIcon extends Component {
  static propTypes = {
    dealID: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      status: null,
      access: true,
    };

    this.timeoutTip;
  }

  async getAIExtractionStatus() {
    const { status, access } = this.state;
    const { dealID } = this.props;

    if (status === 'AI processing complete' || access === false) return;

    this.setState({ loading: true });
    try {
      const deal = await Fire.getDeal(dealID);
      this.setState({ loading: false, status: deal.isProcessingAI ? 'AI processing...' : 'AI processing complete' });
    } catch (err) {
      //When the user is an observer not on the deal we cannot fetch the deal unless we add the user. So we will just show the AI icon.
      this.setState({ loading: false, access: false });
    }
  }

  onMouseEnter = () => {
    this.timeoutTip = setTimeout(async () => {
      await this.getAIExtractionStatus();
    }, 500);
  };

  render() {
    const { loading, status, access } = this.state;

    const content = (
      <span onMouseEnter={this.onMouseEnter} onMouseLeave={() => clearTimeout(this.timeoutTip)}>
        {loading && access ? <Loader size="small" /> : <Icon name="aiAuto" />}
      </span>
    );

    return (
      <div className="ai-status-icon">
        {access && status ? <TooltipButton tip={status}>{content}</TooltipButton> : content}
      </div>
    );
  }
}
