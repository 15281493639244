import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import SectionType from '@core/enums/SectionType';
import Section from '@core/models/Section';
import VariableFilter from '@core/models/VariableFilter';

import { ButtonIcon, Ellipsis } from '@components/dmp';
import Loader from '@components/dmp/Loader';

import TemplateLink from '@components/editor/TemplateLink';

import ConditionsView from './ConditionsView';

const OPTION_TYPE = {
  TITLES: 'titles',
  SECTIONS: 'sections',
};

const MIN_SEARCH = 3;
const SEARCH_DEBOUNCE = 500;

@autoBindMethods
export default class SectionSuggest extends Component {
  static propTypes = {
    teamID: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    onResults: PropTypes.func,
    onSelect: PropTypes.func,
    contextual: PropTypes.bool,
    minFilter: PropTypes.number,
    section: PropTypes.instanceOf(Section),
    addNewClause: PropTypes.func,
  };

  static defaultProps = {
    minFilter: 2,
    contextual: false,
    onResults: _.noop,
    onSelect: _.noop,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cl: {},
      selectedKey: null,
      activeIndex: -1,
    };

    this.getSuggestions = _.debounce((search) => {
      const { teamID, section } = this.props;

      if (!this._isMounted) return;

      //only run search if there are at least 3 characters,
      //OR if there are existing results (i.e., user is hitting backspace to broaden search)
      if (search.length < MIN_SEARCH) return this.reset();

      API.call('searchCL', { query: search, team: teamID, type: section.sectiontype }, this.generateOptions);
    }, SEARCH_DEBOUNCE);
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input !== this.props.input) {
      this.setState({ loading: true });
      this.getSuggestions(this.props.input);
    }
  }

  reset() {
    this.setState({
      loading: false,
      cl: {},
      selectedKey: null,
      activeIndex: -1,
    });
    this.props.onResults({});
  }

  generateOptions({ hits }) {
    const { section, onResults } = this.props;
    const cl = {};

    if (!this._isMounted || !section) return;

    _.forEach(hits, (hit) => {
      let key;
      if ([SectionType.TEMPLATE_HEADER, SectionType.TEMPLATE_FOOTER].includes(section.sectiontype)) {
        key = hit.titleCL ? hit.titleCL.toLowerCase() : 'untitled';
      } else {
        key = hit.title ? hit.title.toLowerCase() : 'untitled';
      }

      if (!cl[key]) cl[key] = [];
      // Disable clauses that are already in the current template
      if (hit.dealID === section.deal.dealID) hit.disabled = true;
      cl[key].push(hit);
    });

    // Sort disabled options (in current template) to end
    _.forEach(cl, (options, key) => {
      cl[key] = _.sortBy(options, 'disabled').reverse();

      // Special case... if ALL results for a given clause name are in THIS template,
      // They would all be disabled, so don't even show it
      if (cl[key].length === _.filter(cl[key], 'disabled').length) {
        delete cl[key];
      }
    });

    this.setState({
      loading: false,
      cl,
      activeIndex: _.keys(cl).length > 0 ? 0 : -1,
    });

    onResults(cl);
  }

  get optionCount() {
    const { selectedKey, cl } = this.state;

    if (selectedKey && cl[selectedKey]) {
      // Only count enabled options
      return _.filter(cl[selectedKey], (opt) => !opt.disabled).length;
    } else {
      return _.keys(cl).length;
    }
  }

  get noResults() {
    const { loading, cl } = this.state;
    const { input } = this.props;
    return input.length >= MIN_SEARCH && !loading && !_.keys(cl).length;
  }

  get loading() {
    return this.state.loading;
  }

  get optionMode() {
    const { selectedKey, cl } = this.state;

    if (selectedKey && cl[selectedKey]) return 'section-select';
    else if (!selectedKey) return 'clause-select';
    else return 'empty';
  }

  handleKey(e) {
    const { activeIndex, cl } = this.state;

    // Don't handle keys if there's no data showing!
    if (!_.keys(cl).length) return false;

    switch (e.key) {
      case 'ArrowUp':
        if (this.optionMode === 'clause-select') {
          this.setState({ activeIndex: activeIndex <= 0 ? this.optionCount - 1 : activeIndex - 1 });
          return true;
        }
        return false;
      case 'ArrowDown':
        if (this.optionMode === 'clause-select') {
          this.setState({ activeIndex: activeIndex + 1 >= this.optionCount ? 0 : activeIndex + 1 });
          return true;
        }
        return false;
      case 'Tab':
        // Tab cycles through available sections; shift+tab for reverse order
        if (this.optionMode === 'section-select') {
          let nextIdx;
          if (e.shiftKey) {
            nextIdx = activeIndex <= 0 ? this.optionCount - 1 : activeIndex - 1;
          } else {
            nextIdx = activeIndex + 1 >= this.optionCount ? 0 : activeIndex + 1;
          }
          this.setState({ activeIndex: nextIdx });
          return true;
        }
        return false;
      case 'Enter':
        this.selectOption(activeIndex);
        return true;
      case 'Escape':
        // If we're in section select, the first escape backs out to clause selection
        if (this.optionMode === 'section-select') {
          this.setState({ selectedKey: null });
        }
        // Otherwise resetting will clear data and therefore hide the menu
        else {
          this.reset();
        }
        return true;
      default:
        return false;
    }
  }

  selectOption(idx) {
    const { onSelect } = this.props;
    const { activeIndex, selectedKey, cl } = this.state;

    if (idx + 1 > this.optionCount || activeIndex === -1) return;

    // If there's no selected key yet, it means we're in clause-select mode,
    // still selecting a group of clause names (e.g., "Indemnification")
    if (!selectedKey) {
      this.setState({
        optionType: OPTION_TYPE.SECTIONS,
        selectedKey: _.keys(cl)[idx],
        activeIndex: 0,
      });
    }

    // If we already have a selected key, selecting an option means committing/selecting an individual section
    // which should be handled by parent (e.g., for insertion/linking)
    if (selectedKey && cl[selectedKey]) {
      onSelect(cl[selectedKey][idx]);
    }
  }

  renderOptions() {
    const { activeIndex, cl, selectedKey } = this.state;
    let options = [];
    let optionType;

    if (!selectedKey) {
      _.forEach(cl, (sections, key) => {
        const isHeaderFooter = [SectionType.TEMPLATE_HEADER, SectionType.TEMPLATE_FOOTER].includes(sections[0].type);
        options.push({
          title: isHeaderFooter ? sections[0].titleCL : sections[0].title,
          highlight: { __html: isHeaderFooter ? sections[0].highlight.titleCL : sections[0].highlight.title },
          count: sections.length,
          key,
        });
      });
      optionType = OPTION_TYPE.TITLES;
    } else if (cl[selectedKey]) {
      _.forEach(cl[selectedKey], (section) => {
        options.push(section);
      });
      optionType = OPTION_TYPE.SECTIONS;
    }

    let elOptions = [];

    switch (optionType) {
      case OPTION_TYPE.TITLES:
        elOptions = _.map(options, (option, idx) => (
          <div
            key={idx}
            className={cx('option', 'clause-select', { active: activeIndex === idx })}
            onClick={() => this.selectOption(idx)}
            onMouseEnter={() => this.setState({ activeIndex: idx })}
            data-cy="clause-select-item"
          >
            <Ellipsis className="clause-title" dangerouslySetInnerHTML={option.highlight} data-cy="clause-title" />
            <span className="statusLabel review" data-cy="clause-status-label">
              {option.count}
            </span>
          </div>
        ));
        break;

      case OPTION_TYPE.SECTIONS:
        elOptions = _.map(options, (option, idx) => {
          // Only show 1 at a time
          if (idx !== activeIndex) return null;

          let templateName = option.displayTemplate || option.template;
          if (option.disabled) templateName += ' (current)';

          const isHeaderFooter = [SectionType.TEMPLATE_HEADER, SectionType.TEMPLATE_FOOTER].includes(option.type);

          return (
            <div
              key={idx}
              className={cx('option', 'section-select', { active: activeIndex === idx, disabled: option.disabled })}
              onClick={() => (!option.disabled ? this.selectOption(idx) : null)}
              onMouseEnter={() => (!option.disabled ? this.setState({ activeIndex: idx }) : null)}
              data-cy="clause-preview"
            >
              <div className="section-full">
                <div className="title" data-cy="clause-title-preview">
                  {option.title}
                </div>
                {!isHeaderFooter && (
                  <div className="section-preview" data-cy="clause-body-preview">
                    {option.body}
                  </div>
                )}
                <div className="section-conditions"></div>
              </div>
            </div>
          );
        });
        break;

      default:
        break;
    }

    return elOptions;
  }

  renderMeta() {
    const { contextual } = this.props;
    const { activeIndex, cl, selectedKey, loading } = this.state;

    if (!selectedKey) {
      return (
        <div className="cl-meta clause-select" data-cy="clause-meta">
          {loading ? (
            <div className="lhs">
              {contextual && (
                <div className="cl-searching">
                  <Loader size="small" />
                  <div className="info">Searching clause library</div>
                </div>
              )}
            </div>
          ) : (
            <div className="lhs">
              {contextual && <div className="info">{`${this.optionCount} results found.`}</div>}
            </div>
          )}
          <div className="rhs">
            <div className="kb">
              <div className="instruction">
                <span>[return]</span> to view
              </div>
              <div className="instruction">
                <span>[esc]</span> to hide
              </div>
            </div>
          </div>
        </div>
      );
    } else if (cl[selectedKey]) {
      const option = cl[selectedKey][activeIndex];
      const linkedConditions = _.map(option.conditions, (con) => new VariableFilter(con.variable, con.values));

      const tips = (
        <div className={cx('kb', { separate: !contextual })}>
          <div className="instruction">
            <span>[return]</span> to use
          </div>
          <div className="instruction">
            <span>[tab]</span> to cycle
          </div>
          <div className="instruction">
            <span>[esc]</span> to go back
          </div>
        </div>
      );

      return (
        <>
          <div className="cl-meta section-select" data-cy="clause-meta">
            <div className="lhs">
              <TemplateLink className="info" title={option.displayTemplate || option.template} dealID={option.dealID} />
              <ConditionsView conditions={linkedConditions} />
            </div>

            <div className="rhs">
              <div className="cycle">
                <ButtonIcon
                  dark
                  size="small"
                  icon="chevronLeft"
                  disabled={this.optionCount <= 1}
                  onClick={() => this.handleKey({ key: 'Tab', shiftKey: true })}
                />
                <span className="current-section" data-cy="current-section">
                  {activeIndex + 1}/{this.optionCount}
                </span>
                <ButtonIcon
                  dark
                  size="small"
                  icon="chevronRight"
                  disabled={this.optionCount <= 1}
                  onClick={() => this.handleKey({ key: 'Tab' })}
                  data-cy="btn-next-cl"
                />
              </div>
              {contextual && tips}
            </div>
          </div>
          {!contextual && tips}
        </>
      );
    }
  }

  render() {
    const { contextual, addNewClause } = this.props;
    const { cl, loading } = this.state;

    return (
      <div className={cx('section-suggest', { contextual })} data-cy="section-suggest">
        {_.keys(cl).length ? (
          <>
            <div className={cx('options', this.optionMode)}>{this.renderOptions()}</div>
            {this.renderMeta()}
          </>
        ) : (
          <>
            {contextual && (
              <div className="cl-meta clause-select no-results" data-cy="clause-meta">
                <div className="lhs">
                  {loading ? (
                    <div className="cl-searching">
                      <Loader size="small" />
                      <div className="info">Searching clause library</div>
                    </div>
                  ) : (
                    <>
                      {this.noResults ? (
                        <div className="info">
                          {`No clause found. `}
                          {addNewClause && (
                            <a className="add-cl-button" onClick={addNewClause}>
                              Add new clause
                            </a>
                          )}
                        </div>
                      ) : (
                        <div className="info">Clause library</div>
                      )}
                    </>
                  )}
                </div>

                <div className="rhs">
                  <div className="kb">
                    <div className="instruction">
                      <span>{`[esc]${!this.noResults ? ' [backspace]' : ''}`}</span> to exit
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
