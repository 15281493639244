import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { Alert as BSAlert, utils } from 'react-bootstrap';

import { classNamePrefixer } from '@core/utils/Generators';

import Icon from '@components/dmp/Icon';
import { ICONS } from '@components/dmp/shared/icons';

utils.bootstrapUtils.addStyle(BSAlert, 'dark');
utils.bootstrapUtils.addStyle(BSAlert, 'light');

const cl = classNamePrefixer('alert');

const Alert = ({ centered, children, className, icon, inline, size, title, dmpStyle, ...passingProps }) => {
  const classNames = cx(
    cl(),
    className,
    cl(dmpStyle),
    { [cl(size)]: size !== 'default' },
    { [cl('inline')]: inline },
    { [cl('centered')]: centered },
    { [cl('w-icon')]: !!icon }
  );

  let iconColor;

  switch (dmpStyle) {
    case 'danger':
      iconColor = 'red';
      break;
    case 'warning':
      iconColor = 'yellow';
      break;
    case 'info':
      iconColor = 'blue';
      break;
    case 'success':
      iconColor = 'green';
      break;
  }

  return (
    <BSAlert bsStyle={dmpStyle} className={classNames} {...passingProps}>
      {icon && <Icon name={icon} className={cl('icon')} color={iconColor} />}
      <div className={cl('content')}>
        {title && <h4 className={cl('title')}>{title}</h4>}
        {children}
      </div>
    </BSAlert>
  );
};

Alert.defaultProps = {
  centered: false,
  inline: false,
  size: 'default',
  dmpStyle: 'info',
};

Alert.propTypes = {
  centered: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  icon: PropTypes.oneOf(Object.keys(ICONS)),
  inline: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'default']),
  title: PropTypes.node,
  dmpStyle: PropTypes.oneOf(['danger', 'info', 'success', 'warning', 'dark', 'light']),
};

export default Alert;
