import React from 'react';

import PromoText from './PromoText';

const PromoFooter = () => (
  <div id="page-footer">
    <div className="footer-content">
      <img src="/assets/svg/logo-black.svg" alt="Outlaw" className="logo" />
      <div>
        <PromoText />
      </div>
    </div>
  </div>
);

export default PromoFooter;
