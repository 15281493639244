import { assign, cloneDeep, find, get, isEqual, merge, pick } from 'lodash';

import DealRole from '../enums/DealRole';
import { VariableType } from './Variable';

export const ELEMENT_TYPE = {
  SIMPLE: 'simple',
  SIGNATURE: 'signature',
  INITIALS: 'initials',
  VARIABLE: 'variable',
};

export const OVERFLOW_TYPE = {
  WRAP: 'wrap',
  TRUNCATE: 'truncate',
};

export const DEFAULT_ELEMENT_WIDTH = 120;
export const MIN_ELEMENT_WIDTH = {
  [ELEMENT_TYPE.SIGNATURE]: 20,
  [ELEMENT_TYPE.VARIABLE]: 50,
  [ELEMENT_TYPE.SIMPLE]: 50,
};

export const SIGNATURE_HEIGHT_RATIO = 0.25;

export const TEXT_OPTIONS_ALIGN = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

/*
  Fonts available in Formatting options and PDF rendering
  "standard: true" means that it's standard in the pdf-lib library we're using.

  Note: when rendered in the browser, the "formats.regular" name will be used to set the
        proper font-family. Then fontWeight and fontStyle will pickup bold and italic.
*/
export const FONTS = {
  helvetica: {
    key: 'helvetica',
    displayName: 'Sans',
    standard: true,
    formats: {
      regular: 'Helvetica',
      bold: 'HelveticaBold',
      'bold-italic': 'HelveticaBoldOblique',
      italic: 'HelveticaOblique',
    },
  },
  times: {
    key: 'times',
    displayName: 'Serif',
    standard: true,
    formats: {
      regular: 'TimesRoman',
      bold: 'TimesRomanBold',
      'bold-italic': 'TimesRomanBoldItalic',
      italic: 'TimesRomanItalic',
    },
  },
  courier: {
    key: 'courier',
    displayName: 'Mono',
    standard: true,
    formats: {
      regular: 'Courier',
      bold: 'CourierBold',
      'bold-italic': 'CourierBoldOblique',
      italic: 'CourierOblique',
    },
  },
};

export const DEFAULT_FONT = FONTS.helvetica.key;

// Default text style for variable fields
export const DEFAULT_TEXT_OPTIONS = {
  size: 12,
  font: FONTS[DEFAULT_FONT].formats.regular,
  italic: false,
  bold: false,
  textAlign: TEXT_OPTIONS_ALIGN.LEFT,
  lineHeight: 15,
  color: 'black',
};

export const FIELD_PADDING = 5;

export default class PDFElement {
  id; // Generated from key + variable
  key;
  deal; //reference to parent Deal for variable/user/party lookup etc

  // Required
  elementType;
  variable;
  page;
  x;
  y;

  // Used to store base64 image data for initials and signature,
  // or for text value for simple elements
  data;

  // Optional
  options = cloneDeep(DEFAULT_TEXT_OPTIONS);
  width = DEFAULT_ELEMENT_WIDTH;
  overflowType = OVERFLOW_TYPE.WRAP;
  rotation = 0;

  constructor(json, deal, key = null) {
    this.deal = deal;
    assign(this, pick(json, ['page', 'x', 'y', 'elementType', 'variable', 'data']), { key });

    this.id = `${this.variable}-${this.key}`;

    if (json.width) this.width = json.width;
    if (json.overflowType) this.overflowType = json.overflowType;
    if (json.rotation) this.rotation = json.rotation;

    if (json.options) this.options = merge(this.options, json.options);

    // Make sure that we do not allow unsupported fonts
    if (!FONTS[this.options.font]) this.options.font = DEFAULT_FONT;
  }

  get displayName() {
    const variable = this.dealVariable;
    // Return the varaible displayName if we have it, otherwise, let's use the variable key
    if (variable && variable.displayName) {
      return variable.displayName;
    }

    return this.variable;
  }

  get dealVariable() {
    const pieces = get(this, 'variable', '').split('.');
    const party = find(this.deal.parties, { partyID: pieces[0] });

    if (pieces.length === 2 && party) {
      return party.generatePartyVariable(pieces[1]);
    }

    return this.deal.variables[this.variable] || null;
  }

  // An individual DealUser can be assigned to either a signature Element,
  // or a Party variable (e.g., @Company.fullName)
  get dealUser() {
    const dealVariable = this.dealVariable;

    if (!dealVariable) return null;
    if (this.elementType !== ELEMENT_TYPE.SIGNATURE && dealVariable.type !== VariableType.PARTY) return null;

    const users = this.deal.getUsersByParty(dealVariable.name.split('.')[0]);
    return get(users, '[0]', null);
  }

  get displayValue() {
    const variable = this.dealVariable;
    if (!variable) return null;

    return variable.val;
  }

  get assigned() {
    const variable = this.dealVariable;
    if (!variable || !variable.assigned) return null;

    const assignedParty = this.deal.variables[variable.assigned];

    return get(assignedParty, 'displayName', variable.assigned);
  }

  get signed() {
    // If element type is not a SIGNATURE or INITIALS it can't be signed
    if (this.elementType === ELEMENT_TYPE.VARIABLE) return false;
    // variable is equivalent to a partyID -- if there is none, it can't be signed
    if (!this.variable) return false;

    // Here we have a partyID, so verify there's a user corresponding to that party
    if (!this.deal.getUsersByParty(this.variable).length) return false;

    // Finally, we've got a valid user who should sign;
    // If signed, signature data will be present in the data property
    return !!this.data;
  }

  get canConfigure() {
    if (!this.deal.currentDealUser) {
      return false;
    }

    if ([DealRole.OWNER, DealRole.EDITOR].includes(this.deal.currentDealUser.role)) {
      return true;
    }

    // This allows a user to resize, position and clear their own signature once signed
    if (this.elementType === ELEMENT_TYPE.SIGNATURE && this.signed && this.dealUser === this.deal.currentDealUser) {
      return true;
    }

    return false;
  }

  get canEdit() {
    const { dealVariable, dealUser, deal, canConfigure } = this;

    // No editing for fully executed deals, or if there's no current user (anon access)
    if (deal.signed || !deal.currentDealUser) {
      return false;
    }

    // Owners / editors can always edit
    if (canConfigure) {
      return true;
    }

    // If this is a simple text field and we get here, it's a non-editor so they can't edit
    if (!dealVariable) {
      return false;
    }

    switch (dealVariable.type) {
      case VariableType.SIMPLE:
        // For SIMPLE vars, users in assigned party can also edit
        return dealVariable.assigned && dealVariable.assigned === deal.currentDealUser.partyID;
      case VariableType.PARTY:
        // For PARTY vars (e.g., title/org) users can edit self
        return deal.isOwner || get(dealUser, 'uid') === deal.currentDealUser.uid;
      default:
        return false;
    }
  }

  get isFilled() {
    switch (this.elementType) {
      case ELEMENT_TYPE.VARIABLE:
        return !!this.displayValue;
      case ELEMENT_TYPE.SIGNATURE:
      case ELEMENT_TYPE.SIMPLE:
        return !!this.data;
      default:
        return false;
    }
  }

  get json() {
    const obj = pick(this, ['elementType', 'variable', 'page', 'x', 'y', 'data']);

    if (this.width !== DEFAULT_ELEMENT_WIDTH) obj.width = this.width;

    if ([ELEMENT_TYPE.SIMPLE, ELEMENT_TYPE.VARIABLE].includes(this.elementType)) {
      // Only store optional properties if they differ from default values
      if (!isEqual(this.options, DEFAULT_TEXT_OPTIONS)) obj.options = this.options;
      if (this.overflowType !== OVERFLOW_TYPE.WRAP) obj.overflowType = this.overflowType;
      if (this.rotation !== 0) obj.rotation = this.rotation;
    }

    return obj;
  }
}
