import { filter, forOwn, pick, values } from 'lodash';

import DealAction from '../enums/DealAction';
import Activity from './Activity';
import Vote from './Vote';
import { CHECKPOINT_GROUP_APPROVAL_RULE } from './WorkflowStep';

export const ReadyCheckStatus = {
  NEW: 'new', //readycheck hasn't been created yet
  OPEN: 'open',
  COMPLETE: 'complete',
  CANCELLED: 'cancelled',
  SKIPPED: 'skipped',
};

export const ReadyCheckResult = {
  INCOMPLETE: { data: 'incomplete', label: 'In Progress' },
  PASSED: { data: 'passed', label: 'Passed' },
  FAILED: { data: 'failed', label: 'Failed' },
  SKIPPED: { data: 'skipped', label: 'Skipped' },
  CANCELLED: { data: 'cancelled', label: 'Cancelled' },
};

export const ReadyLabels = {
  ACTION: 'Create',
  ACTION_PAST: 'Created',
  ACTION_END: 'Close',
  ACTION_CANCEL: 'Cancel',
  EVENT: 'Checkpoint',
  VOTE: 'Respond',
  VOTED: 'Responded',
  NOT_VOTED: 'Waiting',
  VOTE_YES: 'Approve',
  VOTED_YES: 'Approved',
  VOTE_NO: 'Reject',
  VOTED_NO: 'Rejected',
  HELP: 'Learn more',
  HELP_URL: 'http://help.getoutlaw.com/onboarding/checkpoints',
  RUN: 'Run',
  SKIP: 'Skip',
  RE_RUN: 'Re-Run',
};

export default class ReadyCheck extends Activity {
  status;
  votes = {};
  title = ReadyLabels.EVENT;
  workflowCheckpointKey = null;
  checkpointGroupApprovalRule = null;

  constructor(json) {
    super(json);
    this.status = json.status || ReadyCheckStatus.OPEN;
    forOwn(json.votes, (v, k) => (this.votes[k] = new Vote(v)));

    this.workflowCheckpointKey = json.workflowCheckpointKey;
    this.checkpointGroupApprovalRule = json.checkpointGroupApprovalRule;

    //if ReadyCheck is complete, we actually want its date to be the date of completion (i.e., final vote), not origination
    if (this.status == ReadyCheckStatus.COMPLETE) {
      forOwn(this.votes, (v) => {
        if (v.response) {
          this.date = new Date(Math.max(this.date, v.date));
        }
      });
    }
  }

  get result() {
    const votes = values(this.votes);
    if (this.status === ReadyCheckStatus.SKIPPED) {
      return ReadyCheckResult.SKIPPED;
    }

    if (this.status === ReadyCheckStatus.CANCELLED) {
      return ReadyCheckResult.CANCELLED;
    }

    if (this.checkpointGroupApprovalRule) {
      if (filter(votes, { response: DealAction.REJECT }).length > 0) return ReadyCheckResult.FAILED;
      if (this.checkpointGroupApprovalRule === CHECKPOINT_GROUP_APPROVAL_RULE.FIRST) {
        if (filter(votes, { response: DealAction.APPROVE }).length > 0) return ReadyCheckResult.PASSED;
      } else {
        // UNANIMOUS CASE
        if (filter(votes, { response: DealAction.APPROVE }).length === votes.length) return ReadyCheckResult.PASSED;
      }
      return ReadyCheckResult.INCOMPLETE;
    }
    //if not all votes are in, we're not done
    if (votes.length > filter(votes, 'response').length) return ReadyCheckResult.INCOMPLETE;
    //if there's 1 rejection, ReadyCheck fails
    else if (filter(votes, { response: DealAction.REJECT }).length > 0) return ReadyCheckResult.FAILED;
    //if everyone approves, ReadyCheck passes
    else if (filter(votes, { response: DealAction.APPROVE }).length == votes.length) return ReadyCheckResult.PASSED;
    //should never get here
    else return ReadyCheckResult.INCOMPLETE;
  }

  get json() {
    const obj = pick(this, ['user', 'status', 'action', 'id', 'workflowCheckpointKey', 'checkpointGroupApprovalRule']);
    if (this.message) obj.message = this.message;
    obj.votes = {};
    forOwn(this.votes, (vote, key) => (obj.votes[key] = vote.json));
    return obj;
  }

  getVotes(deal) {
    const votes = [];

    //for each voter, we need to lookup the deal user in order to build a displayable icon with initials and tooltip
    //note, some voters may not be on the deal (e.g., if user leaves the deal after ReadyCheck is issued)
    forOwn(this.votes, (v, k) => {
      const du = deal.getUserByID(k);
      if (du != null) {
        votes.push({
          uid: du.uid,
          fullName: du.fullName,
          email: du.email,
          initials: du.initials,
          response: v.response || null,
          displayResponse: v.displayResponse,
        });
      }
    });

    return votes;
  }

  getAutoCloseStatus(uid, response) {
    const updatedVotesByVoter = Object.values(this?.votes).map((v) => {
      if (v.user === uid) {
        return { ...v, response };
      } else {
        return v;
      }
    });
    if (this.checkpointGroupApprovalRule) {
      if (this.checkpointGroupApprovalRule === CHECKPOINT_GROUP_APPROVAL_RULE.FIRST) {
        if (updatedVotesByVoter.some((v) => v.response)) {
          return ReadyCheckStatus.COMPLETE;
        }
      } else {
        if (
          updatedVotesByVoter.some((v) => v.response === DealAction.REJECT) ||
          updatedVotesByVoter.every((v) => v.response === DealAction.APPROVE)
        ) {
          return ReadyCheckStatus.COMPLETE;
        }
      }
      return ReadyCheckStatus.OPEN;
    } else {
      return this.status;
    }
  }
}
