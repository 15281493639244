class Dealer {
  get mobile() {
    //https://stackoverflow.com/questions/36297612/window-innerwidth-in-chromes-device-mode
    if (typeof window == 'object')
      return (window.visualViewport ? window.visualViewport.width : window.innerWidth) < 768;
    else return false;
  }
}

const DealerInstance = new Dealer();

export default DealerInstance;
