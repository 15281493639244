import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl } from 'react-bootstrap';

import Caption from '@core/models/Caption';
import { LINE_SPACINGS } from '@core/models/TypeStyle';
import { generateNumberOptions } from '@core/utils/Generators';

import { Dropdown, MenuItem, Switch } from '@components/dmp';

import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import Fire from '@root/Fire';

const DIVIDER_WIDTHS = generateNumberOptions(1, 20, 1, 0);

@autoBindMethods
export default class CaptionSidebar extends Component {
  static propTypes = {
    caption: PropTypes.instanceOf(Caption).isRequired,
  };

  // NB: this could technically be a stateless component, but seems highly likely that it will grow in complexity
  // So we'll keep it stateful so as not have to refactor twice
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateSymbol(symbol) {
    const { caption } = this.props;
    Fire.saveSection(caption, { divider: symbol || null });
  }

  updateWidth(dividerWidth) {
    const { caption } = this.props;
    Fire.saveSection(caption, { dividerWidth });
  }

  updateLineSpacing(dividerLineSpacing) {
    const { caption } = this.props;
    Fire.saveSection(caption, { dividerLineSpacing });
  }

  updateEnabled(dividerEnabled) {
    const { caption } = this.props;
    Fire.saveSection(caption, { dividerEnabled });
  }

  render() {
    const { caption } = this.props;

    return (
      <div className="caption-sidebar" data-cy="caption-sidebar">
        <DealPanelItem borderBottom>
          <div className="property-title" data-cy="property-title">
            Caption
          </div>
          <div className="property-row" data-cy="property-row-divider">
            <Switch
              id="chk-divider-enabled"
              checked={caption.dividerEnabled}
              onChange={() => this.updateEnabled(!caption.dividerEnabled)}
              className="item-label"
              size="small"
              data-cy="switch-divider"
            >
              Enable divider
            </Switch>
          </div>
          {caption.dividerEnabled && (
            <div className="property-row" data-cy="caption-property-row">
              <div className="property-row-item">
                <div className="item-label" data-cy="item-label-symbol">
                  Symbol
                </div>
                <FormControl
                  disabled={!caption.dividerEnabled}
                  placeholder="e.g., ')'"
                  type="text"
                  value={caption.divider}
                  onChange={(e) => this.updateSymbol(e.target.value)}
                  bsSize="small"
                  data-cy="item-symbol"
                />
              </div>
              <div className="property-row-item">
                <div className="item-label" data-cy="item-label-width">
                  Width
                </div>
                <Dropdown
                  disabled={!caption.dividerEnabled}
                  id="dd-caption-divider-width"
                  title={caption.dividerEnabled ? `${caption.dividerWidth}%` : '--'}
                  onSelect={this.updateWidth}
                  size="small"
                  block
                  dataCyToggle="dd-caption-divider-width"
                >
                  {_.map(DIVIDER_WIDTHS, (w, idx) => (
                    <MenuItem key={idx} eventKey={w.value}>
                      {w.label}%
                    </MenuItem>
                  ))}
                </Dropdown>
              </div>
              <div className="property-row-item">
                <div className="item-label" data-cy="item-label-width">
                  Line Spacing
                </div>
                <Dropdown
                  disabled={!caption.dividerEnabled}
                  id="dd-caption-divider-line-spacing"
                  title={caption.dividerEnabled ? `${caption.dividerLineSpacing}` : '--'}
                  onSelect={this.updateLineSpacing}
                  size="small"
                  block
                  dataCyToggle="dd-caption-divider-line-spacing"
                >
                  {_.map(LINE_SPACINGS, (sp, idx) => (
                    <MenuItem key={idx} eventKey={sp.value} data-cy="caption-divider-line-spacing-option">
                      {sp.label}
                    </MenuItem>
                  ))}
                </Dropdown>
              </div>
            </div>
          )}
        </DealPanelItem>
      </div>
    );
  }
}
