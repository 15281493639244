import { get, pick } from 'lodash';

export const DEFAULT_TAG_COLOR = '#b2b2b2';
export const TAG_MAX_LENGTH = 25;

export default class Tag {
  tagID;
  tag;
  color;
  count;

  constructor(tagID, json) {
    this.tagID = tagID;
    this.tag = get(json, 'tag', null);
    this.color = get(json, 'color', DEFAULT_TAG_COLOR);
    this.count = 0;
  }

  get json() {
    return pick(this, ['tag', 'color']);
  }
}
