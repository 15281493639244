import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { HEADER_FOOTER_CONFIG_OPTIONS } from '@core/enums/SectionType';
import Section from '@core/models/Section';

import { Icon } from '@components/dmp';

const PagesRenderView = (props) => {
  const section = props.section.isTemplateHeaderFooterSubSection ? props.section.sourceParent : props.section;
  const { deal, headerFooterConfigKey } = section;
  const { NO_PAGES } = HEADER_FOOTER_CONFIG_OPTIONS;

  const pageBreakID = _.get(section, 'pageBreakID', null);

  const selectedHeaderFooterConfig = headerFooterConfigKey
    ? deal.headerFooterConfig.find((config) => {
        if (config.key === headerFooterConfigKey) {
          if (config.id) {
            return config.id === pageBreakID;
          } else return true;
        }
      })
    : deal.headerFooterConfig.find((config) => config.key === HEADER_FOOTER_CONFIG_OPTIONS.NO_PAGES);

  const noPages = !selectedHeaderFooterConfig || selectedHeaderFooterConfig?.key === NO_PAGES;

  const className = cx('pages-render-view', { paged: !noPages }, { noPages });

  return (
    <div className={className} data-cy="header-condition">
      <Icon name="overview" size="default" noFill={true} />
      <span>
        [{section.displayname}]
        <span className="pages-render-view-message">
          Print:{' '}
          {selectedHeaderFooterConfig?.key === 'pageBreak'
            ? `From ${selectedHeaderFooterConfig?.displayName}`
            : selectedHeaderFooterConfig?.displayName}
        </span>
      </span>
    </div>
  );
};

PagesRenderView.propTypes = {
  section: PropTypes.instanceOf(Section),
};

export default PagesRenderView;
