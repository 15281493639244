import {
  ContentState,
  Modifier,
  SelectionState,
  convertFromHTML,
  convertToRaw,
  getDefaultKeyBinding,
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { isEqual as _isEqual, forEach, get } from 'lodash';

import { NUMBER_FINDER, extractNumber, MULTIPART_NUMBER_FINDER } from "./DealNumberFormat";
import { ValueType, VariableType, sanitize } from "./Variable";
import { sanitize as sanitizeHTML } from "./Version";
import { discoverOrder } from "../utils/OrderFormatter";
import { PARTY_NAMES, SALUTATIONS, TITLE_JOINERS } from "../enums/Legalese";
import { getCursor } from '../utils/HTMLInput';

//really variables should not allow spaces in names, but
//legacy section references have spaces so we need to include them
//so that the text replacement still happens
export const rxVariable = /\[[!@#$%*+^~][a-zA-Z0-9][-_\w\d\s.\(\)]*\]/g;

// Normal rx for VariableSuggest which requires starting with '['
export const rxSuggest = /\[([!@#$%*+^][a-zA-Z]*)?[-_\w\d.]*(?=[\s]|$)/g;
// Repeater-specific rx which also triggers on {
export const rxSuggestFields = /[[{]([!@#$%*+^][a-zA-Z]*)?[-_\w\d.]*(?=[\s]|$)/g;

// Repeater fields are wrapped in curly braces, ie {field}
export const rxRepeaterField = /{([\w\d]+)}/gi;

export const rxReference = /(Section|Clause|Article|Paragraph|Appendix|Exhibit|Schedule|Attachment)[\s]+[-\w\d\(\).]+/g;

// Match instances of either multiple underscores (i.e., blank line) or [Words In Brackets] (which lawyers tend to use to denote fields)
export const rxCandidate = /(_{2,}|\[[\w\s]+\])/gi;

// First, look AFTER the field for the FIRST capitalized phrase appearing inside double quotes,
// e.g., (the “Effective Date”)
// export const rxDefinedTerm = /\([-\w\s,.]*[“"]((\b[A-Z][\w]*[\s\n]*\b)+)["”][\w\s]*\)/g;
export const rxDefinedTerm = /[“"]((\b[A-Z][\w.]*[\s\n]*\b)+)["”]/g;

export const rxVariableReplace = /[!@#$%*+^\[\]]/gi;

export const rxFootnotes = /\[\^([a-zA-Z0-9-]{15})\]/g;


//Variable replacement and keybindings via Draft
//--------------------------------------------------------

function findWithRegex(regex, contentBlock, callback) {
  const text = typeof contentBlock.getText == 'function' ? contentBlock.getText() : contentBlock;
  let matchArr, start;
  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
}

export function titleBindings(e, state) {
  // console.log(e.key, e.shiftKey);
  if (e.key === 'Enter') {
    return 'reveal-body';
  }

  if (e.key === 'Tab') {
    return e.shiftKey ? 'outdent' : 'indent';
  }

  if (e.key === 'ArrowUp' && !e.shiftKey) {
    return 'up';
  }

  if (e.key === 'ArrowDown' && !e.shiftKey) {
    return 'down';
  }

  if (e.key === 'Escape') {
    return 'escape';
  }

  //ctrl+s or cmd+s = save section
  if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
    return 'save';
  }

  const val = state.getCurrentContent().getPlainText();

  if (!val && (e.key == 'Backspace' || e.key == 'Delete')) {
    return 'delete-title';
  }

  return getDefaultKeyBinding(e);
}

export function bodyBindings(e, state) {
  // console.log(e.key);
  //ctrl+s or cmd+s = save section
  if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
    return 'save';
  }

  if (e.key === 'ArrowUp' && !e.shiftKey) {
    return isAtStart(state) ? 'up' : getDefaultKeyBinding(e);
  }

  if (e.key === 'ArrowDown' && !e.shiftKey) {
    return isAtEnd(state) ? 'down' : getDefaultKeyBinding(e);
  }

  if (e.key === 'Escape') {
    return 'escape';
  }

  //enter (without shift) = save and new section
  if (e.key === 'Enter' && !e.shiftKey) {
    return 'enter';
  }

  if (e.key === 'Tab') {
    return e.shiftKey ? 'outdent' : 'indent';
  }

  return getDefaultKeyBinding(e);
}

export function isAtEnd(state) {
  const sel = state.getSelection();
  const currentContent = state.getCurrentContent();
  const block = currentContent.getBlockForKey(sel.getAnchorKey());

  if (block != currentContent.getLastBlock() || !sel.isCollapsed()) return false;
  //if we're on an entity, use the end of the entity as position
  let pos = sel.getFocusOffset(),
    entityKey = block.getEntityAt(pos);
  while (entityKey != null) entityKey = block.getEntityAt(++pos);

  return pos >= block.getText().length;
}
export function isAtStart(state) {
  const sel = state.getSelection();
  const currentContent = state.getCurrentContent();
  const block = currentContent.getBlockForKey(sel.getAnchorKey());

  if (block != currentContent.getFirstBlock() || !sel.isCollapsed()) return false;

  return sel.getStartOffset() == 0;
}

export const ENTITY_TYPE = {
  VARIABLE: 'variable',
  DIFF_ADDED: 'added',
  DIFF_REMOVED: 'removed',
};

export function getMarkup(contentState) {
  // First remove variable tokens
  let raw = tokenizeVariables(contentState, true);
  // Now get raw CS blocks
  raw = convertToRaw(raw);

  //replace the variable entities with underlying varText
  // TODO: pretty sure this is now unnecessary, but to be confirmed...
  raw.blocks.map((block) => {
    if (block.entityRanges != null && block.entityRanges.length > 0) {
      let newText = '',
        cursor = 0;
      block.entityRanges.map((range, idx) => {
        //first gather other text up to current range
        const chunk = block.text.slice(cursor, range.offset);
        cursor += chunk.length;
        newText += chunk;

        const entity = raw.entityMap[range.key];
        if (entity != null && entity.data != null && entity.data.varText != null) {
          newText += entity.data.varText;
        } else {
          newText += block.text.slice(range.offset, range.offset + range.length);
        }
        cursor += range.length;

        if (idx + 1 == block.entityRanges.length && cursor < block.text.length) {
          newText += block.text.slice(cursor);
        }
      });

      block.text = newText;
      block.entityRanges = [];
    }
  });
  let html = draftToHtml(raw);

  // draftToHtml package uses <ins> tags instead of <u> tags, which is (again) stupidly opinionated
  // DraftJS (and Outlaw, and the Internet at large) prefer <u> so let's use keep using that, kthx
  // https://stackoverflow.com/questions/12148517/whats-the-difference-between-the-u-tag-and-the-ins-tag
  html = html.replace(/(<\/?)(ins)>/gi, '$1u>');

  return html;
}

export const strategies = {
  variable: (contentBlock, callback) => {
    findWithRegex(rxVariable, contentBlock, callback);
  },

  variableSuggest: (contentBlock, callback) => {
    findWithRegex(rxSuggest, contentBlock, callback);
  },

  variableFieldSuggest: (contentBlock, callback) => {
    findWithRegex(rxSuggestFields, contentBlock, callback);
  },

  // Find all diffs (both added and removed)
  diffFinder: (block, callback, cs) => {
    block.findEntityRanges((char) => {
      const entityKey = char.getEntity();
      if (entityKey) {
        try {
          const entity = cs.getEntity(entityKey);
          return [ENTITY_TYPE.DIFF_ADDED, ENTITY_TYPE.DIFF_REMOVED].includes(entity.type);
        } catch (e) {
          return false;
        }
      }
      return false;
    }, callback);
  },

  candidateRef: (contentBlock, callback) => {
    findWithRegex(rxReference, contentBlock, callback);
  },
  fieldFinder: (contentBlock, callback) => {
    findWithRegex(rxCandidate, contentBlock, callback);
  },

  repeaterField: (contentBlock, callback) => {
    findWithRegex(rxRepeaterField, contentBlock, callback);
  },

  bold: (contentBlock, callback) => {
    findWithRegex(/\<strong\>.+\<\/strong\>/gi, contentBlock, callback);
  },
  underline: (contentBlock, callback) => {
    findWithRegex(/\<u\>.+\<\/u\>/gi, contentBlock, callback);
  },
  italic: (contentBlock, callback) => {
    findWithRegex(/\<em\>.+\<\/em\>/gi, contentBlock, callback);
  },

  // commented as these are now handled internally in PLAIN elements and DiffViews as Breakables

  // linebreak: (contentBlock, callback, contentState) => {
  //   findWithRegex(/\<br[\s]*\/\>/ig, contentBlock, callback);
  // },

  //additional strategies go here! (separate with commas -- it's an object)
  // editorVariable: (contentBlock, callback, contentState) => {
  //   findWithRegex(/\[[!@#$%][-_\w\d\s.\(\)]+\]/g, contentBlock, callback);
  //   // findWithRegex(/\[[\w\d\s.-]+\]/ig, contentBlock, callback);
  // },
};
// regex for definitions (actual definition phrase in match[1])
// /\([\w\s]*[“"]([\w\s]+)["”]\)/ig

// Given a ContentState with a SelectionState representing a candidate field (e.g., [Field Name] or _______)
// We want to look through the surrounding text for common patterns to identify what this field most likely represents
export function suggestElement(cs, selectedField) {
  const block = cs.getBlockForKey(selectedField.getAnchorKey());
  const text = block.getText();
  const start = Math.min(selectedField.getAnchorOffset(), selectedField.getFocusOffset());
  const end = Math.max(selectedField.getAnchorOffset(), selectedField.getFocusOffset());
  const fieldText = text.slice(start, end - start);
  const textAfter = text.slice(end);
  const textBefore = text.slice(0, start);
  const suggestion = {
    type: VariableType.SIMPLE,
    name: '',
    displayName: '',
  };

  let match = rxDefinedTerm.exec(textAfter);
  if (match) {
    suggestion.displayName = match[1];
    suggestion.name = sanitize(match[1]);

    // If we find something this way, see if the defined term is likely a party
    const regParty = new RegExp(`(${PARTY_NAMES.join(')|(')})`, 'i');
    if (match[1].match(regParty) != null) suggestion.type = VariableType.PARTY;
  } else {
    // If the above yields nothing, then look BEFORE the field
    // for the LAST (most recent) occurance of a capitalized phrase
    // e.g., Effective Date: ________
    const REG_FORM = /((\b[A-Z][\w]*[\s]*\b)+)/g;
    while ((match = REG_FORM.exec(textBefore)) !== null) {
      suggestion.displayName = match[1].trim();
      suggestion.name = sanitize(match[1].trim());
    }
  }

  // If we get here and still don't have a suggested displayName from the surrounding text
  // Just use whatever is in brackets, e.g., [Name of Counsel]
  if (!suggestion.displayName) {
    match = /\[([^\]]+)\]/.exec(fieldText);
    if (match && match[1]) {
      suggestion.displayName = match[1].trim();
      suggestion.name = sanitize(match[1].trim());
    }
  }

  // One more special case detail -- if the word "date" is in the variable display name, it's a date!
  if (suggestion.displayName.match(/date/i)) suggestion.valueType = ValueType.DATE;

  return suggestion;
}

// Parse html pasted from external sources (Google Docs, Word, etc) into Sections
// NB: we're intentionally using DraftJS's version of html conversion here,
// which is very aggressive and opinionated about stripping tags, empty content, etc
// In other places (see StateFromHTML.js) that's not desirable, but here it's perfect
export function parseExternalHTML(html) {
  let sections = [];

  const blocks = convertFromHTML(sanitizeHTML(html)).contentBlocks;

  if (blocks) {
    blocks.map((block) => {
      const json = parseSection(block.getText().trim());
      if (json) sections.push(json);
    });
  }

  // WIP: we can use DraftJS's conversion to maintain styled text on paste (bold/italic/underline)
  // But it's separate scope and more work is needed to properly parse into multiple Sections here
  // So leaving as is for now: https://trello.com/c/KyFCoGcx
  /*
  let converted = convertFromHTML(sanitizeHTML(html));
  let cs = ContentState.createFromBlockArray(converted.contentBlocks, converted.entityMap);
  sections = [parseSection(cs)];
  */

  sections = condenseSections(sections);
  return sections;
}

// Parse a raw content section which may have numbering and a title
// into a structured object (displayname and content)
// with whitespace and numbering stripped
export function parseSection(raw, useMarkup) {
  if (!raw) return null;

  let section = {};

  // If we're parsing a ContentSection (from inbound docx upload) then parsing is simple
  // If there's a bold section as either the first range in the first block
  // or the entirety of the first block (and there are more blocks), then that's the title
  if (raw instanceof ContentState) {
    let sel;

    // Poorly formatted docx files will have manual numbering explicitly in text
    // instead of being specified in proper lists -- so strip them out here
    // they get picked up elsewhere in parseDocx() so they can be ignored here so as not to duplicate
    let rawText = raw.getPlainText();
    const num = extractNumber(rawText);
    if (num) {
      sel = SelectionState.createEmpty(raw.getFirstBlock().getKey()).merge({
        anchorOffset: 0,
        focusOffset: num.raw.length,
      });
      raw = Modifier.removeRange(raw, sel, 'forward');
      //update raw text so we ignore number when looking for titles
      rawText = raw.getPlainText();
    }

    const title = extractTitle(rawText);

    if (title) {
      section.displayname = title.trim();

      // Now we can use DraftJS to split title off and create the body
      // but, the body will now have leading space (either a newline char or space or both)
      // so we want to remove that as well
      sel = SelectionState.createEmpty(raw.getFirstBlock().getKey()).merge({
        anchorOffset: 0,
        focusOffset: title.length,
      });
      raw = Modifier.removeRange(raw, sel, 'forward');
      const leadingSpace = /[^\s]/.exec(raw.getPlainText());
      if (leadingSpace) {
        sel = SelectionState.createEmpty(raw.getFirstBlock().getKey()).merge({
          anchorOffset: 0,
          focusOffset: leadingSpace.index,
        });
        raw = Modifier.removeRange(raw, sel, 'forward');
      }

      // If the title was everything we had, make sure we don't write nearly empty strings (' ') to body
      if (raw.getPlainText().trim() == '') section.content = null;
      // Otherwise, finally import body as is
      else section.content = useMarkup ? getMarkup(raw) : convertToRaw(raw);
    }
    //otherwise assume no title and the whole thing is a body
    else {
      section.displayname = null;
      section.content = useMarkup ? getMarkup(raw) : convertToRaw(raw);
    }
    return section;
  }
  // Otherwise we're looking at a string (pasted text),
  // so make a best-effort attempt to identify titles and numbering
  else {
    //strip inbound numbering, but numbering can look the same as 1-word section titles
    //so grab the string and test to see whether it's a number
    const candidateNumber = raw.match(NUMBER_FINDER);
    if (candidateNumber && discoverOrder(candidateNumber[1]) > -1) {
      const multi = raw.match(MULTIPART_NUMBER_FINDER);
      raw = raw.replace(multi ? multi[0] : candidateNumber[0], '');
    }
    const title = extractTitle(raw);

    section = {};

    //if we found a title, we can now populate both title and body; otherwise just body
    //but, special case for all caps. if the entire block is uppercase, don't parse out title separately
    const body = title ? escapeTags(raw.substring(title.length)).trim() : escapeTags(raw).trim();

    if (title && (raw.toUpperCase() != raw || !body)) {
      section.displayname = title.trim();
      section.content = escapeTags(raw.substring(title.length)).trim();
    } else {
      section.content = escapeTags(raw).trim();
    }

    return section;
  }
}

export function extractTitle(text) {
  if (!text) return null;
  // Title candidates are All Capitalized Words followed by either a period, colon, line break or end of string (title-only)
  const firstPhrase = text.match(/^\s*([^.:\n]+)([.:\n]|$|\b|)/);

  if (firstPhrase) {
    const wordsOnly = firstPhrase[1];
    const joinersStripped = wordsOnly.replace(new RegExp(`\\b(${TITLE_JOINERS.join('|')})\\b`, 'ig'), '');
    const capitalizedWords = joinersStripped.match(/\b[A-Z][\w]*\b/g);
    const allWords = joinersStripped.match(/\b[\w]+\b/gi);

    if (capitalizedWords && allWords && capitalizedWords.length == allWords.length) {
      // Two more checks if we do find a Capitalized Phrase:
      // 1) make sure it's not a salutation, e.g., Dear Mr. Evan Schneyer:
      // 2) make sure it's not a date
      let reg = new RegExp(`\\b(${SALUTATIONS.join('|')})\\b`);
      if (reg.exec(firstPhrase) || !isNaN(Date.parse(firstPhrase))) return null;
      return firstPhrase[0];
    }
  }
  return null;
}

// When pasting content from external sources (Google Docs / HTML / Word)
// We basically get a series of independent content blocks
// Since Outlaw's Section model supports both a title and body field,
// See if we can condense them wherever possible for "smart" pasting
export function condenseSections(sections) {
  const condensed = [];
  let previousSection = null;

  forEach(sections, (section) => {
    // If section has both title/body, treat as normal -- nothing to do
    if (section.displayname && section.content) {
      condensed.push(section);
      previousSection = null;
    }
    // If title only, add to condensed but mark as previous section for potential merging
    else if (section.displayname && !section.content) {
      condensed.push(section);
      previousSection = section;
    }
    // If body only, we have a candidate for merging
    else if (!section.displayname && section.content) {
      // If we have a previousSection, it was already added to condensed array, so merge body in!
      if (previousSection) {
        previousSection.content = section.content;
      }
      // If not, just add as normal (eg subsequent body-only sections)
      else {
        condensed.push(section);
        previousSection = null;
      }
    }
    // Note, this leaves the case with no title or body -- skip entirely to avoid creating empty sections
  });

  return condensed;
}

// Find words in <angle brackets> which have no closing tags
// These are interpreted by DraftJS as invalid HTML markup and therefore are invisible
// So we want to escape them instead, to ensure fidelity of pasted/uploaded content
export function escapeTags(text) {
  let openTag;
  // Find opening tags
  const openReg = /<([^/>]*)>/g;
  // Exclude tags like <br> or <br />
  const brReg = /^(br|img|hr)\s?\/?$/;

  // Find all opening html tags
  while ((openTag = openReg.exec(text)) !== null) {
    // Full list: http://xahlee.info/js/html5_non-closing_tag.html (but we only care about a few)
    // If the opening tag is a (w)br, hr or img, ignore
    if (brReg.test(openTag[1])) continue;
    // If there's a valid closing tag that occurs *after* this opening tag, ignore
    if (text.substring(openTag.index + openTag[0].length).indexOf(`</${openTag[1]}>`) > -1) continue;
    // Otherwise, it's an unclosed tag -- escape it
    text = text.replace(openTag[0], `&lt;${openTag[1]}&gt;`);
  }
  return text;
}

// This turns variables into immutable entities so that they can be identified and deleted as a whole on Backspace
// Or, if strip = true, it strips all variable entities for equality comparison or saving
export function tokenizeVariables(contentState, strip = false) {
  let newState = contentState;

  contentState.getBlockMap().forEach((block) => {
    const text = block.getText();
    let match, selection, entityKey;

    while ((match = rxVariable.exec(text)) !== null) {
      const varText = match[0];
      const varName = varText.substring(2, varText.length - 1);
      const varType = varText[1];

      selection = SelectionState.createEmpty(block.getKey()).merge({
        anchorOffset: match.index,
        focusOffset: match.index + match[0].length,
      });

      if (strip) {
        newState = Modifier.applyEntity(newState, selection, null);
      } else {
        newState = newState.createEntity(ENTITY_TYPE.VARIABLE, 'IMMUTABLE', { name: varName, varText, varType });
        entityKey = newState.getLastCreatedEntityKey();
        newState = Modifier.applyEntity(newState, selection, entityKey);
      }
    }
  });
  return newState;
}

export function containsEntity(contentState, selectionState, entityTypes = []) {
  let start = selectionState.getStartOffset();
  let end = selectionState.getEndOffset();
  const block = contentState.getBlockForKey(selectionState.getStartKey());

  // This function is mainly used to see whether user can delete text in an editor, and disallow variable deletion
  // If the selection is collapsed, we actually need to look at the previous character
  // So if the cursor is at the end of a variable, don't allow backspace
  if (selectionState.isCollapsed()) {
    start = Math.max(0, start - 1);
  }

  let entityKey = null,
    entity = null;
  for (let i = start; i < end; i++) {
    entityKey = block.getEntityAt(i);
    if (entityKey) {
      entity = contentState.getEntity(entityKey);
      if (!entityTypes.length || entityTypes.includes(entity.type)) {
        return true;
      }
    }
  }

  return false;
}

// This is an annoying special case because redlining SHOULD still be allowed if EITHER:
// 1) There's an entity at cursor but not before (i.e., cursor is at beggining of a variable), OR
// 2) There no entity at cursoer but there is one before (i.e., cursor at end of a variable)
// ... but not BOTH
export function isInsideVariable(contentState, selectionState) {
  const block = contentState.getBlockForKey(selectionState.getStartKey());
  let cursor = selectionState.getStartOffset();

  if (cursor === 0) return false;

  const keyAtCursor = block.getEntityAt(cursor);
  const entityAtCursor = keyAtCursor ? contentState.getEntity(keyAtCursor) : null;
  const keyBack1 = block.getEntityAt(cursor - 1);
  const entityBack1 = keyBack1 ? contentState.getEntity(keyBack1) : null;

  // If there's no entity on either one, we're good
  if (!entityAtCursor && !entityBack1) return false;
  // If there are both, we're in the middle of a variable
  if (get(entityAtCursor, 'type') === ENTITY_TYPE.VARIABLE && get(entityBack1, 'type') === ENTITY_TYPE.VARIABLE)
    return true;
  // Here we've got either one or the other so we're ok
  return false;
}

// DraftJS doesn't actually make it easy to know whether an editor is "dirty" or not (i.e., has content changes)
// So we can use convertToRaw and then do a deep object comparison
// This way we can accurately detect no changes if (for example) a user adds and then deletes the same text
// Note, for comparison we need to first strip the variable entities so that they don't appear as differences
export function isEqual(cs1, cs2) {
  let raw1 = null,
    raw2 = null;
  if (cs1) {
    raw1 = tokenizeVariables(cs1, true);
    raw1 = convertToRaw(raw1);
  }
  if (cs2) {
    raw2 = tokenizeVariables(cs2, true);
    raw2 = convertToRaw(raw2);
  }
  return _isEqual(raw1, raw2);
}

// Multiple consecutive spaces in html are collapsed to one; replace with unicode non-breaking space character
export function keepSpaces(s) {
  return s.replace(/ {2}/g, '\u00a0'.repeat(2));
}

// This is a DraftJS helper function related to interactions involving VariableSuggest
// It was originally inside SectionEditor but has been ported to here for easier reuse;
// We may want to actually refactor VariableSuggest further to encapsulate this check,
// but doing so would make VariableSuggest fully dependent on DraftJS, so... TBD
//
// The mechanics of this are somewhat counterintuitive,
// because we think of components like VariableSuggest as responding directly to keyboard events (onKeyDown etc)
// But that would create bugs, e.g., typing '[' to open, then moving cursor away, then back again -- how do you *reopen* it???
// So instead, we *always* show it based on a combination of text and cursor state (i.e., ContentState and SelectionState in DraftJS lingo)
// The result is still that it *appears* to respond directly to keyboard events but is more stable,
// i.e., it shows up anytime the cursor is *anywhere within* an existing incomplete text fragment ranging from '[' to '[#varia' etc
export function findVariableSuggestTarget({ editorState, refTargets, rx }) {
  const cs = editorState.getCurrentContent();
  const sel = editorState.getSelection();
  const block = cs.getBlockForKey(sel.getStartKey());
  const text = block.getText();
  const cursor = sel.getStartOffset();

  let vsTarget = null,
    match = null;

  while ((match = rx.exec(text)) !== null) {
    if (cursor >= match.index && match.index + match[0].length >= cursor) {
      vsTarget = {
        block,
        start: match.index,
        input: match[0],
      };
    }
  }

  if (vsTarget) {
    const refKey = `${block.getKey()}|${vsTarget.start}`;
    const el = get(refTargets[refKey], 'current');
    if (el && vsTarget) vsTarget.target = el;
  }

  return vsTarget;
}

export function findVariableSuggestText({ element, rx }) {
  const text = element.value;
  const cursor = getCursor(element);

  let vsTarget = null,
    match = null;

  while ((match = rx.exec(text)) !== null) {
    if (cursor >= match.index && match.index + match[0].length >= cursor) {
      vsTarget = {
        start: match.index,
        input: match[0],
      };
    }
  }

  return vsTarget;
}
