import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Button, Ellipsis } from '@components/dmp';

const PDFHeader = ({ title, pageCount, onRename, canCommit, onCommit, editable }) => {
  return (
    <div className="pdf-header" data-cy="pdf-header">
      <Ellipsis className={cx('pdf-name', { editable })} onClick={editable ? onRename : _.noop}>
        {title}
      </Ellipsis>
      <div className="page-count">{pageCount > 0 ? `${pageCount} page${pageCount > 1 ? 's' : ''}` : <>&nbsp;</>}</div>
      {canCommit && (
        <div className="action-wrapper">
          <Button className="action" dmpStyle="primary" onClick={onCommit}>
            Save New Version
          </Button>
        </div>
      )}
    </div>
  );
};

PDFHeader.propTypes = {
  title: PropTypes.string.isRequired,
  pageCount: PropTypes.number.isRequired,
  editable: PropTypes.bool.isRequired,
  onRename: PropTypes.func.isRequired,
  canCommit: PropTypes.bool.isRequired,
  onCommit: PropTypes.func.isRequired,
};

export default PDFHeader;
