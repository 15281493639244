import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { Loader } from '@components/dmp';

import { Icon } from '@components/dmp';

// This component is shared across client and server to ensure a seamless end-user app bootstrapping experience
// The process is as follows:
// 1. server renders empty page with this preloader statically rendered, so user sees it immediately
// 2. browser loads app files (style, outlaw.js bundle etc) -- no visible change
// 3. react app bootstraps, but auth has not yet been checked (see render() in app.js)
// 4. while auth is checking (and/or new user registration), react app still renders this same component!

const Preloader = ({ animate, hideLogo }) => {
  return (
    <div id="preloader" className={cx({ 'hide-logo': hideLogo })}>
      {!hideLogo && (
        <Icon name="outlineLogo" className={cx('preloader-logo', { animate })} style={{ width: 120, height: 'auto' }} />
      )}
      <Loader centered size="large" />
    </div>
  );
};

Preloader.defaultProps = {
  animate: false,
  hideLogo: false,
};

Preloader.propTypes = {
  animate: PropTypes.bool,
  hideLogo: PropTypes.bool,
};

export default React.memo(Preloader);
