import { map } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import SectionType from '../enums/SectionType';

// This is a dynamically generated  class which represents a columns "container" for Sections
// There is no actual underlying data for these SectionColumns objects;
// They are generated in Deal.js constructor based on contiguous Sections that have Section.style.column === true
// The added getters on this class are essentially to support an implicit Interface;
// they are required for rendering so that SectionColumns can be treated as Sections in certain scenarios

export default class SectionColumns {
  deal = null;
  sectiontype = SectionType.COLUMNS;
  sections = [];
  pageBreak = false;

  constructor(deal) {
    this.deal = deal;
  }

  get id() {
    const joinedId = map(this.sections, 'id').join('|');
    if (!joinedId) {
      return uuidv4();
    }
    return joinedId;
  }

  get passesConditions() {
    return true;
  }
}
