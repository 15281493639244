const WEBHOOKS = {
        dealStatus: {
            key: 'dealStatus',
            name: 'Deal status',
            subtypes: [
                {key: 'create', name: 'Create', description: 'Trigger every time a Contract is created.'},
                {key: 'delete', name: 'Delete', description: 'Trigger every time a Contract is deleted.'},
                {key: 'done', name: 'Done', description: 'Trigger when a Contract is completed.'},
                {key: 'statusChange', name: 'Status change', description: 'Trigger every time the status of a contract change.'}]
        }
    }
;
export default WEBHOOKS;
