import React, { Component, Fragment, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import { Modifier, SelectionState } from 'draft-js';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Modal, Overlay, Popover } from 'react-bootstrap';

import { ENTITY_TYPE } from '@core/models/Content';
import Diff from '@core/models/Diff';
import Section from '@core/models/Section';
import Version from '@core/models/Version';
import { Dt, dt } from '@core/utils/Environment';
import { getUniqueKey } from '@core/utils/Generators';

import { Button, Loader } from '@components/dmp';

import { SectionActions } from '@components/section_types/SectionMenu';

@autoBindMethods
export default class DiffView extends Component {
  static propTypes = {
    // https://github.com/facebook/draft-js/blob/master/src/model/decorators/DraftDecorator.js
    // These give us ability to correctly identify the text region in the ContentState that's being wrapped in a DiffView
    decoratedText: PropTypes.string.isRequired,
    blockKey: PropTypes.string.isRequired,
    start: PropTypes.number.isRequired,
    end: PropTypes.number.isRequired,
    entityKey: PropTypes.string,

    // Container for the Popover
    container: PropTypes.object,
    // Nested content to be rendered in <span>
    children: PropTypes.node.isRequired,

    // Underlying Section being edited
    section: PropTypes.instanceOf(Section).isRequired,
    // callback when user responds (Approve / Reject)
    onReview: PropTypes.func.isRequired,
    // Not every DiffView gets re-rendered when ContentState changes, if the edits (or DiffView reviews) were in a different ContentBlock
    // So this gives us a way to retrieve the true *current* ContentState when applying a review()
    getEditorState: PropTypes.func.isRequired,
    // (Previous) Section.version that the current diffs will be referencing when saved
    version: PropTypes.instanceOf(Version),
    // Still colorize but make inactive for case of stale edits in ContentSection EditorState
    disabled: PropTypes.bool,
    resetEditorState: PropTypes.func.isRequired,
    handleFocusChange: PropTypes.func.isRequired,
    approvingSection: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      reviewing: false,
      version: props.version,
      reviewingAll: false,
      reviewingSection: false,
      loading: false,
    };

    this.diffRef = createRef();
    this.id = getUniqueKey();
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps({ version }) {
    if (version.id != this.props.version.id) {
      this.setState({ version });
    }
  }

  get diff() {
    const { entityKey, getEditorState, section } = this.props;
    if (!entityKey || !getEditorState()) return null;
    const cs = getEditorState().getCurrentContent();
    return Diff.get(section, cs, entityKey);
  }

  async review(action) {
    const { section, onReview, start, end, blockKey, getEditorState } = this.props;
    const du = section.deal.currentDealUser;
    const type = this.diff.type;
    let cs = getEditorState().getCurrentContent();
    let block = cs.getBlockForKey(blockKey);
    let newDiff, newType, history, currentIndex;

    const diff = this.diff;

    // Get selection in the editor representing the diff being reviewed
    const selection = SelectionState.createEmpty(block.getKey()).merge({ anchorOffset: start, focusOffset: end });

    switch (action) {
      case SectionActions.UNDO:
        // If user had removed text, remove the entity (text is already still there so just remove the redline)
        if (type === ENTITY_TYPE.DIFF_REMOVED) {
          cs = Modifier.applyEntity(cs, selection, null);
        }
        // If user had added text, just remove it (which removes the entity)
        else {
          cs = Modifier.removeRange(cs, selection, 'forward');
        }
        break;
      case SectionActions.APPROVE:
        // Approving an addition means remove the entity
        if (type === ENTITY_TYPE.DIFF_ADDED) {
          cs = Modifier.applyEntity(cs, selection, null);
        }
        // Approving a rejection means removing the text (and with it, the entity)
        else {
          cs = Modifier.removeRange(cs, selection, 'forward');
        }
        break;

      case SectionActions.APPROVE_SECTION:
        this.setState({ reviewingSection: true, reviewingAll: false });
        break;

      case SectionActions.APPROVE_ALL:
        this.setState({ reviewingSection: false, reviewingAll: true });
        break;

      // IMPORTANT: Outlaw has a deliberately different UI around rejecting diffs,
      // because in a contract scenario, rejecting someone else's changes means you are still not in agreement
      // so it effectively means we want to flip the previous diff to a new one of the opposite type,
      // but still keep it intact as a diff instead of resolving
      case SectionActions.REJECT:
        newType = type === ENTITY_TYPE.DIFF_ADDED ? ENTITY_TYPE.DIFF_REMOVED : ENTITY_TYPE.DIFF_ADDED;
        // Either way, we need to first capture a reference to the *prior* diff's id... which is the one passed in props
        newDiff = Diff.create(section, {
          contentState: cs,
          user: du.uid,
          type: newType,
          priorDiff: diff.id,
        });
        cs = Modifier.applyEntity(cs, selection, newDiff.entityKey);

        break;
      case SectionActions.REVERT:
        history = diff.history;
        currentIndex = history.indexOf(diff);
        if (currentIndex > 0) {
          let prevDiff = history[currentIndex - 1];
          prevDiff = Diff.create(section, { contentState: cs, ...prevDiff.entityData });
          cs = Modifier.applyEntity(cs, selection, prevDiff.entityKey);
        }
        break;
    }

    // ContentState will have been modified in some way above; pass editorState back to parent (ContentSection) and close the popover
    onReview(cs);

    if (this._isMounted) this.setState({ reviewing: false });
  }

  async confirmReviewAll(action) {
    const { section, resetEditorState, handleFocusChange, approvingSection } = this.props;

    switch (action) {
      case SectionActions.APPROVE_SECTION:
        approvingSection(true);
        this.setState({ loading: true });
        await API.call('approveAllRedlines', { dealID: section.deal.dealID, sectionID: section.id });
        this.setState({ loading: false, reviewingSection: false });
        approvingSection(false);
        resetEditorState();
        handleFocusChange(section.id, false);
        break;
      case SectionActions.APPROVE_ALL:
        this.setState({ loading: true });
        await API.call('approveAllRedlines', { dealID: section.deal.dealID });
        this.setState({ reviewingAll: false, loading: false });
        resetEditorState();
        handleFocusChange(section.id, false);
        break;
    }
  }

  hide() {
    const { loading } = this.state;
    const reviewing = !loading;
    this.setState({ reviewing, reviewingAll: false });
  }

  renderHistoryItem(diff, idx) {
    const { displayAction, displayName, displayDate } = diff;

    // If the last Diff is pending and can be reverted, we don't need to show it
    // because a separate message will show instead
    if (diff.isPending && diff.canRevert) return null;

    return (
      <div className="diff-step" key={idx}>
        <div className="name-action">
          <span className="name">{displayName}</span> {displayAction} this text
        </div>
        <div className="date">{displayDate}</div>
      </div>
    );
  }

  renderApproveAllOptions() {
    return (
      <div className="diff-all-review" data-cy="diff-review-all">
        <div className="diff-review-approve-section">
          <Button
            bsClass="redline-diff"
            dmpStyle="link"
            className="approve-section"
            onClick={() => this.review(SectionActions.APPROVE_SECTION)}
            data-cy="approve-section"
          >
            Approve entire section
          </Button>
        </div>

        <div className="diff-review-approve-all">
          <Button
            bsClass="redline-diff"
            dmpStyle="link"
            className="approve-all"
            onClick={() => this.review(SectionActions.APPROVE_ALL)}
            data-cy="approve-all"
          >
            Approve entire {dt}
          </Button>
        </div>
      </div>
    );
  }

  renderApproveAllModal() {
    const { reviewingAll, loading } = this.state;

    return (
      <Modal
        className="modal-confirm-approve-all"
        backdropClassName="backdrop"
        show={reviewingAll}
        onHide={this.hide}
        data-cy="modal-confirm-approve-all"
      >
        <Modal.Header closeButton>
          <span className="headline">Confirm approval</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <p className="bold">Are you sure you want to approve all redlines in this {dt}?</p>
            <p>This action cannot be undone.</p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={this.hide} disabled={loading} data-cy="btn-cancel-text">
            Go Back
          </Button>

          <Button
            className="btn-confirm-approve-all"
            dmpStyle={loading ? 'default' : 'primary'}
            onClick={() => this.confirmReviewAll(SectionActions.APPROVE_ALL)}
            disabled={loading}
            loading={loading}
            data-cy="btn-confirm-text"
          >
            Approve Entire {Dt}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { children, container, disabled } = this.props;
    const { reviewing, reviewingAll, reviewingSection, loading } = this.state;
    const diff = this.diff;
    if (!diff) return null;

    const { isPending, canRevert, canUndo, canApprove, pendingAction } = diff;

    const className = cx(
      diff.type,
      'diff',
      { 'can-review': !disabled },
      { 'can-revert': canRevert },
      { 'can-undo': canUndo },
      { pending: isPending }
    );

    // Rendering the Popover inside the <span> adjacent to {children} was causing some very strange orphaning issues,
    // where the Popover wouldn't hide and then would instead target a different DiffView (!!)
    // Using a fragment fixes this, so that the Popover is no longer a sibling of {children}
    return (
      <>
        <span
          className={className}
          ref={this.diffRef}
          onClick={() => (disabled ? null : this.setState({ reviewing: true }))}
        >
          {children}
        </span>
        {reviewing && (
          <Overlay
            show={reviewing && !disabled}
            onHide={() => this.setState({ reviewing: false })}
            target={this.diffRef.current}
            container={container}
            placement="top"
            rootClose
          >
            <Popover className="pop-diff pop-redlines" id={`pop-diff-${this.id}`}>
              <div className="diff-info">
                <div className="diff-history">{diff.history.map(this.renderHistoryItem)}</div>
                {canRevert && isPending && (
                  <div className="pending">Your {pendingAction} will be saved when you click 'Save'</div>
                )}
              </div>
              {!canUndo && !canRevert && (
                <div className="diff-review" data-cy="diff-review">
                  <div className="diff-border">
                    <div className="diff-review-action">
                      <Button
                        dmpStyle="link-reject"
                        className="reject"
                        onClick={() => this.review(SectionActions.REJECT)}
                        data-cy="reject-diff"
                      >
                        Reject
                      </Button>
                      <Button
                        dmpStyle="link-success"
                        className="approve"
                        onClick={() => this.review(SectionActions.APPROVE)}
                        data-cy="approve-diff"
                      >
                        Approve
                      </Button>
                    </div>
                  </div>
                  {this.renderApproveAllOptions()}
                </div>
              )}
              {canUndo && (
                <>
                  <div className="diff-review" data-cy="diff-review">
                    <div className="diff-border">
                      <div className="diff-review-action">
                        <Button
                          dmpStyle="link-reject"
                          className="reject"
                          onClick={() => this.review(SectionActions.UNDO)}
                          data-cy="undo-diff"
                        >
                          Undo
                        </Button>
                        {canApprove && (
                          <Button
                            dmpStyle="link-success"
                            className="approve"
                            onClick={() => this.review(SectionActions.APPROVE)}
                            data-cy="approve-diff"
                          >
                            Approve
                          </Button>
                        )}
                      </div>
                    </div>
                    {this.renderApproveAllOptions()}
                  </div>
                </>
              )}
              {canRevert && (
                <div className="diff-review" data-cy="diff-review">
                  <Button
                    bsClass="redline-diff"
                    dmpStyle="link"
                    className="reject"
                    onClick={() => this.review(SectionActions.REVERT)}
                    data-cy="cancel-redline"
                  >
                    Cancel {pendingAction}
                  </Button>
                </div>
              )}
            </Popover>
          </Overlay>
        )}

        {reviewingSection && (
          <Overlay
            show={reviewingSection || reviewingAll}
            onHide={() => this.setState({ reviewingAll: false, reviewingSection: false })}
            target={this.diffRef.current}
            container={container}
            placement="top"
            rootClose={!(reviewingSection && loading)}
          >
            <Popover className="pop-diff" id={`pop-diff-${this.id}`}>
              <div className={`diff-info confirm-diff-info ${loading ? 'disabled' : ''}`}>
                <div className="diff-history">Are you sure you want to approve all redlines in this section?</div>
              </div>
              {reviewingSection && (
                <div className={`diff-review confirm-diff ${loading ? 'disabled' : ''}`} data-cy="diff-review">
                  <div className="confirm-review-approve-section">
                    <Button
                      disabled={loading}
                      bsClass="redline-diff"
                      dmpStyle="link"
                      className={`go-back-diff ${loading ? 'disabled' : ''}`}
                      onClick={() => this.setState({ reviewingSection: false, reviewing: true })}
                      data-cy="go-back-diff"
                    >
                      Go back
                    </Button>
                    {loading && <Loader inline />}
                    <Button
                      disabled={loading}
                      bsClass="redline-diff"
                      dmpStyle="link"
                      className={`approve-section ${loading ? 'disabled' : ''}`}
                      onClick={() => this.confirmReviewAll(SectionActions.APPROVE_SECTION)}
                      data-cy="confirm-approve-section"
                    >
                      {loading ? 'Approving section' : 'Approve entire section'}
                    </Button>
                  </div>
                </div>
              )}
            </Popover>
          </Overlay>
        )}

        {reviewingAll && this.renderApproveAllModal()}
      </>
    );
  }
}
