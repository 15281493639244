import React, { Component } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

import { Button, Icon, Loader, Switch } from '@components/dmp';

import API from '@root/ApiClient';
import Fire from '@root/Fire';
import trackEvent from '@utils/EventTracking';

export default class DocRequestModal extends Component {
  static defaultProps = {
    show: false,
  };

  static propTypes = {
    deal: PropTypes.object.isRequired,
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      requesting: false,
      error: null,
      testMode: false,
    };
  }

  async save() {
    const { deal, user } = this.props;
    const { testMode } = this.state;

    const users = _.map(deal.users, 'uid');

    const currentIndex = deal.currentWorkflowStep.index;
    const nextStep = deal.workflow.steps[currentIndex + 1];
    this.setState({ requesting: true, error: null });
    try {
      if (testMode) {
        await Fire.updateDealInfo(deal.info, { name: `Test - ${deal.info.name}` });
      }
      await API.call('updateDealStatus', { dealID: deal.dealID, step: nextStep.key });
      await API.call('notifyProviders', {
        users,
        teamID: deal.team,
        dealName: deal.name,
        dealID: deal.dealID,
      });

      const isProviderWF = _.get(deal, 'workflow.serviceProviders', false),
        partner = _.get(deal, 'workflow.serviceProviderName', null);

      if (isProviderWF && partner) {
        const eventData = {
          serviceType: 'Demand', //should eventually be enumerated somewhere for additional types
          docID: deal.dealID,
          step: nextStep.key,
          user: user.email,
          teamID: deal.team,
          partner,
        };
        await trackEvent(testMode ? 'ProviderRequestTest' : 'ProviderRequest', eventData);
      }

      this.setState({ requesting: false });
      this.onHide();
    } catch (err) {
      //track permission errors
      this.setState({ error: err.message, requesting: false });
    }
  }

  onHide() {
    const { onHide } = this.props;
    this.setState({ error: false, testMode: false });
    onHide();
  }

  render() {
    const { requesting, error, testMode } = this.state;
    const { deal, show, onHide } = this.props;
    const { workflow } = deal;

    return (
      <Modal dialogClassName="deal-settings" show={show} onHide={() => this.onHide()}>
        <Modal.Header closeButton>
          <span className="headline">Prepare {deal.info.name}?</span>
        </Modal.Header>

        <Modal.Body>
          <div className="wrapper">
            <div className="request-container">
              <div className="request-left">
                {testMode && !requesting ? (
                  <div className="request-deal-title">{`Test - ${deal.info.name}`}</div>
                ) : (
                  <div className="request-deal-title">{deal.info.name}</div>
                )}
                {!testMode && (
                  <div className="request-deal-service-provider">Prepared by {workflow.serviceProviderName}</div>
                )}
              </div>
              <div className="request-right">
                <Icon name="vine" className="vine-icon" size="large" />
              </div>
            </div>
            {!testMode && (
              <div className="request-container-subtext">
                You'll recieve a notification when the {deal.info.name} is ready for review.
                <div className="request-estimated-time">Estimated time: 24-36 hours</div>
              </div>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          {error && <span className="request-error">{error}</span>}
          {requesting && <Loader className="request-loader" />}
          {!requesting && (
            <div>
              <Switch
                checked={testMode}
                onChange={() =>
                  this.setState((prevState) => {
                    return {
                      testMode: !prevState.testMode,
                    };
                  })
                }
                className="float-left"
                size="small"
              >
                Test mode (Service Provider only)
              </Switch>
              <Button size="small" onClick={() => this.save()} data-cy="btn-save" dmpStyle="filevine">
                {testMode ? `Send test` : `Send to Service Provider`}
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}
