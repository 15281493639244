import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { FormControl, FormGroup, Overlay, Popover } from 'react-bootstrap';

import { Button, Dropdown, Form, Icon, MenuItem } from '@components/dmp';

import CONFIG from '@root/Config';

const { DEFAULT_CONNECTIONS } = CONFIG;

const DEFAULT_CONNECTION_TYPES = DEFAULT_CONNECTIONS || ['salesforce', 'filevine'];

@autoBindMethods
export default class ConnectionFilter extends Component {
  static propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    filter: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      id: '',
      type: null,
      filter: null,
    };

    this.anchor = React.createRef();
  }

  componentDidMount() {
    const { id, type, filter } = this.props;

    this.setState({
      id: id || '',
      type: type || null,
      filter: filter || null,
    });
  }

  componentDidUpdate(prevProps) {
    const { id, type, filter } = this.props;

    if (prevProps.id !== id) this.setState({ id: id || '' });
    if (prevProps.type !== type) this.setState({ type: type || null });
    if (prevProps.filter !== filter) this.setState({ filter: filter || null });
  }

  get canSubmit() {
    return this.state.type && this.state.id;
  }

  configureFilter() {
    this.setState({ showPopup: true });
  }

  saveFilter() {
    const { onChange } = this.props;
    const { id, type } = this.state;

    onChange({ id, type });

    this.setState({ showPopup: false });
  }

  clearFilter() {
    const { onChange, type } = this.props;
    this.setState({
      showPopup: false,
      id: '',
      type: null,
    });

    onChange({ id: null, type });
  }

  render() {
    const { filter } = this.props;
    const { showPopup } = this.state;

    const label = filter ? filter.type : 'Connections';

    return (
      <div className="variable-filter" ref={this.anchor}>
        <div className={cx('filter-name', { active: !!filter })} onClick={this.configureFilter}>
          <div>{label}</div>
          <Icon name="filterExpand" />
        </div>

        {filter && (
          <div className="existing" onClick={this.configureFilter}>
            <div className="filter-display" data-cy="filter-display">
              {filter.id}
            </div>
          </div>
        )}

        {showPopup && this.renderConfig()}
      </div>
    );
  }

  renderConfig() {
    const { filter } = this.props;
    const { id, type } = this.state;

    return (
      <Overlay
        show={true}
        onHide={() => this.setState({ showPopup: false })}
        target={this.anchor.current}
        placement="right"
        rootClose
      >
        <Popover className="connection-filter popover-deal-connection-filter" id={`pop-connection-filter-${type}`}>
          <Form className="configuring">
            <FormGroup>
              <Dropdown
                block
                disabled={!!filter}
                id={`dd-operators-${type}`}
                onSelect={(selectedType) => this.setState({ type: selectedType })}
                size="small"
                title={type || 'select one'}
              >
                {DEFAULT_CONNECTION_TYPES.map((connectionType) => (
                  <MenuItem key={connectionType} eventKey={connectionType}>
                    {_.capitalize(connectionType)}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
            <FormGroup>
              <FormControl
                type="text"
                bsSize="small"
                value={id}
                placeholder={'Enter ID'}
                onChange={(e) => this.setState({ id: e.target.value })}
                data-cy="connection-id-input"
              />
            </FormGroup>
            <div className="actions">
              <Button size="small" dmpStyle="link" onClick={this.clearFilter} data-cy="btn-clear-connection-filter">
                Clear
              </Button>
              <Button
                data-cy="btn-apply-connection-filter"
                disabled={!this.canSubmit}
                dmpStyle="primary"
                onClick={this.saveFilter}
                size="small"
              >
                Apply
              </Button>
            </div>
          </Form>
        </Popover>
      </Overlay>
    );
  }
}
