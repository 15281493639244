import React, { Component } from 'react';

import _ from 'lodash';

import SectionType from '@core/enums/SectionType';

export default class PageBreak extends Component {
  render() {
    const { section } = this.props;
    const { deal } = section;
    let { id } = section;

    if (section.sectiontype === SectionType.ITEM && section.parent.isRepeater) {
      id = section.parent.id;
    }

    const config = _.find(deal.pageBreakConfig, { id });

    let content = config ? _.upperFirst(config.displayName) : 'Page break';

    if (section.sectiontype === SectionType.ITEM && section.parent.isRepeater) {
      content = `Page break [${section.parent?.name || 'Repeater'} - Item ${section.order + 1}]`;
    } else if (section.sectiontype === SectionType.LIST && section.isRepeater) {
      content = `Page break [${section?.name || 'Repeater'}]`;
    }

    return (
      <div className="page-break" data-cy="page-break" style={deal.style.layout.PageMargin.pageBreakMargin}>
        <span className="page-break-name">{content}</span>
      </div>
    );
  }
}
