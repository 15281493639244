import React, { Component } from 'react';

import autobindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, FormGroup } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import Lens, { CLAUSE_LENS_PROMPTS } from '@core/models/Lens';

import { Alert, Button, ButtonIcon, Dropdown, Icon, MenuItem, Validator } from '@components/dmp';

import Fire from '@root/Fire';

@autobindMethods
class LensClauseEditor extends Component {
  static defaultProps = {};

  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    lens: PropTypes.instanceOf(Lens),
    onHide: PropTypes.func.isRequired,
    clauseFilter: PropTypes.object,
  };

  static defaultProps = {
    clauseFilter: null,
    lens: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      relatedSections: [],
      clauseFilter: {},
      title: '',
      deleting: false,
    };
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  componentDidUpdate(prevProps) {
    if (!this._isMounted) return;
    if (prevProps.lens !== this.props.lens || prevProps.newType !== this.props.newType) {
      this.populate(this.props);
    }
  }

  populate(props) {
    const { lens, clauseFilter } = props;
    const { title } = this.state;

    this.setState({
      relatedSections: lens.relatedSections,
      clauseFilter: clauseFilter ? clauseFilter : { id: null, riskValue: 0 },
      title: lens.title || title,
    });
  }

  validateRiskValue(riskValue) {
    return !isNaN(parseInt(riskValue));
  }

  async cancel(e) {
    const { lens, deal, onHide } = this.props;
    if (e) e.stopPropagation();
    if (lens.isCreating) await Fire.saveLens(deal.dealID, lens, true);
    onHide(false);
  }

  async save() {
    const { deal, lens, onHide } = this.props;
    const { relatedSections, clauseFilter, title } = this.state;

    if (this.editingClauseFilter) {
      lens.clauseFilters[clauseFilter.id] = clauseFilter;
      if (this.props.clauseFilter.id !== clauseFilter.id) lens.clauseFilters[this.props.clauseFilter.id] = null;
    }
    if (this.addingClauseFilter && !!clauseFilter.id) lens.clauseFilters[clauseFilter.id] = clauseFilter;
    lens.relatedSections = relatedSections;
    lens.title = title;

    await Fire.saveLens(deal.dealID, lens);
    const persistEditing = _.size(relatedSections) > 0 && title.length >= 3 && !clauseFilter.id;
    onHide(persistEditing);
  }

  async delete() {
    const { deal, lens, onHide } = this.props;
    const { clauseFilter } = this.state;

    delete lens.clauseFilters[clauseFilter.id];
    await Fire.saveLens(deal.dealID, lens);
    onHide(false);
  }

  renderDeletionAlert() {
    const alertText = 'Are you sure you want to delete this target range?';

    return (
      <Alert dmpStyle="danger" size="small">
        {alertText}
        <div className="alert-actions">
          <a className="cancel" onClick={() => this.setState({ deleting: false })}>
            Cancel
          </a>
          <a onClick={this.delete}>Delete</a>
        </div>
      </Alert>
    );
  }

  renderLinkingAlert() {
    const { deal } = this.props;
    const { relatedSections } = this.state;

    const section = _.find(deal.sections, { id: relatedSections[0] });

    const alertText = 'Clause extraction must be enabled for the linked section. Would you like to enable it?';

    return (
      <Alert dmpStyle="danger" size="small">
        {alertText}
        <div className="alert-actions">
          <a onClick={() => Fire.saveSection(section, { extract: !section.extract })}>Enable extraction</a>
        </div>
      </Alert>
    );
  }

  renderPromptOptions() {
    const { lens } = this.props;
    const { clauseFilter } = this.state;

    return (
      <div className="lens-property-block">
        <div className="lens-editior-title">
          <Icon name={!this.editingClauseFilter ? 'plus2' : 'edit'} className="condition-icon" />
          {!this.editingClauseFilter ? 'Add scoring factor' : 'Edit scoring factor'}
          {this.editingClauseFilter && (
            <ButtonIcon
              icon="trash"
              className="delete-target-range"
              size="default"
              onClick={() => this.setState({ deleting: true })}
            />
          )}
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Add Target Range</div>

          <div className="variable-options">
            <Dropdown
              id={`dd-clause-check`}
              title={clauseFilter?.id ? _.find(CLAUSE_LENS_PROMPTS, { id: clauseFilter.id }).title : 'Select one'}
              size="small"
              onSelect={(id) => this.setState({ clauseFilter: { id, riskValue: clauseFilter.riskValue } })}
              block
            >
              {CLAUSE_LENS_PROMPTS.map((option) => (
                <MenuItem
                  key={option.id}
                  eventKey={option.id}
                  disabled={!!_.find(lens.clauseFilters, { id: option.id })}
                >
                  {option.title}
                </MenuItem>
              ))}
            </Dropdown>
          </div>
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Add Risk Score</div>

          <div className="variable-options" data-cy="variable-options">
            <FormGroup className="risk-value dmp-validator-container">
              <FormControl
                bsSize="small"
                value={clauseFilter.riskValue}
                placeholder="Enter an integer"
                onChange={(e) => this.setState({ clauseFilter: { id: clauseFilter.id, riskValue: e.target.value } })}
                data-cy="lens-risk-score"
              />
              <Validator
                validateEmpty
                value={clauseFilter.riskValue}
                validate={this.validateRiskValue}
                onResult={_.noop}
                validTip="Valid risk value"
                invalidTip="Must be a valid integer"
              />
            </FormGroup>
          </div>
        </div>
        {this.renderActions()}
      </div>
    );
  }

  renderTitle() {
    const { relatedSections, title } = this.state;
    const { deal, lens } = this.props;

    const sortedRelatedSections = _.without(
      _.map(deal.sections, (section) => _.find(relatedSections, (sec) => sec === section.id)),
      undefined
    );

    const linkedSections = _.map(sortedRelatedSections, (id) =>
      _.get(deal, `sections[${id}].displayTOC`, '(untitled)')
    );

    return (
      <div className="lens-property-block">
        <div className="lens-editior-title">
          <Icon name={lens.isCreating ? 'plus2' : 'edit'} className="condition-icon" />
          {lens.isCreating ? 'Clause lens setup' : 'Edit clause lens config'}
        </div>
        <div className="lens-property">
          <div className="lens-property-label">Lens title</div>
          <FormControl
            type="text"
            bsSize="small"
            value={title}
            placeholder="Enter Title"
            onChange={(e) => this.setState({ title: e.target.value })}
          />
        </div>
        <div className="lens-property linking-sections">
          <div className="lens-property-label">Linked section</div>

          <div className="variable-options" data-cy="variable-options"></div>
          <FormGroup>
            <FormControl
              bsSize="small"
              componentClass="textarea"
              disabled
              value={linkedSections.join('\n')}
              placeholder="Select sections from left-hand side to associate with this Lens"
            />
          </FormGroup>
        </div>
        {this.renderActions()}
      </div>
    );
  }

  renderActions() {
    return (
      <div className="actions">
        <Button className="cancel" dmpStyle="link" size="small" onClick={this.cancel} data-cy="btn-cancel-var">
          Cancel
        </Button>

        <Button className="save" size="small" disabled={this.canSave} onClick={this.save} data-cy={'btn-save-lens'}>
          Save
        </Button>
      </div>
    );
  }

  get canSave() {
    const { lens, editingConfig, deal } = this.props;
    const { relatedSections, title, clauseFilter } = this.state;

    if (lens.isCreating || editingConfig) {
      let isSetForExtraction = true;
      if (_.size(relatedSections) === 1) {
        //check if the section is set for extraction...
        const section = _.find(deal.sections, { id: relatedSections[0] });
        isSetForExtraction = section.extract;
      } else {
        isSetForExtraction = false;
      }

      return !isSetForExtraction || title.length < 3;
    } else {
      return !clauseFilter.id;
    }
  }

  get linkingAlert() {
    const { lens, editingConfig, deal } = this.props;
    const { relatedSections } = this.state;

    if (lens.isCreating || editingConfig) {
      let isSetForExtraction = true;
      if (_.size(relatedSections) === 1) {
        //check if the section is set for extraction...
        const section = _.find(deal.sections, { id: relatedSections[0] });
        isSetForExtraction = section.extract;
      }

      return !isSetForExtraction;
    }
  }

  get editingClauseFilter() {
    return !!this.props.clauseFilter;
  }

  get addingClauseFilter() {
    return !this.props.clauseFilter;
  }

  render() {
    const { deleting } = this.state;
    const { lens, editingConfig } = this.props;
    const { isCreating } = lens;

    const editConfig = isCreating || editingConfig;

    return (
      <div
        key={lens.id}
        className={cx('edit-lens-container', {
          inline: !editConfig,
          newRange: !editConfig && !this.props.clauseFilter,
        })}
      >
        {editConfig && (
          <div className={cx('lens-editing-title', { error: this.linkingAlert })}>
            <Icon name={lens.title ? 'clause' : 'lens'} />
            {lens.title || 'Add new lens'}
          </div>
        )}
        {this.linkingAlert && this.renderLinkingAlert()}
        {deleting && this.renderDeletionAlert()}
        {editConfig && this.renderTitle()}
        {!editConfig && this.renderPromptOptions()}
      </div>
    );
  }
}

export default LensClauseEditor;
