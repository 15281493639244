import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Button as BSButton, utils } from 'react-bootstrap';

import { classNamePrefixer } from '@core/utils/Generators';

import { Loader } from '@components/dmp';
import Icon from '@components/dmp/Icon';
import { ICONS } from '@components/dmp/shared/icons';

utils.bootstrapUtils.addStyle(BSButton, 'filevine');

const cl = classNamePrefixer('btn');

const Button = React.forwardRef(
  (
    {
      children,
      className,
      dark,
      dmpStyle,
      icon,
      iconOnly,
      loading,
      noPadding,
      noUnderline,
      size,
      tool,
      ...passingProps
    },
    ref
  ) => {
    const isIconOnly = iconOnly || (icon && !children);

    // Support custom link-{type}
    let bsStyle = dmpStyle;
    if (bsStyle.includes('link-')) {
      bsStyle = 'link';
    }

    const bsSize = size !== 'default' ? size : null;

    const classNames = cx(
      cl(),
      className,
      cl(dmpStyle),
      { [cl('link')]: bsStyle === 'link' },
      { [cl(size)]: size !== 'default' },
      { [cl('no-padding')]: noPadding },
      { [cl('icon')]: icon },
      { [cl('icon-only')]: isIconOnly },
      { [cl('dark')]: dark },
      { [cl('no-underline')]: noUnderline },
      { [cl('tool')]: tool }
    );

    let IconComponent = null;
    if (icon) {
      const iconSize = size === 'default' && isIconOnly ? 'large' : 'default';
      if (ICONS[icon]) {
        IconComponent = <Icon name={icon} dark={dark} size={iconSize} />;
      } else {
        throw new Error(`@dmp/Button : <Icon name="${icon}"> does not exist.`);
      }
    }

    return (
      <BSButton bsStyle={bsStyle} bsSize={bsSize} className={classNames} ref={ref} {...passingProps}>
        {!loading && IconComponent}
        {loading && (
          <>
            <Loader size={size} dark={dark} />{' '}
          </>
        )}
        {children}
      </BSButton>
    );
  }
);

Button.displayName = 'Button';

Button.defaultProps = {
  icon: null,
  iconOnly: false,
  loading: false,
  noPadding: false,
  noUnderline: false,
  size: 'default',
  dmpStyle: 'default',
  dark: false,
  tool: false,
};

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconOnly: PropTypes.bool,
  loading: PropTypes.bool,
  noPadding: PropTypes.bool,
  noUnderline: PropTypes.bool,
  tool: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'default', 'large']),
  dmpStyle: PropTypes.oneOf([
    'danger',
    'link',
    'link-danger',
    'link-info',
    'link-primary',
    'link-success',
    'link-reject',
    'default',
    'primary',
    'filevine',
  ]),
  dark: PropTypes.bool,
};

export default Button;
