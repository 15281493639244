import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { DEFAULT_COLOR } from '@core/models/Branding';

class StatusLabel extends Component {
  render() {
    const { color, status } = this.props;

    return (
      <span className={`statusLabel ${status.toLowerCase().replaceAll(' ', '-')}`} style={{ backgroundColor: color }}>
        {status}
      </span>
    );
  }
}

StatusLabel.defaultProps = {
  color: DEFAULT_COLOR,
};

StatusLabel.propTypes = {
  status: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export default StatusLabel;
