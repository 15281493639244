import React from 'react';

import PropTypes from 'prop-types';

import DropdownDots from '@components/dmp/DropdownDots';

const MenuItem = DropdownDots.MenuItem;

const ThemeListItem = ({ theme, editMeta, editStyles, confirmDelete }) => {
  const handleAction = (action) => {
    switch (action) {
      case 'editMeta':
        editMeta(theme);
        break;
      case 'editStyles':
        editStyles(theme);
        break;
      case 'delete':
        confirmDelete(theme);
        break;
      default:
        break;
    }
  };

  if (!theme) return null;

  let title = theme.name;
  if (theme.isDefault) title += ' (Default)';

  return (
    <div className="table-inner d-flex justify-content-between" data-cy="theme-item">
      <div className="col theme-title">
        <span>{title}</span>
        <small className="block">{theme.description}</small>
      </div>

      <DropdownDots
        pullRight
        onClick={(e) => e.stopPropagation()}
        id={`dd-${theme.themeKey}`}
        onSelect={handleAction}
        dataCyToggle="theme-dd-toggle"
      >
        <MenuItem eventKey="editMeta">Edit info</MenuItem>
        <MenuItem eventKey="editStyles">Edit styles</MenuItem>
        <MenuItem eventKey="delete">Delete</MenuItem>
      </DropdownDots>
    </div>
  );
};

ThemeListItem.propTypes = {
  theme: PropTypes.object.isRequired,
  editMeta: PropTypes.func.isRequired,
  editStyles: PropTypes.func.isRequired,
  confirmDelete: PropTypes.func.isRequired,
};

export default ThemeListItem;
