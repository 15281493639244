import { assign, filter, get, map, startCase } from 'lodash';
import UAParser from 'ua-parser-js';

import DealAction, { CHECKPOINT_ACTIVITY_MESSAGE } from '../enums/DealAction';

export default class Activity {
  action = null;
  id = null;
  user = null;
  message = null;
  userAgent = null;
  sectionID = null; // only populated for Sections
  _ip = null;
  location = null;
  meta = null;

  constructor({ action, id, user, message, ip: _ip, location, sectionID, userAgent, meta }) {
    assign(this, {
      action,
      id,
      user,
      message,
      _ip,
      location,
      sectionID,
      userAgent,
      meta,
    });
    if (this.id == null) this.id = new Date().getTime().toString();
    this.date = new Date(parseInt(this.id));
  }

  get displayMessage() {
    switch (this.action) {
      case DealAction.SUBMIT:
        return 'resolved thread';
      case DealAction.APPROVE:
        return 'approved section changes';
      case DealAction.DELETE:
        return 'deleted section';
      case DealAction.UPDATE:
      case DealAction.EDIT:
        return 'edited section';
      case DealAction.REJECT:
      default:
        return this.message || '';
    }
  }

  getAuditMessage(deal) {
    let msg = null;
    switch (this.action) {
      case DealAction.CREATE:
        return `${deal.isExternal ? 'uploaded V1' : 'started contract'}`;
      case DealAction.COPY:
        return 'started contract';
      case DealAction.READ:
        return `viewed contract`;
      case DealAction.UPDATE:
      case DealAction.APPROVE: {
        const verb = this.action == DealAction.UPDATE ? 'edited' : 'approved edits on';
        return `${verb} ${this.getSectionNumber(deal)}`;
      }
      case DealAction.DELETE:
        msg = 'deleted a section';
        return msg;
      case DealAction.SEND:
        msg = `shared contract`;
        if (this.message) {
          msg += ' with ';
          //most send actions have recipient email in message
          const recipient = deal.getUserByEmail(this.message);
          if (recipient) msg += `${recipient.get('fullName')} (${this.message})`;
          else msg += `${this.recipient}`;
        }
        return msg;
      case DealAction.SIGN:
        return 'signed contract';
      case DealAction.BULK_SIGN:
        return 'bulk signed contract';
      case DealAction.UNSIGN:
        return 'removed signature';
      case DealAction.COMPLETE:
        return 'Contract fully executed';
      case DealAction.CLEAR:
        return 'removed all signatures';
      case DealAction.SHARE_SECRET:
      case DealAction.VIEW_SECRET:
      case DealAction.VARIABLE:
        // Variable/Secret actions store the name of the variable as the message
        // make sure we can properly look it up before attempting to display
        let v, name;
        if (!(name = this.message) || !(v = deal.variables[name])) return null;
        if (this.action === DealAction.VARIABLE) {
          return `edited [${v.displayName || v.name}]`;
        } else {
          return `${this.action == DealAction.SHARE_SECRET ? 'entered' : 'viewed'} secret: [${
            v.displayName || v.name
          }]`;
        }
      case DealAction.CHECKPOINT_ACTIVITY:
        const [checkpointActivityAction, checkpointGroupName] = this.message?.split(':');
        return `${CHECKPOINT_ACTIVITY_MESSAGE[checkpointActivityAction]?.message} ${checkpointGroupName}`;
      case DealAction.READY_CHECK:
        // Only show ReadyChecks that are completed and successfully passed
        // Can't use real enums here because it results in a circular dependency

        if (this.status !== 'complete' || get(this.result, 'data') !== 'passed') return null;
        // Lookup and list out all users who approved
        msg = 'completed checkpoint';
        // If the event is a READY_CHECK, it will actually be a ReadyCheck model which inherits from Activity (see ActivityLog.constructor)
        const users = filter(
          this.getVotes(deal),
          (user) => user.response === 'approve' && (!!user.fullName || !!user.email)
        );
        // Should be impossible to ever be 0 length, but just in case, to avoid an awkward empty-ish string
        if (users.length) {
          msg += ` (approved by: ${map(users, (du) => du.fullName || du.email).join(', ')})`;
        }
        return msg;
      case DealAction.REJECT:
        return 'commented on ' + this.getSectionNumber(deal);
      case DealAction.SUBMIT:
        return 'resolved comments on ' + this.getSectionNumber(deal);
      case DealAction.CREATE_VERSION:
        msg = 'uploaded ';
        const version = deal.versions[this.message];
        if (version) {
          msg += ' V' + version.ordinal;
        } else {
          msg += ' a version';
        }
        return msg;
      case DealAction.DELETE_VERSION:
        return `deleted a version`;
      case DealAction.WEBHOOK:
        return `Contract created from ${startCase(this.message || 'API')}`;
      case DealAction.WEBHOOK_UPDATE:
        return `Contract updated from ${startCase(this.message || 'API')}`;
      case DealAction.INTEGRATION:
        if (this.meta && this.meta.from && this.meta.to) {
          return `Integration completed successfully: [${this.meta.from}] to [${this.meta.to}]`;
        }
        return 'Integration completed successfully';
      case DealAction.PASTE:
        const count = parseInt(this.message) || 1;
        return `pasted ${count} item${count > 1 ? 's' : ''} into ${this.getSectionNumber(deal)}`;
      case DealAction.CLEAR_LIST:
        return `deleted all items from ${this.getSectionNumber(deal)}`;
      case DealAction.UPDATE_STATUS:
        return `updated status to ${this.message}`;
      default:
        // eslint-disable-next-line no-console
        console.log(`Event type [${this.action}] not yet supported - omitting from view`);
        return null;
    }
  }

  getSectionNumber(deal) {
    const sec = this.sectionID ? deal.sections[this.sectionID] : null;
    if (sec) return sec.displayReference;
    else return 'untitled section';
  }

  get isWebhook() {
    return this.action === DealAction.WEBHOOK || this.action === DealAction.WEBHOOK_UPDATE;
  }
}
