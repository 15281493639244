import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import Template from '@core/models/Template';

import { Combobox, Dropdown, Icon, MenuItem } from '@components/dmp';
import Loader from '@components/dmp/Loader';

import API from '@root/ApiClient';

@autoBindMethods
export default class TemplateSelector extends Component {
  static defaultProps = {
    team: null,
    selectedTemplate: null,
    disabled: false,
    className: null,
    activeOnly: true,
    width: '100%',
    size: 'medium',
    menuWidth: 250,
  };

  static propTypes = {
    team: PropTypes.object,
    onSelect: PropTypes.func,
    selectedTemplateKey: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    activeOnly: PropTypes.bool,
    filter: PropTypes.func,
    size: PropTypes.string,
    width: PropTypes.string,
    menuWidth: PropTypes.number,
    templateSwap: PropTypes.bool,
    onClear: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      templates: null,
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    const currentTeam = this.props.team;
    const prevTeam = prevProps.team;

    // Check if the team has changed
    if (currentTeam?.teamID !== prevTeam?.teamID) {
      this.loadTemplates();
    }
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.team) this.loadTemplates();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  findTemplateByKey(templateKey) {
    const { templates } = this.state;

    if (!templateKey) return null;

    return templates ? _.find(templates, { key: templateKey }) : null;
  }

  async loadTemplates() {
    const { selectedTemplateKey, onSelect, activeOnly, team } = this.props;

    // Check if team is provided
    if (!team) {
      // It would probably be best to use derivedStateFromProps
      this.setState({ templates: null });
      return;
    }

    await this.setState({ loading: true });

    // Fetch templates for the provided team
    const rawTemplates = await API.call('getTeamTemplates', { teamID: team.teamID, activeOnly });

    // Convert raw data to Template instances
    const templates = rawTemplates.map((json) => new Template(json, json.dealID));

    if (!this._isMounted) return;

    await this.setState({ templates, loading: false });

    // If a template is already selected, ensure it's the updated one
    if (selectedTemplateKey) {
      const template = this.findTemplateByKey(selectedTemplateKey);
      //if (template) onSelect(template);
    }
  }

  selectTemplate(option) {
    const { onSelect } = this.props;
    onSelect(option.template);
  }

  render() {
    const { loading, templates } = this.state;
    const { disabled, className, size, open, type, width, placeholder, onClear, selectedTemplateKey, team } =
      this.props;

    const options = team && templates ? this.buildOptions() : [];

    let defaultOption = null;
    if (selectedTemplateKey) {
      defaultOption = options.find((opt) => opt.key === selectedTemplateKey) || null;
    }

    return (
      <Combobox
        data-cy="template-selector"
        className={cx('template-selector', className)}
        defaultValue={defaultOption}
        disabled={loading || disabled}
        options={options}
        width={width}
        type={type}
        enableEmpty={type !== 'search'}
        onSelect={this.selectTemplate}
        placeholder={placeholder || 'Select a template'}
        size={size}
        open={open}
        customMessages={{
          emptyList: 'No templates available.',
          emptyFilter: 'No matching templates found.',
        }}
        onClear={onClear}
      />
    );
  }

  buildOptions() {
    const { filter, team } = this.props;
    let templates = this.state.templates;

    if (typeof filter === 'function') {
      templates = filter(templates, team);
    }

    //does combo box have a disbaled option state?
    return templates.map((template) => ({
      label: template.title,
      value: template.dealID,
      description: template.description,
      template: template,
      key: template.key,
    }));
  }
}
