import { assign, filter, find, uniq } from 'lodash';

import { SectionType } from '../enums';
import Variable, { VariableType } from './Variable';
import { sanitize } from './Version';

export const PARTY_PROPERTIES = [
  {
    data: 'none',
    label: 'Display name (default)',
    description: 'Colloquial organization name without corporate identifier (Inc / LLC / etc)',
    isExportableColumn: false,
  },
  {
    data: 'legalName',
    label: 'Legal name',
    description: 'Full organization name if supplied, or the full name of the signer if an individual',
    isExportableColumn: false,
  },
  {
    data: 'firstName',
    label: 'First name',
    description: 'First name of the signer',
    isExportableColumn: false,
  },
  {
    data: 'lastName',
    label: 'Last name',
    description: 'Last name of the signer',
    isExportableColumn: false,
  },
  {
    data: 'fullName',
    label: 'Full name',
    description: 'Full name of the signer',
    isExportableColumn: true,
  },
  {
    data: 'email',
    label: 'Email',
    description: 'Email address of the signer',
    isExportableColumn: true,
  },
  {
    data: 'address',
    label: 'Address',
    description: "Signer's address if supplied",
    isExportableColumn: true,
  },
  {
    data: 'phone',
    label: 'Phone',
    description: "Signer's phone number if supplied",
    isExportableColumn: true,
  },
  {
    data: 'org',
    label: 'Company',
    description: "Signer's organization name if supplied",
    isExportableColumn: true,
  },
  {
    data: 'title',
    label: 'Title',
    description: "Signer's title at organization if supplied",
    isExportableColumn: true,
  },
  {
    data: 'partyName',
    label: 'Party name',
    description: 'Party name (pluralized if multiple users in party)',
    isExportableColumn: false,
  },
];

export default class Party {
  constructor(json, deal) {
    assign(this, json);
    this.deal = deal;
  }

  get displayName() {
    if (this.users.length > 1) {
      return sanitize(this.pluralName || this.partyName || this.partyID);
    } else {
      return sanitize(this.partyName || this.partyID);
    }
  }

  get(property, returnEmpty) {
    const users = this.users;

    let text = '',
      vals = [];

    if (property === 'partyName') return this.displayName;

    if (users.length > 0) {
      users.map((user) => {
        //make sure requested property is actually populated for each user
        //if no property requested, pull displayName
        const val = user[property || 'displayName'];
        if (val) vals.push(val);
      });

      const uniqueVals = uniq(vals);

      if (property === 'addressProperties') {
        return uniqueVals[0];
      }

      //now build a displayable string based on the populated values we have
      uniqueVals.map((v, i) => {
        text += v;
        if (uniqueVals.length - i > 2) text += ', ';
        if (uniqueVals.length - i == 2) text += ' and '; //fuck the Oxford comma... :-)
      });
    }

    //if we found something populated from one or more users, return that text
    if (text || returnEmpty) return text;
    //otherwise show (empty) party name which may also included property
    else if (property && property != 'displayName') {
      const p = find(PARTY_PROPERTIES, { data: property });
      return p ? `${this.displayName}: ${p.label}` : this.displayName;
    } else {
      return this.displayName;
    }
  }

  get users() {
    return this.deal.getUsersByParty(this.partyID);
  }
  get isSigning() {
    const sectionCheck = filter(this.deal.sections, {
      sectiontype: SectionType.SIGNATURE,
      passesConditions: true,
    });

    const partyAssignedToSigningSection = sectionCheck.some((section) => {
      const signatories = section.signatories ?? [];
      return signatories.includes(this.partyID);
    });

    return partyAssignedToSigningSection || false;
  }

  // In some cases (e.g., PDFElement adding) a target variable will not exist on the Deal (in Deal.variables)
  // Because it's not used anywhere in Section text (for pdf deals)
  // So we need a helper function to generate one manually
  generatePartyVariable(property) {
    const propDef = find(PARTY_PROPERTIES, { data: property });
    return new Variable({
      name: `${this.partyID}.${property}`,
      type: VariableType.PARTY,
      displayName: propDef.label,
      deal: this.deal,
    });
  }
}
