import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';

import Deal from '@core/models/Deal';
import Lens, { LENS_TYPES } from '@core/models/Lens';
import Team from '@core/models/Team';

import { Alert, Button } from '@components/dmp';

import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

import LensAdvancedEditor from './LensAdvancedEditor';
import LensClauseEditor from './LensClauseEditor';
import LensDisplay from './LensDisplay';
import LensVariableEditor from './LensVariableEditor';

@autoBindMethods
export default class LensSidebar extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    team: PropTypes.instanceOf(Team).isRequired,
    onLinkingLens: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      deletingLens: null,
      editingLens: null,
      lensType: 'variable',
      editingFilter: null,
      editingConfig: false,
      editingGroup: null,
      showLens: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.clean();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { editingLens, lensType } = this.state;

    if (prevProps.deal !== this.props.deal) {
      if (editingLens) {
        const lens = _.get(this.props.deal, `template.lenses[${editingLens.id}]`);
        if (lens && editingLens !== lens) {
          this.setState({ editingLens: lens });
        }
      }
    }

    if (prevState.lensType !== lensType) this.clean();
  }

  toggleShowLens(lens, action) {
    const { showLens } = this.state;

    switch (action) {
      case 'add':
        showLens.push(lens);
        this.setState({ showLens });
        break;
      case 'remove':
      default:
        let editedShowLens = _.remove(showLens, ({ id }) => {
          return id !== lens.id;
        });
        this.setState({ showLens: editedShowLens });
        break;
    }
  }

  async clean() {
    const { deal } = this.props;
    const { lenses } = deal.template;

    for (const lens of _.values(lenses)) {
      if (lens.isCreating) await Fire.saveLens(deal.dealID, lens, true);
    }
  }

  /////////////
  //FUNCTIONS//
  ////////////

  async newLensInstance() {
    const { deal, onLinkingLens } = this.props;
    const { lensType, showLens } = this.state;

    const rawLens = await Fire.generateLensInstance(deal.dealID, lensType);

    const lens = new Lens(rawLens);
    this.setState({ editingLens: lens, showLens: [...showLens, lens] });

    if (lensType === 'clause') {
      onLinkingLens(lens);
    }
  }

  reset(persistEditing) {
    const { onLinkingLens } = this.props;
    const { editingLens } = this.state;

    this.setState({
      editingLens: persistEditing ? editingLens : null,
      editingConfig: false,
      editingFilter: null,
      deletingLens: null,
    });
    onLinkingLens();
  }

  setDeletingLens(lens) {
    this.setState({ deletingLens: lens });
  }

  setEditingLens(lens) {
    this.setState({ editingLens: lens });
  }

  setEditingFilter(filter) {
    this.setState({ editingFilter: filter });
  }

  setEditingConfig(lens) {
    const { onLinkingLens } = this.props;
    this.setState({
      editingConfig: true,
      editingLens: lens,
    });

    onLinkingLens(lens);
  }

  async delete() {
    const { deal } = this.props;
    const { deletingLens } = this.state;

    await Fire.saveLens(deal.dealID, deletingLens, true);
    this.reset();
  }

  renderLensPannels() {
    const { deal } = this.props;
    const { lensType } = this.state;
    return (
      <div className="filter-bar">
        <ButtonGroup className="panel-tabs">
          {LENS_TYPES.map((typeDef, idx) => {
            if (typeDef.show(deal.template))
              return (
                <TooltipButton tipID={`tip-lens-${idx}`} key={idx} tip={typeDef.tip} placement="top">
                  <Button
                    dmpStyle="link"
                    active={typeDef.key === lensType}
                    onClick={() => this.setState({ lensType: typeDef.key })}
                  >
                    {typeDef.plural}
                  </Button>
                </TooltipButton>
              );
          })}
        </ButtonGroup>
      </div>
    );
  }

  renderLenses(lens, idx) {
    const { deal } = this.props;
    const { editingFilter, editingLens, editingConfig, showLens, deletingLens, lensType } = this.state;
    const isEditing = editingLens?.id === lens.id;
    const isShowing = !!_.find(showLens, { id: lens.id });
    const isDeleting = deletingLens?.id === lens.id;
    const activeEditingState = isEditing && (lens.isCreating || editingConfig);

    return (
      <DealPanelItem borderBottom className="lens-block" key={idx}>
        {activeEditingState && this.renderLensType(lens)}
        {!activeEditingState && (
          <LensDisplay
            lens={lens}
            isEditing={isEditing}
            isShowing={isShowing}
            isDeleting={isDeleting}
            deal={deal}
            toggleShowLens={this.toggleShowLens}
            setDeletingLens={this.setDeletingLens}
            setEditingLens={this.setEditingLens}
            setEditingFilter={this.setEditingFilter}
            setEditingConfig={this.setEditingConfig}
            reset={this.reset}
            editingFilter={editingFilter}
            editingConfig={editingConfig}
          />
        )}
        {this.showAlert(lens) && this.renderAlerts()}
      </DealPanelItem>
    );
  }

  renderLensType(lens) {
    const { deal } = this.props;
    const { editingFilter, editingConfig, lensType } = this.state;

    switch (lensType) {
      case 'advanced':
        return (
          <LensAdvancedEditor
            deal={deal}
            lens={lens}
            onHide={(persistEditing) => this.reset(persistEditing)}
            advancedFilter={editingFilter}
            editingConfig={editingConfig}
          />
        );
      case 'clause':
        return (
          <LensClauseEditor
            deal={deal}
            lens={lens}
            onHide={(persistEditing) => this.reset(persistEditing)}
            clauseFilter={editingFilter}
            editingConfig={editingConfig}
          />
        );
      case 'variable':
        return (
          <LensVariableEditor
            deal={deal}
            lens={lens}
            onHide={(persistEditing) => this.reset(persistEditing)}
            valueFilter={editingFilter}
          />
        );
    }
  }

  renderAlerts() {
    const { deletingLens } = this.state;

    const alertText = `Are you sure you want to delete this ${deletingLens.type} Lens? All dependent conditions, target ranges, and values will also be deleted from Lens.`;

    return (
      <Alert dmpStyle="danger" size="small">
        {alertText}
        <div className="alert-actions">
          <a className="cancel" onClick={() => this.setState({ deletingLens: null })}>
            Cancel
          </a>
          <a onClick={this.delete}>Delete</a>
        </div>
      </Alert>
    );
  }

  showAlert(lens) {
    const { deletingLens } = this.state;
    return deletingLens?.id === lens.id;
  }

  get lensCountText() {
    const { lensType } = this.state;
    return `${_.upperFirst(lensType)} Lenses (${this.lenses.length})`;
  }

  get lenses() {
    const { deal } = this.props;
    const { variableLenses, clauseLenses, advancedLenses } = deal.template;
    const { lensType } = this.state;
    let lenses = [];

    switch (lensType) {
      case 'advanced':
        lenses = advancedLenses;
        break;
      case 'clause':
        lenses = clauseLenses;
        break;
      case 'variable':
        lenses = variableLenses;
        break;
    }

    return _.sortBy(lenses, ['relatedVariable', 'title']);
  }

  get creatingMode() {
    const { deal } = this.props;
    const { lensType } = this.state;

    switch (lensType) {
      case 'advanced':
        return !!_.find(deal.template.advancedLenses, { isCreating: true });
      case 'clause':
        return !!_.find(deal.template.clauseLenses, { isCreating: true });
      case 'variable':
        return !!_.find(deal.template.variableLenses, { isCreating: true });
    }
  }

  render() {
    const { deal, openSettings } = this.props;
    const { lensType } = this.state;

    return (
      <div className="lens-sidebar" data-cy="lens-sidebar">
        {this.renderLensPannels()}
        <DealPanelItem borderBottom className="add-lens">
          <div className="lens-count" data-cy="lens-count">
            {this.lensCountText}
          </div>

          <div className="spacer" />

          <Button
            ref={this.refNewVital}
            size="small"
            icon="lens"
            onClick={() => this.newLensInstance()}
            data-cy="new-lens-btn"
            disabled={this.creatingMode}
          >
            New
          </Button>
        </DealPanelItem>

        {!deal.template.scoring && (
          <DealPanelItem borderBottom className="add-lens add-variable-lens">
            <Alert dmpStyle="danger" size="small" data-cy="matrix-alert">
              Please select a Scoring Matrix in <a onClick={openSettings}>Template settings</a> to enable Lens.
            </Alert>
          </DealPanelItem>
        )}

        {this.lenses.length > 0 ? (
          <div className="lens-list panel-scroll" data-cy="lens-list">
            {_.map(_.orderBy(this.lenses, ['isCreating', 'title'], ['desc', 'asc']), this.renderLenses)}
          </div>
        ) : (
          <DealPanelItem> {`No ${lensType} lenses defined`} </DealPanelItem>
        )}
      </div>
    );
  }
}
