import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { FormGroup, Overlay, Radio } from 'react-bootstrap';

import InviteStatus from '@core/enums/InviteStatus';

import { Button, Form, Icon, Popover } from '@components/dmp';

@autoBindMethods
export default class SharingStatusFilter extends Component {
  static propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func,
    filter: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      type: null,
      filter: null,
    };

    this.anchor = React.createRef();
  }

  componentDidMount() {
    const { type, filter } = this.props;

    this.setState({
      type: type || null,
      filter: filter || null,
    });
  }

  componentDidUpdate(prevProps) {
    const { type, filter } = this.props;

    if (prevProps.type !== type) this.setState({ type: type || null });
    if (prevProps.filter !== filter) this.setState({ filter: filter || null });
  }

  get canSubmit() {
    return this.state.type;
  }

  configureFilter() {
    this.setState({ showPopup: true });
  }

  saveFilter() {
    const { onChange } = this.props;
    const { type } = this.state;

    onChange(type);

    this.setState({ showPopup: false });
  }

  clearFilter() {
    const { onChange } = this.props;

    this.setState({
      showPopup: false,
      type: null,
    });

    onChange(null);
  }

  render() {
    const { type, filter } = this.props;
    const { showPopup } = this.state;

    return (
      <div className="variable-filter" ref={this.anchor}>
        <div className={cx('filter-name', { active: !!filter })} onClick={this.configureFilter}>
          <div>Sharing</div>
          <Icon name="filterExpand" />
        </div>

        {filter && (
          <div className="existing" onClick={this.configureFilter}>
            <div className="filter-display" data-cy="filter-display">
              {type[0].toUpperCase() + type.slice(1)}
            </div>
          </div>
        )}

        {showPopup && this.renderConfig()}
      </div>
    );
  }

  renderConfig() {
    const { showPopup, type } = this.state;

    return (
      <Overlay
        show={true}
        onHide={() => this.setState({ showPopup: false })}
        target={this.anchor.current}
        placement="right"
        rootClose
      >
        <Popover
          className="sharing-status-filter popover-deal-sharing-status-filter"
          id={`pop-sharing-status-filter-${type}`}
          title="Sharing"
          closeBtn={showPopup}
          onHide={() => this.setState({ showPopup: false })}
          data-cy="popover-deal-sharing-status-filter"
        >
          <Form className="configuring">
            <FormGroup className="sharing-status-options">
              <Radio
                checked={type === InviteStatus.FAILED}
                name="sharing-filter"
                onChange={() => this.setState({ type: InviteStatus.FAILED })}
                value={InviteStatus.FAILED}
                data-cy="radio-failed"
              >
                Failed (invitation was not received)
              </Radio>
              <Radio
                checked={type === InviteStatus.ACCEPTED}
                name="sharing-filter"
                onChange={() => this.setState({ type: InviteStatus.ACCEPTED })}
                value={InviteStatus.ACCEPTED}
                data-cy="radio-accepted"
              >
                Successful (invitation accepted)
              </Radio>
            </FormGroup>
            <div className="actions">
              <Button size="small" dmpStyle="link" onClick={this.clearFilter} data-cy="btn-clear-sharing-status-filter">
                Clear
              </Button>
              <Button
                data-cy="btn-apply-sharing-status-filter"
                disabled={!this.canSubmit}
                dmpStyle="primary"
                onClick={this.saveFilter}
                size="small"
              >
                Apply
              </Button>
            </div>
          </Form>
        </Popover>
      </Overlay>
    );
  }
}
