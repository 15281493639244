import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { generateExportURL } from '@core/utils/Generators';

import { Button, Ellipsis } from '@components/dmp';

import Fire from '@root/Fire';

@autoBindMethods
class Storage extends Component {
  taskID = null;

  static defaultProps = {};

  static propTypes = {
    location: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isDownloading: false,
      taskID: null,
      fileName: 'task-export.csv',
    };

    this.downloadRef = React.createRef();
  }

  componentDidMount() {
    const { taskID, taskType, fileName } = qs.parse(location.search);

    const newState = { taskID, taskType };
    if (fileName) newState.fileName = fileName;

    this.setState(newState);
  }

  async onDownload() {
    const { taskID, taskType, fileName } = this.state;
    this.setState({ isDownloading: true });

    const token = await Fire.token();
    const downloadURL = generateExportURL({
      token,
      type: 'storage',
      options: { taskID, taskType, filename: fileName },
    });

    this.setState({ downloadURL }, () => {
      this.downloadRef.current.click();
      this.setState({ isDownloading: false });
    });
  }

  render() {
    const { isDownloading, downloadURL, fileName } = this.state;

    // Make this page width 100%,
    // Center button
    // Make button "Downloading file" + on the next line, smaller, the file name.

    return (
      <main className="dashboard">
        <div id="page-storage">
          <Button
            id="download-btn"
            dmpStyle="primary"
            loading={isDownloading}
            disabled={isDownloading}
            size="large"
            onClick={this.onDownload}
          >
            Download file
            <Ellipsis>
              <small>{fileName}</small>
            </Ellipsis>
          </Button>

          <a ref={this.downloadRef} href={downloadURL} download />
        </div>
      </main>
    );
  }
}

export default Storage;
