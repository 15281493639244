import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import User from '@core/models/User';

import Icon from '@components/dmp/Icon';

import CONFIG from '@root/Config';

@autoBindMethods
class ErrorView extends Component {
  static defaultProps = {
    error: null,
    dealID: null,
  };
  static propTypes = {
    error: PropTypes.object,
    dealID: PropTypes.string,
    user: PropTypes.instanceOf(User),
  };

  constructor(props) {
    super(props);

    this.state = { error: props.error };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: error };
  }

  componentDidCatch(error, errorInfo) {
    return { error: error };
  }

  render() {
    const { error } = this.state;
    const { user } = this.props;
    const isGuest = user ? user.anonymous : true;
    const support = CONFIG.INSTANCE === 'filevine' ? 'Filevine' : 'Outlaw';

    const message =
      error && error.error
        ? error.error
        : isGuest
        ? 'Try refreshing the page, if this issue persists contact the sender.'
        : `Try refreshing the page, if the issue persists contact ${support} support`;

    if (!!error) {
      return (
        <div className="error-container">
          <div className="error-block">
            <span className="header">
              <Icon name="nope" />
              <b>There's an error with this page</b>
            </span>
            <br />
            <div className="message">{message}</div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorView;
