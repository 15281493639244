import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonIcon, Icon } from '@components/dmp';
import MobileNav from '@components/dmp/MobileNav';

import DealPanel from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import Dealer from '@root/Dealer';

@autoBindMethods
export default class BundleView extends Component {
  static propTypes = {
    deal: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    onToggle: PropTypes.func.isRequired,
    container: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    if (!Dealer.mobile) {
      this.toggle(true);
    }
  }

  toggle(show) {
    const { onToggle } = this.props;
    this.setState({ show });
    onToggle(show);
  }

  viewItem(dealID) {
    const { history } = this.props;
    history.push(`/deals/${dealID}`);

    // On mobile, close menu after navigation
    if (Dealer.mobile) {
      this.toggle(false);
    }
  }

  renderBundleItem(item, isParent) {
    const { deal } = this.props;
    const dealInfo = isParent ? item : item.dealInfo;
    const caption = isParent ? 'Parent Document' : item.parentTitle;
    const active = deal.dealID === dealInfo.dealID;

    return (
      <DealPanelItem
        key={dealInfo.dealID}
        borderBottom
        active={active}
        onClick={() => (active ? null : this.viewItem(dealInfo.dealID))}
      >
        <div className="bundle-item-meta">{caption}</div>
        <div className="bundle-item-title">
          <Icon name="document" />
          <span>{dealInfo.name}</span>
        </div>
      </DealPanelItem>
    );
  }

  render() {
    const { deal, container } = this.props;
    const { show } = this.state;

    if (!deal.bundle) return null;

    const mobile = Dealer.mobile;

    return (
      <>
        <ButtonIcon
          tool={!mobile}
          icon="bundle"
          onClick={() => this.toggle(!show)}
          className={cx('btn-bundle', 'dmp-btn-large', { active: show })}
          data-cy="btn-bundle"
        />

        {mobile && (
          <MobileNav show={show} onHide={() => this.toggle(false)} className="bundle-view" title="Document Bundle">
            {this.renderBundleItem(deal.bundle.parent, true)}
            {_.map(deal.bundle.children, (child) => this.renderBundleItem(child, false))}
          </MobileNav>
        )}

        {!mobile && (
          <DealPanel
            id="bundle-view"
            container={container}
            className="bundle-view"
            title="Document Bundle"
            onHide={() => this.toggle(false)}
            show={show}
            canClose={false}
          >
            <div className="panel-body">
              {this.renderBundleItem(deal.bundle.parent, true)}
              {_.map(deal.bundle.children, (child) => this.renderBundleItem(child, false))}
            </div>
          </DealPanel>
        )}
      </>
    );
  }
}
