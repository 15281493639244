import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DEFAULT_THEME, THEMES } from '@core/models/StyleFactory';
import Team from '@core/models/Team';

import { Dropdown, MenuItem } from '@components/dmp';

const ThemeSelector = ({
  id,
  onSelect,
  showDescription,
  team,
  pullRight,
  disabled,
  className,
  themeKey,
  block,
  size,
  menuWidth,
}) => {
  const [, teamThemeKey] = (themeKey || '').split(':');
  const teamThemes = team ? _.sortBy(team.themes, ['isDefault']).reverse() : [];
  let selectedTheme = null;

  const defaultTeamTheme = _.find(teamThemes, { isDefault: true });

  if (!themeKey && defaultTeamTheme) {
    selectedTheme = defaultTeamTheme;
  } else if (teamThemeKey) {
    selectedTheme = _.find(teamThemes, { themeKey: teamThemeKey }) || defaultTeamTheme;
  }
  if (!selectedTheme) {
    selectedTheme = _.find(THEMES, { themeKey }) || _.find(THEMES, { themeKey: DEFAULT_THEME });
  }

  const currentThemeKey = selectedTheme.themeKey;

  return (
    <>
      <Dropdown
        className={cx('dd-theme-selector', className)}
        disabled={disabled}
        id={`theme-selector-${id}`}
        onSelect={onSelect}
        pullRight={pullRight}
        size={size}
        title={selectedTheme.name}
        block={block}
        menuWidth={menuWidth}
      >
        {teamThemes.length > 0 && <MenuItem header>{team.info.name} Themes</MenuItem>}
        {_.map(teamThemes, (theme) => (
          <MenuItem
            key={theme.themeKey}
            eventKey={`${team.teamID}:${theme.themeKey}`}
            active={theme.themeKey === currentThemeKey}
          >
            {theme.name} {theme.isDefault && '(default)'}
          </MenuItem>
        ))}

        {teamThemes.length > 0 && <MenuItem divider />}

        <MenuItem header>Outlaw Themes</MenuItem>
        {_.map(THEMES, (theme, idx) => (
          <MenuItem key={idx} eventKey={theme.themeKey} active={theme.themeKey === currentThemeKey}>
            {theme.name}
          </MenuItem>
        ))}
      </Dropdown>
      {showDescription && <small>{selectedTheme.description}</small>}
    </>
  );
};

ThemeSelector.defaultProps = {
  selectedTheme: null,
  pullRight: false,
  disabled: false,
  showDescription: true,
  className: null,
  block: false,
  size: 'default',
  menuWidth: 250,
};

ThemeSelector.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  team: PropTypes.instanceOf(Team),
  showDescription: PropTypes.bool,
  block: PropTypes.bool,
  themeKey: PropTypes.string,
  pullRight: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  menuWidth: PropTypes.number,
};

export default ThemeSelector;
