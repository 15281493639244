import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { FormGroup, Overlay, Popover } from 'react-bootstrap';

import { Button, Dropdown, Form, Icon, MenuItem } from '@components/dmp';

@autoBindMethods
export default class BooleanFilter extends Component {
  static propTypes = {
    handleSelection: PropTypes.func.isRequired,
    filterValue: PropTypes.bool,
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
      option: null,
    };

    this.boolFilterOptions = [
      { key: true, value: `Is ${props.title}` },
      { key: false, value: `Is not ${props.title}` },
    ];

    this.filterAnchor = React.createRef();
  }

  saveFilter() {
    const { handleSelection } = this.props;
    const { option } = this.state;

    const value = _.find(this.boolFilterOptions, { value: option }).key;

    this.setState({ showPopup: false });
    handleSelection(value);
  }

  clearFilter() {
    const { handleSelection } = this.props;
    this.setState({
      showPopup: false,
      option: null,
    });

    handleSelection(null);
  }

  render() {
    const { filterValue, title } = this.props;
    const { showPopup } = this.state;
    const filter = _.find(this.boolFilterOptions, { key: filterValue });

    return (
      <div className="variable-filter" ref={this.filterAnchor}>
        <div
          className={cx('filter-name', { active: !!filter })}
          onClick={() => this.setState({ showPopup: true })}
          data-cy="observer-filter"
        >
          <div className="title">{title}</div>
          <Icon name="filterExpand" />
        </div>

        {showPopup && this.renderConfig()}

        {filter && (
          <div className="existing" onClick={() => this.setState({ showPopup: true })} data-cy="filter-display">
            <div className="filter-display">{filter.value}</div>
          </div>
        )}
      </div>
    );
  }

  renderConfig() {
    const { option } = this.state;
    const title = option ? option : 'Select one';

    return (
      <Overlay
        show={true}
        onHide={() => this.setState({ showPopup: false })}
        target={this.filterAnchor.current}
        placement="right"
        rootClose
      >
        <Popover className="observer-filter" id={`observer-filter`} title={'Observer'}>
          <Form className="configuring">
            <FormGroup>
              <Dropdown
                id={`dd-operators`}
                title={title}
                size="small"
                onSelect={(o) => this.setState({ option: o })}
                block
                dataCyToggle="dd-operators"
              >
                {this.boolFilterOptions.map((option) => (
                  <MenuItem key={option.value} eventKey={option.value}>
                    {option.value}
                  </MenuItem>
                ))}
              </Dropdown>
            </FormGroup>
            <div className="actions">
              <Button size="small" dmpStyle="link" onClick={this.clearFilter}>
                Clear
              </Button>
              <Button size="small" dmpStyle="primary" onClick={this.saveFilter} data-cy="btn-apply">
                Apply
              </Button>
            </div>
          </Form>
        </Popover>
      </Overlay>
    );
  }
}
