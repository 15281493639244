import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

@autoBindMethods
export default class Sidebar extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    modals: PropTypes.node,
  };

  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };
  }

  render() {
    const { children, modals } = this.props;
    const { expanded } = this.state;

    return (
      <div className={cx('sidebar', { expanded })}>
        {!expanded ? (
          <TooltipButton tipID="filter-contracts-show" tip="Show filters" placement="right">
            <Button
              icon="filterExpand"
              className="sidebar-expand"
              onClick={() => this.setState({ expanded: true })}
              data-cy="btn-show-filters"
            />
          </TooltipButton>
        ) : (
          <Button
            icon="filterCollapse"
            className="sidebar-collapse"
            onClick={() => this.setState({ expanded: false })}
            data-cy="btn-collapse-filters"
          />
        )}
        <div className="sidebar-wrapper" data-cy="admin-sidebar-wrapper">
          {children}
          {modals}
        </div>
      </div>
    );
  }
}
