import React, { useState } from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import DealStatus from '@core/enums/DealStatus';

import { Button, Loader } from '@components/dmp';

import ProductMessage from '@components/DraftFlowSwitcher/ProductMessage';
import DealStatusSelector from '@components/deal/DealStatusSelector';
import Fire from '@root/Fire';

const DraftFlowSwitcher = ({ deal, history, show, close }) => {
  const [saving, setSaving] = useState(false);
  const [currentStep, setCurrentStep] = useState(_.get(deal, 'workflow.steps[0]'));
  const [originalStep] = useState(currentStep);

  if (!deal) {
    return null;
  }

  const updateStatus = async (step) => {
    setSaving(true);
    await Fire.updateDealInfo(deal.info, { status: step.key });
    setSaving(false);
    history.push(`/deals/${deal.dealID}/${step.key === DealStatus.DRAFT.data ? 'draft' : 'contract'}`);
  };

  let title, message, selectStatus, cancel, ok;

  // If we're in DRAFT status user is contemplating advancing to FLOW
  if (deal.info.status === DealStatus.DRAFT.data) {
    title = 'Edit in Flow';
    cancel = 'Cancel';
    ok = 'Edit in Flow';
    message = <ProductMessage inDraft={true} />;
    selectStatus = true;
  }
  // Otherwise we're in FLOW and user is contemplating going BACK to DRAFT
  else {
    title = 'Revert to Draft';
    cancel = 'Cancel';
    ok = 'Revert to Draft';
    message = <ProductMessage inDraft={false} />;
  }

  return (
    <Modal dialogClassName="draft-flow-switcher" show={show} onHide={close}>
      <Modal.Header closeButton>
        <span className="headline">{title}</span>
      </Modal.Header>
      <Modal.Body>
        <div className="wrapper">
          <div className="environment-info alert alert-warning" role="alert">
            {message}
          </div>
          {selectStatus && (
            <FormGroup>
              <ControlLabel>Initial status</ControlLabel>
              <div className="contents deal-status">
                <DealStatusSelector
                  steps={deal.workflow.steps}
                  onSelect={setCurrentStep}
                  currentStep={currentStep}
                  disabled={saving}
                />
              </div>
            </FormGroup>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        {saving && <Loader />}
        <Button
          onClick={() => {
            setCurrentStep(originalStep);
            close();
          }}
          disabled={saving}
        >
          {cancel}
        </Button>
        <Button
          dmpStyle="primary"
          disabled={saving || (selectStatus && !currentStep)}
          onClick={() => updateStatus(selectStatus ? currentStep : { key: DealStatus.DRAFT.data })}
        >
          {ok}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DraftFlowSwitcher.propTypes = {
  deal: PropTypes.object,
  history: PropTypes.object,
  show: PropTypes.bool,
  close: PropTypes.func,
};

export default DraftFlowSwitcher;
