import { assign, find, findIndex, get, pick, sortBy } from 'lodash';

// Before we had DealVersion/Attachment models, there was a different storage path structure
export const LEGACY_KEY = 'LEGACY';

// Key to use for original DealVersion, when no subsequent versions have been stored
export const ORIGINAL_KEY = 'ORIGINAL';

// Key to use for original DealVersion, when no subsequent versions have been stored
export const PENDING_KEY = 'PENDING';

// Sanitize filename that may or may not include an extention (e.g., people type '.pdf')
export const strip = (filename) => filename.replace(/\.[\w]+$/, '').trim();

export default class DealVersion {
  deal;
  key;

  // Attachment keys
  pdfKey = null;
  docxKey = null;

  // Additional metadata specified manually
  owner = null;
  dateCreated = null;
  // Origin values are enumerated in DEAL_TYPE but cannot be imported because it would create a circular reference
  origin = null;
  dealStatus = null;
  description = null;

  static Legacy(deal) {
    const creationEvent = deal.creationEvent;

    return new DealVersion(
      {
        pdfKey: LEGACY_KEY,
        owner: get(creationEvent, 'user', null),
        dateCreated: get(creationEvent, 'id', null),
        origin: 'external',
      },
      deal,
      LEGACY_KEY
    );
  }

  static Original(deal) {
    const creationEvent = deal.creationEvent;

    return new DealVersion(
      {
        pdfKey: null,
        owner: get(creationEvent, 'user', null),
        dateCreated: get(creationEvent, 'id', null),
        dealStatus: deal.info.status,
        origin: 'native',
      },
      deal,
      ORIGINAL_KEY
    );
  }

  static Pending(deal) {
    return new DealVersion(
      {
        owner: get(deal.currentDealUser, 'uid', null),
        dealStatus: deal.info.status,
        origin: 'native',
      },
      deal,
      PENDING_KEY
    );
  }

  constructor(json, deal, key = null) {
    this.deal = deal;

    assign(this, pick(json, ['dateCreated', 'dealStatus', 'description', 'docxKey', 'origin', 'owner', 'pdfKey']), {
      key,
    });

    if (key === LEGACY_KEY) {
      this.pdfKey = LEGACY_KEY;
    }
    if (!this.dealStatus) {
      this.dealStatus = get(deal, 'info.status');
    }

    // If not specified, discover from file types
    if (!this.origin) {
      if (this.isOriginal) {
        this.origin = 'native';
      }
      if (this.docxKey) {
        this.origin = 'externalWord';
      } else {
        this.origin = 'external';
      }
    }
  }

  get pdfBucketPath() {
    return get(this.deal, `attachments[${this.pdfKey}].bucketPath`, null);
  }

  get docxBucketPath() {
    return get(this.deal, `attachments[${this.docxKey}].bucketPath`, null);
  }

  // Get the bucket path for the external document depending upon the origin
  get documentBucketPath() {
    let bucketPath = null;
    if (this.origin === 'externalWord' && this.docxBucketPath) {
      bucketPath = this.docxBucketPath;
    } else if (this.pdfBucketPath) {
      bucketPath = this.pdfBucketPath;
    }
    return bucketPath;
  }

  get json() {
    return pick(this, ['pdfKey', 'docxKey', 'owner', 'origin', 'dateCreated', 'description', 'dealStatus']);
  }

  get date() {
    return new Date(parseInt(this.dateCreated));
  }

  get ordinal() {
    const versions = sortBy(this.deal.versions, 'dateCreated');
    return findIndex(versions, this) + 1;
  }

  get isMine() {
    const me = this.deal.currentDealUser;
    return me && this.owner === me.uid;
  }

  get isOriginal() {
    return this.key === ORIGINAL_KEY;
  }

  get isCurrent() {
    return this === this.deal.currentVersion;
  }

  get dealUser() {
    return this.deal.getUserByID(this.owner);
  }

  get displayName() {
    const dealUser = this.dealUser;

    if (this.isMine) {
      return 'You';
    } else if (dealUser) {
      return dealUser.fullName || dealUser.email;
    } else {
      return 'A deleted user';
    }
  }

  get displayStatus() {
    const workflow = this.deal.workflow;
    const idx = findIndex(workflow.steps, { key: this.dealStatus });
    return idx > -1 ? workflow.steps[idx].name : this.dealStatus;
  }

  get displayOrigin() {
    let text = 'This version';
    switch (this.origin) {
      case 'native':
        if (this.isOriginal) {
          text += '  was created via template on Outlaw';
        } else {
          text += '  was created via eSigning tools on Outlaw';
        }
        break;
      case 'external':
        text += ' is a pdf file upload';
        break;
      case 'externalWord':
        text += ' is a docx file upload';
        break;
    }
    return text;
  }

  get filename() {
    let attachment, ext;

    if (this.key === ORIGINAL_KEY) {
      return this.deal.info.name;
    } else if (this.docxKey) {
      attachment = this.deal.attachments[this.docxKey];
      ext = 'docx';
    } else if (this.pdfKey) {
      attachment = this.deal.attachments[this.pdfKey];
      ext = 'pdf';
    }
    if (attachment) {
      return `${attachment.title}.${ext}`;
    }
    return '';
  }

  get step() {
    const workflow = this.deal.workflow;
    return find(workflow.steps, { key: this.dealStatus }) || workflow.steps[0];
  }

  get docx() {
    return this.docxKey ? this.deal.attachments[this.docxKey] || null : null;
  }

  get pdf() {
    return this.pdfKey ? this.deal.attachments[this.pdfKey] || null : null;
  }

  get deepLink() {
    return `/deals/${this.deal.dealID}/v/${this.ordinal}`;
  }

  get hasChanges() {
    return this === this.deal.currentVersion && get(this.deal, 'pdfElements.length') > 0;
  }
}
