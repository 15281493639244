import { filter, get } from 'lodash';

import SectionType from '../enums/SectionType';
import { formatOrder } from '../utils/OrderFormatter';
import { defaultNumberFormat, unnumberedFormat } from './DealStyle';
import Source from './Source';

export default class Item extends Source {
  // This is similar to Source.sourceNumber, but uses parent instead of sourceParent due to LIST/ITEM model structure
  // And only building the "full" numbering relevant to the LIST (instead of the Deal)
  sourceNumber(full = null) {
    let nf = this.numberFormat;
    const formatter = (item) => {
      const index = item.numberedSibs.indexOf(item);
      // If the current section is not numbered, it will not be found in the above filter()
      // This is necessary to avoid child numbered sections of an unnumbered parent from rendering blank formats, e.g., ()4. or .4.
      if (index === -1) return '';
      nf = item.numberFormat;
      return formatOrder(index, nf);
    };

    if ((full || nf.full) && !nf.docx && nf.type !== 'unordered') {
      let item = this;
      const pieces = [];
      const continuous = get(this, 'list.continuous');
      const master = continuous ? item.appendix || this.deal.root : item.list || item.appendix || this.deal.root;
      while (item && item !== master) {
        pieces.push(formatter(item));
        // List items use the parent/child instead of sourceParent/sourceChildren properties to enable internal nesting
        // This was done because Lists are user-generated and therefore not part of the template/document "source" content
        // However, if the list is continuous, checking both properties here
        // will enable traversal all the way up the tree past the List to the "master" (either appendix or deal)
        // meaning that continuous List Items will be able to inheret *full* numeric numbering, e.g., 2.4.3
        item = continuous && !item.isItem ? item.sourceParent : item.parent;
      }
      return pieces.reverse().join('');
    } else {
      return formatter(this);
    }
  }

  // This is only used as a helper to identify when content is changing in ContentSection.shouldComponentUpdate
  // Once we refactor to remove that, this can go away too
  get flatText() {
    let text = `${this.indentLevel}|${this.order}|`;
    text += this.currentVersion.getText('title', true, this.deal.variables) + '|';
    text += this.currentVersion.getText('body', true, this.deal.variables);
    return text;
  }

  // Similar to sourceNumber, reference master name to build full reference title
  get displayReference() {
    const master = this.list || this.appendix;
    const masterName = get(master, 'displayReference', 'untitled');
    return `${masterName}: Item ${this.sourceNumber(true)}`;
  }

  get indentLevel() {
    const list = this.list;

    // ITEMs in LISTs are always children of the list, but may or may not be set to indent
    // Depending on list settings
    if (list) {
      let indent = get(this, 'list.indent') ? 1 : 0;
      return indent + this.itemIndentLevel + list.indentLevel;
    }

    // ITEMs in legacy SCOPE sections do not support nesting;
    // this forces all ITEMS to be rendered at top-level of the corresponding APPENDIX
    return 0;
  }

  // Get the indentLevel relative to the parent LIST (instead of absolute to Deal root)
  get itemIndentLevel() {
    const list = this.list;
    if (!list) return 0;
    let indent = 0;
    let parent = this.parent;
    while (parent && parent !== list) {
      indent++;
      parent = parent.parent;
    }
    return indent;
  }

  get numberFormat() {
    // If this ITEM is in a LIST, get the numbering format specified in the LIST
    const list = this.list;
    if (list) {
      if (list.continuous) return super.numberFormat;
      const numbering = get(list.style, 'numbering', []) || [];
      if (numbering.length > 0) {
        return numbering[this.itemIndentLevel] || unnumberedFormat();
      }
      // If nothing is specified, assume unnumbered
      // This is different from default behavior for Deal sections,
      // but gives the added flexibility for LISTs to behave more like generic UGC placeholders
      else {
        return unnumberedFormat();
      }
    }
    // If no list reference is found, this ITEM must be part of a legacy SCOPE section
    // SCOPE items were fixed at default 1. 2. 3. numbering
    else {
      return defaultNumberFormat();
    }
  }

  // Shorthand instead of always needing to type this.parent.chilren
  // The ITEM type filtering is also necessary because legacy SCOPE sections also have an APPENDIX section as a child
  // As a means to make an implicit association between these ITEMs and that APPENDIX for rendering
  get sibs() {
    return filter(this.parent.children, { sectiontype: SectionType.ITEM });
  }

  get numberedSibs() {
    const itemSibs = this.sibs;

    // This is the "magic" line of code
    // items in a continuous list will get interpolated with their parent (list) siblings
    // to create a continuous numbering effect
    if (this.list && this.list.continuous && this.itemIndentLevel === 0) {
      return this.list.numberedSibs;
    }

    return itemSibs;
  }

  // If this is part of a LIST, return a reference to that section
  get list() {
    let parent = this.parent;
    while (parent) {
      if (parent.sectiontype === SectionType.LIST) return parent;
      else parent = parent.parent;
    }
    return null;
  }
}
