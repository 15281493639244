import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import DealRole from '@core/enums/DealRole';
import { ATTACHMENT_TYPE } from '@core/models/Attachment';
import User from '@core/models/User';
import { SAVEABLE_VAR_FIELDS } from '@core/models/Variable';

import { Button, Dropdown, MenuItem } from '@components/dmp';

import AttachmentUploader from '@components/deal/AttachmentUploader';
import DealAttachmentBlock from '@components/deal/DealHeader/DealAttachmentBlock';
import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DeleteAttachment from '@components/deal/DeleteAttachment';
import Fire from '@root/Fire';

const ATTACHMENT_SORT = [
  {
    key: 'date',
    iterator: 'date',
    title: 'Sort by date',
    reverse: true,
  },
  {
    key: 'title',
    iterator: (att) => att.title.toLowerCase(), //WTF lodash?!
    title: 'Sort alphabetically',
  },
];

@autoBindMethods
export default class DealAttachments extends Component {
  static propTypes = {
    deal: PropTypes.object.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      uploading: null,
      deleting: null,
      sortBy: 'date',
    };
  }

  get currentSort() {
    return _.find(ATTACHMENT_SORT, { key: this.state.sortBy });
  }

  // VersionUploader is used to both upload new versions and update existing ones
  // We're using the state.uploading var to manage both;
  // If it's true, that means we're uploading a new version (i.e., no existing version being updated)
  // Otherwise it's a reference to the version being updated
  get updatingAttachment() {
    const { uploading } = this.state;
    return typeof uploading === 'boolean' ? null : uploading;
  }

  // Only deal Owners/Editors can manage (upload/edit/delete) Attachments
  get canManage() {
    return [DealRole.OWNER, DealRole.EDITOR].includes(_.get(this.props.deal, 'currentDealUser.role'));
  }

  //when we delete a attachment thats tied to a variable we need to update the variable as well.
  async onDelete(item) {
    const { deal } = this.props;

    if (item.attachmentType === ATTACHMENT_TYPE.VARIABLE) {
      const variable = _.find(deal.variables, { name: item.variableID });
      variable.value = null;
      const saveableVariable = _.pick(variable, SAVEABLE_VAR_FIELDS);
      await Fire.saveVariableDefinition(deal, saveableVariable);
    }
  }

  render() {
    const { deal, user, id, container, show, onHide, target, title, history } = this.props;
    const { uploading, deleting } = this.state;

    // Only display the user facing attachments
    let attachments = _.filter(deal.attachedFiles, { attachmentType: ATTACHMENT_TYPE.STORAGE });

    //map variable attachments to their variable
    attachments = _.map(attachments, (attachment) => {
      const { attachmentType, key } = attachment;
      if (attachmentType === ATTACHMENT_TYPE.VARIABLE) {
        const variable = _.find(deal.variables, (variable) => {
          return variable.value?.key === key;
        });
        if (variable) {
          attachment.variableID = variable.name;
        }
        return attachment;
      } else {
        return attachment;
      }
    });

    attachments = _.sortBy(attachments, this.currentSort.iterator);

    if (this.currentSort.reverse) {
      attachments.reverse();
    }

    return (
      <DealPanel id={id} onHide={onHide} show={show} target={target} title={title} container={container}>
        <div className="filter-bar">
          <Dropdown
            disabled={attachments.length < 2}
            dmpStyle="link"
            size="small"
            id="dd-sort-attachments"
            title={this.currentSort.title}
            onSelect={(sort) => this.setState({ sortBy: sort.key })}
            noUnderline
            dataCyToggle="dd-sort-attachments"
          >
            {ATTACHMENT_SORT.map((sort) => (
              <MenuItem key={sort.key} eventKey={sort} data-cy="sort-attachment-by">
                {sort.title}
              </MenuItem>
            ))}
          </Dropdown>
          {this.canManage && (
            <Button size="small" onClick={() => this.setState({ uploading: true })} data-cy="btn-new-attachment">
              New Attachment
            </Button>
          )}
        </div>
        <div className="versions-list panel-scroll" data-cy="attachment-list">
          {_.map(attachments, (attachment, idx) => (
            <DealAttachmentBlock
              key={idx}
              attachment={attachment}
              interactive={this.canManage}
              onEdit={(attachment) => this.setState({ uploading: attachment })}
              onDelete={(attachment) => this.setState({ deleting: attachment })}
            />
          ))}
        </div>

        <AttachmentUploader
          deal={deal}
          user={user}
          show={!!uploading}
          item={this.updatingAttachment}
          history={history}
          attachmentType={ATTACHMENT_TYPE.STORAGE}
          onClose={() => this.setState({ uploading: null })}
        />

        <DeleteAttachment
          user={user}
          show={!!deleting}
          item={deleting}
          interactive={false}
          onClose={() => this.setState({ deleting: null })}
          onDelete={this.onDelete}
        />
      </DealPanel>
    );
  }
}
