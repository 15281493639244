import { assign, get, pick } from 'lodash';

import { LEGACY_KEY } from './DealVersion';

export const ATTACHMENT_TYPE = {
  // Attached file represents a full contract (e.g., for third party paper)
  VERSION: 'version',
  // Attached file represents the full body of an Appendix in the contract
  APPENDIX: 'appendix',
  // Attached file represents (and should be inserted into) the value of a variable
  // (should probably be reserved for images?)
  VARIABLE: 'variable',
  // Attached file is only stored for reference material alongside contract e.g., for re-download
  // Used for both general "deal room" scenarios (e.g., attaching emails etc),
  // and to store source .doc/.docx files
  STORAGE: 'storage',
};

// 50MB max filesize for all Attachments, period.
export const MAX_SIZE = 50000000;

// Full list of all filetypes supported by Outlaw
export const ACCEPTED_TYPES = {
  PDF: {
    extension: 'pdf',
    mime: 'application/pdf',
    display: 'pdf',
    icon: 'pdf',
  },
  WORD: {
    extension: 'docx',
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    display: 'docx',
    icon: 'docx',
  },
  JSON: {
    extension: 'json',
    mime: 'application/json',
    display: 'json',
    icon: 'json',
  },
  VINE: {
    extension: 'vine',
    mime: 'application/json',
    display: 'vine',
    icon: 'vine',
  },
  PNG: {
    extension: 'png',
    mime: 'image/png',
    display: 'png',
    icon: 'image',
  },
  JPG: {
    extension: 'jpg',
    mime: 'image/jpeg',
    display: 'jpg',
    icon: 'image',
  },
  TXT: {
    extension: 'txt',
    mime: 'text/plain',
    display: 'jpg',
    icon: 'txt',
  },
  XLSX: {
    extension: 'xlsx',
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    display: 'xlsx',
    icon: 'spreadsheet',
  },
};

// Acceptable types for new contract uploads (.vine only allowed here, not for Versions)
// Annoying (but necessary) special case here; we only want to allow json import for Outlaws
// So this needs to be a function instead of pure const
export function NEW_CONTRACT_TYPES(user) {
  const types = [ACCEPTED_TYPES.PDF, ACCEPTED_TYPES.WORD];
  if (get(user, 'isAdmin')) {
    types.push(ACCEPTED_TYPES.VINE);
  }
  return types;
}

// Acceptable types for template uploads
export const TEMPLATE_TYPES = [ACCEPTED_TYPES.VINE, ACCEPTED_TYPES.WORD];

// Acceptable types for full contract (Version) uploads
export const VERSION_TYPES = [ACCEPTED_TYPES.PDF, ACCEPTED_TYPES.WORD];

// Acceptable types for simple file (Storage) uploads
export const STORAGE_TYPES = [
  ACCEPTED_TYPES.PDF,
  ACCEPTED_TYPES.JPG,
  ACCEPTED_TYPES.PNG,
  ACCEPTED_TYPES.TXT,
  ACCEPTED_TYPES.WORD,
  ACCEPTED_TYPES.JSON,
  ACCEPTED_TYPES.XLSX,
];

export default class Attachment {
  deal;
  key;

  extension = null;
  title = null;
  description = null;
  attachmentType = null;
  // date property is also stored on Version model,
  // so we only need it on Attachment if it's NOT a Version
  date = null;

  static Legacy(deal) {
    return new Attachment(
      {
        attachmentType: ATTACHMENT_TYPE.VERSION,
        extension: ACCEPTED_TYPES.PDF.extension,
        title: deal.info.name,
      },
      deal,
      LEGACY_KEY
    );
  }

  constructor(json, deal, key = null) {
    assign(this, pick(json, ['extension', 'title', 'description', 'attachmentType', 'date']), { key });

    if (this.attachmentType !== ATTACHMENT_TYPE.VERSION) {
      if (json.date instanceof Date) {
        this.date = json.date;
      } else if (typeof json.date === 'string') {
        this.date = new Date(parseInt(json.date));
      }
      // Should never get here, but just in case of unhandled legacy issues
      else {
        this.date = new Date();
      }
    }

    this.deal = deal;
  }

  get bucketPath() {
    if (this.key === LEGACY_KEY) {
      return `deals/${this.deal.dealID}/root.pdf`;
    } else {
      return `deals/${this.deal.dealID}/attachments/${this.key}.${this.extension}`;
    }
  }

  get json() {
    const json = pick(this, ['extension', 'title', 'description', 'attachmentType']);
    if (this.attachmentType !== ATTACHMENT_TYPE.VERSION) {
      json.date = this.date.getTime().toString();
    }
    return json;
  }

  get filename() {
    return `${this.title}.${this.extension}`;
  }
}
