import { observable } from 'mobx';

class OutlawModel {
  @observable user = null;
  @observable linkingSectionID = null;
}

const Model = new OutlawModel();
if (typeof window == 'object') window.Model = Model;
//const Model = new OutlawModel;

export default Model;
