import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

import TooltipButton from '@components/editor/TooltipButton';

const cl = classNamePrefixer('checkbox');

const Checkbox = ({
  block,
  bold,
  className,
  id,
  checked,
  onChange,
  name,
  children,
  disabled,
  tip,
  tipPlacement,
  dataCy,
  ...passingProps
}) => {
  const classNames = cx(
    cl(),
    { [cl('bold')]: bold },
    { [cl('block')]: block },
    { [cl('disabled')]: disabled },
    className
  );

  const check = (
    <div className={classNames} {...passingProps}>
      <label>
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          name={name}
          data-cy={dataCy}
        />
        <span>{children || ''}</span>
      </label>
    </div>
  );

  if (tip)
    return (
      <TooltipButton tipID={`tip-${id}`} tip={tip} placement={tipPlacement}>
        {check}
      </TooltipButton>
    );

  return check;
};

Checkbox.defaultProps = {
  block: false,
  bold: false,
  checked: false,
  disabled: false,
  name: null,
  tip: null,
  tipPlacement: 'top',
};

Checkbox.propTypes = {
  block: PropTypes.bool,
  bold: PropTypes.bool,
  checked: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.any,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  tip: PropTypes.node,
  tipPlacement: PropTypes.string,
};

export default Checkbox;
