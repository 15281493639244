import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import AdminSearchParams from '@core/models/AdminSearchParams';
import Team from '@core/models/Team';
import User from '@core/models/User';

import { Alert, DataTable } from '@components/dmp';

import { AUDIT_MANAGEMENT_COLUMNS } from '@components/deal/Columns';
import API from '@root/ApiClient';

@autoBindMethods
export default class Admin extends Component {
  static propTypes = {
    adminSearchParams: PropTypes.instanceOf(AdminSearchParams),
    og: PropTypes.func.isRequired,
    team: PropTypes.instanceOf(Team),
    teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
    user: PropTypes.instanceOf(User),
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      currentPage: 0,
      totalPages: 0,
      selectedUser: null,
    };

    this.onSearchData = _.debounce((value) => {
      this.props.adminSearchParams.query = value;
    }, 150);
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.og({ title: 'Outlaw - Admin - Audit Logs' });
    this.getAuditLogData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const adminSearchParams = _.get(this.props, 'adminSearchParams');
    const prevParams = _.get(prevProps, 'adminSearchParams');
    // Anytime SearchParams changes, run a new search
    if (!_.isEqual(adminSearchParams, prevParams)) {
      this.getAuditLogData();
    }
  }

  async getAuditLogData() {
    const { adminSearchParams } = this.props;
    this.setState({ loading: true });

    const { teamID, hitsPerPage, page } = adminSearchParams.apiArgs;

    if (!teamID) {
      this.setState({ loading: false, data: [] });
      return;
    }

    const apiParams = { teams: teamID, page, pageSize: hitsPerPage };

    const results = await API.call('getUserAuditLog', apiParams);
    if (!results.data) {
      this.setState({ loading: false, data: [] });
      return;
    }

    if (!this._isMounted) return;

    this.setState({
      data: results.data,
      totalHits: results.totalResults,
      currentPage: page,
      totalPages: results.totalPages,
      loading: false,
    });
  }

  get columns() {
    return [...AUDIT_MANAGEMENT_COLUMNS];
  }

  renderResults() {
    const { adminSearchParams } = this.props;
    const { currentPage, totalPages = 1, data, totalHits } = this.state;

    let startDisplay = 0;
    let endDisplay = 0;
    let resultSummary = '';

    startDisplay = currentPage * adminSearchParams.hitsPerPage + 1;
    endDisplay = startDisplay + data.length - 1;

    if (!totalHits) {
      resultSummary = 'No audit log data found.';
    } else {
      totalPages > 1
        ? `Showing ${startDisplay} - ${endDisplay} of ${totalHits} users`
        : `Showing ${data.length} user${data.length > 1 ? 's' : ''}`;
    }

    return (
      <div className="top-of-results" data-cy="top-of-results">
        <div className="results-and-tags">
          <div className="results">{resultSummary}</div>
        </div>
      </div>
    );
  }

  renderSelectTeam() {
    return (
      <Alert type="info" centered size="small">
        Audit Logs are Team specific.
        <br />
        Please select at least 1 Team to show results.
      </Alert>
    );
  }

  renderDataTable() {
    const { currentPage, data, totalPages, loading } = this.state;
    const { adminSearchParams } = this.props;

    return (
      <div className="audit-data-list">
        <DataTable
          clickable
          columns={this.columns}
          dropshadow
          loading={loading}
          data={data}
          loadingText="Fetching..."
          manual
          hasFixedColumns
          onPageChange={(pageIndex) => adminSearchParams.setPage(pageIndex + 1)}
          onPageSizeChange={(pageSize) => (adminSearchParams.hitsPerPage = pageSize)}
          page={currentPage}
          pages={totalPages}
          pageSize={adminSearchParams.hitsPerPage}
          showPaginationTop
          // onSortedChange={this.onSortChange}
          // sorted={this.sorted}
          // sortable
          TopPaginationComponent={this.renderResults}
        />
      </div>
    );
  }

  render() {
    const { adminSearchParams } = this.props;

    const hasTeams = !!adminSearchParams.getParams('teamID')?.length;

    return (
      <div className="admin-tab-header" data-cy="admin-tab-header">
        {/* <div className="d-flex justify-content-between align-items-center flex-wrap ">
          <h4>Audit Logs</h4>
          <div className="d-flex">
            <div className="admin-search-audit-log-data" data-cy="admin-search-audit-log-data">
              <FormControl
                type="text"
                onChange={(e) => this.onSearchData(e.target.value)}
                placeholder="Search team member by id, name, email, or title"
              />
            </div>
          </div>
        </div> */}
        {!hasTeams ? this.renderSelectTeam() : this.renderDataTable()}
      </div>
    );
  }
}
