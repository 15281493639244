import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

const cl = classNamePrefixer('loader');

const Loader = (props) => {
  const { centered, dark, full, inline, padding, size, className } = props;

  const cn = cx(
    cl(),
    cl(size),
    className,
    { [cl('centered')]: centered },
    { [cl('full')]: full },
    { [cl('inline')]: inline },
    { [cl('padding')]: padding },
    { [cl('dark')]: dark }
  );

  return (
    <div className={cn}>
      <div className={cl('spinner')}></div>
    </div>
  );
};

Loader.defaultProps = {
  centered: false,
  dark: false,
  full: false,
  inline: false,
  padding: false,
  size: 'default',
};

Loader.propTypes = {
  centered: PropTypes.bool,
  className: PropTypes.string,
  dark: PropTypes.bool,
  full: PropTypes.bool,
  inline: PropTypes.bool,
  padding: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'default', 'large']),
};

export default Loader;
