let instance = null;

class Core {
  Fire;
  Config = () => ({});
  functions = {
    stateFromHTML: null,
  };

  constructor() {
    if (instance) return instance;
    instance = this;
  }

  init({ Fire, Config, functions }) {
    console.log('Initializing @getoutlaw/Core');
    this.Fire = Fire;
    this.Config = () => Config;

    console.log('functions', functions);
    Object.keys(functions).forEach((name) => {
      if (this.functions.hasOwnProperty(name)) {
        console.log(`@getoutlaw/Core: Share function "${name}" with Core.`);
        this.functions[name] = functions[name];
      }
    });
  }
}

instance = new Core();

export default instance;
