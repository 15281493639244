import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, Modal, Radio } from 'react-bootstrap';

import WEBHOOKS from '@core/enums/Webhooks';
import User from '@core/models/User';

import { Button, Checkbox, Dropdown, Loader, MenuItem, Setting } from '@components/dmp';

@autoBindMethods
export default class WebhookModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    webhook: PropTypes.object,
    user: PropTypes.instanceOf(User).isRequired,
    team: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      url: null,
      enabled: false,
      eventType: null,
      subtypes: [],
    };
  }

  componentDidMount() {
    const { webhook } = this.props;
    this.populate(webhook);
  }

  componentDidUpdate(prevProps) {
    const { webhook, show } = this.props;
    if (show && !prevProps.show) {
      this.populate(webhook);
    }
  }

  handleChange(property, event) {
    this.setState({ [property]: event.target.value });
  }

  get canSubmit() {
    const { url, eventType, subtypes } = this.state;
    return url && eventType && subtypes?.length;
  }

  get isNew() {
    return !this.props.webhook;
  }

  get eventTypes() {
    return WEBHOOKS;
  }

  get eventTypeName() {
    const eventType = _.find(this.eventTypes, { key: this.state.eventType });
    return _.get(eventType, 'name', 'Select Event Type');
  }

  get subtypes() {
    const { eventType } = this.state;
    return WEBHOOKS[eventType].subtypes;
  }

  updateSubtypes(event) {
    let { subtypes } = this.state;

    if (!subtypes) {
      subtypes = [];
    }

    const subtype = event.target.name;

    if (!subtypes.includes(subtype)) {
      // add subtype
      subtypes.push(subtype);
    } else {
      // remove subtype
      subtypes = subtypes.filter((e) => {
        return e !== subtype;
      });
    }

    this.setState({ subtypes });
  }

  updateEnabled() {
    let { enabled } = this.state;
    enabled = !enabled;
    this.setState({ enabled });
  }

  populate(webhook) {
    this.setState({
      url: _.get(webhook, 'url', ''),
      enabled: _.get(webhook, 'enabled', ''),
      eventType: _.get(webhook, 'eventType', ''),
      subtypes: _.get(webhook, 'subtypes', ''),
    });
  }

  async saveWebhook() {
    const { webhook, onClose, team, user } = this.props;

    const json = _.pick(this.state, ['url', 'eventType', 'enabled', 'subtypes']);
    json.id = _.get(webhook, 'id', null);
    if (this.isNew) json.enabled = false;

    await this.setState({ loading: true });
    await API.call('saveTeamWebhook', { teamID: team.teamID, uid: user.id, webhook: json });
    await this.setState({ loading: false });

    onClose();
  }

  render() {
    const { show, onClose, onDelete } = this.props;
    const { loading, url, eventType, subtypes } = this.state;

    return (
      <Modal backdrop="static" keyboard={false} dialogClassName="webhook" show={show} onHide={onClose}>
        <Modal.Header>
          <span className="headline">{this.isNew ? 'Add' : 'Update'} Webhook</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <Setting title="URL">
              <FormControl
                bsSize="small"
                disabled={loading}
                onChange={(e) => this.handleChange('url', e)}
                placeholder="https://your-service-url.com/hook"
                value={url}
              />
            </Setting>
            <Setting title="Event Type">
              <Dropdown
                disabled={loading}
                id="dd-webhook-event-type"
                onSelect={(eventType) => this.setState({ eventType })}
                title={this.eventTypeName}
                width={220}
              >
                {_.map(this.eventTypes, (eventType) => (
                  <MenuItem key={eventType.key} eventKey={eventType.key} disabled={eventType.disabled}>
                    {eventType.name}
                  </MenuItem>
                ))}
              </Dropdown>
            </Setting>

            {eventType && (
              <Setting title="Event Action" subtitle="Specific Action(s) triggering the Webhook">
                <div>
                  {_.map(this.subtypes, (subtype) => (
                    <div>
                      <Checkbox
                        checked={subtypes.indexOf(subtype.key) > -1}
                        id={`chk-subtype-${subtype.name}`}
                        key={subtype.key}
                        name={subtype.key}
                        onChange={this.updateSubtypes}
                      >
                        {subtype.name}
                        {subtype.description && (
                          <>
                            <br />
                            <small>{subtype.description}</small>
                          </>
                        )}
                      </Checkbox>
                      <br />
                    </div>
                  ))}
                </div>
              </Setting>
            )}
          </div>
        </Modal.Body>

        <Modal.Footer>
          {loading && <Loader />}
          {this.isNew ? null : (
            <Button dmpStyle="danger" style={{ float: 'left' }} onClick={onDelete}>
              Delete
            </Button>
          )}
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button dmpStyle="primary" disabled={!this.canSubmit || loading} onClick={this.saveWebhook}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
