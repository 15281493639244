class ReporterClass {
  get Sentry() {
    return (typeof window !== 'undefined' && window.Sentry) || null;
  }

  error(error, log) {
    // eslint-disable-next-line no-console
    if (log || !this.Sentry) {
      console.error(log, error);
    }

    if (this.Sentry) {
      this.Sentry.captureException(error);
    }
  }
}

const Reporter = new ReporterClass();
export default Reporter;
