import { assign, pick } from 'lodash';

import Address from './Address';

export const DEFAULT_COLOR = '#854AD3'; //default to outlaw brand color

export const LETTERHEAD_OPTIONS = [
  { key: 'default', value: 'Default Layout' },
  { key: 'customized', value: 'Team Letterhead' },
];

export default class Branding {
  license = '';
  legalName = '';
  logo = null;
  address = '';
  addressProperties = {};
  brandColor = DEFAULT_COLOR;

  constructor(json) {
    const branding = pick(json, ['license', 'legalName', 'logo', 'address', 'letterhead', 'addressProperties']);

    if (
      branding.addressProperties &&
      typeof branding.addressProperties === 'object' &&
      !(branding.addressProperties instanceof Address)
    ) {
      branding.addressProperties = new Address(branding.addressProperties);
      if (!branding.address || !branding.address.length) {
        branding.address = branding.addressProperties.fullAddress;
      }
    }
    assign(this, branding);
    if (json && typeof json.brandColor == 'string') this.brandColor = json.brandColor;
  }

  get empty() {
    return !this.legalName && !this.logo && !this.address;
  }

  get json() {
    if (this.addressProperties && this.addressProperties instanceof Address) {
      return {
        ...pick(this, ['license', 'legalName', 'logo', 'address', 'brandColor', 'letterhead']),
        addressProperties: this.addressProperties.json,
      };
    }
    return pick(this, ['license', 'legalName', 'logo', 'address', 'brandColor', 'letterhead']);
  }
}
