import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import SectionType from '@core/enums/SectionType';
import Payment from '@core/models/Payment';

import ColorLabel from '@components/ColorLabel';
import DealBranding from '@components/deal/DealBranding';
import ContentSection from '@components/section_types/ContentSection';
import ListSection from '@components/section_types/ListSection';
import PaymentSection from '@components/section_types/PaymentSection';
import Fire from '@root/Fire';

@autoBindMethods
export default class OverviewSection extends Component {
  static propTypes = {
    section: PropTypes.object.isRequired,
    editSection: PropTypes.func,
    user: PropTypes.object.isRequired,
    readonly: PropTypes.bool,
    editable: PropTypes.bool,
    container: PropTypes.any,
    showBranding: PropTypes.bool,
    locked: PropTypes.bool,
    signed: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      //stores the newly added source section ID
      queueFocus: null,
    };
    this.sectionRefs = {};
  }

  componentDidUpdate() {
    const { deal } = this.props;

    if (this.state.queueFocus) {
      //If we have a section added and its queued for focus wait until the update deal is passed down. (from dealView loadDeal);
      if (_.findIndex(_.keys(deal.sections), (id) => this.state.queueFocus === id) > -1) {
        this.onCreate(this.state.queueFocus);
        this.setState({ queueFocus: null });
      }
    }
  }

  get className() {
    const { section } = this.props;
    return cx('deal-section', section.sectiontype.toLowerCase(), section.status.data);
  }

  get isEmpty() {
    const { section } = this.props;
    switch (section.sectiontype) {
      case 'PAYMENT':
        return section.deal.payments.length == 0;
      case 'SCOPE':
        return _.filter(section.children, { sectiontype: 'ITEM' }).length == 0;
      default:
        return false;
    }
  }

  get canAdd() {
    const { section } = this.props;
    const deal = section.deal,
      du = deal.currentDealUser;
    if (!du) return false;

    const app = _.find(section.children, { sectiontype: 'APPENDIX' });

    //special case for OverviewSections that map to appendices
    //appendices can be assigned like variables, which allows editing of just that content
    //even though user is not editor
    if (!deal.locked && app && du && app.assigned && app.assigned == du.partyID) return true;

    if (!deal.currentDealUser.canEdit) return false;

    switch (section.sectiontype) {
      case 'PAYMENT':
      case 'SCOPE':
        return true;
      default:
        return false;
    }
  }

  get children() {
    const { section } = this.props;

    // First filter view based on conditions
    const filtered = section.deal.applyConditions(section.children);

    // Now flatten so that "child" LIST sections instead appear inline as siblings
    // (And don't get nested inside of accordions)
    const flattened = [];
    _.forEach(filtered, (child) => {
      flattened.push(child);
      _.forEach(child.children, (grandchild) => {
        if (grandchild.sectiontype === SectionType.LIST) flattened.push(grandchild);
      });
    });
    return flattened;
  }

  async addItem(idx) {
    const { section } = this.props;

    let newSectionID = null;
    switch (section.sectiontype) {
      case 'PAYMENT': {
        const payment = new Payment({}, section.deal);
        if (idx === undefined || idx == -1) section.deal.payments.push(payment);
        else section.deal.payments.splice(idx, 0, payment);
        Fire.savePayment(payment, {});
        break;
      }
      case 'SCOPE':
        newSectionID = await Fire.addItemSection(section, section.children.length);
        this.setState({ queueFocus: newSectionID });
        break;
      default:
        return;
    }
  }

  onCreate(newSectionID) {
    const newSection = _.get(this.sectionRefs[newSectionID], 'current');
    if (newSection) newSection.focus();
  }

  renderChildren() {
    const { section, container, editable, readonly, user, signed, locked } = this.props;

    switch (section.sectiontype) {
      case 'CONTENT': //legacy data
      case SectionType.SUMMARY: {
        return _.map(this.children, (child) => {
          const props = {
            key: child.id,
            container,
            editable,
            readonly,
            overviewMode: true,
            parentStatus: section.status,
            section: child,
            user,
            signed,
            locked,
          };

          switch (child.sectiontype) {
            case SectionType.LIST:
              return <ListSection {...props} />;
            default:
              return <ContentSection {...props} hideMenu renderChildren />;
          }
        });
      }
      // PAYMENT and SCOPE cases need to remain in order to support legacy documents;
      // However these sectiontypes are no longer supported (new templates cannot add them) as of April 2022
      case SectionType.PAYMENT: {
        const app = _.find(section.children, { sectiontype: SectionType.APPENDIX });
        return section.deal.payments.map((pmt, idx) => (
          <PaymentSection
            key={idx}
            user={user}
            overviewMode
            payment={pmt}
            section={pmt}
            appendix={app}
            create={(idx) => this.addItem(idx)}
          />
        ));
      }
      case SectionType.SCOPE: {
        // A scope can have APPENDIX type child sections to tie it to its place in pdf output
        // so we need to only show user-created ITEM type sections here
        const children = _.filter(section.children, { sectiontype: 'ITEM' });

        return _.map(children, (child) => {
          // Ensure that there's a ref established for every ContentSection that gets rendered
          if (!this.sectionRefs[child.id]) this.sectionRefs[child.id] = createRef();
          return (
            <ContentSection
              setQueueFocus={(id) => this.setState({ queueFocus: id })}
              ref={this.sectionRefs[child.id]}
              container={container}
              editable={editable}
              editableTitle
              key={child.id}
              overviewMode
              parentStatus={section.status}
              readonly={readonly}
              section={child}
              user={user}
              onCreate={this.onCreate}
              signed={signed}
              locked={locked}
            />
          );
        });
      }
      default:
        return null;
    }
  }

  render() {
    const { section, showBranding } = this.props;

    if (SectionType.src(section.sectiontype)) return null;
    //no more parties sections! only render signature directly on source view
    if (section.sectiontype == 'PARTIES') return null;

    const { layout } = section.deal.style;
    const styleTitle = section.styleTitle.css;
    const styleBody = section.styleBody.css;

    if (section.style.isAligned) {
      styleTitle.textAlign = section.style.align;
      styleBody.textAlign = section.style.align;
    }

    return (
      <div className={this.className}>
        {/* Show branding if specified (will be true on first block)*/}
        {showBranding && <DealBranding branding={section.deal.branding} deal={section.deal} />}

        {/* Don't show deal section headers for public deals*/}
        {!section.deal.info.public && (
          <div className="section-title" style={{ marginBottom: layout.Section.web.bottom }}>
            <div style={styleTitle}>{section.displayname}</div>
            <div style={styleBody}>{section.currentVersion.body.getPlainText()}</div>
          </div>
        )}

        {this.renderChildren()}

        {this.canAdd && (
          <div className="add-item" data-cy="add-item" onClick={this.addItem}>
            <span>Add item</span>
            {this.isEmpty && <ColorLabel status="todo" right label=" " />}
          </div>
        )}
      </div>
    );
  }
}
