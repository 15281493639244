import { useCallback, useEffect, useState } from 'react';

function useKeySequence(target = [], callback = () => {}) {
  const [entered, setEntered] = useState([]);

  const handleKeydown = useCallback((event) => {
    const key = event.key.toLowerCase();
    const targetKey = target[entered.length];

    if (key === targetKey) {
      if (entered.length + 1 === target.length) {
        callback();
        setEntered([]);
      } else {
        setEntered((prev) => [...prev, key]);
      }
    } else {
      // Reset when wrong key pressed
      setEntered([]);
    }
  });

  useEffect(() => {
    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [entered]);
}

export default useKeySequence;
