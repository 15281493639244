import { assign, get, pick } from 'lodash';

import DealRole from '../enums/DealRole';
import Address from './Address';
import User from './User';

export default class Teammate {
  id = null;
  email = null;
  role = null;

  fullName = null;
  org = null;
  title = null;
  phone = null;
  address = null;
  addressProperties = {};

  constructor(data) {
    if (data instanceof User) {
      this.id = data.id;
      this.email = get(data, 'info.email', data.email);
      this.role = DealRole.OWNER;
      assign(this, pick(data.info, ['fullName', 'org', 'title', 'phone', 'address', 'addressProperties']));
    } else if (typeof data === 'object') {
      assign(this, pick(data, ['id', 'email', 'fullName', 'org', 'title', 'phone', 'address', 'addressProperties']));
      this.role = DealRole.VIEWER;
    }
    if (
      this.addressProperties &&
      typeof this.addressProperties === 'object' &&
      !(this.addressProperties instanceof Address)
    ) {
      this.addressProperties = new Address(this.addressProperties);
      if (this.addressProperties.fullAddress && this.addressProperties.fullAddress.length) {
        this.address = this.addressProperties.fullAddress;
      }
    }
  }

  get json() {
    if (this.addressProperties && this.addressProperties instanceof Address) {
      return {
        ...pick(this, ['id', 'email', 'fullName', 'org', 'role', 'title', 'phone', 'address']),
        addressProperties: this.addressProperties.json,
      };
    }
    return pick(this, ['id', 'email', 'fullName', 'org', 'role', 'title', 'phone', 'address']);
  }
}
