import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';

import { Icon } from '@components/dmp';

const DropdownIndicator = () => {
  return <Icon name="chevronDown" />;
};

export default class MultiselectDropdown extends Component {
  static propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    defaultValue: PropTypes.array,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { options, onChange, defaultValue, onMenuClose } = this.props;

    return (
      <Select
        data-cy="multi-select"
        options={options}
        onChange={onChange}
        onMenuClose={onMenuClose}
        defaultValue={defaultValue}
        isMulti
        closeMenuOnSelect={false}
        isClearable={false}
        name="multi-select"
        className="multi-select-variable-editor"
        classNamePrefix="select"
        components={{ DropdownIndicator }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: '#edeff0',
            primary: '#edeff0',
            primary75: '#d9dee4',
            primary50: '#d9dee4',
            dangerLight: '#d9dee4',
          },
        })}
      />
    );
  }
}
