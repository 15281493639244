import React, { Component } from 'react';

import cx from 'classnames';

import { Link } from 'react-router-dom';

import { ButtonIcon, Icon, MobileNav } from '@components/dmp';

import UserMenu, { ACCOUNT_ITEMS } from '@components/UserMenu';
import TooltipButton from '@components/editor/TooltipButton';
import SearchBox, { SEARCH_PROMPT } from '@components/search/SearchBox';
import TeamInfo from '@components/teams/TeamInfo';
import { DASH_ITEMS } from '@root/nav/NavDash';

export default class TopBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false, //for mobile hamburger slide in/out
      creatingTeam: false,
      editingTeam: null,
      showSearch: false,
    };
  }

  get isContractsPage() {
    const { location } = this.props;
    return location.pathname.indexOf('/dashboard/contracts') > -1;
  }

  nav(path) {
    this.setState({ show: false });
    if (typeof path == 'function') path();
    else this.props.history.push(path);
  }

  renderModals() {
    const { user, team, editingTeam, creatingTeam, selectTeam, getTeams, toggleTeamCreation, history, location } =
      this.props;
    const { showSearch } = this.state;

    if (!user) return null;

    return (
      <>
        <SearchBox
          show={showSearch}
          close={() => this.setState({ showSearch: false })}
          history={history}
          location={location}
          user={user}
        />

        <TeamInfo
          creatingTeam={creatingTeam}
          editingTeam={editingTeam}
          selectTeam={selectTeam}
          getTeams={getTeams}
          team={creatingTeam ? null : team}
          toggleTeamCreation={toggleTeamCreation}
          user={user}
        />
      </>
    );
  }

  renderMobile() {
    const { history, location, user, dark } = this.props;
    const { show } = this.state;
    const returnPath = encodeURIComponent(location.pathname);

    return (
      <div className="top-bar">
        <ButtonIcon
          icon="hamburger"
          className={cx('hamburger', { ['dark-mode']: dark })}
          onClick={() => this.setState({ show: true })}
        ></ButtonIcon>
        <MobileNav show={show} onHide={() => this.setState({ show: false })} renderToRoot={false}>
          <ul>
            {user &&
              !user.anonymous &&
              DASH_ITEMS.map((item) => {
                if (typeof item.show === 'function' && !item.show(this.props)) return null;

                const disabled = typeof item.disabled === 'function' && item.disabled(this.props);
                if (disabled) return null;

                return (
                  <li key={item.path} onClick={() => this.nav(item.path)}>
                    <a>{item.title}</a>
                  </li>
                );
              })}

            {user &&
              !user.anonymous &&
              ACCOUNT_ITEMS.map((item) => (
                <li key={item.path} onClick={() => this.nav(item.path)}>
                  <a>{item.title}</a>
                </li>
              ))}

            {user && user.anonymous && (
              <li>
                <a className="sign-up" onClick={() => history.push(`/signup/?redirect=${returnPath}`)}>
                  Sign Up
                </a>
                {' / '}
                <a className="sign-in" onClick={() => history.push(`/login/?redirect=${returnPath}`)}>
                  Log in
                </a>
              </li>
            )}
          </ul>

          <img src="/assets/svg/logo-outline-black.svg" alt="Outlaw" className="logo" />

          {this.isContractsPage && (
            <div className="omnisearch" onClick={() => this.setState({ showSearch: true })} data-cy="omnisearch">
              <Icon name="search" />
            </div>
          )}
        </MobileNav>
        {this.renderModals()}
      </div>
    );
  }

  renderDesktop() {
    const { routeConfig, history, location, user } = this.props;

    const mode = routeConfig.topBarMode || 'default';
    // if currently viewing template, logo links back to templates list instead of contracts
    const isOnTemplate = mode === 'dealview' && location.pathname.match(/\/editor$/);
    const linkPath = isOnTemplate
      ? '/dashboard/templates'
      : mode === 'dealview'
      ? '/dashboard/contracts'
      : '/dashboard';

    // setup logo to be a smart link wrapped in a tooltip if we're in a deal
    const logo = (
      <Link to={linkPath}>
        <img src="/assets/svg/logo-color.svg" alt="Outlaw" className="logo" />
      </Link>
    );

    return (
      <div className={`top-bar mode-${mode}`}>
        {mode === 'dealview' ? (
          <TooltipButton
            placement="right"
            tipID="just-the-logo-tip"
            tip={`Back to ${isOnTemplate ? 'Templates' : 'Contracts'}`}
          >
            {logo}
          </TooltipButton>
        ) : (
          logo
        )}

        {/* Don't show search on deal view */}
        {mode !== 'dealview' && (
          <>
            <div className="omnisearch" onClick={() => this.setState({ showSearch: true })} data-cy="omnisearch">
              <Icon name="search" />
              <span>{SEARCH_PROMPT}</span>
            </div>
          </>
        )}

        <UserMenu user={user} history={history} location={location} />

        {this.renderModals()}
      </div>
    );
  }

  render() {
    const { mobile } = this.props;
    if (mobile) return this.renderMobile();
    else return this.renderDesktop();
  }
}
