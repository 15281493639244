import { difference, find, forEach, get, includes, map } from 'lodash';

import { States } from '../enums/USStates';
import { DateFormatter } from '../utils/DateTime';
import { getValueAsNumber } from '../utils/ValueTypeFormatter';
import { OPERATORS } from './Operator';
import { EMPTY_SEARCH_VALUE, LEGACY_EMPTY_SEARCH_VALUE, ValueType, isNumeric } from './Variable';

export const DYNAMIC_DATES = [
  { key: 'nextWeek', title: 'Next 7 days', val: 7 },
  { key: 'nextMonth', title: 'Next 30 days', val: 30 },
  { key: 'nextQuarter', title: 'Next 90 days', val: 90 },
  { key: 'nextYear', title: 'Next 365 days', val: 365 },
  { key: 'lastWeek', title: 'Last 7 days', val: -7 },
  { key: 'lastMonth', title: 'Last 30 days', val: -30 },
  { key: 'lastQuarter', title: 'Last 90 days', val: -90 },
  { key: 'lastYear', title: 'Last 365 days', val: -365 },
];

export default class VariableFilter {
  // variable is just the name of the variable (not full object)
  // so we also need to know what valueType it is, for display
  variable = null;
  valueType = null;
  operator = null;
  values = [];

  constructor(name, condition = {}) {
    this.variable = name;

    //Assume we are trying to clear the filter
    if (condition === null) return;

    //Legacy conditions were stored as variable: [values]
    //So, if condition is an array, assume we are processing a Legacy condition
    if (Array.isArray(condition)) {
      //If no values, assume empty comparison
      this.values = condition || [];

      //Conditions were all assumed to be Lists
      this.valueType = ValueType.LIST;
      this.operator = OPERATORS.IN;
    } else {
      const { operator, valueType, values } = condition;

      this.valueType = valueType;

      //Search passes in Operator as a string like '<'
      if (!operator) {
        //If no operator, assume List comparison unless DATE
        this.operator = valueType === ValueType.DATE ? OPERATORS.DYNAMIC : OPERATORS.IN;
      } else {
        this.operator = typeof operator === 'string' ? find(OPERATORS, { key: operator }) : operator;
      }

      this.values = values || [];
    }
  }

  get json() {
    return {
      operator: get(this, 'operator.key', null),
      valueType: this.valueType,
      variable: this.variable,
      values: this.values,
    };
  }

  // Condensed text version of the filter for display
  get displayLabel() {
    let val1 = this.values[0] || '',
      val2 = this.values[1] || '',
      options = this.values;
    // Non-dynamic dates are stored numerically (seconds) so need to convert
    if (this.valueType === ValueType.DATE && this.operator.key !== OPERATORS.DYNAMIC.key) {
      //get time offset in miliseconds add 60 THIS CHANGES SUMMER TO WINTER with datlight savings take upper end
      if (val1) val1 = DateFormatter.mdy(new Date(val1 * 1000 + DateFormatter.getOffset(new Date(val1 * 1000))));
      if (val2) val2 = DateFormatter.mdy(new Date(val2 * 1000 + DateFormatter.getOffset(new Date(val2 * 1000))));
    }

    // States are stored by keys; find full names for display
    if (this.valueType === ValueType.STATE) {
      options = map(options, (state) => States[state]);
    }

    switch (this.operator.key) {
      case OPERATORS.EQUAL.key:
      case OPERATORS.UNEQUAL.key:
      case OPERATORS.LESS.key:
      case OPERATORS.BEFORE.key:
      case OPERATORS.GREATER.key:
      case OPERATORS.AFTER.key:
        return `${this.variable} ${this.operator.title.toLowerCase()} ${val1}`;
      case OPERATORS.BETWEEN.key:
        return `${this.variable} ${this.operator.title.toLowerCase()} ${val1} - ${val2}`;
      case OPERATORS.KNOWN.key:
      case OPERATORS.UNKNOWN.key:
        return `${this.variable} ${this.operator.title.toLowerCase()}`;
      case OPERATORS.IN.key:
      case OPERATORS.OUT.key:
      case OPERATORS.ALL.key:
        return `${this.variable} ${this.operator.title.toLowerCase()} [${options.join(', ')}]`;
      case OPERATORS.DYNAMIC.key:
        return `${this.variable} ${get(find(DYNAMIC_DATES, { val: val1 }), 'title', '')}`;
      default:
        return '';
    }
  }

  get isNumeric() {
    return isNumeric(this.valueType);
  }

  get errorMsg() {
    switch (this.operator.key) {
      case OPERATORS.EQUAL.key:
      case OPERATORS.UNEQUAL.key:
      case OPERATORS.IN.key:
      case OPERATORS.OUT.key:
      case OPERATORS.ALL.key:
      case OPERATORS.LESS.key:
      case OPERATORS.BEFORE.key:
      case OPERATORS.GREATER.key:
      case OPERATORS.AFTER.key:
      case OPERATORS.DYNAMIC.key:
        return !this.values.length ? '(not set)' : null;

      case OPERATORS.BETWEEN.key:
        return this.values.length != 2 ? '(values missing)' : null;

      default:
        return null;
    }
  }

  // Test this filter against a given value to see whether it passes
  test(value, isNumeric = false) {
    let compare1 = this.values[0] || null,
      compare2 = this.values[1] || null,
      compareValue = value;

    if (this.operator?.key === OPERATORS.KNOWN.key) return !!compareValue;
    if (this.operator?.key === OPERATORS.UNKNOWN.key) return !compareValue;

    if (isNumeric) {
      compareValue = getValueAsNumber(value);
      compare1 = getValueAsNumber(compare1);
      compare2 = getValueAsNumber(compare2);
    }

    //Legacy support: Previously all Variables were Lists with IN comparison
    if (!this.operator || !this.operator?.key) this.operator = OPERATORS.IN;

    //If a date is passed in as a string, try to convert it for comparison
    if (this.valueType === ValueType.DATE) {
      if (!compareValue) {
        if (this.operator?.key === OPERATORS.UNEQUAL.key) return false;
        if (this.operator?.key === OPERATORS.UNKNOWN.key) return true;

        return false;
      } else {
        if (typeof compareValue === 'string') {
          compareValue = new Date(compareValue);

          if (isNaN(compareValue)) return false;

          compareValue.setHours(0, 0, 0, 0);
          compareValue = compareValue.getTime();
        } else if (typeof compareValue === 'number') {
          compareValue = new Date(compareValue * 1000 + DateFormatter.getOffset(new Date(compareValue * 1000))); //Saved without milliseconds
          compareValue.setHours(0, 0, 0, 0);
          compareValue = compareValue.getTime();
        }

        if ([OPERATORS.DYNAMIC.key].includes(this.operator?.key)) {
          compare1 = DateFormatter.daysFromNow(compare1);
        } else {
          compare1 = new Date(compare1 * 1000 + DateFormatter.getOffset(new Date(compare1 * 1000)));
        }

        compare1.setHours(0, 0, 0, 0);
        compare1 = compare1.getTime();

        if (compare2) {
          compare2 = new Date(compare2 * 1000 + +DateFormatter.getOffset(new Date(compare2 * 1000)));
          compare2.setHours(0, 0, 0, 0);
          compare2 = compare2.getTime();
        }
      }
    }

    //If state is passed in as the full name
    if (this.valueType === ValueType.STATE) {
      compareValue = States[value] || Object.keys(States).find((key) => States[key] === value);
    }

    const today = new Date().setHours(0, 0, 0, 0); //Last midnight

    if (isNumeric) {
      value = Number(value);
      compare1 = Number(compare1);
      compare2 = Number(compare2);
    }

    if (
      this.valueType === ValueType.MULTI_SELECT &&
      compareValue &&
      compareValue !== EMPTY_SEARCH_VALUE &&
      compareValue !== LEGACY_EMPTY_SEARCH_VALUE
    ) {
      compareValue = compareValue.split(', ');
    }

    switch (this.operator.key) {
      case OPERATORS.EQUAL.key:
        return compareValue == compare1;
      case OPERATORS.UNEQUAL.key:
        return compareValue != compare1;
      case OPERATORS.KNOWN.key:
        return !!compareValue && compareValue !== EMPTY_SEARCH_VALUE && compareValue !== LEGACY_EMPTY_SEARCH_VALUE;
      case OPERATORS.UNKNOWN.key:
        return !compareValue || compareValue === EMPTY_SEARCH_VALUE || compareValue === LEGACY_EMPTY_SEARCH_VALUE;
      case OPERATORS.IN.key:
        if (this.valueType === ValueType.MULTI_SELECT) {
          let found = false;
          if (compareValue) {
            forEach(this.values, (value) => {
              if (includes(compareValue, value)) found = includes(compareValue, value);
            });
          }
          return found;
        } else {
          return this.values.indexOf(compareValue) > -1;
        }
      case OPERATORS.OUT.key:
        if (this.valueType === ValueType.MULTI_SELECT) {
          let notFound = false;
          if (compareValue) {
            forEach(this.values, (value) => {
              if (!includes(compareValue, value)) notFound = !includes(compareValue, value);
            });
          }
          return notFound;
        } else {
          return this.values.indexOf(compareValue) === -1;
        }
      case OPERATORS.ALL.key:
        if (compareValue && compareValue !== EMPTY_SEARCH_VALUE && compareValue !== LEGACY_EMPTY_SEARCH_VALUE) {
          const diff = difference(this.values, compareValue);
          return diff.length === 0;
        } else {
          return false;
        }
      case OPERATORS.LESS.key:
      case OPERATORS.BEFORE.key:
        return compareValue < compare1;
      case OPERATORS.GREATER.key:
      case OPERATORS.AFTER.key:
        return compareValue > compare1;
      case OPERATORS.BETWEEN.key:
        return compareValue >= compare1 && compareValue <= compare2;
      case OPERATORS.DYNAMIC.key:
        // Dynamic dates are actually always *between* today and another (dynamic) date
        // comparison depends on whether that other date is in the future
        if (compare1 >= today) {
          //The date is in the future
          return compareValue >= today && compareValue <= compare1;
        } else {
          //The date is in the past
          return compareValue >= compare1 && compareValue <= today;
        }
      default:
        console.log(`[SEARCH] - operator [${this.operator.title}] not yet supported`);
        return true;
    }
  }
}
