import _ from 'lodash';

import { getUniqueKey } from '../utils/Generators';
import LensValueFilter from './LensValueFilter';

export const LENS_TYPES = [
  {
    key: 'variable',
    title: 'Variable',
    plural: 'Variables',
    tip: 'Variable Lenses have filters for comparison of values with this template',
    show: () => true,
    icon: 'variable',
  },
  {
    key: 'clause',
    title: 'Clause',
    plural: 'Clauses',
    tip: 'Clause Lenses have linked sections for comparison of language with this template',
    show: (template) => template.documentAI,
    icon: 'clause',
  },
  {
    key: 'advanced',
    title: 'Advanced',
    plural: 'Advanced',
    tip: 'Advanced Lenses scan the entire document for a custom question.',
    show: () => true,
    icon: 'numbering',
  },
];

export const CLAUSE_LENS_PROMPTS = [
  {
    id: 'exists',
    title: 'Exists',
    prompt: '',
    question: 'Does clause appear in document?',
    answer: (failedCheck) => {
      return failedCheck ? 'No' : 'Yes';
    },
  },
  {
    id: 'not-exist',
    title: 'Does Not Exist',
    prompt: '',
    question: 'Is clause absent from document?',
    answer: (failedCheck) => {
      return failedCheck ? 'No, clause appears in document.' : 'Yes, clause is absent from document.';
    },
  },
  {
    id: 'additional-terms',
    title: 'Has Additional Terms',
    prompt:
      'Does the text in the <records> tag have additional information that is not present in the text found in the <sample> tag? Please give your answer as a boolean value. Please give your confidence interval in your answer on a separate line formatted as a decimal from 0.00 to 1.00.',
    question: 'Matches origin clause?',
    answer: (failedCheck) => {
      return failedCheck ? 'No, clause contains additional terms.' : 'Yes, clause matches origin clause.';
    },
  },
];

export const ADVANCED_LENS_DEFAULT = {
  id: getUniqueKey(),
  option: 'None of the above',
  riskValue: 0,
  isDefault: true,
  timestamp: Date.now(),
};

export default class Lens {
  id;
  type;
  title;

  //for Variable Lenses
  relatedVariable;
  valueFilters = {};

  //for Clause Lenses
  relatedSections;
  clauseFilters = {};

  //for Advanced Lenses
  question;
  advancedFilters = {};

  constructor({
    id,
    type,
    title,
    relatedSections,
    valueFilters = {},
    relatedVariable = null,
    clauseFilters = {},
    advancedFilters = {},
    question = null,
  }) {
    _.assign(this, { advancedFilters, id, type, title, relatedVariable, relatedSections, question });

    // Serialize/deserialize delimited properties (relatedSections)
    this.relatedSections = relatedSections ? relatedSections.split('|') : [];

    _.forEach(clauseFilters, (clauseFilter) => {
      const title = _.find(CLAUSE_LENS_PROMPTS, { id: clauseFilter.id }).title;
      this.clauseFilters[clauseFilter.id] = { ...clauseFilter, title };
    });

    _.forEach(valueFilters, (lensValueFilter) => {
      this.valueFilters[lensValueFilter.id] = new LensValueFilter(lensValueFilter);
    });
  }

  get allFilters() {
    return { ...this.valueFilters, ...this.clauseFilters, ...this.advancedFilters };
  }

  get isCreating() {
    if (this.type === 'variable') {
      return !this.relatedVariable;
    }
    if (this.type === 'clause') {
      return this.relatedSections === 0 || !this.title;
    }

    if (this.type === 'advanced') {
      return !this.question || !this.title;
    }
  }

  get json() {
    const obj = _.pick(this, [
      'id',
      'type',
      'title',
      'relatedVariable',
      'clauseCheck',
      'clauseFilters',
      'groups',
      'valueFilters',
      'advancedFilters',
      'question',
    ]);

    // Serialize/deserialize delimited properties (relatedSections)
    obj.relatedSections = this.relatedSections.length ? this.relatedSections.join('|') : null;

    return obj;
  }

  get filters() {
    switch (this.type) {
      case 'advanced':
        return 'ai';
      case 'clause':
        return this.clauseFilters;
      case 'variable':
        return this.valueFilters;
    }
  }

  get icon() {
    switch (this.type) {
      case 'advanced':
        return '';
      case 'clause':
        return 'clause';
      case 'variable':
        return 'variable';
    }
  }

  get isVariable() {
    return this.type === 'variable';
  }

  get isClause() {
    return this.type === 'clause';
  }

  get isAdvanced() {
    return this.type === 'advanced';
  }

  get options() {
    let options = '';
    _.values(this.advancedFilters).forEach((filter, index) => {
      const number = String.fromCharCode(65 + index);
      options += `${number}. ${filter.option}\n`;
    });
    return options;
  }
}
