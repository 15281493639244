import * as React from 'react';

import { Dropdown, MenuItem } from '@components/dmp';

const LockTakeover = ({ lock, section, handleLock, handleUnlock, focusSection }) => {
  const handleReleaseLock = (action) => {
    switch (action) {
      case 'take-over':
        handleUnlock(section.id);
        handleLock(section.id);
        focusSection(section.id);
        break;
      case 'release-lock':
        handleUnlock(section.id);
        break;
      default:
        break;
    }
  };

  return (
    <Dropdown
      id={`dd-pop-lock`}
      size="small"
      dmpStyle="link"
      iconOnly={true}
      title={null}
      noCaret
      noPadding
      icon="lockClosed"
      tip={{ tip: `${lock.displayName} is editing this section. Click to unlock.`, placement: 'top' }}
      onSelect={handleReleaseLock}
    >
      <MenuItem disabled>{lock.displayName} is editing this section</MenuItem>
      <MenuItem divider></MenuItem>
      <MenuItem eventKey="take-over">Take over</MenuItem>
      <MenuItem eventKey="release-lock">Release lock</MenuItem>
    </Dropdown>
  );
};

export default LockTakeover;
