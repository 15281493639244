import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import ReactTable, { ReactTableDefaults } from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';

import { classNamePrefixer } from '@core/utils/Generators';

import { Loader } from '@components/dmp';
import DataTablePagination from '@components/dmp/DataTablePagination';

import CONFIG from '@root/Config';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const PAGE_SIZE_OPTIONS = [50, 100, 250];

// Temporary tweak for CLA which will offload the problem to them!
// until we implement proper CSV export on the backend using Tasks.
if (CONFIG.INSTANCE) {
  PAGE_SIZE_OPTIONS.push(1000);
}

/*
  DataTable is currently using an older, but more stable version of react-table (v6).
  Once we decide that we would benefit from it, we could move to v7, but it will
  require a lot of changes since they've changed the whole API and structure.
  Reference: https://github.com/tannerlinsley/react-table/tree/v6.10.2
*/

Object.assign(ReactTableDefaults, {
  defaultPageSize: 50,
  minRows: 0,
  multiSort: false,
  noDataText: '',
  pageSizeOptions: PAGE_SIZE_OPTIONS,
  showPaginationTop: false,
});

const TableLoader = ({ className, loading, loadingText, ...rest }) => {
  if (!loading) return null;

  const classNames = cx(
    '-loading',
    { '-active': loading },
    { '-no-header': !rest.showPagination || !rest.showPaginationTop },
    { '-no-footer': !rest.showPagination || !rest.showPaginationBottom },
    className
  );

  return (
    <div className={classNames} {...rest} data-cy="table-loader">
      <div className="-loading-inner">
        <Loader />
        <div className="-loading-inner-text" data-cy="loading-inner-text">
          {loadingText}
        </div>
      </div>
    </div>
  );
};

TableLoader.propTypes = {
  className: PropTypes.any,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
};

const TdComponent = ({ className, children, to, ...rest }) => {
  const classNames = cx(className, 'rt-td'),
    TdComponent = to ? Link : 'div',
    passingProps = _.omit(rest, 'isLocked');

  return (
    <TdComponent className={classNames} to={to} {...passingProps}>
      {children}
    </TdComponent>
  );
};

TdComponent.propTypes = {
  className: PropTypes.any,
  children: PropTypes.node,
  isLocked: PropTypes.bool,
  to: PropTypes.string,
};

const TrComponent = ({ children, className, isLocked, to, needsReview, ...rest }) => {
  const classNames = cx(
    className,
    'rt-tr',
    { '-clickable': !!to },
    { '-locked': isLocked },
    { needsReview: needsReview }
  );

  return (
    <div className={classNames} role="row" {...rest}>
      {children}
    </div>
  );
};

TrComponent.propTypes = {
  className: PropTypes.any,
  children: PropTypes.node,
  isLocked: PropTypes.bool,
  to: PropTypes.string,
};

const cl = classNamePrefixer('data-table');

const DataTable = React.forwardRef((props, ref) => {
  const { canScroll, className, clickable, dropshadow, hasFixedColumns, list, noHeader, ...tableProps } = props;

  const extraProps = {};

  let ReactTableComponent = ReactTable;
  if (hasFixedColumns) {
    ReactTableComponent = ReactTableFixedColumns;
    // Ref is obtained diffrently with a ReactTable HOC component
    extraProps.innerRef = ref;
  } else {
    extraProps.ref = ref;
  }

  const classNames = cx(
    cl(),
    { [cl('style-list')]: list },
    { [cl('no-scroll')]: !canScroll },
    { [cl('clickable')]: clickable },
    { [cl('dropshadow')]: dropshadow },
    className
  );

  if (noHeader) {
    tableProps.TheadComponent = () => null;
  }

  return (
    <div className={classNames}>
      <ReactTableComponent
        TdComponent={TdComponent}
        TrComponent={TrComponent}
        LoadingComponent={TableLoader}
        PaginationComponent={DataTablePagination}
        {...tableProps}
        {...extraProps}
      />
    </div>
  );
});

DataTable.defaultProps = {
  canScroll: true,
  clickable: false,
  dropshadow: false,
  filterable: false,
  list: false,
  noHeader: false,
  hasFixedColumns: false,
};

DataTable.propTypes = {
  canScroll: PropTypes.bool,
  className: PropTypes.any,
  clickable: PropTypes.bool,
  dropshadow: PropTypes.bool,
  filterable: PropTypes.bool,
  list: PropTypes.bool,
  noHeader: PropTypes.bool,
  hasFixedColumns: PropTypes.bool,
  // There's other props available, to know them,
  // please look at the doc referenced at the beginning of this file.
};

export default DataTable;
