import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';
import RCSwitch from 'rc-switch';

import { classNamePrefixer } from '@core/utils/Generators';

const cl = classNamePrefixer('switch');

const Switch = ({ children, size, disabled, className, ...passingProps }) => {
  const classNames = cx(cl(), { [cl('disabled')]: disabled }, { [cl(size)]: size !== 'default' }, className);

  return (
    <div className={classNames}>
      <label>
        <RCSwitch disabled={disabled} {...passingProps} />
        <span>{children || ''}</span>
      </label>
    </div>
  );
};

Switch.defaultProps = {
  size: 'default',
  disabled: false,
};

Switch.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'default']),
};

export default Switch;
