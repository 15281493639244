import React from 'react';

import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

const cl = classNamePrefixer('setting');

const Setting = ({ title, subtitle, actions, children }) => {
  return (
    <div className={cl()}>
      {(title || subtitle) && (
        <div className={cl('label')}>
          {title && <div className="setting-title">{title}</div>}
          {subtitle && <div className="setting-subtitle">{subtitle}</div>}
        </div>
      )}
      <div className={actions ? cl('actions') : cl('ui')}>{children}</div>
    </div>
  );
};

Setting.defaultProps = {
  title: '',
  subtitle: '',
  actions: false,
};

Setting.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  actions: PropTypes.bool,
};

export default Setting;
