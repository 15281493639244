import React, { Component } from 'react';

import _ from 'lodash';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import { isAdmin } from '@core/models/User';
import { Dt } from '@core/utils/Environment';
import { getSafeKey } from '@core/utils/Generators';

import { Alert, Button, Loader, Validator } from '@components/dmp';

import LogoUploader from '@components/LogoUploader';
import API from '@root/ApiClient';
import Fire from '@root/Fire';

export default class TeamInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      id: '',
      license: '',
      legalName: '',
      logo: null,
      address: '',
      addressProperties: {},
      splitAddress: false,
      errorFormFields: [],
      brandColor: '',
      validID: false,
      saving: false,
      letterhead: null,
      salesforceID: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.populate(this.props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this._isMounted) this.populate(props);
  }

  populate(props) {
    if (props.team) {
      const { name, logo, license, legalName, address, brandColor, letterhead, addressProperties, salesforceID } =
        props.team.info;
      this.setState({
        name: name || '',
        id: props.team.teamID,
        logo: logo || null,
        letterhead: letterhead || null,
        legalName: legalName || '',
        license: license || '',
        address: address || '',
        addressProperties: addressProperties || {},
        brandColor: brandColor || 'rgba(40, 44, 52, .95)' /*'#854AD3'*/, //default to outlaw brand color
        splitAddress: this.hasAddressProperties(addressProperties),
        errorFormFields: [],
        salesforceID: salesforceID || null,
      });
    } else {
      this.setState({
        name: '',
        id: '',
        license: '',
        legalName: '',
        logo: null,
        address: '',
        addressProperties: {},
        brandColor: '#854AD3',
        letterhead: null,
        splitAddress: false,
        errorFormFields: [],
        salesforceID: null,
      });
    }
  }

  get existing() {
    return this.props.team != null;
  }

  hasAddressProperties(addressProperties) {
    return (
      addressProperties &&
      Object.keys(addressProperties).length > 0 &&
      Object.values(addressProperties).some((val) => !!val)
    );
  }

  onIDChange(raw) {
    const id = getSafeKey(raw, false, true, '');
    this.setState({ id });
  }

  async validateTeamID(teamID) {
    return await API.call('validateTeamID', { teamID });
  }

  updateField(field, value) {
    if (field.includes('addressProperties')) {
      const [, addressProp] = field.split('.');
      if (addressProp) {
        this.setState((prevState) => {
          if (typeof prevState.addressProperties === 'object') {
            return {
              addressProperties: { ...prevState.addressProperties, [addressProp]: value },
            };
          } else {
            return {
              addressProperties: { [addressProp]: value },
            };
          }
        });
        return;
      }
    }
    this.setState({ [field]: value });

    // Updating team name or id on a new team also requires key sanitization,
    // followed by (debounced) validation to ensure availability
    if ((field === 'name' || field === 'id') && !this.existing) {
      const id = getSafeKey(value, false, true, '');
      this.setState({ id });
    }
  }

  async createTeam() {
    const { selectTeam, user, getTeams, toggleTeamCreation, team } = this.props;

    const teamID = this.state.id;
    const info = _.pick(this.state, [
      'name',
      'logo',
      'license',
      'legalName',
      'address',
      'brandColor',
      'letterhead',
      'addressProperties',
      'salesforceID',
    ]);

    if (this.hasAddressProperties(info.addressProperties) || this.hasAddressProperties(team?.info?.addressProperties)) {
      info.address = '';
    }

    await this.setState({ saving: true });

    API.call('createTeam', { uid: user.id, teamID, info }, async (team) => {
      // Reload all teams and specify which one to select
      await getTeams(user, team.teamID);

      if (this._isMounted) this.setState({ saving: false });

      toggleTeamCreation(false);
    });
  }

  updateTeam() {
    const teamID = this.state.id;
    const { team, selectTeam } = this.props;
    const info = _.pick(this.state, [
      'name',
      'logo',
      'license',
      'legalName',
      'address',
      'brandColor',
      'letterhead',
      'addressProperties',
      'salesforceID',
    ]);

    if (this.hasAddressProperties(info.addressProperties) || this.hasAddressProperties(team?.info?.addressProperties)) {
      info.address = '';
    }

    this.setState({ saving: true });
    Fire.updateTeamInfo(this.state.id, info, async (saved) => {
      API.call('userAuditLog', {
        teamID,
        eventCategory: 'team',
        eventType: 'update',
        eventSubtype: 'info',
        eventDetails: info,
      });
      //this breaks convention a little bit, but we don't have an active listener on the team
      //so need to manually update info with what was just saved in db, and propagate through app
      team.info = saved;
      this.setState({ saving: false }, () => selectTeam(team));
    });
  }

  close() {
    const { selectTeam, team, editingTeam, creatingTeam, toggleTeamCreation } = this.props;

    this.setState({ id: '', name: '', saving: false, splitAddress: false, errorFormFields: [] }, () => {
      if (creatingTeam) toggleTeamCreation(false);
      else selectTeam(team);
    });
  }

  renderAddressForm() {
    const { addressProperties } = this.state;
    if (!this.hasAddressProperties(addressProperties) && this.state.address.length > 0 && !this.state.splitAddress) {
      return (
        <FormControl
          componentClass="textarea"
          value={this.state.address}
          placeholder={`e.g., 61 Greenpoint Ave.
          Brooklyn, NY 11222`}
          disabled={this.state.saving}
          onChange={(e) => this.updateField('address', e.target.value)}
        />
      );
    }

    return (
      <>
        <FormGroup>
          <FormControl
            type="text"
            value={this.state.addressProperties?.line1 || ''}
            placeholder="Address Line 1"
            disabled={this.state.saving}
            onChange={(e) => this.updateField('addressProperties.line1', e.target.value)}
            data-cy="team-address-line-1"
          />
        </FormGroup>
        <FormGroup>
          <FormControl
            type="text"
            value={this.state.addressProperties?.line2 || ''}
            placeholder="Address Line 2"
            disabled={this.state.saving}
            onChange={(e) => this.updateField('addressProperties.line2', e.target.value)}
            data-cy="team-address-line-2"
          />
        </FormGroup>
        <div className="address-properties">
          <FormGroup>
            <FormControl
              type="text"
              value={this.state.addressProperties?.city || ''}
              placeholder="City"
              disabled={this.state.saving}
              onChange={(e) => this.updateField('addressProperties.city', e.target.value)}
              data-cy="team-address-city"
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              type="text"
              value={this.state.addressProperties?.state || ''}
              placeholder="State/Province"
              disabled={this.state.saving}
              onChange={(e) => this.updateField('addressProperties.state', e.target.value)}
              data-cy="team-address-state"
            />
          </FormGroup>
        </div>
        <div className="address-properties">
          <FormGroup>
            <FormControl
              type="text"
              value={this.state.addressProperties?.postalCode || ''}
              placeholder="Zip/Postal Code"
              disabled={this.state.saving}
              onChange={(e) => this.updateField('addressProperties.postalCode', e.target.value)}
              data-cy="team-address-postal-code"
            />
          </FormGroup>
          <FormGroup>
            <FormControl
              type="text"
              value={this.state.addressProperties?.country || ''}
              placeholder="Country"
              disabled={this.state.saving}
              onChange={(e) => this.updateField('addressProperties.country', e.target.value)}
              data-cy="team-address-country"
            />
          </FormGroup>
        </div>
      </>
    );
  }

  render() {
    const { user, creatingTeam, editingTeam } = this.props;
    const { id, validID, name, saving, splitAddress, address, addressProperties } = this.state;

    if (!user) return null;

    return (
      <Modal
        dialogClassName="team-creator"
        show={creatingTeam || editingTeam}
        onHide={() => this.close()}
        data-cy="modal-team-creator"
      >
        <Modal.Header closeButton>
          <span className="headline">{this.existing ? 'Team Details' : 'Create Team'}</span>
        </Modal.Header>

        <Modal.Body>
          <FormGroup>
            <ControlLabel>Team Name</ControlLabel>
            <div className="contents team-name">
              <FormControl
                type="text"
                ref="teamName"
                value={name}
                placeholder="e.g., My Company"
                disabled={saving}
                onChange={(e) => this.updateField('name', e.target.value)}
                data-cy="contents-team-name"
              />
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Team ID</ControlLabel>
            <div className="contents team-id dmp-validator-container">
              <FormControl
                type="text"
                value={this.state.id}
                readOnly={this.existing}
                placeholder="e.g., MyCompany"
                onChange={(e) => this.updateField('id', e.target.value)}
                data-cy="team-id"
              />
              {!this.existing && (
                <Validator
                  validate={this.validateTeamID}
                  onResult={(validID) => this.setState({ validID })}
                  value={id}
                  validTip="Available"
                  invalidTip="A team with this ID already exists"
                />
              )}
            </div>
          </FormGroup>

          <FormGroup>
            <ControlLabel>Legal Name</ControlLabel>
            <div className="contents org-name">
              <FormControl
                type="text"
                value={this.state.legalName}
                placeholder="e.g., My Company LLC"
                disabled={this.state.saving}
                onChange={(e) => this.updateField('legalName', e.target.value)}
                data-cy="team-legal-name"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Address</ControlLabel>
            <div className="contents org-address">
              {this.renderAddressForm()}
              {!this.hasAddressProperties(addressProperties) && address.length > 0 && !splitAddress && (
                <Alert dmpStyle="warning" className="split-address-alert">
                  Split address to individual fields for greater control across team & templates.
                  <a
                    onClick={() => {
                      this.setState({ splitAddress: !splitAddress });
                      this.updateField('addressProperties.line1', address);
                    }}
                  >
                    Convert
                  </a>
                </Alert>
              )}
            </div>
          </FormGroup>
          <FormGroup>
            <ControlLabel>{Dt} Footer</ControlLabel>
            <div className="contents contract-footer">
              <FormControl
                type="text"
                value={this.state.license}
                placeholder="e.g., Confidential and proprietary"
                disabled={this.state.saving}
                onChange={(e) => this.updateField('license', e.target.value)}
                data-cy="team-footer"
              />
            </div>
          </FormGroup>
          <FormGroup>
            <ControlLabel>Logo</ControlLabel>
            <LogoUploader img={this.state.logo} onUpload={(logo) => this.setState({ logo })} />
          </FormGroup>
          <FormGroup>
            <ControlLabel>Letterhead</ControlLabel>
            <LogoUploader
              img={this.state.letterhead}
              type="letterhead"
              onUpload={(letterhead) => this.setState({ letterhead })}
            />
          </FormGroup>
          {isAdmin(user) && (
            <FormGroup>
              <ControlLabel>Salesforce ID</ControlLabel>
              <div className="contents salesforce-id">
                <FormControl
                  type="text"
                  value={this.state.salesforceID}
                  placeholder="e.g., 50130000000014c"
                  disabled={this.state.saving}
                  onChange={(e) => this.updateField('salesforceID', e.target.value)}
                  data-cy="salesforce-id-input"
                />
              </div>
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          {this.state.saving && <Loader size="large" />}
          <Button onClick={() => this.close()} disabled={this.state.saving}>
            Cancel
          </Button>
          <Button
            dmpStyle="primary"
            disabled={!this.existing && (!validID || !name || saving)}
            onClick={() => (this.existing ? this.updateTeam() : this.createTeam())}
            data-cy={`btn-${this.existing ? 'update' : 'create'}`}
          >
            {this.existing ? 'Update' : 'Create'}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
