import _ from 'lodash';

import { getUniqueKey } from '@core/utils/Generators';

import VariableFilter from './VariableFilter';

export default class LensValueFilter {
  id;
  conditions;
  valueFilter;
  riskValue;

  constructor({ id, conditions, valueFilter, riskValue }) {
    _.assign(this, { id });

    this.conditions = {};
    this.valueFilter = null;
    this.riskValue = riskValue || 0;
    this.id = id || getUniqueKey();

    _.forEach(conditions, (variableFilterJSON) => {
      const { variable, valueType, operator, values } = variableFilterJSON;
      const vf = new VariableFilter(variable, { valueType, operator, values });
      this.conditions[variable] = vf;
    });

    if (valueFilter) {
      const { valueType, operator, values, variable } = valueFilter;
      this.valueFilter = new VariableFilter(variable, { valueType, operator, values });
    }
  }
}
