import { assign, find, forEach, map } from 'lodash';

import { STEPS, STEPS_LEGAL, STEPS_STATIC, STEPS_SERVICE_PROVIDER } from '../enums/DealStatus';
import CheckpointGroup from './CheckpointGroup';
import WorkflowStep from './WorkflowStep';

//Checks to see if the current user has the permissions to access restricted steps.
export const hasRestrictedWorkflowAccess = (deal, user) => {
  return deal.currentWorkflowStep.restricted ? isServiceProvider(deal, user) : true;
}

//Checks if a user is a serviceProvider on a given team. Used in a few spots so broken out.
export const isServiceProvider = (deal, user) => {
    return user.teamMemberships && user.teamMemberships[deal.team] ? user.teamMemberships[deal.team].serviceProvider : false;
}

export const DEFAULT_WORKFLOW = {
  name: 'Contracts - (Default)',
  workflowKey: 'default',
  description: 'Standard automated Outlaw workflow for contracts that require eSigning',
  steps: STEPS,
  isOutlaw: true,
};

export const DEFAULT_WORKFLOW_STATIC = {
  name: 'Outlaw (Default)',
  workflowKey: 'default',
  description: 'Standard Outlaw workflow for contracts and other legal documents that do not require eSigning',
  steps: STEPS_STATIC,
  isOutlaw: true,
};

export const WORKFLOW_LEGAL = {
  name: 'Legal filings',
  workflowKey: 'dotvine',
  description: 'Standard base workflow for legal documents and court filings',
  steps: STEPS_LEGAL,
  isOutlaw: false,
};

export const WORKFLOW_SERVICE_PROVIDER = {
  name: 'Service Providers',
  workflowKey: 'serviceProviders',
  description: 'Standard Demands workflow',
  steps: STEPS_SERVICE_PROVIDER,
  isOutlaw: false,
  serviceProviders: true,
}

export default class Workflow {
  name = null;
  workflowKey = null;
  description = null;
  isDefault = false;
  steps = [];
  aiEngine = null;

  isOutlaw = false;

  // Assigned after instantiation when Workflow is attached to a Deal instance
  deal = null;
  serviceProviders = false;

  constructor({
    name,
    workflowKey,
    description = null,
    steps = [],
    isDefault = false,
    isOutlaw = false,
    teamCheckpointGroups = null,
    serviceProviders = false,
    serviceProviderName = 'Case Partners',
    collectionStepName = 'Overview',
    aiEngine = 'openAI',
  }) {
    assign(this, { name, workflowKey, description, isDefault, isOutlaw, serviceProviders, serviceProviderName, collectionStepName, aiEngine});
    forEach(steps, (stepJSON) => {
      if (stepJSON.checkpoint && teamCheckpointGroups) {
        const checkpointGroup = find(Object.values(teamCheckpointGroups), {
          checkpointGroupKey: stepJSON.checkpointGroupKey,
        });
        if (!checkpointGroup) {
          stepJSON.checkpoint = false;
        } else {
          stepJSON.checkpointGroup = new CheckpointGroup(checkpointGroup);
        }
      }
      this.steps.push(new WorkflowStep(stepJSON, this));
    });
  }

  get json() {
    return {
      name: this.name,
      workflowKey: this.workflow,
      description: this.description,
      isDefault: this.isDefault,
      steps: map(this.steps, 'json'),
    };
  }
}
