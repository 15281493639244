import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import DealAction from '@core/enums/DealAction';

import { Icon } from '@components/dmp';

const ICONS = {
  deal: <Icon name="deal" />,
  sharing: <Icon name="sharing" />,
  comments: <Icon name="comments" />,
  redlining: <Icon name="redlining" />,
};

const ActionBucketIcon = ({ action, type }) => {
  let icon = ICONS[type] ? ICONS[type] : '';

  // Exception for the Completed Deal action
  if (type === 'deal' && action === DealAction.COMPLETE) icon = <Icon name="circleCheck" />;
  if (type === 'deal' && action === DealAction.INTEGRATION) icon = <Icon name="connect" />;

  return icon;
};

ActionBucketIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(ICONS)),
  action: PropTypes.oneOf(Object.values(DealAction)),
};

export default ActionBucketIcon;
