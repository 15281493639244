
// https://github.com/facebook/draft-js/issues/1361
import { convertFromHTML } from 'draft-convert';

const jsdom = typeof document === 'undefined' ? require('jsdom') : {};
const { JSDOM } = jsdom;

let customJSDOM = null;
let customDoc = null;

function serverDOMBuilder(html) {
  if (!customJSDOM) {
    customJSDOM = new JSDOM(`<!DOCTYPE html>`);

    const {
      HTMLElement,
      HTMLAnchorElement,
      HTMLImageElement,
      // Latest version of DraftJS also references the general "Node" type,
      // which appears to also be included in jsdom (thank god)
      Node,
    } = customJSDOM.window;

    // Add the missing HTML Elements on global for convertFromHTML to work
    global.HTMLElement = HTMLElement;
    global.HTMLAnchorElement = HTMLAnchorElement;
    global.HTMLImageElement = HTMLImageElement;
    global.Node = Node;

    customDoc = customJSDOM.window.document.implementation.createHTMLDocument();
  }

  customDoc.documentElement.innerHTML = html;
  const body = customDoc.getElementsByTagName('body')[0];
  return body;
}

export default function stateFromHTML(html) {
  // if DOMBuilder is undefined convertFromHTML will use the browser dom,
  //  hence we set DOMBuilder to undefined when document exist
  let DOMBuilder = typeof document === 'undefined' ? serverDOMBuilder : undefined;

  // DraftJS is *insanely* fucking opinionated and strips empty blocks (<p></p>) out of the conversion,
  // Hence the missing line breaks (https://trello.com/c/otg9zlW5)
  // So we're using a different library that doesn't do that, and also has a cleaner method signature (returns a ContentState)
  // And see below -- we're still able to use this server-side by passing in a custom DOMBuilder (whew!)
  // https://github.com/HubSpot/draft-convert/blob/1b78ab19250f4d558d8d5ea4fb0c83f121cc395f/src/convertFromHTML.js#L675
  return convertFromHTML(html, { flat: false }, DOMBuilder);
}
