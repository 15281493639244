import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import { FEATURES, canHaveTeam } from '@core/models/User';
import { Dt } from '@core/utils/Environment';

import { Icon } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

export const DASH_ITEMS = [
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: <Icon name="dashboard" size="large" />,
    show: (p) => canHaveTeam(p.user),
  },
  {
    path: '/dashboard/contracts',
    title: `${Dt}s`,
    icon: <Icon name="contracts" size="large" />,
  },
  {
    path: '/dashboard/templates',
    title: 'Templates',
    icon: <Icon name="template" size="large" />,
    disabled: (p) =>
      p.user.teams != null &&
      !Object.keys(_.pickBy(p.user.teams, (val) => ['owner', 'editor'].indexOf(val) > -1)).length,
    show: (p) => canHaveTeam(p.user),
  },
  {
    path: '/dashboard/team',
    title: 'Team',
    icon: <Icon name="users" size="large" />,
    // With TeamSelector no longer global, we need to enable the Team menu item
    // So that a user can access the page to setup their first team
    // test: (p) => p.user.teams && p.user.team,
    help: 'No team created yet.',
    show: (p) => canHaveTeam(p.user),
  },
  {
    path: '/dashboard/batch',
    title: 'Batch Operations',
    icon: <Icon name="import" size="large" />,
    disabled: (p) => !p.user.can(FEATURES.BATCH),
    show: (p) => canHaveTeam(p.user),
  },
  {
    path: '/dashboard/connect',
    title: 'Connect',
    icon: <Icon name="connect" size="large" />,
    disabled: (p) => !p.user.can(FEATURES.CONNECT),
    show: (p) => canHaveTeam(p.user),
  },
  {
    path: '/admin',
    title: 'Admin',
    icon: <Icon name="settingsCog" size="large" />,
    show: (p) => p.user.isAdmin === true,
    bottom: true,
  },
];

const NavDash = (props) => {
  const {
    location: { pathname },
  } = props;
  const current = pathname.split('?')[0];

  return (
    <div id="primary-nav">
      {DASH_ITEMS.map((item, idx) => {
        if (typeof item.show === 'function' && !item.show(props)) return null;

        const disabled = typeof item.disabled === 'function' && item.disabled(props);
        const active = current == item.path ? true : false;
        if (disabled) {
          return (
            <TooltipButton
              key={item.path}
              tipID={`nav-tip-${idx}`}
              tip={item.help || 'Access restricted'}
              placement="right"
            >
              <a disabled className="disabled" data-cy={item.title}>
                {item.icon}
              </a>
            </TooltipButton>
          );
        }

        return (
          <TooltipButton key={item.path} tipID={`nav-tip-${idx}`} tip={item.title} placement="right">
            <Link className={cx({ active }, { bottom: item.bottom })} to={item.path} data-cy={item.title}>
              {item.icon}
            </Link>
          </TooltipButton>
        );
      })}
    </div>
  );
};

NavDash.defaultProps = {
  user: null,
};

NavDash.propTypes = {
  location: PropTypes.object.isRequired,
  user: PropTypes.object,
};

export default NavDash;
