const InviteStatus = {
  ADDED: 'added',
  INVITED: 'invited',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  OWNED: 'owned',
  EXPIRED: 'expired',
  FAILED: 'failed',

  canView: (stat) => ['accepted', 'owned', 'invited'].indexOf(stat) > -1,
  notify: (stat) => ['accepted', 'owned'].indexOf(stat) > -1,
};

export default InviteStatus;
