//For some reason this does not work form Core
import discoverEnvironment from '@utils/Environment';

const env = discoverEnvironment();
console.log(`Config[${env}]`);

let CONFIG = { ENV: env, ...CONFIG_FILE };

// Merge the .env / config variables inside our main CONFIG object.
if (typeof CONFIG_ENV !== 'undefined') {
  CONFIG = { ...CONFIG, ...CONFIG_ENV };
}

// Define Assets Url
CONFIG.ASSETS_URL = CONFIG.ASSETS_URL || CONFIG.BASE_URL;

export default CONFIG;
