import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import { Redirect } from 'react-router-dom';

import Login from '@components/Login';
import PromoFooter from '@components/PromoFooter';

@autoBindMethods
class LoginView extends Component {
  static defaultProps = {
    user: null,
  };

  static propTypes = {
    og: PropTypes.func,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    loginError: PropTypes.string,
    match: PropTypes.object,
    user: PropTypes.object,
  };

  render() {
    const { og, location, loginError, history, user } = this.props;
    const searchParams = qs.parse(location.search);
    const loginProps = { loginError, location, history };
    const redirect = _.get(searchParams, 'redirect', null);

    og({ title: `Outlaw - Login` });

    // See routes -- same page is served for /login /signup and /forgot
    // Grab mode from path, then set title and pass to login component
    loginProps.mode = location.pathname.substr(1).split('/')[0];

    // If user has logged in, go to dashboard unless pushed here from another deep link
    if (user && !user.anonymous) {
      const from = redirect ? decodeURIComponent(redirect) : '/dashboard';
      return <Redirect to={from} />;
    }

    // Use pre-filled email and fullName if they're present
    loginProps.userEmail = _.get(searchParams, 'email', null);
    loginProps.userFullName = _.get(searchParams, 'fullName', null);

    const loginTypesParam = _.get(searchParams, 'loginTypes', null);
    if (loginTypesParam) {
      loginProps.loginTypes = loginTypesParam.split(',');
    }

    return (
      <div className="login-page">
        <div className="col1">
          <div className="col1-inner" data-cy="col1-inner">
            <img src="/assets/svg/logo-outline-black.svg" alt="Outlaw" className="login-logo" data-cy="login-logo" />
            <Login {...loginProps} />
          </div>
          <PromoFooter />
        </div>
      </div>
    );
  }
}

export default LoginView;
