import { pick } from 'lodash';

export default class CellBorder {
  width;
  color;
  style;

  constructor({ width = 0, color = '#000000', style = 'none' }) {
    this.width = width;
    this.color = color;
    this.style = style;
  }

  get json() {
    if (this.style === 'none') return null;
    return pick(this, ['width', 'color', 'style']);
  }

  get css() {
    if (this.style === 'none') return null;
    return `${this.width}px ${this.style} ${this.color}`;
  }
}
