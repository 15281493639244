import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { FormControl } from 'react-bootstrap';

import AdminSearchParams from '@core/models/AdminSearchParams';
import Team from '@core/models/Team';
import User from '@core/models/User';
import UserRecord from '@core/models/UserRecord';

import { ButtonIcon, DataTable } from '@components/dmp';

import { USER_MANAGEMENT_COLUMNS } from '@components/deal/Columns';
import API from '@root/ApiClient';
import UserEditor from '@routes/admin/UserEditor';

@autoBindMethods
export default class Admin extends Component {
  static propTypes = {
    og: PropTypes.func.isRequired,
    team: PropTypes.instanceOf(Team),
    teams: PropTypes.arrayOf(PropTypes.instanceOf(Team)),
    user: PropTypes.instanceOf(User),
    adminSearchParams: PropTypes.instanceOf(AdminSearchParams),
  };

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      currentPage: 0,
      totalPages: 0,
      selectedUser: null,
    };

    this.onSearchUser = _.debounce((value) => {
      this.props.adminSearchParams.query = value;
    }, 150);
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.og({ title: 'Outlaw - Admin - Users' });
    this.getUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    const adminSearchParams = _.get(this.props, 'adminSearchParams');
    const prevParams = _.get(prevProps, 'adminSearchParams');
    // Anytime SearchParams changes, run a new search
    if (!_.isEqual(adminSearchParams, prevParams)) {
      this.getUsers();
    }
  }

  async getUsers() {
    const { adminSearchParams } = this.props;
    await this.setState({ loading: true });

    const results = await API.call('getUsers', adminSearchParams.apiArgs);
    if (!this._isMounted) return;

    const data = _.map(results.hits, (record) => new UserRecord(record));

    this.setState({
      data,
      totalHits: results.nbHits,
      currentPage: results.page,
      totalPages: results.nbPages,
      loading: false,
    });
  }

  onSortChange(newSorted) {
    const { adminSearchParams } = this.props;
    // Since we're not doing multi-column sorting, let's simply grab the first item in the array
    const sortObject = _.get(newSorted, '[0]');
    const { desc, id } = sortObject;
    adminSearchParams.sort = `${id}.${desc ? 'desc' : 'asc'}`;
  }

  get sorted() {
    const { adminSearchParams } = this.props;
    const sort = _.get(adminSearchParams, 'sort');

    let sortBy, sortDir;
    [sortBy, sortDir] = sort.split('.');
    return [{ id: sortBy, desc: !!(sortDir === 'desc') }];
  }

  get editColumn() {
    return {
      key: 'actions',
      id: 'actions',
      Cell: ({ original }) => (
        <ButtonIcon
          icon="deal"
          size="default"
          data-cy="edit-user"
          onClick={() => this.setState({ selectedUser: original })}
        />
      ),
      minWidth: 40,
      maxWidth: 40,
      className: 'col-actions',
      fixed: 'right',
      sortable: false,
      clickable: false,
      resizable: false,
    };
  }

  get columns() {
    return [...USER_MANAGEMENT_COLUMNS, this.editColumn];
  }

  getTdProps(state, rowInfo) {
    const { adminSearchParams } = this.props;
    return {
      teams: adminSearchParams.getParams('teams'),
    };
  }

  getTrProps(state, rowInfo) {
    const { adminSearchParams } = this.props;
    return {
      teams: adminSearchParams.teams,
    };
  }

  renderResults() {
    const { adminSearchParams } = this.props;
    const { currentPage, totalHits, totalPages, data } = this.state;

    let startDisplay = 0;
    let endDisplay = 0;
    let resultSummary = '';

    startDisplay = currentPage * adminSearchParams.hitsPerPage + 1;
    endDisplay = startDisplay + data.length - 1;

    if (!totalHits) {
      resultSummary = 'No users found';
    } else {
      resultSummary =
        totalPages > 1
          ? `Showing ${startDisplay} - ${endDisplay} of ${totalHits} users`
          : `Showing ${data.length} user${data.length > 1 ? 's' : ''}`;
    }

    return (
      <div className="top-of-results" data-cy="top-of-results">
        <div className="results-and-tags">
          <div className="results">{resultSummary}</div>
        </div>
      </div>
    );
  }

  render() {
    const { currentPage, data, totalPages, loading, selectedUser } = this.state;
    const { adminSearchParams, user } = this.props;

    return (
      <div className="admin-tab-header" data-cy="admin-tab-header">
        <div className="d-flex justify-content-between align-items-center flex-wrap ">
          <h4>Users</h4>
          <div className="d-flex">
            <div className="admin-search-users" data-cy="admin-search-users">
              <FormControl
                type="text"
                onChange={(e) => this.onSearchUser(e.target.value)}
                placeholder="Search team member by id, name, email, or title"
              />
            </div>
          </div>
        </div>
        <div className="users-list">
          <DataTable
            clickable
            columns={this.columns}
            dropshadow
            loading={loading}
            data={data}
            loadingText="Fetching..."
            manual
            hasFixedColumns
            onPageChange={(pageIndex) => adminSearchParams.setPage(pageIndex + 1)}
            onPageSizeChange={(pageSize) => (adminSearchParams.hitsPerPage = pageSize)}
            page={currentPage}
            pages={totalPages}
            pageSize={adminSearchParams.hitsPerPage}
            showPaginationTop
            onSortedChange={this.onSortChange}
            sorted={this.sorted}
            sortable
            getTdProps={this.getTdProps}
            getTrProps={this.getTrProps}
            TopPaginationComponent={this.renderResults}
          />
        </div>
        {!!selectedUser && (
          <UserEditor
            user={user}
            selectedUser={selectedUser}
            onHide={() => this.setState({ selectedUser: null })}
            onSave={() => {
              this.setState({ selectedUser: null });
              this.getUsers();
            }}
          />
        )}
      </div>
    );
  }
}
