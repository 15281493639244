import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, Modal } from 'react-bootstrap';

import DealRole from '@core/enums/DealRole';
import { STEPS } from '@core/enums/DealStatus';
import Teammate from '@core/models/Teammate';
import User from '@core/models/User';
import { dt } from '@core/utils/Environment';

import { Button, Loader } from '@components/dmp';

import TeammateBlock, { VARIOUS_ROLE } from '@components/deal/TeammateBlock';
import TeammateSelector from '@components/teams/TeammateSelector';
import Fire from '@root/Fire';

const LOCKED_STATUSES = _.map(
  _.filter(STEPS, (step) => ['signing', 'signed'].includes(step.key)),
  'name'
);

@autoBindMethods
export default class BatchUserManager extends Component {
  static propTypes = {
    deals: PropTypes.array.isRequired,
    onUpdate: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User),
    team: PropTypes.object,
    teams: PropTypes.array,
  };

  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      actions: [],
    };
  }

  get summary() {
    const { deals } = this.props;
    return `Manage teammates access across multiple ${dt}s. ${deals.length} ${dt}${
      deals.length > 1 ? 's' : ''
    } selected.`;
  }

  get teammates() {
    const { actions } = this.state;
    const { deals, teams, user } = this.props;
    let uids = [],
      teammates = [];
    // Compile full list of uids of users across all teams for lookup
    _.forEach(teams, (team) => {
      uids = uids.concat(_.keys(team.users));
    });
    uids = _.uniq(uids);

    // Now we want to loop through each user on each deal
    _.forEach(deals, (deal) => {
      _.forEach(deal.users, (du) => {
        // If the user is not a teammate, exclude them -- this is only for managing team access
        if (!du.uid || !uids.includes(du.uid)) return;
        // Once we get here we have either a new user to list,
        // or the user already has access
        let tm = _.find(teammates, { id: du.uid });
        if (!tm) {
          tm = new Teammate({
            id: du.uid,
            fullName: du.fullName,
            email: du.email,
            disabled: false,
          });
          tm.role = du.role;
          teammates.push(tm);
        }
        if (tm.role !== du.role) {
          tm.role = VARIOUS_ROLE.key;
        }
        // Now, if user is self or if deal is in signing/signed and user is a party, disable
        // Note, these are DealRecords so they use the display labels for status instead of underlying keys
        // And the property to check whether user is in a party is "party" instead of "partyID"
        if (tm.id === user.id) {
          tm.disabledReason = `As the owner of the selected ${dt}s, you can not remove yourself.`;
          tm.disabled = true;
        } else if (LOCKED_STATUSES.includes(deal.status) && !!du.party) {
          tm.disabledReason = `This teammate is a signer on one or more of the selected ${dt}s and can not be edited in bulk.`;
          tm.disabled = true;
        }
      });
    });

    // After compiling superset of users across all deals, go back through list
    // Anyone who is not on EVERY deal being managed should show role as various
    _.forEach(teammates, (tm) => {
      const dealsWithUser = _.filter(deals, (deal) => !!_.find(deal.users, { uid: tm.id }));
      if (deals.length !== dealsWithUser.length) {
        tm.role = VARIOUS_ROLE.key;
      }
    });

    _.forEach(actions, (action) => {
      switch (action.type) {
        case 'add':
          teammates.push(action.tm);
          break;
        case 'updateRole':
          _.map(teammates, (tm) => {
            if (tm.id === action.tm.id) {
              tm.role = action.role;
            }
          });
          break;
        case 'remove':
          _.remove(teammates, (tm) => {
            return tm.id === action.tm.id;
          });
          break;
      }
    });

    teammates = _.sortBy(teammates, 'fullName');
    return {
      enabled: _.filter(teammates, (tm) => !tm.disabled),
      disabled: _.filter(teammates, 'disabled'),
    };
  }

  close() {
    const { processing } = this.state;
    const { close } = this.props;

    if (processing) return;

    this.setState({ actions: [] });
    close();
  }

  // Fire calls to modify DealUsers expect a typed DealUser instance
  // so this is just a helper method to get key and dealID properties accessible
  teammateToDealUser(tm, deal) {
    return { deal, key: tm.id };
  }

  // We're using the same data method for both add and update,
  // Because in a batch context, the target user may only have access to some of the selected deals
  async addUser(tm, role = null) {
    const { deals, onUpdate } = this.props;

    await this.setState({ processing: true });

    // 1. If specified, use the role passed.
    if (role) tm.role = role;

    // 2. It is possible that tm.role is various, since Fire.addTeammatesToDeal()
    //    will use that role, we must overwrite it with a valid one.
    if (tm.role === VARIOUS_ROLE.key) tm.role = DealRole.VIEWER;

    const promises = [];

    _.forEach(deals, (deal) => {
      const promise = Fire.addTeammatesToDeal(deal.dealID, [tm], false, role);
      promises.push(promise);

      promise.then(() => {
        // Update current data in parent state which will get refreshed in props via onUpdate()
        let du = _.find(deal.users, { uid: tm.id });
        // For role updates, du obj should already be there
        if (du) {
          du.role = role;
        }
        // For additions, we need to construct a similar (json) object
        // to what will get constructed in DealRecord constructor
        else {
          du = _.merge(_.pick(tm, ['fullName', 'title', 'org', 'address', 'email', 'phone']), {
            uid: tm.id,
            role,
          });
          deal.users.push(du);
        }
      });
    });

    await Promise.all(promises);
    this.setState({ processing: false });
    onUpdate();
  }

  async removeUser(tm) {
    const { deals, onUpdate } = this.props;

    // Show progress bar
    await this.setState({ processing: true });

    const promises = [];

    _.forEach(deals, (deal) => {
      const du = this.teammateToDealUser(tm, deal);
      const promise = Fire.deleteDealUser(du);
      promises.push(promise);

      promise.then(() => {
        // Update current data in parent state which will get refreshed in props via onUpdate()
        _.remove(deal.users, ({ uid }) => uid === tm.id);
      });
    });

    await Promise.all(promises);
    this.setState({ processing: false });
    onUpdate();
  }

  updateAction(tm, role) {
    const { actions } = this.state;

    const updateMember = _.find(actions, (action) => {
      return action.tm.id === tm.id && action.type !== 'remove';
    });

    if (updateMember) {
      _.map(actions, (action) => {
        if (action.tm.id === tm.id) {
          action.role = role;
          action.tm.role = role;
        }
      });
      this.setState({ actions });
    }
    //if this is a current deal user and update role occurs
    else {
      tm.role = role;
      this.setState({ actions: [...actions, { tm, role, type: 'updateRole' }] });
    }
  }

  addAction(tm, role) {
    const { actions } = this.state;

    const undoRemove = _.find(actions, (action) => {
      return action.tm.id === tm.id && action.type === 'remove';
    });

    //This accounts for if we remove a current deal user and add them back
    if (undoRemove) {
      _.remove(actions, (action) => {
        return action.tm.id === tm.id && action.type === 'remove';
      });
      this.setState({ actions: actions });
    }
    //Basic add new
    else {
      this.setState({ actions: [...actions, { tm, role, type: 'add' }] });
    }
  }

  removeAction(tm) {
    const { actions } = this.state;

    const undoAdd = _.find(actions, (action) => {
      return action.tm.id === tm.id && action.type === 'add';
    });
    const removeUpdate = _.find(actions, (action) => {
      return action.tm.id === tm.id && action.type === 'updateRole';
    });

    //This accounts for adding a new person and undoing that actions
    if (undoAdd) {
      _.remove(actions, (action) => {
        return action.tm.id === tm.id;
      });
      this.setState({ actions: actions });
    }
    //This accounts for removing a current du from the deal
    else {
      //if we added a update action on a teammate then removed them remove the update action.
      if (removeUpdate) {
        _.remove(actions, (action) => {
          return action.tm.id === tm.id;
        });
      }
      this.setState({ actions: [...actions, { tm, type: 'remove' }] });
    }
  }

  processActions() {
    const { actions } = this.state;
    const { close } = this.props;

    _.forEach(actions, (action) => {
      switch (action.type) {
        case 'add':
        case 'updateRole':
          this.addUser(action.tm, action.role);
          break;
        case 'remove':
          this.removeUser(action.tm);
          break;
      }
    });

    this.setState({ actions: [] });
    close();
  }

  render() {
    const { show, deals, team, teams, user } = this.props;
    const { processing } = this.state;

    if (!deals.length) return null;

    const teammates = this.teammates;
    const existing = _.map(teammates.enabled, 'id').concat(_.map(teammates.disabled, 'id'));

    return (
      <Modal dialogClassName="batch-user-manager" show={show} onHide={this.close}>
        <Modal.Header closeButton>
          <span className="headline">Manage users</span>
        </Modal.Header>
        <Modal.Body>
          <div className="summary">{this.summary}</div>
          <div className={cx('user-lists', { processing })}>
            <div className="current-users" data-cy="current-users">
              <div className="col-headers">
                <ControlLabel className="col-user">User</ControlLabel>
                <ControlLabel className="col-role">Permission</ControlLabel>
              </div>
              <div className="scroll">
                <div className="teammates-list" data-cy="current-teammates-list">
                  {teammates.enabled.map((tm, i) => (
                    <TeammateBlock
                      key={i}
                      admin
                      teammate={tm}
                      updateRole={this.updateAction}
                      onRemove={() => this.removeAction(tm)}
                    />
                  ))}
                  {teammates.disabled.map((tm, i) => (
                    <TeammateBlock key={i} teammate={tm} admin disabled disabledReason={tm.disabledReason} />
                  ))}
                </div>
              </div>
            </div>

            <TeammateSelector
              existingUsers={existing}
              onSelect={this.addAction}
              team={team}
              teams={teams}
              user={user}
              menuWidth={270}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          {processing && <Loader />}
          <div className="spacer" />
          <Button onClick={this.processActions} disabled={processing} data-cy="btn-apply">
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
