import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';

import SearchParams from '@core/models/SearchParams';
import User from '@core/models/User';
import { Dt } from '@core/utils/Environment';

import { Button } from '@components/dmp';
import Dropdown from '@components/dmp/Dropdown';
import DropdownDots from '@components/dmp/DropdownDots';

import FacetBar from '@components/search/FacetBar';
import Fire from '@root/Fire';

const MenuItem = Dropdown.MenuItem;

@autoBindMethods
export default class FilterManager extends Component {
  static defaultProps = {
    onDelete: _.noop,
    teams: [],
  };

  static propTypes = {
    onDelete: PropTypes.func,
    show: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    user: PropTypes.instanceOf(User).isRequired,
    teams: PropTypes.array,
    trash: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      renamingFilter: null,
      managedTeamID: null,
      filterTitle: '',
    };
  }

  handleFilterAction(filter, action) {
    switch (action) {
      case 'delete':
        this.deleteFilter(filter);
        break;
      case 'rename':
        this.setState({ renamingFilter: filter, filterTitle: filter.title });
        break;
      default:
        break;
    }
  }

  async renameFilter(filter) {
    const { filterTitle } = this.state;
    filter.title = filterTitle;

    await Fire.saveFilter(filter);
    this.setState({ renamingFilter: null, filterTitle: '' });
  }

  async deleteFilter(filter) {
    const { onDelete } = this.props;

    await Fire.deleteFilter(filter);
    onDelete(filter.filterID, filter.teamID);
  }

  renderFilter(filter, idx) {
    const { user } = this.props;
    const { renamingFilter, filterTitle } = this.state;
    const renaming = filter === renamingFilter;
    //only allow the edit action if a user is editing personal filters or if they are the team owner of the saved filters being accessed
    const allowEditAction = filter.isUserFilter || user.canEditTeam(filter.teamID);

    // <FacetBar> needs an instance of SearchParams to visualize filters per filter
    // But it's not interactive so don't need location/history args
    const searchParams = new SearchParams(null, null, filter.searchParams);

    return (
      <div className={cx('filter', { renaming })} key={idx} data-cy="saved-filter">
        {renaming && (
          <>
            <FormControl
              type="text"
              value={filterTitle}
              placeholder="e.g., My Filter"
              onChange={(e) => this.setState({ filterTitle: e.target.value })}
              data-cy="filter-title-input"
            />
            <div className="actions">
              <Button
                onClick={() => this.setState({ renamingFilter: null, filterTitle: '' })}
                data-cy="btn-cancel-renaming-filter"
              >
                Cancel
              </Button>

              <Button
                dmpStyle="primary"
                disabled={!filterTitle}
                onClick={() => this.renameFilter(filter)}
                data-cy="btn-update-filter"
              >
                Save
              </Button>
            </div>
          </>
        )}
        {!renaming && (
          <div className="d-flex justify-content-between">
            <div>
              <div className="filter-title">{filter.title}</div>
              <div className="filter-filters">
                <FacetBar interactive={false} searchParams={searchParams} tags={user.tags} />
              </div>
            </div>
            {allowEditAction && (
              <div>
                <DropdownDots
                  id={`dd-manage-filter-${filter.filterID}`}
                  data-cy="dd-manage-filter"
                  horizontal
                  pullRight
                  onSelect={(action) => this.handleFilterAction(filter, action)}
                >
                  <MenuItem eventKey="rename" data-cy="rename-saved-filter">
                    Rename
                  </MenuItem>
                  <MenuItem eventKey="delete" data-cy="delete-saved-filter">
                    Delete
                  </MenuItem>
                </DropdownDots>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { show, close, user, teams, trash } = this.props;
    const { managedTeamID } = this.state;
    const ownedTeams = _.filter(teams, (verifiedTeam) => user.canEditTeam(verifiedTeam.teamID));
    const managedTeam = managedTeamID ? _.find(teams, { teamID: managedTeamID }) : null;
    const filters = managedTeam ? managedTeam.filters : user.filters.getVisible();
    const scopeButtonTitle = managedTeam ? managedTeam.info.name : 'Personal';

    const activeFilters = trash
      ? _.filter(filters, (filter) => filter.searchParams.deleted)
      : _.filter(filters, (filter) => !filter.searchParams.deleted);

    return (
      <Modal dialogClassName="filter-manager" show={show} onHide={close} data-cy="modal-filter-manager">
        <Modal.Header closeButton>
          <span className="headline">
            {trash ? 'Manage saved filters for Trash' : `Manage saved filters for ${Dt}s`}
          </span>
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="team-selector-wrapper">
            <ControlLabel>Saved filters</ControlLabel>
            <Dropdown
              id="dd-select-filter-scope"
              data-cy="dd-select-filter-scope"
              disabled={!ownedTeams.length}
              onSelect={(teamID) => this.setState({ managedTeamID: teamID })}
              title={scopeButtonTitle}
              size="small"
              width={270}
              pullRight
            >
              <MenuItem eventKey={null} active={!managedTeamID}>
                Personal
              </MenuItem>
              <MenuItem divider />
              {_.map(ownedTeams, (team) => (
                <MenuItem key={team.teamID} eventKey={team.teamID} active={managedTeamID === team.teamID}>
                  {team.info.name}
                </MenuItem>
              ))}
            </Dropdown>
          </FormGroup>
          <div className="filter-group-list" data-cy="filter-group-list">
            {!activeFilters.length ? (
              <div className="empty">No saved filters</div>
            ) : (
              activeFilters.map(this.renderFilter)
            )}
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
