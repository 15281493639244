import React from 'react';

import { dt } from '@root/utils/Environment';

const PromoText = () => (
  <>
    Outlaw is a modern {dt} management solution that streamlines the {dt} lifecycle for organizations - from templated
    document generation to cloud-based redlining to real-time negotiation and eSigning.{' '}
    <a target="_blank" rel="noreferrer" href="https://getoutlaw.com">
      Learn more
    </a>
  </>
);

export default PromoText;
