import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { Button, Dropdown, Icon, MenuItem, Swatch } from '@components/dmp';

const DealStatusSelector = ({
  className,
  currentStep,
  disabled,
  enableAllSteps,
  loading,
  onSelect,
  steps,
  toolbar,
  isServiceProvider,
  ...passingProps
}) => {
  const currentStepKey = _.get(currentStep, 'key');

  const btnClassName = cx({ 'btn-tool': toolbar });
  const btnProps = { className: btnClassName };
  if (toolbar) {
    btnProps.bsStyle = 'link';
  }

  // If disabled (e.g., for non-owners who can't update status)
  // Just basically render the toggle button which does nothing
  if (disabled) {
    return (
      <Button dmpStyle="link" size="small" className="no-action-status deal-status-display btn-tool">
        <Swatch color={currentStep.color} />
        {currentStep.name}
      </Button>
    );
  }

  const disabledDropdown = loading || disabled;

  return (
    <Dropdown
      {...passingProps}
      dmpStyle={toolbar ? 'link' : undefined}
      noUnderline={toolbar ? true : false}
      block={!toolbar}
      onSelect={onSelect}
      className={cx('dd-deal-status', { 'in-toolbar': toolbar }, className)}
      id="dd-flow-status"
      loading={loading}
      disabled={disabledDropdown}
      btnClassName={btnClassName}
      menuWidth={230}
      dataCyToggle="dd-flow-status"
      title={
        currentStep ? (
          <>
            {!loading && <Swatch color={currentStep.color} />}
            {currentStep.name}
          </>
        ) : (
          'Select'
        )
      }
      size={toolbar ? 'small' : null}
    >
      {steps.map((step, idx) => {
        // Allow override of the dynamic step behevior logic so that statuses can be explicitly set if necessary
        // Used in cases like AttachmentUploader and ContractUploader, where we're setting status on a NEW DealVersion
        const disabled =
          !enableAllSteps && (step.behavior.status === 'disabled' || (!isServiceProvider && step.restricted));
        const menuItem = (
          <MenuItem
            className={`status-${step.key}`}
            active={step.key === currentStepKey}
            key={step.key}
            eventKey={step}
            disabled={disabled}
            border={!step.isLast}
            data-cy="status-step"
          >
            <OverlayTrigger
              key={step.key}
              placement="right"
              overlay={
                step.behavior.tip ? (
                  <Tooltip id={`tip-status-${step.key}`} className="content-left">
                    {step.behavior.tip}
                  </Tooltip>
                ) : (
                  <></>
                )
              }
            >
              <div className="menu-content">
                <div className="top-line">
                  <div>
                    <Swatch color={step.color} />
                    <div className="step-name">{step.name}</div>
                  </div>
                  {step.signable ? (
                    <Icon name="signatureScript" />
                  ) : step.checkpoint ? (
                    <Icon name="checkpoint" />
                  ) : step.locked ? (
                    <Icon name="lockClosed" />
                  ) : step.isFirst ? (
                    <Icon name="fieldsEdit" />
                  ) : null}
                </div>
                <div className="dmp-menu-item-info">{step.description}</div>
              </div>
            </OverlayTrigger>
          </MenuItem>
        );

        return menuItem;
      })}
    </Dropdown>
  );
};

DealStatusSelector.defaultProps = {
  disabled: false,
  enableAllSteps: false,
  toolbar: false,
};

DealStatusSelector.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.object,
  disabled: PropTypes.bool,
  enableAllSteps: PropTypes.bool,
  loading: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  toolbar: PropTypes.bool,
};

export default DealStatusSelector;
