const DealStatus = {
  NONE: { data: 'none', title: 'No Activity' },
  DISCUSS: { data: 'discuss', title: '', count: true, subtitle: 'Discuss flagged issues together' },
  PROGRESS: { data: 'progress', title: 'In Progress', subtitle: '' },
  CHANGED: { data: 'changed', title: 'Changed', subtitle: '' },

  TEMPLATE: { data: 'template', title: '', subtitle: '' },

  COMPLETE: { data: 'complete', title: 'Complete', subtitle: '' },
  REVIEW: {
    data: 'review',
    title: 'Review',
    count: true,
    subtitle: 'There are open issues - work together to resolve',
  },

  TODO: { data: 'todo', title: 'To do', count: true, subtitle: 'Start by filling in all required fields' },
  DRAFT: { data: 'draft', title: 'Draft', subtitle: 'Ready to share with other parties', action: 'Share' },
  SHARED: { data: 'shared', title: 'Shared', subtitle: 'Shared and waiting for review', action: 'Re-send invitation' },
  PROPOSED: { data: 'proposed', title: '', subtitle: '' },
  AGREED: { data: 'agreed', title: 'Complete', subtitle: 'Review and sign when ready', action: 'Sign' },
  SIGNING: { data: 'signing', title: 'Signing', count: true, subtitle: 'Sign below to complete the deal' },
  SIGNED: { data: 'signed', title: 'Agreed', subtitle: 'Nice work, Outlaw! This deal is done.' },

  //status for internal/testing use -- for paths that should be unreachable
  UNKNOWN: { data: 'unknown' },

  //generate a 'pending' DealStatus object with the count as its count property
  pending: function (count) {
    return { data: 'pending', title: 'Review', count: count };
  },
};

export const STEPS = [
  {
    key: 'todo',
    name: 'Setup',
    description: 'Contract variables are still to be completed',
    color: '#b2b2b2',
  },
  {
    key: 'draft',
    name: 'Draft',
    description: 'Contract has not yet been shared with all parties',
    color: '#ffb534',
  },
  {
    key: 'review',
    name: 'Review',
    description: 'Contract has not yet been executed',
    color: '#4480f1',
  },
  {
    key: 'signing',
    name: 'Signing',
    description: 'Contract execution is in progress',
    color: '#854AD3',
    locked: true,
    signable: true,
  },
  {
    key: 'signed',
    name: 'Done',
    description: 'Contract is executed and editing is disabled',
    color: '#46ca8c',
    locked: true,
  },
];

export const STEPS_STATIC = [
  {
    key: 'todo',
    name: 'Setup',
    color: '#b2b2b2',
    locked: false,
    signable: false,
  },
  {
    key: 'complete',
    name: 'Done',
    color: '#46ca8c',
    locked: true,
    signable: false,
  },
];

export const STEPS_LEGAL = [
  {
    key: 'drafting',
    name: 'Drafting',
    description: 'Document variables need to be completed or checked',
    color: '#ffb534',
  },
  {
    key: 'review',
    name: 'Review',
    description: 'Document is ready for team approval',
    color: '#4480f1',
  },
  {
    key: 'finalizing',
    name: 'Finalizing',
    description: 'Document can be finalized for submission or sharing',
    color: '#854AD3',
    locked: true,
    signable: true,
  },
  {
    key: 'filed',
    name: 'Filed',
    description: 'Document cannot be edited or revised',
    color: '#46ca8c',
    locked: true,
  },
];

export const STEPS_SERVICE_PROVIDER = [
  {
    checkpoint: false,
    checkpointSkippable: false,
    color: "#b2b2b2",
    description: "Fill in required supporting data for service provider",
    key: "todo",
    locked: false,
    name: "Setup",
    restricted: false,
    signable: false
  },
  {
    checkpoint: false,
    checkpointSkippable: false,
    color: "#EE6662",
    description: "Document request has been sent to service provider",
    key: "request",
    locked: false,
    name: "Request",
    restricted: true,
    signable: false
  },
  {
    checkpoint: false,
    checkpointSkippable: false,
    color: "#F6B36B",
    description: "Service provider has accepted this document request and work will begin shortly",
    key: "accepted",
    locked: false,
    name: "Accepted",
    restricted: true,
    signable: false
  },
  {
    checkpoint: false,
    checkpointSkippable: false,
    color: "#67A0F8",
    description: "Document drafting is in progress by service provider",
    key: "drafting",
    locked: false,
    name: "Drafting",
    restricted: true,
    signable: false
  },
  {
    checkpoint: false,
    checkpointSkippable: false,
    color: "#854AD3",
    description: "Review service provider's draft for this document request",
    key: "review",
    locked: false,
    name: "Review",
    restricted: false,
    signable: false
  },
  {
    checkpoint: false,
    checkpointSkippable: false,
    color: "#2DCB8D",
    description: "Service provider's work has been received and accepted as complete",
    key: "complete",
    locked: true,
    name: "Completed",
    restricted: false,
    signable: false
  },
  {
    checkpoint: false,
    checkpointSkippable: false,
    color: "#2DCB8D",
    description: "This document has been fully compiled, signed and is ready for mailing or filing",
    key: "signed",
    locked: true,
    name: "Signed",
    restricted: false,
    signable: false
  },
  {
    checkpoint: false,
    checkpointSkippable: false,
    color: "#2DCB8D",
    description: "This document has been filed or mailed",
    key: "sent",
    locked: true,
    name: "Sent",
    restricted: false,
    signable: false
  }
]

export default DealStatus;
