import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';
import qs from 'query-string';

import Login from '@components/Login';
import Preloader from '@components/Preloader';
import PromoFooter from '@components/PromoFooter';
import API from '@root/ApiClient';

@autoBindMethods
export default class TeamInviteView extends Component {
  static defaultProps = {
    account: null,
    user: null,
  };

  static propTypes = {
    account: PropTypes.object,
    getTeams: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loginError: PropTypes.string,
    match: PropTypes.object,
    og: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      teamInfo: null,
      noAccess: false,
      responding: false,
      done: false,
    };
  }

  componentDidMount() {
    //invite loads on page load
    this.getTeamInfo(this.props);

    this.props.og({ title: `Team Invitation - Outlaw` });
  }
  UNSAFE_componentWillReceiveProps(props) {
    //if user logs in after invite is loaded (frequently the case) attempt rsvp
    if (props.user && props.user.email && this.state.teamInfo) this.rsvp(props.user);
  }

  getTeamInfo(props) {
    const { inviteID, teamID } = props.match.params;
    const uid = props.user ? props.user.id : null;

    API.callAnon(
      'getTeamInfo',
      { teamID, inviteID, uid },
      (teamInfo) => {
        this.setState({ teamInfo }, () => {
          //if user is already logged in attempt rsvp
          //needs to happen after state updates so that we can access invite object in rsvp
          if (props.user) this.rsvp(props.user);
        });
      },
      (err) => {
        console.log(err);
        this.setState({ noAccess: true });
      }
    );
  }

  async rsvp(user) {
    const { getTeams, match } = this.props;
    const { inviteID, teamID } = match.params;
    const { noAccess, responding, done, teamInfo } = this.state;
    //avoid calling rsvp if we don't have sufficient info
    //also avoid duplicate calls if one is already in process (state.responding)
    if (!user || !teamInfo || !teamID || noAccess || responding || done) return;

    //here we've got all the data we need (invite, user etc)
    //but still need to confirm that user CAN rsvp
    //if user is anonymous (no email) we need to stop here
    if (!user.email) {
      console.log('Ignoring rsvp attempt from anonymous user');
      return;
    }

    //finally here we're all set to go
    this.setState({ responding: true });
    try {
      await API.call('joinTeam', { user, inviteID, teamID });
      // First, reload the teams, because we're now part of it and our teams are out of date.
      await getTeams(user);

      //redirect after response -- go straight into Team page
      this.setState({ responding: false, done: true });
      this.props.history.push(`/dashboard/team`);
    } catch (err) {
      console.log(err);
      this.setState({ noAccess: true, responding: false });
    }
  }

  render() {
    const { location, history, loginError } = this.props;
    const { teamInfo, noAccess, responding } = this.state;
    const loginProps = { location, history, loginError };
    const searchParams = qs.parse(location.search);

    const loginTypesParam = _.get(searchParams, 'loginTypes', null);
    if (loginTypesParam) {
      loginProps.loginTypes = loginTypesParam.split(',');
    }

    if (noAccess) return this.renderNoAccess();
    if (!teamInfo || responding) return <Preloader />;

    return (
      <main className="access-page invitation">
        <div className="col1">
          <div className="col1-inner">
            {teamInfo.logo && <img className="team-logo" src={teamInfo.logo} alt="logo" />}
            <div className="login-signup-titles">
              <div className="alert alert-info" role="alert">
                Join the {teamInfo.name} team on Outlaw
              </div>
            </div>
            <Login {...loginProps} />
          </div>
          <PromoFooter />
        </div>
      </main>
    );
  }

  renderNoAccess() {
    //this shows if the currently logged in user does not have access to this invite
    return (
      <main className="access-page no-access">
        <div className="col1">
          <div className="col1-inner">
            <h1>Sorry, no deal.</h1>
            <h4>You don't have access to this team.</h4>
          </div>
          <PromoFooter />
        </div>
      </main>
    );
  }
}
