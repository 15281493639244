import { pick } from 'lodash';

export default class ServiceConfig {
  // Key to service as used in SERVICES enum in Integration.js
  service = '';
  // Service endpoint for API calls
  url = '';
  // Unique ID for Outlaw to auth against service API as this customer
  appID = '';
  // Service token or "secret" for Outlaw to auth against service API
  token = '';
  // Outlaw user ownerID, the user creating the deal
  ownerID = null;

  constructor(json) {
    if (json.service) this.service = json.service;
    if (json.url) this.url = json.url;
    if (json.token) this.token = json.token;
    if (json.appID) this.appID = json.appID;
    if (json.ownerID) this.ownerID = json.ownerID;
  }

  get json() {
    return pick(this, ['service', 'url', 'appID', 'token', 'ownerID']);
  }
}
