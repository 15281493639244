import React, { useEffect, useState } from 'react';

import FlexSearch from 'flexsearch';
import PropTypes from 'prop-types';

const teammateDocument = {
  preset: 'default',
  tokenize: 'full',
  language: 'en',
  minLength: 3,
  document: {
    id: 'id',
    index: ['fullName', 'email'],
  },
};

const TeammateSearch = ({ teammates, onApplyFilter }) => {
  const [index, setIndex] = useState(new FlexSearch.Document(teammateDocument));
  const [query, setQuery] = useState('');
  const delayedQuery = _.debounce((q) => {
    if (q === query) return;

    const searchResults = index.search(query);
    const ids = {};

    //Document search results are like [{match: 'string', results: [...ids]}], so we need to flatten it
    searchResults.forEach((sr) => sr.result.forEach((r) => (ids[r] = r)));

    onApplyFilter(Object.keys(ids), q);
  }, 300);

  useEffect(() => {
    teammates.forEach((teammate) => {
      const { id, fullName, email } = teammate;
      setIndex(index.add({ id, fullName, email }));
    });
  }, [teammates]);

  const handleQuery = (e) => {
    const q = e.target.value;
    setQuery(q);
    delayedQuery(q);
  };

  return (
    <div className="teammate-search">
      <input
        type="text"
        onChange={handleQuery}
        placeholder="Filter by name or email"
        className="form-control input-sm"
        data-cy="teammate-search-input"
      />
    </div>
  );
};

TeammateSearch.propTypes = {
  teammates: PropTypes.array.isRequired,
  onApplyFilter: PropTypes.func.isRequired,
};

export default TeammateSearch;
