/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';

import { Replay } from '@sentry/browser';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { createBrowserHistory } from 'history';

import { Route, Router } from 'react-router-dom';

import Core from '@core/Core';
import { isCypressRunning } from '@core/utils/Environment';
import stateFromHTML from '@core/utils/StateFromHTML';

import App from '@components/App';
import CONFIG from '@root/Config';
import Fire from '@root/Fire';

import './style/outlaw.scss';

// Global app initialization stuff needs to be done outside react component (i.e., immediately)
console.log(`Using environment [${CONFIG.ENV}] TEST YO BRO`);
const { FIREBASE, ENV } = CONFIG;

// This is Evan replacing an old useless comment with our 10,000th commit 😎

if (window.location.hostname === 'localhost') {
  // Override auth domain when running locally
  FIREBASE.authDomain = `localhost:${window.location.port}`;
}
const firebaseApp = firebase.initializeApp(FIREBASE);
const firebaseAuth = firebase.auth();
const firebaseDB = firebase.database();
const firebaseStorage = firebase.storage();

export { firebaseApp, firebaseAuth, firebaseDB, firebaseStorage };

Fire.init(firebaseDB, firebaseAuth, firebaseStorage);

// Init Core
Core.init({ Fire, Config: CONFIG, functions: { stateFromHTML: stateFromHTML } });

const history = createBrowserHistory();

if (window?.Sentry && ENV !== 'DEV') {
  // Add Session Replay
  window.Sentry.getCurrentHub()
    .getClient()
    .addIntegration(
      new Replay({
        maskAllText: true,
        blockAllMedia: true,
      })
    );
}

const render = (Component) => {
  ReactDOM.render(
    <Router history={history}>
      <Route render={(renderProps) => <Component {...renderProps} />} />
    </Router>,
    document.querySelector('#outlaw')
  );
};

render(App);

if (module.hot && !isCypressRunning) {
  console.log('We are on 🔥!');

  module.hot.accept(['./components/App'], () => {
    const NextApp = require('./components/App').default;
    render(NextApp);
  });
}
