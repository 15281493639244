import React from 'react';

import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

import Icon from '@components/dmp/Icon';
import { ICONS } from '@components/dmp/shared/icons';

const cl = classNamePrefixer('btn');

const ButtonIcon = React.forwardRef(
  (
    {
      checkbox,
      checked,
      className,
      dark,
      disabled,
      faded,
      icon,
      onClick,
      size,
      tool,
      noFill,
      indicator,
      color,
      ...passingProps
    },
    ref
  ) => {
    const classNames = cx(
      'btn', // This is required for <a> tags button to behave like regular ones (e.g ButtonGroup)
      cl(),
      cl('icon'),
      cl('icon-only'),
      cl('icon-link'),
      cl(size),
      { [cl('dark')]: dark },
      { [cl('icon-checked')]: checked },
      { [cl('icon-checkbox')]: checkbox },
      { [cl('icon-disabled')]: disabled },
      { [cl('tool')]: tool },
      { [cl('no-fill')]: noFill },
      className
    );

    const handleClick = () => {
      if (disabled) {
        return;
      }
      onClick();
    };

    if (!ICONS[icon]) {
      throw new Error(`@dmp/ButtonIcon : <Icon name="${icon}"> does not exist.`);
    }

    return (
      <a className={classNames} onClick={handleClick} {...passingProps} ref={ref}>
        <Icon name={icon} dark={dark} faded={faded} size={size} noFill={noFill} color={color} />
        {!!indicator && indicator}
      </a>
    );
  }
);

ButtonIcon.displayName = 'ButtonIcon';

ButtonIcon.defaultProps = {
  // TODO: Checkbox mode is only working in dark mode right now
  // It was implemented during 3PP POC
  checkbox: false,
  checked: false,
  dark: false,
  disabled: false,
  faded: false,
  onClick: _.noop,
  size: 'large',
  tool: false,
  noFill: false,
  color: undefined,
};

ButtonIcon.propTypes = {
  checkbox: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  dark: PropTypes.bool,
  disabled: PropTypes.bool,
  faded: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xsmall', 'small', 'default', 'large']),
  tool: PropTypes.bool,
  noFill: PropTypes.bool,
  // TODO: Review this. I do not believe that it belongs here (ping @leohuettel)
  indicator: PropTypes.node,
};

export default ButtonIcon;
