import {
  cloneDeep,
  compact,
  forOwn,
  isArray,
  isEmpty,
  isNaN,
  isNull,
  isObject,
  isString,
  isUndefined,
  merge,
  pull,
  reduce,
} from 'lodash';

export const convertPointsToPixels = (points, scale = 1) => {
  //return Math.round(points * 96 / 72) * scale;
  return Math.round(points * scale);
};

export const convertPixelsToPoints = (pixels, scale = 1) => {
  //return Math.round((pixels / scale) * 72 / 96);
  return Math.round(pixels / scale);
};

// TODO Move somewhere else, I don't think that it belong here
export const getFullName = (firstName, lastName) => {
  return compact([firstName, lastName]).join(' ');
};

export const flattenedKeysObject = (obj, path = []) => {
  return !isObject(obj)
    ? { [path.join('.')]: obj }
    : reduce(obj, (cum, next, key) => merge(cum, flattenedKeysObject(next, [...path, key])), {});
};

export const convertHexToRgb = (color) => {
  let colorHex = parseInt(color, 16);
  let r = (colorHex >> 16) & 255;
  let g = (colorHex >> 8) & 255;
  let b = colorHex & 255;
  return `rgb(${r}, ${g}, ${b})`;
};

/*
  Remove all null, undefined, NaN, empty String, empty array and empty object values recursively.
  From Stackoverflow: https://stackoverflow.com/a/26202058/2179494
*/
export const pruneEmpty = (obj) => {
  return (function prune(current) {
    forOwn(current, function (value, key) {
      if (
        isUndefined(value) ||
        isNull(value) ||
        isNaN(value) ||
        (isString(value) && isEmpty(value)) ||
        (isObject(value) && isEmpty(prune(value)))
      ) {
        delete current[key];
      }
    });
    // remove any leftover undefined values from the delete
    // operation on an array
    if (isArray(current)) pull(current, undefined);
    return current;
  })(cloneDeep(obj)); // Do not modify the original object, create a clone instead
};
