import CONFIG from './Config';

export const ATTRIBUTES = {
  REFERRING_TEAM: 'referring_team', //if user came in as a recipient, team ID of referring team
  DEALS_TOTAL: 'deals_total', //total number of deals user has in account
  DEALS_RECIPIENT: 'deals_recipient', //number of deals user has received
  DEALS_OWNER: 'deals_owner', //number of deals user owns
  DEALS_CLOSED: 'deals_closed', //number of completed deals user is on (regardless of sender or recipient)
  TEAMS: 'teams', //comma-separated list of teams user is on
  GUEST: 'guest', //set to 'true' if user signed a deal as guest but never actually logged in
};

export const HELP = {
  CONDITIONALS: 'https://filevine.zendesk.com/knowledge/articles/17343968838299/en-us?brand_id=4415356259995',
  CONNECTED_VARS: 'https://help.getoutlaw.com/hc/en-us/articles/17344637750299-Connected-Variables-Introduction',
  CONNECTION_MGMT:
    'https://help.getoutlaw.com/hc/en-us/articles/17344647644059-Connecting-an-Outlaw-Template-to-a-Filevine-Project',
};

class CRM {
  login(account, user) {
    if (!account.isAnonymous) this.initializeUserEventTracking(user);
  }

  initializeUserEventTracking(user) {
    if (typeof pendo === 'undefined' || window.Cypress) return;
    const disablePendoGuides = !!CONFIG.HELP_URL;

    console.log(`Initializing Pendo.${disablePendoGuides ? ' Disabled Guides' : ''}`);

    pendo.initialize({
      visitor: {
        id: user.id, // Required if user is logged in, default creates anonymous ID
        email: user.email, // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional
        organization: user.info.org,
      },
      // TODO: Implement when using Pendo Feedback
      account: {},
      guides: {
        disabled: disablePendoGuides,
      },
    });
  }
}

const crm = new CRM();

export default crm;
