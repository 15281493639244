import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import PropTypes from 'prop-types';

import { ButtonIcon } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';

@autoBindMethods
export default class IDCopy extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      copied: false,
      showIcon: false,
    };
  }

  async handleClick(value) {
    navigator.clipboard.writeText(value);
    this.setState({ copied: true });

    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 2000);
  }

  render() {
    const { value, name } = this.props;
    const { copied, showIcon } = this.state;

    return (
      <div
        className="id-tip"
        onMouseEnter={() => this.setState({ showIcon: true })}
        onMouseLeave={() => this.setState({ showIcon: false })}
      >
        <span className="id">{value}</span>
        {showIcon && !copied && (
          <TooltipButton tip={`Copy ${name} ID to clipboard`}>
            <ButtonIcon icon="copy" className="copy" onClick={() => this.handleClick(value)} />
          </TooltipButton>
        )}
        {showIcon && copied && <ButtonIcon icon="check" />}
      </div>
    );
  }
}
