import React, { Component } from 'react';

import { arrayMoveImmutable } from 'array-move';
import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';

import Deal from '@core/models/Deal';
import { DEFAULT_COLS, TRASH_COLS } from '@core/models/SearchParams';

import { Button, DragHandle, Ellipsis, Tag } from '@components/dmp';

import { findColumn } from '../deal/Columns';

const SortableItem = sortableElement(({ value, onRemove }) => (
  <li className="sortable">
    <Tag block color="gray" onRemove={() => onRemove(value.key)}>
      <DragHandle />
      <Ellipsis>{value.Header}</Ellipsis>
    </Tag>
  </li>
));

const SortableContainer = sortableContainer(({ children }) => <ul>{children}</ul>);

@autoBindMethods
export default class ColumnsManager extends Component {
  static defaultProps = {
    columns: null,
    dealTemplate: null,
    defaultColumns: DEFAULT_COLS,
    trashDefaultColumns: TRASH_COLS,
    onChange: _.noop,
    onRemoveColumn: _.noop,
  };

  static propTypes = {
    columns: PropTypes.array,
    defaultColumns: PropTypes.array,
    dealTemplate: PropTypes.instanceOf(Deal),
    onChange: PropTypes.func,
    onRemoveColumn: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      columnModels: [],
    };
  }

  get isDefault() {
    return this.props.columns.join(',') === this.props.defaultColumns.join(',');
  }

  componentDidMount() {
    this.loadColumns();
  }

  componentDidUpdate(prevProps, prevState) {
    const { columnModels } = this.state;
    const { columns } = this.props;

    if (columnModels.length !== columns.length || columns !== prevProps.columns) {
      this.loadColumns();
    }
  }

  async loadColumns() {
    const { dealTemplate, columns, teamTemplates } = this.props;

    const columnModels = [];

    for await (const key of columns) {
      let column = findColumn(key, dealTemplate);
      if (column) {
        columnModels.push(column);
      } else if (teamTemplates) {
        for await (const template of teamTemplates) {
          column = findColumn(key, template);
          if (column) {
            columnModels.push(column);
          }
        }
      }
    }

    this.setState({ columnModels: _.uniqBy(columnModels, 'key') });
  }

  resetColumns() {
    const { isTrash, onChange } = this.props;
    isTrash ? onChange([...this.props.trashDefaultColumns]) : onChange([...this.props.defaultColumns]);
  }

  onSortEnd({ oldIndex, newIndex }) {
    const { columns, onChange } = this.props;
    const newColumns = arrayMoveImmutable([...columns], oldIndex, newIndex);

    onChange(newColumns);
  }

  render() {
    const { columnModels } = this.state;
    const { onRemoveColumn } = this.props;

    return (
      <div>
        <div className="list-title d-flex justify-content-between align-items-center">
          <span>Active columns</span>
          <Button
            className="reset"
            dmpStyle="link"
            size="small"
            onClick={this.resetColumns}
            disabled={this.isDefault}
            data-cy="reset-columns"
          >
            Reset
          </Button>
        </div>

        <SortableContainer axis="y" onSortEnd={this.onSortEnd} distance={5}>
          {columnModels.map((column, idx) => (
            <SortableItem key={`item-${column.key}`} index={idx} value={column} onRemove={onRemoveColumn} />
          ))}
        </SortableContainer>
      </div>
    );
  }
}
