import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from '@components/dmp';

const TemplateLink = (props) => {
  const { title, dealID, className } = props;

  return (
    <div className={cx('template-link', className)} data-cy="template-link">
      <Icon name="template" size="default" />
      <a target="_blank" href={`/templates/${dealID}`}>
        {title}
      </a>
    </div>
  );
};

TemplateLink.propTypes = {
  title: PropTypes.string.isRequired,
  dealID: PropTypes.string.isRequired,
  className: PropTypes.string,
};

TemplateLink.defaultProps = {
  className: null,
};

export default TemplateLink;
