import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

const clItem = classNamePrefixer('deal-panel-item', '');
const DealPanelItem = React.forwardRef(
  ({ active, disabled, borderBottom, children, className, transparent, type, ...passingProps }, ref) => {
    const classNames = cx(
      clItem(),
      { [clItem('active')]: active },
      { [clItem('transparent')]: transparent },
      { [clItem('border-bottom')]: borderBottom },
      clItem(`type-${type}`),
      { [clItem('disabled')]: disabled },
      className
    );

    return (
      <div ref={ref} className={classNames} {...passingProps}>
        {children}
      </div>
    );
  }
);

DealPanelItem.displayName = 'DealPanelItem';
DealPanelItem.defaultProps = {
  active: false,
  disabled: false,
  className: null,
  transparent: false,
  type: 'primary',
  borderBottom: false,
  children: null,
};

DealPanelItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
  borderBottom: PropTypes.bool,
};

export default DealPanelItem;
