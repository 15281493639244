import React, { Component } from 'react';

import autobindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

import DealStatus from '@core/enums/DealStatus';
import { DEAL_TYPE } from '@core/models/Deal';
import Deal from '@core/models/Deal';
import DealRecord from '@core/models/DealRecord';
import Filter from '@core/models/Filter';
import Report from '@core/models/Report';
import SearchParams from '@core/models/SearchParams';
import Team from '@core/models/Team';
import User from '@core/models/User';
import { dt } from '@core/utils/Environment';

import { DataTable, Icon, Loader } from '@components/dmp';

import { findColumn } from '@components/deal/Columns';
import API from '@root/ApiClient';

const NoData = () => (
  <div>
    Nothing to show
    <span>Create or import {dt}s to Outlaw</span>
  </div>
);

@autobindMethods
class ReportItemDefault extends Component {
  static defaultProps = {
    dealTemplate: null,
    reportLink: null,
  };

  static propTypes = {
    report: PropTypes.instanceOf(Report).isRequired,
    filter: PropTypes.instanceOf(Filter).isRequired,
    dealTemplate: PropTypes.instanceOf(Deal),
    setSubtitle: PropTypes.func.isRequired,
    reportLink: PropTypes.string,
    reportTeam: PropTypes.instanceOf(Team),
    user: PropTypes.instanceOf(User).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      total: 0,
      deals: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.getReportData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  get columns() {
    const { report, dealTemplate } = this.props;
    let { columns } = report;

    const tableColumns = _.map(columns, (columnName) => {
      const column = findColumn(columnName, dealTemplate, 'report');
      if (column) {
        column.sortable = false;

        // Handle all the dynamic properties for variable columns
        if (column.isVariable) {
          const varName = column.key.replace('v.', '');
          const templateVariable = dealTemplate.variables[varName];

          if (!templateVariable) return;

          return {
            ...column,
            Header: templateVariable.displayName || templateVariable.name,
            id: column.key,
            accessor: (row) => _.get(_.find(row.variables, { name: varName }), 'displayValue'),
            className: `variable variable-${varName}`,
          };
        }
      }

      return column;
    });

    return _.compact(tableColumns);
  }

  async getReportData() {
    const { filter, user } = this.props;
    let { searchParams } = filter;

    const searchParamsModel = new SearchParams(null, null, searchParams);

    const results = await API.call('getDeals', {
      ...searchParamsModel.apiArgs,
      hitsPerPage: 6,
      teamID: filter.teamID,
      filterID: filter.filterID,
    });

    // results is the json obj from Aloglia. results.hits are an untyped array of DealRecord objects */
    const deals = _.map(results.hits, (record) => new DealRecord(record, user.id));

    // Set deal tags here to avoid doing it in the table rendering (this.columns)
    _.forEach(deals, (deal) => {
      deal.userTags = user.tags.get(deal.tags);
    });

    if (this._isMounted) this.setState({ deals, isLoading: false });

    // Set subtitle
    this.setSubtitle(results);
  }

  setSubtitle({ hits = [], nbHits = 0 }) {
    const { reportLink, reportTeam } = this.props;
    const teamName = _.get(reportTeam, 'info.name');

    let showingSentence = `No ${dt}s`;
    if (hits.length > 0) {
      showingSentence = `Showing ${hits.length} of ${nbHits} ${dt}s`;
    }

    const subtitleComponent = (
      <span>
        {!!teamName && (
          <span>
            <Icon name="users" /> {teamName} &middot;{' '}
          </span>
        )}
        {showingSentence}
        {!!reportLink && (
          <span>
            ,{' '}
            <Link to={reportLink} data-cy="link-view-all">
              view all
            </Link>
          </span>
        )}
      </span>
    );

    this.props.setSubtitle(subtitleComponent);
  }

  rowLocation(state, rowInfo) {
    const dealRecord = _.get(rowInfo, 'original');

    if (!dealRecord) return;

    let url = `/deals/${dealRecord.dealID}`;
    // if deal is locked (signing or signed) default to source view
    if (['signing', 'signed'].indexOf(dealRecord.status) > -1) url += '/contract';
    // if we're viewing an INGESTED/BESPOKE deal, go to Draft mode!
    // (can further update this to also depend on status)
    else if (
      [DEAL_TYPE.INGESTED, DEAL_TYPE.BESPOKE].indexOf(dealRecord.dealType) > -1 &&
      dealRecord.status === DealStatus.DRAFT.title
    )
      url += '/draft';

    return url;
  }

  getTdProps(state, rowInfo, column) {
    let to = this.rowLocation(state, rowInfo) || null;

    if (column.clickable === false || column.id === '_selector') {
      to = null;
    }

    return { to };
  }

  tdComponent({ className, children, to, ...rest }) {
    const classNames = cx(className, 'rt-td'),
      TdComponent = to ? Link : 'div';

    return (
      <TdComponent className={classNames} to={to} {...rest}>
        {children}
      </TdComponent>
    );
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div className="report-item-content" data-cy="report-item-content-regular">
        {isLoading ? <Loader centered /> : this.renderReport()}
      </div>
    );
  }

  renderReport() {
    const { deals } = this.state;

    return (
      <DataTable
        clickable
        columns={this.columns}
        data={deals}
        getTdProps={this.getTdProps}
        list
        noDataText={<NoData />}
        resizable={false}
        showPagination={false}
        sortable={false}
        TdComponent={this.tdComponent}
      />
    );
  }
}

export default ReportItemDefault;
