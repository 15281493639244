import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import { _ } from 'core-js';
import PropTypes from 'prop-types';

import { makeNotificationTypes } from '@core/models/NotificationSettings';

import { Card, CardList, Icon, Switch } from '@components/dmp';

import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

// We make a fake "global" team just for UI convenience:
const GLOBAL_TEAM_INFO = {
  id: '___global___', // Try to prevent an ID clash, it's unlikely any real Team has this ID.
  name: 'Global settings (all teams)',
};

@autoBindMethods
export default class Notifications extends Component {
  static propTypes = {
    userID: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      settings: null,
      teams: [],
      selectedTeam: GLOBAL_TEAM_INFO,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    const userTeams = await Fire.getUserTeams(this.props.userID);
    const settings = await Fire.getUserNotificationSettings(this.props.userID, userTeams);

    const teams = userTeams.map((team) => ({
      id: team.teamID,
      name: team.info.name,
    }));

    this.setState({ settings, teams });
  }

  async saveSettings(settings) {
    await Fire.saveUserNotificationSettings(this.props.userID, settings);
  }

  isTeamEnabled(teamID) {
    return Boolean(this.state.settings?.teams[teamID]?.enabled);
  }

  isNotificationEnabled(team, key) {
    if (key === 'dashboard') {
      return true;
    }
    if (team === GLOBAL_TEAM_INFO) {
      return this.state.settings?.global[key].email;
    }
    return this.state.settings?.teams[team.id][key].email;
  }

  toggleTeamEnabled(teamID) {
    const settings = this.state.settings;
    settings.teams[teamID].enabled = !settings.teams[teamID].enabled;
    this.setState({ settings }, () => {
      this.saveSettings(settings);
    });
  }

  toggleNotification(teamID, key) {
    const settings = this.state.settings;
    if (teamID === GLOBAL_TEAM_INFO.id) {
      settings.global[key].email = !settings.global[key].email;
    } else {
      settings.teams[teamID][key].email = !settings.teams[teamID][key].email;
    }
    this.setState({ settings }, () => {
      this.saveSettings(settings);
    });
  }

  //Compare if the team has the same settings as global settings
  compareSettings(teamID) {
    const { settings } = this.state;
    return _.isEqual(settings.global, _.omit(settings.teams[teamID], ['enabled']));
  }

  render() {
    const { settings, teams, selectedTeam } = this.state;

    const notificationTypes = [
      ...makeNotificationTypes(),
      { key: 'dashboard', title: 'Dashboard Report Availability (managed on Dashboard)' },
    ];

    return (
      <div className="setting-block notifications">
        <div className="block-header">
          <h4>Email Notifications</h4>
          <span className="prompt">Choose events for which you’d like to be notified. Changes are autosaved.</span>
        </div>
        <div className="notifications-content">
          <Card className="notifications-teams" data-cy="notifications-teams">
            <div
              className={`global-notifications-setting ${selectedTeam === GLOBAL_TEAM_INFO ? 'selected' : ''}`}
              onClick={() => this.setState({ selectedTeam: GLOBAL_TEAM_INFO })}
              data-cy="global-notifications-setting"
            >
              <div className="icon-global">
                <Icon name="global" />
              </div>
              <div className="team-name">
                <span>{GLOBAL_TEAM_INFO.name}</span>
              </div>
              <div className="icon-chevron-right">
                <Icon name="chevronRight" />
              </div>
            </div>

            {settings &&
              teams.map((team) => (
                <div
                  key={team.id}
                  className={`team-notifications-setting ${selectedTeam.id === team.id ? 'selected' : ''}`}
                  onClick={() => this.setState({ selectedTeam: team })}
                  data-cy="team-notifications-setting"
                >
                  <div className="icon-users">
                    <Icon name="users" />
                  </div>
                  <div className="team-name">
                    <span>{team.name}</span>
                  </div>
                  {this.isTeamEnabled(team.id) && !this.compareSettings(team.id) && (
                    <div className="icon-override">
                      <TooltipButton
                        tipID="tip-override"
                        tip="This team has notification settings that override global settings"
                        placement="top"
                      >
                        <Icon name="override" />
                      </TooltipButton>
                    </div>
                  )}
                  <div className="icon-chevron-right">
                    <Icon name="chevronRight" />
                  </div>
                </div>
              ))}
          </Card>
          <CardList className="notifications-settings" data-cy="notifications-settings">
            <CardList.Item className="setting-info">
              {selectedTeam === GLOBAL_TEAM_INFO ? (
                <>
                  <h4>Global settings for all teams</h4>
                  <small>Global settings may be overridden for individual teams.</small>
                </>
              ) : (
                <>
                  <h4>{selectedTeam.name}</h4>
                  {(!this.isTeamEnabled(selectedTeam.id) ||
                    (this.isTeamEnabled(selectedTeam.id) && this.compareSettings(selectedTeam.id))) && (
                    <small>Global notifications settings are applied to this team.</small>
                  )}
                </>
              )}
            </CardList.Item>
            {selectedTeam !== GLOBAL_TEAM_INFO && (
              <CardList.Item className="enable-team-settings" data-cy="enable-team-settings">
                <Switch
                  className="switch-enable-team-settings"
                  checked={this.isTeamEnabled(selectedTeam.id)}
                  id="enabled-team-notifications"
                  onChange={() => {
                    this.toggleTeamEnabled(selectedTeam.id);
                  }}
                  size="small"
                >
                  Enable team-specific settings that override global settings
                </Switch>
              </CardList.Item>
            )}

            {(selectedTeam === GLOBAL_TEAM_INFO || this.isTeamEnabled(selectedTeam.id)) && (
              <>
                {notificationTypes.map((notification) => (
                  <CardList.Item key={notification.key} className="notification-setting" data-cy="notification-setting">
                    <Switch
                      className="switch-notification-setting"
                      checked={this.isNotificationEnabled(selectedTeam, notification.key)}
                      id={notification.key}
                      onChange={() => {
                        this.toggleNotification(selectedTeam.id, notification.key);
                      }}
                      size="small"
                      disabled={notification.key === 'dashboard'}
                    >
                      {notification.key === 'dashboard' ? (
                        <>
                          Dashboard Report Availability{' '}
                          <p>
                            (managed on <a href="/dashboard">Dashboard</a>)
                          </p>
                        </>
                      ) : (
                        notification.title
                      )}
                    </Switch>
                    <small>{notification.description || null}</small>
                  </CardList.Item>
                ))}
              </>
            )}
          </CardList>
        </div>
      </div>
    );
  }
}
