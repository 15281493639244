import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import InviteStatus from '@core/enums/InviteStatus';
import Bundle from '@core/models/Bundle';
import { Dt, dt } from '@core/utils/Environment';
import { isEmail } from '@core/utils/Validation';

import { Alert, Button, Card } from '@components/dmp';
import { Icon } from '@components/dmp';

import NoDealAccess from '@components/NoDealAccess';
import Preloader from '@components/Preloader';
import PromoFooter from '@components/PromoFooter';
import SingleSignOnButton from '@components/SingleSignOnButton';
import API from '@root/ApiClient';
import Auth, { PROVIDERS } from '@root/Auth';
import CONFIG from '@root/Config';

@autoBindMethods
export default class InviteView extends Component {
  static defaultProps = {
    account: null,
    user: null,
  };

  static propTypes = {
    account: PropTypes.object,
    anonEmail: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    og: PropTypes.func,
    user: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      invite: null,
      noAccess: false,
      isResponding: false,
      guestEmail: '',
      bundle: null,
    };
  }

  componentDidMount() {
    // invite loads on page load
    this.getInvite();

    this.props.og({ title: `${Dt} Invitation - Outlaw` });
  }
  UNSAFE_componentWillReceiveProps(props) {
    const { user, account } = props;
    // if user logs in after invite is loaded (frequently the case) attempt rsvp
    if (user && account && this.state.invite) this.rsvp(user, account);
  }

  get isLoading() {
    const { invite, noAccess, isResponding } = this.state;

    // If we got a noAccess, it means that we've verified access, so we are not loading anymore.
    if (noAccess) return false;

    return !invite || isResponding || (_.get(invite, 'autoGuest') && _.get(invite, 'to.email'));
  }

  get loginUrl() {
    const { location } = this.props;
    return `/login/?redirect=${encodeURIComponent(location.pathname)}`;
  }

  get openIDUrl() {
    const { location } = this.props;
    return `/openID/?redirect=${encodeURIComponent(location.pathname)}`;
  }

  async loadBundle(deal) {
    const bundleRaw = await API.callAnon('getBundle', { dealID: deal.parentDealID || deal.dealID });
    if (bundleRaw) {
      const bundle = new Bundle(bundleRaw.parent, bundleRaw.children);
      await this.setState({ bundle });
    } else {
      await this.setState({ bundle: null });
    }
  }

  async getInvite() {
    const { account, match, user } = this.props;
    const { inviteID, duKey } = match.params;

    try {
      const invite = await API.callAnon('getInvite', { inviteID, duKey });
      const guestEmail = _.get(invite, 'guestSigning') ? _.get(invite, 'to.email', '') : '';
      // We don't have a user and autoGuest is enabled, go forward!
      // But we also need to ensure that an email was provided in the invite
      if (!user && invite.autoGuest && guestEmail) {
        console.log(`[AUTH] Automatically logging in as guest (${guestEmail})`);
        await Auth.anonLogin(guestEmail);
      }

      if (invite.isBundle) {
        await this.loadBundle(invite.deal);
      }

      await this.setState({ invite, guestEmail });

      // If user is already logged in attempt rsvp
      // needs to happen after state updates so that we can access invite object in rsvp
      if (user && account) {
        this.rsvp(user, account);
      }
    } catch (err) {
      this.setState({ noAccess: true });
    }
  }

  async rsvp(user, account) {
    const { anonEmail, match } = this.props;
    const { inviteID, duKey } = match.params;
    const { noAccess, isResponding, invite, guestEmail } = this.state;

    // Avoid calling rsvp if we don't have sufficient info.
    // Also avoid duplicate calls if one is already in process (state.isResponding)
    if (!user || !invite || !duKey || noAccess || isResponding) return;

    // Here we've got all the data we need (invite, user etc)
    // but still need to confirm that user CAN rsvp
    // if user is anonymous (no email) and guestSigning is not allowed we need to stop here
    if (!user.email && !invite.guestSigning) {
      return;
    }

    //finally here we're all set to go. this will work for both normal cases (logged in users)
    //and for anonymously authed users rsvp-ing to deals which have enabled guest signing
    //the simple act of viewing this route means "yes i want to see the deal"
    //i.e., rsvp = ACCEPTED
    //user can still rsvp "no" by leaving the deal or declining on dashboard
    const inviteResponse = InviteStatus.ACCEPTED;

    const isGuest = account.isAnonymous;

    // If we're a guest add the email we used to sign in
    let rsvpGuestEmail = isGuest ? anonEmail : null;
    if (!rsvpGuestEmail && account.isAnonymous && isEmail(guestEmail)) {
      rsvpGuestEmail = guestEmail;
    }

    // dissalow RSVP while being anonymous and not providing an email
    if (isGuest && !rsvpGuestEmail) {
      return;
    }

    await this.setState({ isResponding: true });

    try {
      const { dealID } = await API.call('rsvp', {
        user,
        inviteID,
        duKey,
        inviteResponse,
        isGuest,
        guestEmail: rsvpGuestEmail,
      });

      if (!dealID) {
        throw new Error(`Deal does not exist after rsvp invite [${inviteID}]`);
      }

      // Redirect after response -- go straight into deal
      this.props.history.push(`/deals/${dealID}`);
    } catch (error) {
      console.error('RSVP', error);
      this.setState({ noAccess: true, isResponding: false });
    }
  }

  async handleSubmit(e) {
    const { account, user } = this.props;
    const { guestEmail } = this.state;

    e.preventDefault();

    // If we were logged in as anonymous already, just hit rsvp
    if (account && account.isAnonymous) {
      await this.rsvp(user, account);
    } else {
      let guestErrorMessage = null;
      await this.setState({ isVerifyingGuest: true });
      // Verify wether we can sign in as a guest (email is not tied to an Outlaw account)
      try {
        const { success, providerId } = await API.callAnon('canSignInAsGuest', { email: guestEmail });

        if (!success) {
          guestErrorMessage = 'Unknown error, please try again later or contact us for help.';
          if (providerId === 'password') {
            guestErrorMessage = (
              <>
                The email address {guestEmail} is a valid Outlaw account. To continue tap{' '}
                <Link to={this.loginUrl}>Log in</Link>, and enter your password.
              </>
            );
          } else if (providerId) {
            const provider = _.find(PROVIDERS, ['id', providerId]);
            if (provider) {
              guestErrorMessage = (
                <>
                  The email address {guestEmail} is connected to {provider.name} (SSO). To continue, tap{' '}
                  <b>Continue with {provider.name}</b> or enter another email.
                </>
              );
            }
          }
        }
      } catch (err) {
        guestErrorMessage = 'Unknown error, please try again later or contact us for help.';
      }

      if (guestErrorMessage) {
        this.setState({
          isVerifyingGuest: false,
          guestErrorMessage,
        });
        return;
      }

      await this.setState({ isResponding: true });

      // Otherwise, trigger the anon login!
      await Auth.anonLogin(guestEmail);

      // no need to set back isResponding to false since this component will unmount on auth change
    }
  }

  renderSSO() {
    return (
      <div className="sso">
        {_.map(
          PROVIDERS,
          (provider, key) =>
            provider.name !== PROVIDERS.OPEN_ID.name && (
              <SingleSignOnButton
                block
                domain={provider.id}
                key={key}
                providerName={provider.name}
                textTemplate="Continue with [PROVIDER]"
              />
            )
        )}
      </div>
    );
  }

  renderOpenID() {
    // Render OpenID differently than the other SSO providers since it's a 2 step process
    return (
      <Button block={true} onClick={() => this.props.history.push(this.openIDUrl)} className="sso-button">
        <img src={`/assets/svg/login-openid.svg`} alt="OpenID" />
        <span>Continue with OpenID</span>
      </Button>
    );
  }

  renderGuestForm() {
    const { isVerifyingGuest, guestEmail } = this.state;
    const isEmailValid = isEmail(guestEmail);
    const btnContinueText = `Continue${isEmailValid ? ' as ' + guestEmail : ''}`;

    return (
      <>
        <div className="or">
          <div>Or</div>
        </div>

        <form className="form-fields" onSubmit={this.handleSubmit}>
          <FormGroup>
            <div className="contents">
              <ControlLabel>Email address</ControlLabel>
              <HelpBlock>This email will receive all notifications and a copy of the {dt}</HelpBlock>
              <FormControl
                disabled={isVerifyingGuest}
                onChange={(e) => this.setState({ guestEmail: e.target.value })}
                placeholder="you@company.com"
                type="text"
                value={guestEmail}
                data-cy="guest-access"
              />
            </div>
          </FormGroup>

          <Button
            dmpStyle="primary"
            block
            disabled={!isEmailValid || isVerifyingGuest}
            type="submit"
            data-cy="btn-submit"
          >
            {isVerifyingGuest ? 'Connecting...' : btnContinueText}
          </Button>
        </form>
      </>
    );
  }

  render() {
    const { invite, noAccess, guestErrorMessage, bundle } = this.state;
    if (this.isLoading) {
      return <Preloader />;
    }

    if (noAccess) {
      return this.renderNoAccess();
    }

    const info = invite.deal || {};
    const { from, to } = invite;

    return (
      <main className="page-invitation">
        <div className="page-content">
          <div className="col1">
            <div className="inner">
              {bundle ? (
                <div className="invitation-info-bundle">
                  {info && info.logo && <img className="team-logo" src={info.logo} alt="logo" />}
                  <h4>Hello {to.fullName || to.email}</h4>
                  <p>You have received documents to review and sign</p>
                  <hr />
                  <h4>Documents</h4>
                  <div className="document">
                    <Icon name={'document'} size={'large'} />
                    <p>{bundle.parent.name}</p>
                  </div>

                  {bundle.children.map((child, idx) => (
                    <div
                      className="document"
                      key={idx}
                      style={{ paddingBottom: idx == bundle.children.length - 1 ? 0 : null }}
                    >
                      <Icon name={'document'} size={'large'} />
                      <p>
                        {child.dealInfo.name} {child.parentTitle && `(${child.parentTitle})`}
                      </p>
                    </div>
                  ))}

                  {from && (
                    <>
                      <hr />
                      <div className="invitation-by">
                        <h4>Sender</h4>
                        <div className="contact">
                          {from.fullName} (<a href="#">{from.email}</a>)
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="invitation-info">
                  {info && info.logo && <img className="team-logo" src={info.logo} alt="logo" />}
                  <p>{Dt} received</p>
                  <h2>{info.name}</h2>
                  {from && (
                    <>
                      <hr />
                      <div className="invitation-by">
                        <p>Invited by</p>
                        <div className="contact">
                          {from.fullName}
                          <br />
                          <a href="#">{from.email}</a>
                          <br />
                          {from.org}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>

          <div className="col2" data-cy="page-invitation-login">
            <div className="inner">
              <Card className="card-continue login-wrapper">
                <h3>Identify yourself to continue</h3>
                <p className="info">
                  Using Outlaw means you agree to our{' '}
                  <a href="https://getoutlaw.com/terms-of-service/">Terms of Service</a>
                </p>

                {!!guestErrorMessage && <Alert>{guestErrorMessage}</Alert>}

                {this.renderSSO()}
                {CONFIG.OPEN_ID_ENABLED && this.renderOpenID()}

                {invite.guestSigning && this.renderGuestForm()}

                <div className="footer-login">
                  Have an Outlaw account?{' '}
                  <Link to={this.loginUrl} data-cy="invite-login">
                    Log in
                  </Link>
                </div>
              </Card>
            </div>
          </div>
        </div>

        <PromoFooter />
      </main>
    );
  }

  renderNoAccess() {
    const dealID = _.get(this.state.invite, 'deal.dealID');

    return (
      <main className="access-page no-access">
        <div className="col1">
          <div className="col1-inner">
            <NoDealAccess
              user={this.props.user}
              history={this.props.history}
              match={this.props.history}
              dealID={dealID}
            />
          </div>
          <PromoFooter />
        </div>
      </main>
    );
  }
}
