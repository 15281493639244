import { assign, pick } from 'lodash';

import DealAction from '../enums/DealAction';
import { ReadyLabels } from './ReadyCheck';

export default class Vote {
  user;
  response;
  date;

  constructor({ user, response, date }) {
    assign(this, { user, response });
    if (date) this.date = new Date(parseInt(date));
  }

  get json() {
    const obj = pick(this, ['user']);
    if (this.response) obj.response = this.response;
    if (this.date) obj.date = this.date.getTime().toString();
    return obj;
  }

  get displayResponse() {
    switch (this.response) {
      case DealAction.APPROVE:
        return ReadyLabels.VOTED_YES;
      case DealAction.REJECT:
        return ReadyLabels.VOTED_NO;
      default:
        return ReadyLabels.NOT_VOTED;
    }
  }
}
