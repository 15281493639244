import { forEach, map } from 'lodash';

import Core from '../Core.js';
import { META_FIELD_TYPES } from '../server/search/Meta';
import { isDateYmd } from '../utils/Validation';
import { ACCESS_TYPES } from './AdminSearchParams';
import { OPERATORS } from './Operator.js';
import User from './User';
import { ValueType } from './Variable.js';

export const MAX_TOTAL_HITS = 10000;

export const DATE_FILTERS = {
  created: 'created_dt',
  signed: 'signedDate_dt',
  updated: 'updated_dt',
};

export const INDEXES = {
  ACTIVITIES: 'outlaw_activities',
  DEALS: 'outlaw_deals_v2',
  SECTIONS: 'outlaw_sections',
  TEAMS: 'outlaw_teams',
  USERS: 'outlaw_users',
};

const STRING_TO_INDEXES = {
  activities: INDEXES.ACTIVITIES,
  deals: INDEXES.DEALS,
  sections: INDEXES.SECTIONS,
  teams: INDEXES.TEAMS,
  users: INDEXES.USERS,
};

const INDEX_USER_TERM = {
  [INDEXES.DEALS]: 'users_n.uid_s',
  [INDEXES.SECTIONS]: 'users_s',
};

const INDEX_TEAM_TERM = {
  [INDEXES.DEALS]: 'sourceTeam_t.raw',
  [INDEXES.SECTIONS]: 'team_s',
};

const INDEX_TEMPLATE_TERM = {
  [INDEXES.DEALS]: 'template_o',
  [INDEXES.SECTIONS]: 'template_s',
};

const SORTABLE_PROPERTIES = {
  name: `${META_FIELD_TYPES.TEXT}.raw`,
  updated: META_FIELD_TYPES.DATE,
  created: META_FIELD_TYPES.DATE,
  signed: META_FIELD_TYPES.DATE,
  fullName: `${META_FIELD_TYPES.STRING}`,
  'deleted_o.time': META_FIELD_TYPES.DATE,
};

const SORTABLE_USER_PROPERTIES = {
  fullName: `${META_FIELD_TYPES.TEXT}.raw`,
  title: `${META_FIELD_TYPES.TEXT}.raw`,
  email: `${META_FIELD_TYPES.TEXT}.raw`,
  teamsCount: `${META_FIELD_TYPES.FLOAT}`,
};

const SORTABLE_TEAM_PROPERTIES = {
  name: `${META_FIELD_TYPES.TEXT}.raw`,
  membersCount: `${META_FIELD_TYPES.FLOAT}`,
  observerCount: `${META_FIELD_TYPES.FLOAT}`,
};

const ACCESS_TYPES_TERM = {
  [ACCESS_TYPES.ADMIN.type]: 'isAdmin_b',
  [ACCESS_TYPES.PARTNER.type]: 'isPartner_b',
};

/*
    The logic of the date filter is:
    1. We will always filter with a dateFrom and a dateTo value.
    2. The value will always be in this format: "dateFrom,dateTo".
    3. If dateTo is not passed, we assume that it is the same as dateFrom.
  */
export const buildESDateFilter = ({ name, value, timezone }) => {
  if (!DATE_FILTERS[name]) return null;

  let [dateFrom, dateTo] = value.split(',');
  if (!dateFrom) {
    dateFrom = new Date().toISOString().split('T')[0];
  }

  if (!isDateYmd(dateFrom)) {
    return null;
  }

  if (!dateTo) {
    dateTo = dateFrom;
  }

  if (!isDateYmd(dateTo)) {
    return null;
  }

  let dateRange = {
    gte: dateFrom,
    lte: dateTo,
  };

  if (timezone) {
    dateRange['time_zone'] = timezone;
  }

  return {
    range: {
      [DATE_FILTERS[name]]: dateRange,
    },
  };
};

export default class SearchParamsES {
  index;
  filters = [];
  aggs = {};
  body = {};

  source = null;
  page = 0;
  size = 20;
  sort = null;

  constructor(index, options = {}) {
    this.index = STRING_TO_INDEXES[index];
    if (!this.index) {
      throw new Error(`SearchParamsES: You must define which index to use ("${index}" does not rexist).`);
    }

    if (options.source !== undefined) this.source = options.source;
    if (options.page !== undefined) this.page = options.page;
    if (options.size !== undefined) this.size = options.size;
    if (options.sort !== undefined) this.sort = options.sort;

    if (options.pit !== undefined) {
      this.body.pit = {
        id: options.pit,
        keep_alive: options.keep_alive ? options.keep_alive : '1m',
      };
    }
    if (options.search_after !== undefined) this.body.search_after = options.search_after;
  }

  async _filterUser(uid) {
    const rawUser = await Core.Fire.getUser(uid);
    const user = new User(rawUser);
    const skipUserFilters = await Core.Fire.hasUnrestrictedAccess(uid);

    // If we are on a server instance and we've enabled UNRESTRICTED_ACCESS_TEAM,
    // We do not need to add the user filtering, we should have access to everything.
    // Let's just return and not add any user type filtering
    // THIS IS DANGEREOUS, WE NEED TO CHECK PEOPLE ON TEAMS, ETC.
    if (skipUserFilters) {
      return null;
    }

    const userFilter = {};
    if (this.index === INDEXES.DEALS) {
      // Deal user matching must be nested
      userFilter.nested = {
        path: 'users_n',
        query: { term: { [INDEX_USER_TERM[this.index]]: uid } },
      };
    } else {
      userFilter.term = { [INDEX_USER_TERM[this.index]]: uid };
    }

    return {
      bool: {
        should: [
          userFilter,
          ...map(user.observerTeamIDs, (teamID) => ({
            term: { [INDEX_TEAM_TERM[this.index]]: teamID },
          })),
        ],
      },
    };
  }

  async filter(filter, value = null, uid = null) {
    if (typeof filter === 'string') {
      let filterParams = null;

      switch (filter) {
        case 'user':
          filterParams = await this._filterUser(value);
          break;
        case 'teams':
          filterParams = {
            bool: {
              should: map(value, (teamID) => ({
                term: { 'sourceTeam_t.raw': teamID },
              })),
            },
          };
          break;
        case 'not:template':
          filterParams = {
            bool: {
              must_not: [{ exists: { field: INDEX_TEMPLATE_TERM[this.index] } }],
            },
          };
          break;
        case 'is:template':
          filterParams = {
            bool: {
              must: [{ exists: { field: INDEX_TEMPLATE_TERM[this.index] } }],
            },
          };
          break;
        case 'date':
          filterParams = buildESDateFilter(value);
          break;
        case 'tags':
          filterParams = { bool: { should: [] } };
          forEach(value, (tag) =>
            filterParams.bool.should.push({
              term: { 'tags_t.raw': `${uid}|${tag}` },
            })
          );
          break;
        case 'types':
          filterParams = { bool: { should: [] } };
          forEach(value, (type) => filterParams.bool.should.push({ term: { type_s: `${type}` } }));
          break;
        case 'not:archived':
          filterParams = {
            bool: { must_not: [{ term: { 'tags_t.raw': `${uid}|archived` } }] },
          };
          break;
        case 'deleted':
          filterParams = {
            bool: {
              must: [{ exists: { field: 'deleted_o' } }],
            },
          };
          break;
        case 'not:deleted':
          filterParams = {
            bool: {
              must_not: [{ exists: { field: 'deleted_o' } }],
            },
          };
          break;
      }

      if (filterParams) {
        this.filters.push(filterParams);
      }
    } else {
      this.filters.push(filter);
    }
  }

  aggregation(name, object) {
    this.aggs[name] = object;
  }

  bodyParam(name, object) {
    this.body[name] = object;
  }

  async variableFilter(variables) {
    for (const variable of variables) {
      let mustFilter = [];
      let mustNotFilter = [];

      // Match on variable name inside the nested object
      mustFilter.push({
        match: {
          'variables_n.name_s': variable.variable,
        },
      });

      // Determine which variable value field to use
      const variableValueField = getVariableValueFieldByType(variable.valueType);

      // Build the variable value filter based on the operator
      console.log(`ES Variable filter for field ${variableValueField} for operator ${variable.operator}`);

      switch (variable.operator) {
        case OPERATORS.EQUAL.key:
          mustFilter.push({
            term: {
              [variableValueField]: {
                value: variable.values[0],
              },
            },
          });
          break;
        case OPERATORS.UNEQUAL.key:
          mustNotFilter.push({
            term: {
              [variableValueField]: variable.values[0],
            },
          });
          break;
        case OPERATORS.KNOWN.key:
          mustFilter.push({
            exists: {
              field: variableValueField,
              boost: 1,
            },
          });
          break;
        case OPERATORS.UNKNOWN.key:
          mustNotFilter.push({
            exists: {
              field: variableValueField,
              boost: 1,
            },
          });
          break;
        case OPERATORS.IN.key:
          mustFilter.push({
            query_string: {
              query: variable.values.map((v) => `(${this.escapeString(v)})`).join(' OR '),
              default_field: variableValueField,
            },
          });
          break;
        case OPERATORS.OUT.key:
          mustNotFilter.push({
            query_string: {
              query: variable.values.map((v) => `(${this.escapeString(v)})`).join(' OR '),
              default_field: variableValueField,
            },
          });
          break;
        case OPERATORS.ALL.key:
          // Match all values
          mustFilter.push({
            query_string: {
              query: variable.values.map((v) => `(${this.escapeString(v)})`).join(' AND '),
              default_field: variableValueField,
            },
          });

          // Array size must match, as there cannot be any other values
          mustFilter.push({
            script: {
              script: `doc['${variableValueField}'].size() == ${variable.values.length}`,
            },
          });
          break;
        case OPERATORS.LESS.key:
        case OPERATORS.BEFORE.key:
          mustFilter.push({
            range: {
              [variableValueField]: {
                lt: variable.values[0],
                boost: 1,
              },
            },
          });
          break;
        case OPERATORS.GREATER.key:
        case OPERATORS.AFTER.key:
          mustFilter.push({
            range: {
              [variableValueField]: {
                gt: variable.values[0],
                boost: 1,
              },
            },
          });
          break;
        case OPERATORS.BETWEEN.key:
          mustFilter.push({
            range: {
              [variableValueField]: {
                gt: variable.values[0],
                lt: variable.values[1],
                boost: 1,
              },
            },
          });
          break;
        case OPERATORS.DYNAMIC.key:
          // Duration will be either a positive or negative number of days
          const duration = variable.values[0];

          let startDate;
          let endDate;
          let now = Math.floor(new Date().getTime() / 1000);

          if (duration < 0) {
            // Date in the past
            startDate = now - Math.abs(duration) * 24 * 60 * 60;
            endDate = now;
          } else {
            // Date in future
            startDate = now;
            endDate = now + Math.abs(duration) * 24 * 60 * 60;
          }

          mustFilter.push({
            range: {
              [variableValueField]: {
                gte: startDate,
                lte: endDate,
                boost: 1,
              },
            },
          });
          break;
        default:
          console.log(`[SEARCH] - operator [${variable.operator.title}] not yet supported`);
          continue;
      }

      const filterParams = {
        bool: {
          must: [
            {
              nested: {
                path: 'variables_n',
                query: {
                  bool: {
                    must: mustFilter,
                    must_not: mustNotFilter,
                  },
                },
              },
            },
          ],
        },
      };

      console.log('[SEARCH] Variable filters', JSON.stringify(filterParams));
      this.filters.push(filterParams);
    } // end for each variable
  }

  async userFilter(filter, value = null, teams = null) {
    if (typeof filter === 'string') {
      let filterParams = null;

      switch (filter) {
        case 'teams':
          filterParams = { bool: { should: [] } };
          forEach(value, (teamID) =>
            filterParams.bool.should.push({
              nested: {
                path: 'teams_n',
                query: {
                  term: { 'teams_n.teamID_s': teamID },
                },
              },
            })
          );
          break;
        case 'roles':
          filterParams = { bool: { should: [] } };
          forEach(value, (role) => {
            if (teams) {
              forEach(teams, (team) => {
                filterParams.bool.should.push({
                  nested: {
                    path: 'teams_n',
                    query: {
                      bool: {
                        must: [{ term: { 'teams_n.role_t': role } }, { term: { 'teams_n.teamID_s': team } }],
                      },
                    },
                  },
                });
              });
            } else {
              filterParams.bool.should.push({
                nested: {
                  path: 'teams_n',
                  query: { term: { 'teams_n.role_t': role } },
                },
              });
            }
          });

          break;
        case 'access':
          filterParams = { bool: { should: [] } };
          forEach(value, (accessType) => {
            filterParams.bool.should.push({
              term: { [ACCESS_TYPES_TERM[accessType]]: true },
            });
          });
          break;
        case 'observer':
          filterParams = { bool: { should: [] } };
          if (teams) {
            forEach(teams, (team) => {
              filterParams.bool.should.push({
                nested: {
                  path: 'teams_n',
                  query: {
                    bool: {
                      must: [{ term: { 'teams_n.observer_b': value } }, { term: { 'teams_n.teamID_s': team } }],
                    },
                  },
                },
              });
            });
          } else {
            filterParams.bool.should.push({
              nested: {
                path: 'teams_n',
                query: { term: { 'teams_n.observer_b': value } },
              },
            });
          }
      }

      if (filterParams) {
        this.filters.push(filterParams);
      }
    } else {
      this.filters.push(filter);
    }
  }

  get json() {
    const json = {
      track_total_hits: true,
      from: this.size * this.page,
      size: this.size,
      sort: this.convertSortToES(this.sort, this.index),
      _source: this.source,
      body: {
        ...this.body,
        query: {
          bool: {
            must: [...this.filters],
          },
        },
        aggs: { ...this.aggs },
      },
    };
    if (!this.body.pit) {
      json.index = this.index;
    }
    return json;
  }

  // These characters need to be escaped when performing a query_string filter
  escapeString(string) {
    const pattern = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"])/g;
    return string.replace(pattern, '\\$1');
  }

  convertSortToES(sort, index) {
    if (!sort) return null;

    if (sort.startsWith('v.')) {
      // TODO: do we need to verify/sanitize this input? The variable name is dynamic so we'd have to use a different pattern than down below
      // Variable sorting, this has a different format: v.varName.varType.sortDirection
      const parts = sort.split('.');
      const variableName = parts[1];
      const sortDirection = parts[3];
      const valueField = getVariableValueFieldByType(parts[2]);

      const variableSort = {
        [valueField]: {
          order: sortDirection,
          nested: {
            path: 'variables_n',
            filter: {
              term: { 'variables_n.name_s': variableName },
            },
          },
        },
      };

      console.log('[SEARCH] Variable sort', variableSort);

      // This type sort needs to be defined in the body
      this.bodyParam('sort', variableSort);
      return null;
    }

    const parts = sort.split('.');
    const sortProperty = parts.slice(0, -1).join('.');
    const sortDir = parts[parts.length - 1];

    let sortPropertyType = null;
    if (STRING_TO_INDEXES.deals === index) {
      sortPropertyType = SORTABLE_PROPERTIES[sortProperty];
    }
    if (STRING_TO_INDEXES.users === index) {
      sortPropertyType = SORTABLE_USER_PROPERTIES[sortProperty];
    }
    if (STRING_TO_INDEXES.teams === index) {
      sortPropertyType = SORTABLE_TEAM_PROPERTIES[sortProperty];
    }
    if (!sortPropertyType) return null;

    return `${sortProperty}_${sortPropertyType}:${sortDir}`;
  }
}

function getVariableValueFieldByType(variableType) {
  let variableValueField = 'variables_n.value_s';

  switch (variableType) {
    case ValueType.DATE:
      variableValueField = 'variables_n.dateValue_dt';
      break;
    case ValueType.NUMBER:
    case ValueType.PERCENT:
    case ValueType.CURRENCY:
      variableValueField = 'variables_n.floatValue_f';
      break;
    case ValueType.MULTI_SELECT:
      variableValueField = 'variables_n.arrayValue_s';
      break;
  }

  return variableValueField;
}
