import React, { Component, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay, Popover } from 'react-bootstrap';

import { getUniqueKey } from '@core/utils/Generators';

@autoBindMethods
export default class LinkView extends Component {
  static propTypes = {
    // Container for the Popover
    container: PropTypes.object.isRequired,

    // URL to follow, like href in <a> tag
    url: PropTypes.string.isRequired,

    // Nested display text to be rendered, like in <a> tag
    children: PropTypes.string.isRequired,

    // Optional className to pass through
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };

    this.refLink = createRef();
    this.id = getUniqueKey();
  }

  focus(e) {
    const { focused } = this.state;
    e.stopPropagation();
    this.setState({ focused: !focused });
  }

  // Prevent mouse events related to showing/hiding popovers from taking whole section into editing
  stop(e) {
    e.stopPropagation();
  }

  nav(e) {
    const { url } = this.props;
    e.stopPropagation();
    window.open(url);
    this.setState({ focused: false });
  }

  render() {
    const { children, container, className } = this.props;
    const { focused } = this.state;

    // Rendering the Popover inside the <span> adjacent to {children} was causing some very strange orphaning issues,
    // where the Popover wouldn't hide and then would instead target a different DiffView (!!)
    // Using a fragment fixes this, so that the Popover is no longer a sibling of {children}
    return (
      <>
        <span
          className={className}
          ref={this.refLink}
          onMouseDown={this.stop}
          onMouseUp={this.stop}
          onClick={this.focus}
        >
          {children}
        </span>
        {focused && (
          <Overlay
            show={true}
            onHide={() => this.setState({ focused: false })}
            target={this.refLink.current}
            container={container}
            placement="top"
            rootClose
          >
            <Popover className="pop-link" id={`pop-link-${this.id}`}>
              <span className="open-link" onMouseDown={this.stop} onMouseUp={this.stop} onClick={this.nav}>
                Open link
              </span>
            </Popover>
          </Overlay>
        )}
      </>
    );
  }
}
