import React, { createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import User from '@core/models/User';

import PageBreak from '@components/PageBreak';

import ContentSection from './ContentSection';
import SignatureBlock from './SignatureBlock';

@autoBindMethods
export default class SignatureSection extends ContentSection {
  static propTypes = {
    user: PropTypes.instanceOf(User),
    section: PropTypes.object.isRequired,
    container: PropTypes.object.isRequired,
    readonly: PropTypes.bool,

    // Legacy shit for Siggy
    activity: PropTypes.object,
    onSignature: PropTypes.func,
    toggleDealStatus: PropTypes.func,
    signed: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    // Create refs in advance new DealUserViews, one per party, as all SignatureBlocks need access
    // Unfortunately this is still a bit hacky but creating yet another "PartyBlock" subcomponent,
    // *just* for this purpose, seems like overkill, so it's a balance...
    const parties = _.get(props, 'section.deal.parties', []);
    this.newEditors = {};
    _.forEach(parties, ({ partyID }) => (this.newEditors[partyID] = createRef()));
  }

  render() {
    const { section, readonly } = this.props;
    const className = cx('source-section', 'signature', { readonly: readonly }, { 'page-break': section.pageBreak });

    const styleTitle = section.styleTitle.css;
    const styleBody = section.styleBody.css;

    return (
      <div data-sectionid={section.id} className={className} style={section.webLayout} data-cy="signature-section">
        {section.pageBreak && <PageBreak section={section} />}
        {section.displayTitle && (
          <div style={styleTitle} data-cy="signature-title">
            {section.displayTitle}
          </div>
        )}

        {section.currentVersion.body.getPlainText() && (
          <div style={styleBody} data-cy="signature-body">
            {this.renderContent()}
          </div>
        )}

        <div className="parties" data-cy="parties">
          {section.signatories.map(this.renderParty)}
        </div>
      </div>
    );
  }

  renderParty(partyID) {
    const { section } = this.props;
    const party = section.deal.getPartyByID(partyID);

    // If the party isn't used anywhere in the contract but was still configured in a signature block (before being removed),
    // it won't be found here. so this avoids a crash bug and just shows nothing for that party
    if (!party) return null;

    // Standard section title/body styles are used for party names and signer info
    const { type, layout } = section.deal.style;
    const blockStyle = {
      marginTop: layout.SignatureBlock.web.top,
      marginBottom: layout.SignatureBlock.web.bottom,
    };

    // We need additional spacing below Party label (2x section spacing) for when there are >1 users in a party
    // Note, even if THIS party isn't the one with >1 users, we still need the spacing for correct alignment
    let partyLabelStyle = _.merge({}, type.SectionTitle.css);
    if (section.deal.multipleSigners) {
      partyLabelStyle.marginBottom = layout.Section.bottom * 2;
    }

    const users = section.deal.getUsersByParty(partyID);

    return (
      <div
        className={`party ${partyID.toLowerCase()}`}
        style={blockStyle}
        key={partyID}
        data-cy={partyID.toLowerCase()}
      >
        <div className="party-name" style={partyLabelStyle}>
          {party.displayName}
        </div>

        {users.length === 0 && this.renderEmptyBlock(partyID)}
        {_.map(users, (dealUser, idx) => this.renderSignatureBlock(partyID, dealUser, idx, idx + 1 === users.length))}
      </div>
    );
  }

  renderEmptyBlock(partyID) {
    const props = _.pick(this.props, [
      'user',
      'section',
      'container',
      'onSignature',
      'activity',
      'toggleDealStatus',
      'readonly',
      'signed',
    ]);

    return <SignatureBlock {...props} empty partyID={partyID} />;
  }

  renderSignatureBlock(partyID, dealUser, idx, isLastInParty) {
    const props = _.pick(this.props, [
      'user',
      'section',
      'container',
      'onSignature',
      'activity',
      'toggleDealStatus',
      'readonly',
      'signed',
    ]);

    // If we have multiple DealUsers in one party, make sure we add space *between* them
    // Using same margin value as below the entire Signature block
    const style = isLastInParty
      ? null
      : { marginBottom: _.get(this.props.section, 'deal.style.layout.SignatureBlock.web.bottom', '48px') };

    return <SignatureBlock {...props} key={idx} dealUser={dealUser} partyID={partyID} style={style} />;
  }
}
