import {assign, pick} from 'lodash';

export default class Webhook {
  url = null;
  enabled = false;
  eventType = null;
  subtypes = null;
  id = null;
  blacklist = null;
  constructor(json) {
    assign(this, pick(json, ['id', 'url', 'enabled', 'eventType', 'subtypes', 'blacklist']));
  }

  get json() {
    return pick(this, ['id', 'url', 'enabled', 'eventType', 'subtypes', 'blacklist']);
  }
}
