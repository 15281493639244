import React, { PureComponent, createRef } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ContentEditable from 'react-contenteditable';

import { DEFAULT_FONT, DEFAULT_TEXT_OPTIONS, FONTS } from '@core/models/PDFElement';
import { classNamePrefixer } from '@core/utils/Generators';

const cl = classNamePrefixer('element-text', 'pdfe');

export const getElementStyle = ({ scale, previewOptions, pdfElementOptions }) => {
  // Scale is passed down in props, but it includes devicePixelRatio baked in
  // we need to take it back out here for text scaling (font size and line height)
  const textScale = scale;

  let options = pdfElementOptions || {};

  // User is currently modifying the options, she the preview of it
  if (previewOptions) options = previewOptions;

  const fontSize = _.get(options, 'size', DEFAULT_TEXT_OPTIONS.size) * textScale;
  const fontWeight = options.bold ? 'bold' : 'normal';
  const fontStyle = options.italic ? 'italic' : 'normal';
  const fontFamily = FONTS[_.get(options, 'font', DEFAULT_FONT)].formats.regular;
  const lineHeight = _.get(options, 'lineHeight', DEFAULT_TEXT_OPTIONS.lineHeight) * textScale;
  const color = _.get(options, 'color', DEFAULT_TEXT_OPTIONS.color);

  return {
    fontSize: `${fontSize}px`,
    lineHeight: `${lineHeight}px`,
    // minHeight is important so that when the field is empty, it still use the text configured height
    minHeight: `${lineHeight}px`,
    color,
    fontWeight,
    fontStyle,
    fontFamily,
  };
};

@autoBindMethods
class ElementText extends PureComponent {
  static defaultProps = {
    className: null,
    onChange: _.noop,
    readOnly: false,
    previewOptions: null,
    focus: false,
    scale: 1,
  };

  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    previewOptions: PropTypes.any, // Need to define real structure
    focus: PropTypes.bool,
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    pdfElementOptions: PropTypes.any,
    scale: PropTypes.number,
  };

  constructor(props) {
    super();
    this.state = { html: props.value || '' };

    this.inputRef = createRef();
  }

  componentDidMount() {
    if (this.props.focus && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const currentValue = _.get(this.inputRef, 'current.innerText', '');
    if (!this.state.isEditing && newProps.value !== currentValue) {
      this.setState({ html: newProps.value || '' });
    }
  }

  get style() {
    return getElementStyle(this.props);
  }

  handleChange(e) {
    this.setState({ html: e.target.value });
  }

  handleBlur() {
    const { onChange, value } = this.props;
    // Attempt to fix an issue where ContentEditable is adding "<div><br></div>"" instead of regular "\n"
    // Handling it during editing would affect performance, so we're doing it before we save the value.
    //let newValue = _.get(this.inputRef, 'current.innerText', '');
    let newValue = _.get(this.inputRef, 'current.innerHTML', '');
    newValue = newValue.replace(/<div><br><\/div>/gi, '\n');
    newValue = newValue.replace(/<div>/gi, '\n');
    newValue = newValue.replace(/<\/div>/gi, '');

    if (newValue === (value || '')) return;

    this.setState({ isEditing: false });
    onChange(newValue);
  }

  handleFocus() {
    this.setState({ isEditing: true });
  }

  render() {
    const { readOnly, value } = this.props;

    if (readOnly) {
      return (
        <div className={cx(cl(), cl('readonly'))} style={this.style}>
          {value}
        </div>
      );
    }

    return (
      <ContentEditable
        className={cl()}
        style={this.style}
        innerRef={this.inputRef}
        html={this.state.html}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
      />
    );
  }
}

export default ElementText;
