import { forEach, get, includes } from 'lodash';

import Core from '../Core.js';

export const USER_STATES = {
  DASHBOARD_REPORTS: 'dashboardReports',
};

export default class UserStateStore extends Object {
  userID = null;

  constructor(store) {
    super();

    this.userID = get(store, 'userID', null);

    if (typeof store !== 'undefined') {
      forEach(USER_STATES, (state) => {
        this[state] = !!store[state];
      });
    }
  }

  hasCompleted(state) {
    return this[state];
  }

  complete(state) {
    // Make sure that it's a valid state,
    if (!includes(USER_STATES, state)) return Promise.reject();

    return new Promise(async (resolve) => {
      console.log(`users/${this.userID}/states/${state}/true`);
      await Core.Fire.update({ [`users/${this.userID}/states/${state}`]: true });
      resolve();
    });
  }
}
