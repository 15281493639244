import React from 'react';

import PropTypes from 'prop-types';

import BorderStyle from '@core/models/BorderStyle';
import LayoutStyle from '@core/models/LayoutStyle';

const Border = ({ borderStyle, pageMargin }) => {
  const inlineBorderStyle = (doubled = false) => {
    return {
      ...borderStyle.inlineStyle(pageMargin.web, doubled),
      ...borderStyle.getHeight(pageMargin.web),
      ...borderStyle.getOffset(pageMargin.web),
    };
  };

  return (
    <>
      <div className="custom-border" style={inlineBorderStyle()} data-cy="custom-border"></div>
      {borderStyle.style === 'double' && (
        <div className="custom-border" style={inlineBorderStyle(true)} data-cy="custom-border-double"></div>
      )}
    </>
  );
};

Border.propTypes = {
  borderStyle: PropTypes.instanceOf(BorderStyle).isRequired,
  pageMargin: PropTypes.instanceOf(LayoutStyle).isRequired,
};

export default Border;
