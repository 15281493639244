import { find, forEach, get, map } from 'lodash';

import Automation from './Automation';
import ServiceConfig from './ServiceConfig';

export default class Integration {
  templateID = null;
  config = {};
  automations = [];

  constructor(json, templateID) {
    this.templateID = templateID;

    if (json.config) {
      this.config = new ServiceConfig(json.config);
    }
    if (json.automations) {
      forEach(json.automations, (val) => this.automations.push(new Automation(val, this.config.service)));
    }
  }

  getAutomation(trigger) {
    return find(this.automations, { trigger });
  }

  get json() {
    return {
      config: get(this.config, 'json', null),
      automations: map(this.automations, 'json'),
    };
  }
}
