import DealAction from './DealAction';

const ACTION_BUCKETS = [
  {
    key: 'deal',
    tip: 'Deal Events',
    actions: [
      DealAction.CREATE,
      DealAction.CREATE_VERSION,
      DealAction.DELETE_VERSION,
      DealAction.COPY,
      DealAction.SIGN,
      DealAction.BULK_SIGN,
      DealAction.UNSIGN,
      DealAction.COMPLETE,
      DealAction.CLEAR,
      DealAction.READY_CHECK,
      DealAction.SHARE_SECRET,
      DealAction.VIEW_SECRET,
      DealAction.VARIABLE,
      DealAction.WEBHOOK,
      DealAction.WEBHOOK_UPDATE,
      DealAction.INTEGRATION,
      DealAction.UPDATE_STATUS,
      DealAction.CHECKPOINT_ACTIVITY,
    ],
  },

  {
    key: 'sharing',
    tip: 'Sharing Events',
    actions: [DealAction.SEND, DealAction.READ],
  },

  {
    key: 'comments',
    tip: 'Comments',
    actions: [DealAction.REJECT, DealAction.SUBMIT],
  },

  {
    key: 'redlining',
    tip: 'Editing',
    actions: [DealAction.DELETE, DealAction.UPDATE, DealAction.APPROVE, DealAction.PASTE, DealAction.CLEAR_LIST],
  },
];

export default ACTION_BUCKETS;
