import { Parser } from 'json2csv';

// https://stackoverflow.com/a/55784435/6506167
export const downloadLink = (json, fields) => {
  const options = { flatten: true };
  if (fields) options.fields = fields;
  const parser = new Parser(options);
  const csv = parser.parse(json);
  return 'data:application/octet-stream,' + encodeURIComponent(csv);
};
