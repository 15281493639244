import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';

import { FEATURES } from '@core/models/User';

import { Button, Checkbox, Key } from '@components/dmp';

@autoBindMethods
export default class UserEditor extends Component {
  static propTypes = {
    selectedUser: PropTypes.object,
    onSave: PropTypes.func,
    onHide: PropTypes.func,
  };

  constructor(props) {
    const { selectedUser } = props;
    super(props);

    this.state = {
      email: _.get(selectedUser, 'email', ''),
      connect: _.get(selectedUser, 'features.connect', FEATURES.CONNECT.defaultValue),
      batch: _.get(selectedUser, 'features.batch', FEATURES.BATCH.defaultValue),
      vineIO: _.get(selectedUser, 'features.vineIO', FEATURES.VINE_IO.defaultValue),
      word: _.get(selectedUser, 'features.word', FEATURES.WORD.defaultValue),
      uid: _.get(selectedUser, 'id', null),
      isAdmin: _.get(selectedUser, 'isAdmin', false),
      isPartner: _.get(selectedUser, 'isPartner', false),
    };
  }

  componentDidUpdate(prevProps) {
    const { selectedUser } = this.props;

    if (!_.isEqual(selectedUser, prevProps.selectedUser)) {
      this.populate(selectedUser);
    }
  }

  populate(selectedUser) {
    this.setState({
      email: _.get(selectedUser, 'email', ''),
      connect: _.get(selectedUser, 'features.connect', FEATURES.CONNECT.defaultValue),
      batch: _.get(selectedUser, 'features.batch', FEATURES.BATCH.defaultValue),
      vineIO: _.get(selectedUser, 'features.vineIO', FEATURES.VINE_IO.defaultValue),
      word: _.get(selectedUser, 'features.word', FEATURES.WORD.defaultValue),
      uid: _.get(selectedUser, 'id', null),
    });
  }

  async updateUser() {
    const { uid } = this.state;
    const { onSave } = this.props;
    const features = this.featureChanges;
    await API.call('updateUser', { updateID: uid, features });
    onSave();
  }

  get featureChanges() {
    const { selectedUser } = this.props;
    const { isAdmin, isPartner } = this.state;
    const features = {};
    _.forEach(FEATURES, (feature) => {
      if (this.state[feature.key] !== !!selectedUser.features[feature.key] && feature.isAdminEditable) {
        features[feature.key] = this.state[feature.key];
      }
    });

    if (isAdmin !== !!selectedUser.isAdmin) {
      features['isAdmin'] = isAdmin;
    }

    if (isPartner !== !!selectedUser.isPartner) {
      features['isPartner'] = isPartner;
    }

    return features;
  }

  render() {
    const { selectedUser, onHide, user } = this.props;
    const { isAdmin, isPartner } = this.state;
    const disabled = _.size(this.featureChanges) === 0;

    return (
      <Modal dialogClassName="user-editor" onHide={onHide} show>
        <Modal.Header closeButton>
          <span className="headline">Update user</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <FormGroup>
              <ControlLabel>User</ControlLabel>
              <div className="contents user" data-cy="contents-user">
                {selectedUser.fullName && (
                  <>
                    <b className="name">{selectedUser.fullName}</b>
                    <br />
                  </>
                )}
                <span className="email">{selectedUser.email}</span>
                <br />
                <Key>{selectedUser.id}</Key>
              </div>
            </FormGroup>
            <hr />
            <FormGroup>
              <ControlLabel>Features</ControlLabel>
              <div className="contents observer" data-cy="contents-observer">
                {_.map(FEATURES, (feature) => {
                  if (feature.isAdminEditable) {
                    return (
                      <Checkbox
                        id={`chk-user-${feature.key}`}
                        checked={this.state[feature.key]}
                        onChange={() => this.setState({ [feature.key]: !this.state[feature.key] })}
                        data-cy="chk-tm-observe"
                        key={feature.key}
                      >
                        {feature.header}
                      </Checkbox>
                    );
                  }
                })}
                {user.isSuper && (
                  <Checkbox
                    id={`chk-user-isAdmin`}
                    checked={isAdmin}
                    onChange={() => this.setState({ isAdmin: !isAdmin })}
                    data-cy="chk-user-isAdmin"
                    key={'isAdmin'}
                  >
                    Admin
                  </Checkbox>
                )}
                {user.isAdmin && (
                  <Checkbox
                    id={`chk-user-isPartner`}
                    checked={isPartner}
                    onChange={() => this.setState({ isPartner: !isPartner })}
                    data-cy="chk-user-isPartner"
                    key={'isPartner'}
                  >
                    Partner
                  </Checkbox>
                )}
              </div>
            </FormGroup>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={onHide}>Cancel</Button>
          <Button dmpStyle="primary" onClick={this.updateUser} disabled={disabled} data-cy="btn-update-feature">
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
