export const CALC_ERRORS = {
  FORMULA: {
    code: 'formula',
    message: 'Formula parse error',
    errorValue: '#ERROR!',
  },
  CIRCULAR: {
    code: 'circular',
    message: 'Circular dependency encountered',
    errorValue: '#REF!',
  },
  DIV0: {
    code: 'divide0',
    message: 'Cannot divide by 0',
    errorValue: '#DIV/0!',
  },
  REF: {
    code: 'ref',
    message: 'Variable in formula not found',
    errorValue: '#NAME?',
  },
  VALUE: {
    code: 'value',
    message: 'Non-numeric values found',
    errorValue: '#VALUE!',
  },
  REDACTED: {
    code: 'redacted',
    message: 'Redacted value detected',
    errorValue: '#REDACTED!',
  },
  DATA: {
    code: 'missing',
    message: 'Missing connected data',
    errorValue: '#DATA!',
  },
};

export default class CalculationError extends Error {
  constructor({ code, message, errorValue }, varName) {
    super(message);
    this.code = code;
    this.errorValue = errorValue;
    this.value = varName;
  }
}
