import { find, forEach } from 'lodash';

import Tag, { DEFAULT_TAG_COLOR } from './Tag';

// Special tag for archived deals which is handled as a negative tag in search (if present, exclude by default)
export const ARCHIVED_TAG = new Tag('archived', {
  tag: 'Archived',
  color: DEFAULT_TAG_COLOR,
});

export default class TagStore extends Array {
  constructor(store) {
    super();
    if (typeof store !== 'undefined') {
      forEach(store, (json, tagID) => {
        this.push(new Tag(tagID, json));
      });
    }
    // Always add in special archived tag
    this.push(ARCHIVED_TAG);
  }

  get(tagIDs) {
    if (Array.isArray(tagIDs)) {
      const tags = [];
      forEach(tagIDs, (tagID) => {
        const tag = find(this, { tagID });
        if (tag) tags.push(tag);
      });
      return tags;
    } else if (typeof tagIDs === 'string') {
      return find(this, { tagID: tagIDs });
    } else return [];
  }

  create(tag) {
    // Create new Tag without a tagID; it will be filled in by Fire.saveTag()
    tag = new Tag(null, { tag });
    this.push(tag);
    return tag;
  }

  mergeCounts(counts) {
    forEach(counts, ({ tagID, count }) => {
      const userTag = this.get(tagID);
      if (userTag) userTag.count = count;
    });
    return this;
  }
}
