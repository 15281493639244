import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import Deal from '@core/models/Deal';

import { Dropdown, MenuItem } from '@components/dmp';

const PartySelector = ({
  id,
  deal,
  assigned,
  onSelect,
  size,
  block,
  dmpStyle,
  tip,
  className,
  noPadding,
  noUnderline,
  dark,
}) => {
  const party = assigned ? deal.getPartyByID(assigned) : null;
  const title = party ? party.displayName : 'Unassigned';

  return (
    <Dropdown
      size={size}
      id={id}
      data-cy="party-selector"
      title={title}
      onSelect={onSelect}
      block={block}
      dmpStyle={dmpStyle}
      className={cx('party-selector', className)}
      tip={tip}
      noPadding={noPadding}
      noUnderline={noUnderline}
      dark={dark}
    >
      <MenuItem eventKey={null} active={!party}>
        Unassigned
      </MenuItem>
      {deal.parties.map((p, idx) => (
        <MenuItem key={idx} eventKey={p.partyID} active={party && p.partyID === party.partyID} ellipsis>
          {p.displayName}
        </MenuItem>
      ))}
    </Dropdown>
  );
};

PartySelector.propTypes = {
  deal: PropTypes.instanceOf(Deal).isRequired,
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  assigned: PropTypes.string,
  className: PropTypes.string,
  block: PropTypes.bool,
  noPadding: PropTypes.bool,
  noUnderline: PropTypes.bool,
  dark: PropTypes.bool,
  dmpStyle: PropTypes.oneOf(['default', 'primary', 'link', 'link-primary', 'link-info', 'anchor']),
  size: PropTypes.oneOf(['small', 'default']),
  // Copied from <TooltipButton />
  tip: PropTypes.shape({
    delay: PropTypes.number,
    disabled: PropTypes.bool,
    placement: PropTypes.string,
    positionTop: PropTypes.number,
    positionLeft: PropTypes.number,
    tip: PropTypes.node,
    tipID: PropTypes.string,
    trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  }),
};

PartySelector.defaultProps = {
  block: false,
  noPadding: false,
  noUnderline: false,
  dark: false,
  dmpStyle: 'default',
  size: 'default',
};

export default PartySelector;
