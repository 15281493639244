import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { FormControl, Modal, Tab, Tabs } from 'react-bootstrap';

import WEBHOOKS from '@core/enums/Webhooks';
import User from '@core/models/User';

import { Alert, Button, Dropdown, Loader, MenuItem, Setting } from '@components/dmp';

import API from '@root/ApiClient';

@autoBindMethods
export default class WebhookTester extends Component {
  static propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
    onClose: PropTypes.func.isRequired,
    webhook: PropTypes.object,
    teamID: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      dealID: '',
      eventType: '',
      eventSubtype: '',
      subtypes: [],
      loading: false,
      httpResponse: null,
      eventMeta: null,
      error: null,
    };
  }

  get eventTypes() {
    return WEBHOOKS;
  }

  get eventTypeName() {
    const eventType = _.find(this.eventTypes, { key: this.state.eventType });
    return _.get(eventType, 'name', 'Select Event Type');
  }

  get eventSubtypeName() {
    const subtype = _.find(this.subtypes, { key: this.state.eventSubtype });
    return _.get(subtype, 'name', 'Select Event Action');
  }

  get subtypes() {
    const { eventType } = this.state;
    return WEBHOOKS[eventType].subtypes;
  }

  async runTest() {
    const { webhook, teamID } = this.props;
    const { dealID, eventType, eventSubtype } = this.state;

    await this.setState({
      loading: true,
      httpResponse: null,
      error: null,
    });

    try {
      const { httpResponse, error, eventMeta } = await API.call('testTeamWebhook', {
        teamID,
        webhook,
        dealID,
        eventType,
        eventSubtype,
      });

      this.setState({ loading: false, error, httpResponse, eventMeta });
    } catch (err) {
      const error = _.get(err, 'response.error', 'Unknown Error.');
      this.setState({ loading: false, httpResponse: null, error, eventMeta: null });
    }
  }

  async doneTesting() {
    const { onClose } = this.props;
    this.setState({ error: null, httpResponse: null, eventType: '', eventSubtype: '', dealID: '', eventMeta: null });
    onClose();
  }

  renderData() {
    const { httpResponse, error, eventMeta } = this.state;

    if (error) {
      console.log(error);
      return <Alert dmpStyle="danger">{error}</Alert>;
    }

    let parsedResponse = null;
    try {
      parsedResponse = JSON.parse(httpResponse);
    } catch (err) {
      console.log(err);
    }

    let parsedEventMeta = null;
    try {
      parsedEventMeta = eventMeta;
    } catch (err) {
      console.log(err);
    }

    return (
      <Tabs defaultActiveKey={0}>
        <Tab eventKey={0} title="Request Meta JSON">
          <pre>
            <code>
              {parsedEventMeta ? JSON.stringify(parsedEventMeta, undefined, 2) : 'Could not parse the event meta.'}
            </code>
          </pre>
        </Tab>
        <Tab eventKey={1} title="Result - Success">
          <pre>
            <code>
              {parsedResponse ? JSON.stringify(parsedResponse, undefined, 2) : 'Could not parse the response.'}
            </code>
          </pre>
        </Tab>
      </Tabs>
    );
  }

  render() {
    const { webhook, onClose } = this.props;
    const { loading, eventMeta, error, dealID, eventType } = this.state;

    if (!webhook) return null;

    let body = "Click 'Test'";

    if (error) body = <Alert dmpStyle="danger">{error}</Alert>;
    else if (eventMeta) body = this.renderData();

    if (loading) body = <Loader />;

    return (
      <Modal className="modal-webhook-tester" show={true} onHide={onClose}>
        <Modal.Header>
          <span className="headline">Test Webhook</span>
        </Modal.Header>
        <Modal.Body>
          <div className="wrapper">
            <Setting title="Webhook URL" subtitle="The URL where the event data will be posted to.">
              <FormControl disabled value={webhook.url} bsSize="small" />
            </Setting>
            <Setting title="Deal ID" subtitle="Test using an existing Deal">
              <FormControl onChange={(e) => this.setState({ dealID: e.target.value })} value={dealID} bsSize="small" />
            </Setting>
            <Setting title="Event Type" subtitle="Test a specific event">
              <Dropdown
                disabled={loading}
                id="dd-webhook-event-type"
                onSelect={(eventType) => this.setState({ eventType })}
                title={this.eventTypeName}
                width={220}
              >
                {_.map(this.eventTypes, (event) => (
                  <MenuItem key={event.key} eventKey={event.key} disabled={event.disabled}>
                    {event.name}
                  </MenuItem>
                ))}
              </Dropdown>
            </Setting>

            {eventType && (
              <Setting title="Event Action" subtitle="Specific Action(s) triggering the Webhook">
                <Dropdown
                  disabled={loading}
                  id="dd-webhook-event-subtype"
                  onSelect={(eventSubtype) => this.setState({ eventSubtype })}
                  title={this.eventSubtypeName}
                  width={220}
                >
                  {_.map(this.subtypes, (subtype) => (
                    <MenuItem key={subtype.key} eventKey={subtype.key}>
                      {subtype.name}
                    </MenuItem>
                  ))}
                </Dropdown>
              </Setting>
            )}
            <div style={{ border: '1px solid #EFEFEF', margin: '30px 0', padding: 20 }}>{body}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="spacer" />
          <Button onClick={this.doneTesting} disabled={loading}>
            Done
          </Button>
          <Button dmpStyle="primary" onClick={this.runTest} disabled={loading}>
            Test
          </Button>
        </Modal.Footer>
        ;
      </Modal>
    );
  }
}
