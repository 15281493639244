import Deal, { DEAL_TYPE } from './Deal';
import PDFDeal from './PDFDeal';

export default class DealFactory {
  static create(json) {
    switch (json.dealType) {
      case DEAL_TYPE.EXTERNAL:
      case DEAL_TYPE.EXTERNAL_WORD:
        return new PDFDeal(json);
      case DEAL_TYPE.INGESTED:
      case DEAL_TYPE.BESPOKE:
      case DEAL_TYPE.NATIVE:
      default:
        // If there are versions defined, it means we'll be displaying the current (latest) version
        // So load as PDFDeal
        if (json.versions) {
          return new PDFDeal(json);
        } else {
          return new Deal(json);
        }
    }
  }
}
