import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Overlay, Popover } from 'react-bootstrap';

import Team from '@core/models/Team';
import { isVine } from '@core/utils/Environment';

import { ButtonClose } from '@components/dmp';

export const DealPanelPropTypes = {
  id: PropTypes.string.isRequired,
  container: PropTypes.object,
  onHide: PropTypes.func,
  show: PropTypes.bool,
  target: PropTypes.object,
  title: PropTypes.node,
  canClose: PropTypes.bool,
  dealTeam: PropTypes.instanceOf(Team),
};

@autoBindMethods
class DealPanel extends Component {
  static defaultProps = {
    container: null,
    onHide: _.noop,
    show: false,
    canClose: true,
    target: null,
    children: null,
    className: null,
  };

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
    ...DealPanelPropTypes,
  };

  render() {
    const { id, children, show, target, container, title, onHide, canClose, className } = this.props;

    return (
      <Overlay
        show={show}
        target={target}
        container={container}
        shouldUpdatePosition
        placement="bottom"
        onHide={onHide}
      >
        <Popover
          id={`pop-${id}`}
          className={cx('deal-panel', { dotvine: isVine }, id, className)}
          data-cy={this.props['data-cy']}
        >
          {(!!title || canClose) && (
            <div className="panel-header">
              {title && (
                <span className="panel-title" data-cy="panel-title">
                  {title}
                </span>
              )}
              {canClose && <ButtonClose className="panel-close" onClick={onHide} data-cy="panel-close" />}
            </div>
          )}

          <div className="panel-body">{children}</div>
        </Popover>
      </Overlay>
    );
  }
}

export default DealPanel;
