import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { ButtonGroup } from 'react-bootstrap';

import { Button } from '@components/dmp';

const TimelineDealHeaderTabs_propTypes = {
  onModeClicked: PropTypes.func.isRequired,
};
export default function TimelineDealHeaderTabs({ onModeClicked }) {
  // NOTE: We set the keys for each tab to valid view modes (see DealView.jsx):
  const tabs = [
    {
      key: 'source',
      text: 'Source',
    },
    {
      key: 'eventdata',
      text: 'Event Data',
    },
    {
      key: 'timeline',
      text: 'Timeline',
    },
  ];

  const [currentTab, setCurrentTab] = useState(tabs[0].key);

  const handleTabClicked = (tabKey) => {
    setCurrentTab(tabKey);
    onModeClicked(tabKey);
  };

  return (
    <ButtonGroup className="panel-tabs">
      {tabs.map((tab) => (
        <Button key={tab.key} dmpStyle="link" active={currentTab === tab.key} onClick={() => handleTabClicked(tab.key)}>
          {tab.text}
        </Button>
      ))}
    </ButtonGroup>
  );
}
TimelineDealHeaderTabs.propTypes = TimelineDealHeaderTabs_propTypes;
