import { filter, forEach } from 'lodash';

import DealAction from '../enums/DealAction';
import DealStatus from '../enums/DealStatus';
import Appendix from './Appendix';

export const SIGNING_RESULT = {
  // Deal is fully executed as a result of this signature
  SUCCESS_DEAL: 'successDeal',
  // User is done signing everywhere they need to sign
  SUCCESS_USER: 'successUser',
  // User successfully signed but there are more places to sign
  USER_PROGRESS: 'userProgress',
  // User cleared one or more signatures
  CLEARED: 'cleared',
  // API.dealCheck failed -- user doesn't have access, or is not signer
  ERROR_ACCESS: 'errorAccess',
  // Could not sign because there were open issues (deal not ready for signing)
  ERROR_ISSUES: 'errorIssues',
  // Could not sign because user had already signed
  ERROR_SIGNED: 'errorSigned',
  // Could not sign because user has not yet viewed contract (in bulk signing context) and it has deviated from template
  ERROR_UNVIEWED: 'errorUnviewed',
};

export default class Signature extends Appendix {
  _signatories = null;
  sigs = {};
  showDates = true;

  constructor(json, deal) {
    super(json, deal);

    //if explicit list of signatories is specified, use that
    if (json.signatories) this._signatories = json.signatories;

    //stored signature data, which is keyed by uid
    if (json.sigs) this.sigs = json.sigs;

    if (json.showDates != null) this.showDates = json.showDates;
  }

  get signatories() {
    let partyIDs = [];
    if (this._signatories) partyIDs = this._signatories.split(',');
    else forEach(this.deal.parties, (p) => partyIDs.push(p.partyID));

    // Now we have an array of partyIDs who should be signers for this SignatureSection
    // However we need an additional filter step to cover a legacy edge case.
    // Before we launched explicit parties (https://trello.com/c/aB554aFa),
    // Deals could go all the way through signing while (mistakenly) having hidden parties present in the data
    // These "dormant" parties wouldn't show up and wouldn't affect Deal flow,
    // because Deal.parties was generated purely from Section content
    // So with the launch of explicit parties, for Deals that have already been signed,
    // we need to again filter out and hide/ignore these these dormant parties
    if (this.deal.info.status == DealStatus.SIGNED.data) {
      partyIDs = filter(partyIDs, (partyID) => {
        const partyUsers = this.deal.getUsersByParty(partyID);
        return partyUsers.length > 0 && partyUsers.length == filter(partyUsers, (u) => this.sigs[u.key] != null).length;
      });
    }

    return partyIDs;
  }

  //prior to late June 2018, signature data used to be attached to DealUsers.
  //for legacy support, we need to see if there is ANY such data; if so, continue this way for this deal
  get legacy() {
    return filter(this.deal.users, 'sig').length > 0;
  }

  get signed() {
    // a signature section with no parties cannot be signed...
    if (!this.signatories.length) return false;

    let signedParties = 0;

    // for each signatory (party), all users in that party must have signed
    this.signatories.map((partyID) => {
      const partyUsers = this.deal.getUsersByParty(partyID);
      if (partyUsers.length > 0 && partyUsers.length == filter(partyUsers, (u) => this.sigs[u.key] != null).length)
        signedParties++;
    });

    return signedParties == this.signatories.length;
  }

  stamp(du, intl) {
    const signed = this.sigs[du.key] != null;
    let actions = [],
      stamp = null;
    if (signed && this.showDates) {
      actions = filter(
        this.deal.activity,
        (a) =>
          ((a.action === DealAction.SIGN && a.message === this.id) || a.action === DealAction.BULK_SIGN) &&
          a.user === du.key
      );
      if (actions.length > 0) {
        stamp = actions.pop();
        if (!intl) intl = Intl.DateTimeFormat().resolvedOptions();
        stamp = stamp.date.toLocaleString(intl.locale, {
          timeZoneName: 'short',
          timeZone: intl.timeZone,
        });
      }
    }
    return stamp;
  }
}
