import React from 'react';

import cx from 'classnames';
import PropTypes from 'prop-types';

import { classNamePrefixer } from '@core/utils/Generators';

const cl = classNamePrefixer('swatch');

const Swatch = ({ color, className }) => {
  return <div className={cx(cl(), className)} style={{ background: color, borderColor: color }} />;
};

Swatch.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired,
};

Swatch.defaultProps = {};

export default Swatch;
