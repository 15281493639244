import React from 'react';

import _ from 'lodash';
import PropTypes from 'prop-types';

import Deal from '@core/models/Deal';
import { ELEMENT_TYPE } from '@core/models/PDFElement';

import { Ellipsis } from '@components/dmp';

const displayElement = (el) => {
  switch (el.elementType) {
    case ELEMENT_TYPE.VARIABLE:
      return `“${el.displayValue}”`;
    case ELEMENT_TYPE.SIGNATURE:
      return `${el.variable} signature`;
    case ELEMENT_TYPE.SIMPLE:
      return el.data || '';
    default:
      return null;
  }
};

const ChangeList = ({ deal, title }) => {
  return (
    <div className="change-list">
      {title && <div className="cl-title">{title}</div>}
      {_.map(deal.changes, (pdfElement, idx) => (
        <div key={idx} className="cl-item">
          <Ellipsis className="cl-change" data-cy="cl-change">
            {displayElement(pdfElement)}
          </Ellipsis>
          <div className="cl-page">Pg. {pdfElement.page + 1}</div>
        </div>
      ))}
    </div>
  );
};

ChangeList.propTypes = {
  deal: PropTypes.instanceOf(Deal).isRequired,
  title: PropTypes.string,
};

export default ChangeList;
