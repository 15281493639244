import { forEach } from 'lodash';

import DealInfo from './DealInfo';

// This is a dynamically generated class which represents a collection of linked Deals
export default class Bundle {
  parent = null;
  children = [];

  constructor(parentRaw, childrenMeta) {
    this.parent = new DealInfo(parentRaw);
    forEach(childrenMeta, (child) => {
      this.children.push({
        parentTitle: child.parentTitle,
        dealInfo: new DealInfo(child.dealInfo),
      });
    });
  }
}
