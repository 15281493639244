import React, { Component } from 'react';

import { ControlLabel, FormControl } from 'react-bootstrap';

import { getParameterByName, passwordValidated } from '@core/utils/Validation';

import { Button, Loader } from '@components/dmp';

import Preloader from '@components/Preloader';
import Auth from '@root/Auth';

export default class UserAuth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'verifyEmail', //verifyEmail is default mode
      loading: false,
      status: null,
      verified: false,
      email: null,
      pw: '',
      error: null,
      verifiedPW: '',
      matched: false,
      isStrong: false,
    };
  }

  componentDidMount() {
    const { og } = this.props;
    og({ title: `Outlaw - Manage your account` });

    this.processLink();
  }

  UNSAFE_componentWillReceiveProps() {
    this.processLink();
  }

  cmonIn() {
    //don't use router. this will force reboot of the app, including reset of firebase auth
    //this is necessary because authState doesn't change when email is verified
    //so this way we get the latest auth object with emailVerified and user is allowed in
    window.location = `${window.location.origin}/dashboard`;
  }

  //https://firebase.google.com/docs/auth/custom-email-handler
  //http://localhost:5000/auth?mode=verifyEmail&oobCode=vO-BN404JGz4KhCaf39HIw5Gh8vf6b3rK2FU9fWdyOUAAAFhyPJMYw
  processLink() {
    const mode = getParameterByName('mode');
    const actionCode = getParameterByName('oobCode');
    const { processing, verified } = this.state;

    //this will happen on deep link
    //need to wait until auth happens before attempting to process
    // if (actionCode && mode == 'verifyEmail' && !account) return;

    //do nothing if action is in progress or already taken
    if (processing || verified) return;

    switch (mode) {
      case 'resetPassword':
        // Display reset password handler and UI.
        this.setState({ processing: true });
        Auth.verifyPasswordResetCode(actionCode)
          .then((email) => {
            this.setState({ mode: 'resetPassword', processing: false, email });
          })
          .catch((error) => {
            // console.log(error);
            this.setState({ mode: 'resetPassword', processing: false, error });
          });
        break;
      case 'verifyEmail':
        // Display email verification handler and UI.
        this.setState({ processing: true });
        Auth.handleVerifyEmail(actionCode)
          .then(() => {
            //show success state
            this.setState({ verified: true, processing: false, error: null });
          })
          .catch((error) => {
            console.log(error);
            this.setState({ verified: false, processing: false, error });
          });
        break;
      default:
        // Error: invalid mode.
        break;
    }
  }

  resend() {
    const user = this.props.account;
    if (!user) return; //should be impossible
    this.setState({ loading: true, status: null });
    Auth.verify(user).then(() => this.setState({ loading: false, status: 'Sent! Check your email again.' }));
  }

  savePassword() {
    const actionCode = getParameterByName('oobCode');
    const { email, pw } = this.state;
    if (!actionCode || !pw) return;

    this.setState({ loading: true });
    Auth.changePassword(
      actionCode,
      email,
      pw,
      () => {
        this.props.history.push(`/login?resetSuccess=true`);
      },
      (err) => {
        console.log(err);
        this.setState({ loading: false });
      }
    );
  }

  verifyPassword(e) {
    const { pw } = this.state;

    this.setState({ verifiedPW: e.target.value });

    const passwordCheck = passwordValidated(e.target.value);
    console.log('mEG', passwordCheck);
    this.setState({ isStrong: passwordCheck });
    this.setState({ matched: pw === e.target.value });
  }

  setPassword(e) {
    const { verifiedPW } = this.state;

    this.setState({ pw: e.target.value });
    this.setState({ matched: verifiedPW === e.target.value });
  }

  render() {
    const { loading, status, verified, mode, isStrong, error, email, verifiedPW, matched } = this.state;
    const { user, account } = this.props;
    const actionCode = getParameterByName('oobCode');

    //initial preloader state during processing
    if (actionCode && mode === 'verifyEmail' && !verified && !error) return <Preloader />;

    return (
      <main className="user-auth flex-middle-outer">
        {mode === 'verifyEmail' && (
          <div className="action confirm">
            <img className="logo" src="/assets/static/graphic-logo-color-2x.png" alt="Home" />
            <h3>{verified ? 'Email verified!' : 'Almost there!'}</h3>

            {
              verified || (account && account.emailVerified) ? (
                <div>
                  <p>
                    Thank you for verifying your email address.
                    <br />
                  </p>
                  <Button size="large" onClick={() => this.cmonIn()}>
                    Go make deals
                  </Button>
                </div>
              ) : error ? (
                <div>
                  <p>
                    Hm, there was a problem verifying your email address.
                    <br />
                    <a onClick={() => this.resend()}>Send another link</a> or{' '}
                    <a onClick={() => Auth.logout()}>log out</a>.
                  </p>
                </div>
              ) : user ? (
                <div>
                  <p>
                    We sent a verification link to <span>{user.email}</span>.<br />
                    Can't find it? <a onClick={() => this.resend()}>Send another</a> or{' '}
                    <a onClick={() => Auth.logout()}>log out</a>.
                  </p>
                </div>
              ) : null /*User not logged in and there's no action code */
            }
          </div>
        )}

        {mode === 'resetPassword' && (
          <div className="action reset-password" data-cy="action-reset-password">
            <img className="logo" src="/assets/static/graphic-logo-color-2x.png" alt="Home" />
            <h3>{error ? 'Reset link expired' : 'Reset your password'}</h3>
            <small>{!error ? `Create a new password for ${email}` : null}</small>

            {error ? (
              <div className="invalidLink">
                <p className="contents">There's something wrong with that password reset link.</p>
                <Button size="default" onClick={() => this.props.history.push('/forgot')}>
                  Request another
                </Button>
              </div>
            ) : (
              <div className="form">
                <div className="contents">
                  <ControlLabel>Password</ControlLabel>
                  <FormControl
                    className="password"
                    type="password"
                    value={this.state.pw}
                    placeholder="New password"
                    onChange={(e) => this.setPassword(e)}
                    data-cy="new-password"
                  />
                </div>
                <div className="contents">
                  <ControlLabel>Re-Enter Password</ControlLabel>
                  <FormControl
                    className="password"
                    type="password"
                    value={this.state.verifiedPW}
                    placeholder="Re-Enter new password"
                    onChange={(e) => this.verifyPassword(e)}
                    onKeyDown={(e) => (e.key === 'Enter' ? this.savePassword() : null)}
                    data-cy="re-enter-new-password"
                  />
                </div>
                {verifiedPW.length > 0 && (
                  <div>
                    <small className={matched ? 'match' : 'no-match'} data-cy="match-password">
                      {matched ? 'Passwords match' : 'Passwords do not match'}{' '}
                    </small>
                  </div>
                )}

                {verifiedPW.length > 0 && (
                  <div>
                    <small className={isStrong ? 'match' : 'no-match'} data-cy="strong-password">
                      {isStrong
                        ? 'Strong password'
                        : 'Password must be at least 8 characters and include one uppercase and lowercase letter, one number, and one special character (!@#$%^&*()\\-_+.)'}{' '}
                    </small>
                  </div>
                )}

                <Button
                  size="large"
                  onClick={() => this.savePassword()}
                  disabled={loading || !isStrong || !matched}
                  data-cy="btn-save-new-password"
                >
                  Save
                </Button>
              </div>
            )}
          </div>
        )}

        {(loading || status) && (
          <div className="status">
            {loading && <Loader />}
            {status && <span>{status}</span>}
          </div>
        )}
      </main>
    );
  }
}
