import React from 'react';

import { ButtonIcon, MenuItem, ModalConfirm } from '@components/dmp';

const LeaveDeal = ({ onHide, onConfirm, show = true }) => (
  <ModalConfirm
    show={show}
    onHide={onHide}
    onConfirm={onConfirm}
    confirmText="Leave contract"
    cancelText="Cancel"
    title="Confirm contract departure"
    body="Are you sure you want to leave this contract?"
  />
);

export default LeaveDeal;
