import React, { useEffect, useState } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';

import Deal, { ASPECTS } from '@core/models/Deal';
import { arrayToBase64, base64ToArray, generateExportURL } from '@core/utils/Generators';

import { Button } from '@components/dmp';

import useKeySequence from '@components/timevine/hooks/useKeySequence';
import API from '@root/ApiClient';
import Fire from '@root/Fire';
import SourceViewVirtual from '@routes/SourceViewVirtual';

const MegaView_propTypes = {
  deal: PropTypes.instanceOf(Deal).isRequired,
  source: PropTypes.array.isRequired,
  container: PropTypes.object.isRequired,
};
export default function MegaView({ deal, source, container }) {
  const sourcePaneColor = 'hsl(0, 0%, 30%)';
  const pdfPaneColor = 'hsl(0, 60%, 60%)';
  const docxPaneColor = 'hsl(205, 100%, 40%)';

  const paneMinWidth = 816 + 50;

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (visible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [visible]);

  useKeySequence(['d', 'd', 'd', 'p'], () => {
    setVisible(true);
  });

  const handleCloseClick = () => {
    setVisible(false);
  };

  return !visible ? null : (
    <div
      style={{
        zIndex: 99999,
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,

        backgroundColor: 'hsl(205, 50%, 20%)',
        padding: 10,

        display: 'flex',
        flexDirection: 'column',

        color: 'white',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 10 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <span
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            MegaView
          </span>
        </div>

        <div style={{ flexGrow: 1 }}></div>
        <Button size="small" onClick={handleCloseClick}>
          Close
        </Button>
      </div>
      <div style={{ flexGrow: 1, display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 10 }}>
        <div
          style={{
            minWidth: paneMinWidth,
            backgroundColor: sourcePaneColor,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <PaneTitle>Native View</PaneTitle>
          <NativeView deal={deal} source={source} container={container} />
        </div>
        <div
          style={{
            minWidth: paneMinWidth,
            backgroundColor: pdfPaneColor,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <PaneTitle>PDF Preview</PaneTitle>
          <PdfView deal={deal} />
        </div>
        <div
          style={{
            minWidth: paneMinWidth,
            backgroundColor: docxPaneColor,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <PaneTitle>DOCX Preview</PaneTitle>
          <DocxView deal={deal} />
        </div>
      </div>
    </div>
  );
}
MegaView.propTypes = MegaView_propTypes;

function PaneTitle({ children }) {
  return <span style={{ fontWeight: 'bold', padding: '5px' }}>{children}</span>;
}

const NativeView_propTypes = {
  deal: PropTypes.instanceOf(Deal).isRequired,
  source: PropTypes.array.isRequired,
  container: PropTypes.object.isRequired,
};
function NativeView({ deal, source, container }) {
  // NOTE: I empirically determined (by direct measurement) this marginTop value in order to make native
  // view document line up with the PDF preview. This was done on Chrome browser on a Mac, and could vary
  // for other setups.
  const marginTop = '3px';

  // TODO: The 90px hardcoded offset in the height calc below will need to be manually updated if we change
  // the header layout at all. In future could auto-compute this using some JS, but not worth it yet.
  return (
    <div style={{ backgroundColor: 'black', height: 'calc(100vh - 90px)', overflowY: 'auto' }}>
      <div className="outer-paper-layout" style={{ marginTop }}>
        <SourceViewVirtual deal={deal} source={source} container={container} overscanRowCount={500} readonly />;
      </div>
    </div>
  );
}
NativeView.propTypes = NativeView_propTypes;

const PdfView_propTypes = {
  deal: PropTypes.instanceOf(Deal).isRequired,
};
function PdfView({ deal }) {
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    const doit = async () => {
      const token = await Fire.token();
      const aspects = [ASPECTS.CONTRACT];
      const options = { aspects: aspects.join(','), emptyBlocks: false };
      const url = await generateExportURL({ deal, token, type: 'pdf', options });
      const blob = await axios.get(url, { responseType: 'blob' });
      const file = new Blob([blob.data], { type: 'application/pdf' });
      setPreviewUrl(URL.createObjectURL(file));
    };
    doit();
  }, []);

  return !previewUrl ? null : (
    <object style={{ flexGrow: 1 }} data={`${previewUrl}#toolbar=0&navpanes=0`} type="application/pdf" />
  );
}
PdfView.propTypes = PdfView_propTypes;

const DocxView_propTypes = {
  deal: PropTypes.instanceOf(Deal).isRequired,
};
function DocxView({ deal }) {
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    const doit = async () => {
      const token = await Fire.token();
      const aspects = [ASPECTS.CONTRACT];
      const options = { aspects: aspects.join(','), emptyBlocks: false };
      const url = await generateExportURL({ deal, token, type: 'docx', options });
      const docx_res = await axios.get(url, { responseType: 'blob' });
      const docx_blob = docx_res.data;
      const docx_array = new Uint8Array(await docx_blob.arrayBuffer());
      const docx_b64 = arrayToBase64(docx_array);
      const pdf_b64 = await API.call('convertDOCXtoPDF', { docx: docx_b64 });
      const pdf_bytes = base64ToArray(pdf_b64);
      const pdf_blob = new Blob([pdf_bytes], { type: 'application/pdf' });
      setPreviewUrl(URL.createObjectURL(pdf_blob));
    };
    doit();
  }, []);

  return !previewUrl ? null : (
    <object style={{ flexGrow: 1 }} data={`${previewUrl}#toolbar=0&navpanes=0`} type="application/pdf" />
  );
}
DocxView.propTypes = DocxView_propTypes;
